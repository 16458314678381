import React from 'react';
import { categorizedPermissions } from '../../utils/constants/permissions';

const CheckboxTable = ({ rolePermissions, handleCheckboxChange }) => {
    return (
        <div className="mb-4">
            {categorizedPermissions.map((categoryGroup) => (
                <div key={categoryGroup.category} className="mb-6">
                    <h3 className="text-lg font-semibold mb-2">{categoryGroup.category}</h3>
                    <div className="flex w-full overflow-x-auto">
                        <table className="min-w-full border border-gray-300">
                            <thead>
                                <tr>
                                    <th className="border-b px-4 py-2 text-black text-[14px]">Action</th>
                                    {categoryGroup.permissions.map((permission) => (
                                        <th key={permission.key} className="text-start text-[14px] text-gray-600 border border-gray-200 px-4 py-2">
                                            {permission.label.split(' ')[0]}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border-b px-4 py-2">Permissions</td>
                                    {categoryGroup.permissions.map((permission) => (
                                        <td key={permission.key} className="border-b px-4 py-2">
                                            <input
                                                type="checkbox"
                                                id={permission.key}
                                                checked={rolePermissions[permission.key] || false}
                                                onChange={() => handleCheckboxChange(permission.key)}
                                                className="form-checkbox h-5 w-5 text-secondary transition duration-150 ease-in-out focus:ring focus:ring-none rounded-md"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CheckboxTable;
