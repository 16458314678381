import React, { useEffect } from 'react';
import CheckboxTable from './CheckboxTable';
import { categorizedPermissions } from '../../utils/constants/permissions';

const CreateRoleForm = ({ roleTitle, setRoleTitle, rolePermissions, setRolePermissions, selectAll, setSelectAll, onSubmit }) => {

    useEffect(() => {
        const initialPermissions = categorizedPermissions.reduce((acc, categoryGroup) => {
            categoryGroup.permissions.forEach((permission) => {
                acc[permission.key] = false;
            });
            return acc;
        }, {});
        setRolePermissions(initialPermissions);
    }, [setRolePermissions]);

    const handleCheckboxChange = (key) => {
        setRolePermissions((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const handleSelectAllChange = () => {
        const newState = !selectAll;
        setSelectAll(newState);
        const allPermissions = categorizedPermissions.reduce((acc, categoryGroup) => {
            categoryGroup.permissions.forEach((permission) => {
                acc[permission.key] = newState;
            });
            return acc;
        }, {});
        setRolePermissions(allPermissions);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            title: roleTitle,
            ...rolePermissions,
        };
        onSubmit(payload);
    };

    return (
        <div>
            <h2 className="text-xl font-semibold mb-2">Create User Role</h2>
            <form onSubmit={handleSubmit} className="bg-white p-6 shadow-md rounded-lg mb-6">
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1">Role Title</label>
                    <input
                        type="text"
                        value={roleTitle}
                        onChange={(e) => setRoleTitle(e.target.value)}
                        className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        placeholder="Enter role title"
                        required
                    />
                </div>

                <div className="mb-4">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="selectAll"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                            className="mr-2"
                        />
                        <label htmlFor="selectAll" className="text-gray-700 font-bold">Select All</label>
                    </div>
                </div>

                <CheckboxTable rolePermissions={rolePermissions} handleCheckboxChange={handleCheckboxChange} />

                <div className="flex justify-end">
                    <button type="submit" className="bg-secondary text-[14px] font-medium text-white py-1.5 px-6 rounded-md">
                        Create Role
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateRoleForm;
