import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { createUserRole, fetchRoles, assignRoleToUser, fetchUsers } from '../../utils/api/api';
import Tabs from './Tabs';
import CreateRoleForm from './CreateRoleForm';
import AssignRoleForm from './AssignRoleForm';
import ExistingRolesList from './ExistingRolesList';
import UsersList from './UsersList';

const UserManagement = () => {
  const [roleTitle, setRoleTitle] = useState('');
  const [rolePermissions, setRolePermissions] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [activeTab, setActiveTab] = useState('createRole');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rolesData = await fetchRoles();
        setRoles(rolesData.userRoles);
        const usersData = await fetchUsers();
        setUsers(usersData.users);
      } catch (error) {
        console.error('Error fetching roles or users:', error);
      }
    };
    fetchData();
  }, []);

  const handleRoleCreation = async (payload) => {
    try {
      await createUserRole(payload);
      toast.success('User role created successfully!');
      const rolesData = await fetchRoles();
      setRoles(rolesData.userRoles);
      setRoleTitle('');
      setRolePermissions({});
      setSelectAll(false);
    } catch (error) {
      toast.error(error.message || 'Failed to create user role');
      console.error('Error:', error);
    }
  };

  const handleRoleAssignment = async () => {
    try {
      await assignRoleToUser(selectedUser, selectedRole);
      toast.success('Role assigned to user successfully!');
    } catch (error) {
      toast.error('Failed to assign role to user');
      console.error('Error:', error);
    }
  };

  return (
    <div className="w-full flex flex-col mx-auto">
      <h1 className="text-2xl font-bold mb-4">User Management</h1>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === 'createRole' && (
        <CreateRoleForm
          roleTitle={roleTitle}
          setRoleTitle={setRoleTitle}
          rolePermissions={rolePermissions}
          setRolePermissions={setRolePermissions}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          onSubmit={handleRoleCreation}
        />
      )}

      {activeTab === 'assignRole' && (
        <AssignRoleForm
          users={users}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          roles={roles}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          onSubmit={handleRoleAssignment}
        />
      )}

      {activeTab === 'existingRoles' && <ExistingRolesList roles={roles} />}
      {activeTab === 'users' && <UsersList users={users} setUsers={setUsers} />}
    </div>
  );
};

export default UserManagement;
