import React, { useEffect, useState } from 'react';
import Layout from '../../elements/Layout';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getSingleEmployee } from '../../utils/api/api';
import { FaAngleDown, FaAngleUp, FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import Loading from '../../elements/Loading';
import { FaLocationDot } from 'react-icons/fa6';

const ViewEmployee = () => {
  const [employee, setEmployee] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('education');
  const [isManagerCollapsed, setIsManagerCollapsed] = useState(true);
  const [isReportsCollapsed, setIsReportsCollapsed] = useState(true);
  const [isContactCollapsed, setIsContactCollapsed] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const getEmployee = async () => {
      setLoading(true);
      try {
        const data = await getSingleEmployee(id);
        setEmployee(data.employee);
        setLoading(false);
      } catch (err) {
        toast.error('Request failed.');
        console.log(err);
        setLoading(false);
      }
    };

    getEmployee();
  }, [id]);

  const renderContent = () => {
    if (activeTab === 'education') {
      return (
        <div
          className="prose"
          dangerouslySetInnerHTML={{ __html: employee.education }}
        />
      );
    } else if (activeTab === 'achievements') {
      return (
        <div
          className="prose"
          dangerouslySetInnerHTML={{ __html: employee.achievements }}
        />
      );
    } else if (activeTab === 'skills') {
      const skills = employee.skills?.split(',') || []; 
      return (
        <div className="flex flex-wrap gap-2">
          {skills.length > 0 ? (
            skills.map((skill, index) => (
              <span
                key={index}
                className="bg-gray-200 px-3 py-1.5 rounded-full text-black text-[14px]">
                {skill.trim()}
              </span>
            ))
          ) : (
            <p className="text-gray-600">No skills listed</p>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <Layout text="Employee">
      {loading && <Loading />}
      <div className="flex w-full flex-col gap-4">
        <div className="gap-6 flex flex-col md:flex-row w-full md:bg-gray-50 pr-4 py-4 rounded-lg border-b border-gray-200">
          <div className="shadow-md md:-mb-24 bg-white w-full md:w-[30%] h-auto p-4 rounded-lg flex flex-col">
            <img src={employee?.photo} className="rounded-md" alt="" />
            <div className="flex mt-6 px-4 justify-between">
              <p className="w-[50%] text-black font-light text-[15px]">{employee.job}</p>
              <div className="bg-gray-200 h-full w-[2px]"></div>
              <p className="w-[50%] text-end text-black font-light text-[15px]">{employee.department}</p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-md md:bg-transparent md:p-0 flex w-full md:w-[70%] items-center justify-between">
            <div className="w-fit flex flex-col">
              <p className="mt-2 text-[22px] font-semibold text-primary">{employee.full_name}</p>
              <button className="w-fit bg-primary px-6 py-1.5 rounded-md text-white text-[14px] font-medium">
                {employee.job}
              </button>
              <p className="text-black font-light text-[15px]">Hired: {employee.hire_date}</p>
              <p className="text-gray-700 text-[13px]">{employee.bio}</p>
            </div>
            <button className="px-6 py-1.5 rounded-md text-white text-[14px] font-medium bg-red-500 ">Delete</button>
          </div>
        </div>
        <div className="w-full flex justify-end">
          <div className="w-full md:w-[68.5%] gap-2 md:gap-6 flex flex-col md:flex-row justify-between">
            <div className="w-full bg-primary p-4 rounded-md flex flex-col">
              <p className="text-white text-[13px]">Gender</p>
              <p className="text-white font-medium text-[15px] capitalize">{employee.gender}</p>
            </div>
            <div className="w-full bg-red-500 p-4 rounded-md flex flex-col">
              <p className="text-white text-[13px]">D.O.B</p>
              <p className="text-white font-medium text-[15px] capitalize">{employee.dob}</p>
            </div>
            <div className="w-full bg-secondary p-4 rounded-md flex flex-col">
              <p className="text-white text-[13px]">Nationality</p>
              <p className="text-white font-medium text-[15px] capitalize">{employee.nationality}</p>
            </div>
          </div>
        </div>
        <div className="mt-4 w-full flex flex-col md:flex-row gap-6">
          <div className="bg-white shadow-sm rounded-lg flex flex-col h-fit p-4 w-full md:w-[30%] ">
            <p className="text-black font-medium text-[20px] border-b border-primary">About</p>
            {/* Manager section */}
            <div>
              <p
                className="text-black font-medium text-[15px] mt-4 cursor-pointer flex items-center w-full justify-between"
                onClick={() => setIsManagerCollapsed(!isManagerCollapsed)}
              >
                Manager {isManagerCollapsed ? <FaAngleDown /> : <FaAngleUp />}
              </p>
              {!isManagerCollapsed && (
                <div>
                  <div className="flex items-center gap-2 mt-2 w-full justify-between">
                    <span className="text-black text-[13px]">Name</span>
                    <p className="text-[12px] text-black ">{employee?.manager?.full_name}</p>
                  </div>
                  <div className="flex items-center gap-2 mt-1 w-full justify-between">
                    <span className="text-black text-[13px]">Email</span>
                    <p className="text-[12px] text-black ">{employee?.manager?.email}</p>
                  </div>
                  <div className="flex items-center gap-2 mt-1 w-full justify-between">
                    <span className="text-black text-[13px]">Phone</span>
                    <p className="text-[12px] text-black ">{employee?.manager?.phone_number}</p>
                  </div>
                </div>
              )}
            </div>

            {/* Reports section */}
            <div>
              <p
                className="text-black font-medium text-[15px] mt-4 cursor-pointer flex items-center w-full justify-between"
                onClick={() => setIsReportsCollapsed(!isReportsCollapsed)}
              >
                Reports {isReportsCollapsed ? <FaAngleDown /> : <FaAngleUp />}
              </p>
              {!isReportsCollapsed && (
                <div>
                  {employee?.reports?.length > 0 ? (
                    employee.reports.map((report, index) => (
                      <div key={index} className="mt-2">
                        <div className="flex items-center gap-2 w-full justify-between">
                          <span className="text-black text-[13px]">Name:</span>
                          <p className="text-[12px] text-black">{report.full_name}</p>
                        </div>
                        <div className="flex items-center gap-2 mt-1 w-full justify-between">
                          <span className="text-black text-[13px]">Email:</span>
                          <p className="text-[12px] text-black">{report.email}</p>
                        </div>
                        <div className="flex items-center gap-2 mt-1 w-full justify-between">
                          <span className="text-black text-[13px]">Phone:</span>
                          <p className="text-[12px] text-black">{report.phone_number}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-[14px] text-gray-500">No reports available</p>
                  )}
                </div>
              )}
            </div>

            {/* Contact section */}
            <div>
              <p
                className="text-black font-medium text-[15px] mt-4 cursor-pointer flex items-center w-full justify-between"
                onClick={() => setIsContactCollapsed(!isContactCollapsed)}
              >
                Contact {isContactCollapsed ? <FaAngleDown /> : <FaAngleUp />}
              </p>
              {!isContactCollapsed && (
                <div>
                  <div className="flex items-center gap-2 mt-2">
                    <FaPhoneAlt size={20} />
                    <p className="text-[12px] text-black ">{employee.phone_number}</p>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <MdEmail size={20} />
                    <p className="text-[12px] text-black ">{employee.email}</p>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <FaLocationDot size={20} />
                    <p className="text-[12px] text-black ">{employee.office_location}</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex w-full h-fit flex-col md:w-[70%] p-4 bg-white rounded-lg">
            <div className="flex w-fit bg-gray-200 rounded-md">
              <button
                className={`px-6 py-1.5 transition-colors duration-300 rounded-md ${activeTab === 'education'
                  ? 'bg-secondary text-white'
                  : 'text-gray-600 hover:bg-gray-300'
                  }`}
                onClick={() => setActiveTab('education')}
              >
                Education
              </button>
              <button
                className={`px-6 py-1.5 transition-colors duration-300 rounded-md ${activeTab === 'achievements'
                  ? 'bg-secondary text-white'
                  : 'text-gray-600 hover:bg-gray-300'
                  }`}
                onClick={() => setActiveTab('achievements')}
              >
                Achievements
              </button>
              <button
                className={`px-6 py-1.5 transition-colors duration-300 rounded-md ${activeTab === 'skills'
                  ? 'bg-secondary text-white'
                  : 'text-gray-600 hover:bg-gray-300'
                  }`}
                onClick={() => setActiveTab('skills')}
              >
                Skills
              </button>
            </div>
            <div className="w-full mt-6">{renderContent()}</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ViewEmployee;
