import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const InvoiceAmountBarChart = ({ monthlyInvoiceData }) => {
    const labels = monthlyInvoiceData.map(item => item.month);
    const dataPoints = monthlyInvoiceData.map(item => parseFloat(item.totalAmount));

    const data = {
        labels,
        datasets: [
            {
                label: 'Total Invoice Amount',
                data: dataPoints,
                backgroundColor: '#F8A81B',
                borderColor: '#F8A81B',
                borderWidth: 1,
                hoverBackgroundColor: '#F8A81B',
                hoverBorderColor: '#F8A81B',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#000',
                },
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    color: '#000',
                },
                grid: {
                    borderDash: [5, 5],
                    color: '#f8f8f8',
                },
            },
        },
    };

    return (
        <div className="bg-white p-4 rounded-lg">
            <h2 className="text-xl font-semibold text-black mb-4">Monthly Invoice Amounts</h2>
            <Bar data={data} options={options} />
        </div>
    );
};

export default InvoiceAmountBarChart;
