import React from 'react';

const ConfirmationModal = ({ onConfirm, onCancel }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70">
            <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-sm">
                <h2 className="text-[20px] border-b border-gray-200 font-medium text-gray-800">Confirm Deletion</h2>
                <p className="mt-2 text-[14px] text-gray-600">This action is irreversible. Are you sure you want to delete this interaction?</p>
                <div className="mt-6 flex justify-end gap-4">
                    <button
                        className="text-secondary font-medium text-[14px]"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-6 py-1.5 text-[14px] bg-secondary text-white rounded-md font-medium transition-colors"
                        onClick={onConfirm}
                    >
                        Delete
                    </button>                   
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
