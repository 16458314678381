import React, { useEffect, useState } from 'react'
import { fetchInvoiceReport } from '../../utils/api/api';
import Layout from '../../elements/Layout';
import PaymentFrequencyChart from './PaymentFrequency';
import InvoiceAmountBarChart from './InvoiceAmountBarChart';
import InvoiceStatusPieChart from './InvoiceStatusPieChart';
import InvoiceList from './InvoiceList';
import toast from 'react-hot-toast';

const InvoiceReports = () => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(false);

    const getSales = async () => {
        setLoading(true)
        try {
            const response = await fetchInvoiceReport();
            setReports(response.invoice_reports);
        } catch (error) {
            toast.error("Failed to get report")
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getSales();
    }, [])

    const monthlyPaymentFrequency = [
        { month: 'January', frequency: 12 },
        { month: 'February', frequency: 19 },
        { month: 'March', frequency: 11 },
        { month: 'April', frequency: 16 },
        { month: 'May', frequency: 14 },
        { month: 'June', frequency: 22 },
        { month: 'July', frequency: 24 },
        { month: 'August', frequency: 27 },
        { month: 'September', frequency: 21 },
        { month: 'October', frequency: 23 },
        { month: 'November', frequency: 18 },
        { month: 'December', frequency: 20 },
    ];

    const monthlyInvoiceData = [
        { month: 'January', totalAmount: 12000 },
        { month: 'February', totalAmount: 9000 },
        { month: 'March', totalAmount: 15000 },
        { month: 'April', totalAmount: 8000 },
        { month: 'May', totalAmount: 20000 },
        { month: 'June', totalAmount: 11000 },
        { month: 'July', totalAmount: 17000 },
        { month: 'August', totalAmount: 19000 },
        { month: 'September', totalAmount: 13000 },
        { month: 'October', totalAmount: 22000 },
        { month: 'November', totalAmount: 16000 },
        { month: 'December', totalAmount: 14000 },
    ];

    const invoiceStatusData = [50, 20, 10, 6];

  return (
    <Layout text="Invoice report">
        <div className="flex w-full flex-col gap-4 mb-4 md:flex-row">
            <div className="w-full md:w-[60%]">
                  <PaymentFrequencyChart monthlyPaymentFrequency={monthlyPaymentFrequency} />
            </div>
              <div className="w-full md:w-[40%]">
                  <InvoiceStatusPieChart invoiceStatusData={invoiceStatusData} />
              </div>
        </div>
          <div className="flex w-full flex-col gap-4 mb-4 md:flex-row">
              <div className="w-full">
                  <InvoiceAmountBarChart monthlyInvoiceData={monthlyInvoiceData} />
            </div>            
        </div>
        <div className="">
            <InvoiceList />
        </div>
    </Layout>
  )
}

export default InvoiceReports