export const messages = [
    {
        image: 'https://images.unsplash.com/photo-1445053023192-8d45cb66099d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cGVyc29ufGVufDB8fDB8fHww',
        name: 'John Doe',
        message: 'I have a question about my recent order.',
        date: '2024-08-15',
        status: 'pending',
        time: '14:32'
    },
    {
        image: 'https://images.unsplash.com/photo-1445053023192-8d45cb66099d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cGVyc29ufGVufDB8fDB8fHww',
        name: 'Jane Smith',
        message: 'Can you provide an update on my delivery?',
        date: '2024-08-14',
        status: 'answered',
        time: '10:15'
    },
    {
        image: 'https://images.unsplash.com/photo-1541190990694-4a612732721c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Michael Brown',
        message: 'I need help with my account settings.',
        date: '2024-08-13',
        status: 'pending',
        time: '09:45'
    },
    {
        image: 'https://images.unsplash.com/photo-1521566652839-697aa473761a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Emily Davis',
        message: 'What are your store hours?',
        date: '2024-08-12',
        status: 'answered',
        time: '16:20'
    },
    {
        image: 'https://images.unsplash.com/photo-1444720895098-cbd6b640c909?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Daniel Wilson',
        message: 'Can I return an item I purchased?',
        date: '2024-08-11',
        status: 'answered',
        time: '11:50'
    },
    {
        image: 'https://images.unsplash.com/photo-1450297549355-bfa4ef391427?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Olivia Johnson',
        message: 'I received the wrong item in my order.',
        date: '2024-08-10',
        status: 'pending',
        time: '13:05'
    },
    {
        image: 'https://images.unsplash.com/photo-1529665730773-4f3fda31a5f9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Liam Martinez',
        message: 'How do I track my order?',
        date: '2024-08-09',
        status: 'answered',
        time: '08:30'
    },
    {
        image: 'https://images.unsplash.com/photo-1517364350421-1d6871888e1b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzl8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Sophia Anderson',
        message: 'I need to update my shipping address.',
        date: '2024-08-08',
        status: 'pending',
        time: '15:10'
    },
    {
        image: 'https://images.unsplash.com/photo-1533748430324-45f466e36937?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzR8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Ethan Thomas',
        message: 'Can you assist with payment issues?',
        date: '2024-08-07',
        status: 'answered',
        time: '12:00'
    },
    {
        image: 'https://images.unsplash.com/36/xIsiRLngSRWN02yA2BbK_submission-photo-7.jpg?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjR8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Ava Moore',
        message: 'What are your return policies?',
        date: '2024-08-06',
        status: 'pending',
        time: '17:25'
    },
];

export const customers = [
    {
        id: 10001,
        image: 'https://images.unsplash.com/photo-1445053023192-8d45cb66099d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cGVyc29ufGVufDB8fDB8fHww',
        name: 'John Doe',
        phone: '+254 700 123456',
        email: 'john.doe@example.com',
        location: 'Nairobi, Kenya',
        lastOrder: '2024-08-15',
        orderCount: 5,
        status: 'active',
    },
    {
        id: 10002,
        image: 'https://images.unsplash.com/photo-1445053023192-8d45cb66099d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cGVyc29ufGVufDB8fDB8fHww',
        name: 'Jane Wambui',
        phone: '+254 722 234567',
        email: 'jane.wambui@example.com',
        location: 'Mombasa, Kenya',
        lastOrder: '2024-08-10',
        orderCount: 3,
        status: 'inactive',
    },
    {
        id: 10003,
        image: 'https://images.unsplash.com/photo-1541190990694-4a612732721c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Michael Otieno',
        phone: '+254 733 345678',
        email: 'michael.otieno@example.com',
        location: 'Kisumu, Kenya',
        lastOrder: '2024-08-17',
        orderCount: 7,
        status: 'active',
    },
    {
        id: 10004,
        image: 'https://images.unsplash.com/photo-1521566652839-697aa473761a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Emily Njeri',
        phone: '+254 711 456789',
        email: 'emily.njeri@example.com',
        location: 'Nakuru, Kenya',
        lastOrder: '2024-08-14',
        orderCount: 2,
        status: 'inactive',
    },
    {
        id: 10005,
        image: 'https://images.unsplash.com/photo-1444720895098-cbd6b640c909?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Daniel Kimani',
        phone: '+254 722 567890',
        email: 'daniel.kimani@example.com',
        location: 'Eldoret, Kenya',
        lastOrder: '2024-08-12',
        orderCount: 4,
        status: 'active',
    },
    {
        id: 10006,
        image: 'https://images.unsplash.com/photo-1450297549355-bfa4ef391427?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Olivia Mwende',
        phone: '+254 710 678901',
        email: 'olivia.mwende@example.com',
        location: 'Machakos, Kenya',
        lastOrder: '2024-08-11',
        orderCount: 6,
        status: 'inactive',
    },
    {
        id: 10007,
        image: 'https://images.unsplash.com/photo-1529665730773-4f3fda31a5f9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Liam Ouma',
        phone: '+254 733 789012',
        email: 'liam.ouma@example.com',
        location: 'Thika, Kenya',
        lastOrder: '2024-08-09',
        orderCount: 8,
        status: 'active',
    },
    {
        id: 10008,
        image: 'https://images.unsplash.com/photo-1517364350421-1d6871888e1b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzl8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Sophia Achieng',
        phone: '+254 722 890123',
        email: 'sophia.achieng@example.com',
        location: 'Nairobi, Kenya',
        lastOrder: '2024-08-16',
        orderCount: 1,
        status: 'inactive',
    },
    {
        id: 10009,
        image: 'https://images.unsplash.com/photo-1533748430324-45f466e36937?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzR8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Ethan Kiprop',
        phone: '+254 733 901234',
        email: 'ethan.kiprop@example.com',
        location: 'Kisii, Kenya',
        lastOrder: '2024-08-13',
        orderCount: 3,
        status: 'active',
    },
    {
        id: 10010,
        image: 'https://images.unsplash.com/36/xIsiRLngSRWN02yA2BbK_submission-photo-7.jpg?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjR8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Ava Wanjiru',
        phone: '+254 710 012345',
        email: 'ava.wanjiru@example.com',
        location: 'Nanyuki, Kenya',
        lastOrder: '2024-08-18',
        orderCount: 5,
        status: 'inactive',
    },
];


export const customerOrders = [
    {
        orderId: 12563,
        image: 'https://images.unsplash.com/photo-1541190990694-4a612732721c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Michael Brown',
        propertyPurchased: '3-Bedroom Apartment, Nairobi',
        date: '4th July, 2024',
        status: 'Completed',
        total: 'KES 9,500,000'
    },
    {
        orderId: 56347,
        image: 'https://images.unsplash.com/photo-1521566652839-697aa473761a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Emily Davis',
        propertyPurchased: '2-Bedroom Apartment, Mombasa',
        date: '7th August, 2024',
        status: 'Pending',
        total: 'KES 6,000,000'
    },
    {
        orderId: 37849,
        image: 'https://images.unsplash.com/photo-1444720895098-cbd6b640c909?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Daniel Wilson',
        propertyPurchased: '4-Bedroom Villa, Karen',
        date: '9th September, 2024',
        status: 'Completed',
        total: 'KES 15,000,000'
    },
    {
        orderId: 89514,
        image: 'https://images.unsplash.com/photo-1450297549355-bfa4ef391427?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Olivia Johnson',
        propertyPurchased: 'Luxury Penthouse, Westlands',
        date: '15th October, 2024',
        status: 'Completed',
        total: 'KES 20,000,000'
    },
    {
        orderId: 76420,
        image: 'https://images.unsplash.com/photo-1529665730773-4f3fda31a5f9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Liam Martinez',
        propertyPurchased: 'Commercial Plot, Ruiru',
        date: '22nd November, 2024',
        status: 'Pending',
        total: 'KES 10,000,000'
    },
    {
        orderId: 34219,
        image: 'https://images.unsplash.com/photo-1517364350421-1d6871888e1b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzl8fHBlcnNvbnxlbnwwfHwwfHx8MA%3D%3D',
        name: 'Sophia Anderson',
        propertyPurchased: 'Studio Apartment, Kilimani',
        date: '3rd December, 2024',
        status: 'Completed',
        total: 'KES 4,500,000'
    }
];

export const customerInteractions = [
  {
    type: 'Phone',
    date: '4th July, 2024',
    summary: 'Discussed the initial offer and requirements for the property.',
    followUp: 'Arrange a date and time for a site visit.'
  },
  {
    type: 'Email',
    date: '7th August, 2024',
    summary: 'Sent a detailed list of available properties and pricing options.',
    followUp: 'Follow up with additional details after receiving customer preferences.'
  },
  {
    type: 'Video',
    date: '9th September, 2024',
    summary: 'Virtual tour of the property, addressing customer questions about amenities.',
    followUp: 'Send a summary of the call and arrange an in-person visit if needed.'
  },
  {
    type: 'Phone',
    date: '15th October, 2024',
    summary: 'Clarified legal aspects of the purchase and answered all questions.',
    followUp: 'Send over the necessary documents for review.'
  },
  {
    type: 'Email',
    date: '22nd November, 2024',
    summary: 'Provided information on the property’s history and neighborhood.',
    followUp: 'Arrange a meeting with the local community representative.'
  },
  {
    type: 'Video',
    date: '3rd December, 2024',
    summary: 'Detailed walk-through of the investment property and potential returns.',
    followUp: 'Schedule a call with the financial advisor for further guidance.'
  }
];

export const customerInvoices = [
  {
    invoiceNumber: 'INV-0001',
    billingCycle: 'June 2024',
    amount: '1500',
    createdDate: '4th July, 2024',
    customerName: 'Michael Brown',
    customerAddress: '123 Elm Street, Chicago, USA',
    dueDate: '20th July, 2024',
    paymentStatus: 'Pending',
  },
  {
    invoiceNumber: 'INV-0002',
    billingCycle: 'July 2024',
    amount: '1750',
    createdDate: '6th August, 2024',
    customerName: 'Emily Davis',
    customerAddress: '456 Oak Avenue, Houston, USA',
    dueDate: '22nd August, 2024',
    paymentStatus: 'Paid',
  },
  {
    invoiceNumber: 'INV-0003',
    billingCycle: 'August 2024',
    amount: '2000',
    createdDate: '5th September, 2024',
    customerName: 'Daniel Wilson',
    customerAddress: '789 Pine Road, Phoenix, USA',
    dueDate: '21st September, 2024',
    paymentStatus: 'Overdue',
  },
  {
    invoiceNumber: 'INV-0004',
    billingCycle: 'September 2024',
    amount: '2200',
    createdDate: '6th October, 2024',
    customerName: 'Olivia Johnson',
    customerAddress: '321 Maple Lane, Philadelphia, USA',
    dueDate: '22nd October, 2024',
    paymentStatus: 'Paid',
  },
  {
    invoiceNumber: 'INV-0005',
    billingCycle: 'October 2024',
    amount: '2500',
    createdDate: '4th November, 2024',
    customerName: 'Liam Martinez',
    customerAddress: '654 Birch Boulevard, San Antonio, USA',
    dueDate: '20th November, 2024',
    paymentStatus: 'Pending',
  },
  {
    invoiceNumber: 'INV-0006',
    billingCycle: 'November 2024',
    amount: '2750',
    createdDate: '5th December, 2024',
    customerName: 'Sophia Anderson',
    customerAddress: '987 Cedar Court, San Diego, USA',
    dueDate: '21st December, 2024',
    paymentStatus: 'Overdue',
  },
];


export const customerContracts = [
  { projectName: "Sunset Villas", visitDate: "12th June, 2024", phone: "+1 555-123-4567", status: "Scheduled" },
  { projectName: "Greenwood Estates", visitDate: "15th June, 2024", phone: "+1 555-234-5678", status: "Visited" },
  { projectName: "Maple Ridge Heights", visitDate: "18th June, 2024", phone: "+1 555-345-6789", status: "Cancelled" },
  { projectName: "Oceanview Condominiums", visitDate: "20th June, 2024", phone: "+1 555-456-7890", status: "Scheduled" },
  { projectName: "Riverside Terraces", visitDate: "25th June, 2024", phone: "+1 555-567-8901", status: "Visited" },
  { projectName: "Lakeview Residences", visitDate: "28th June, 2024", phone: "+1 555-678-9012", status: "Cancelled" },
  { projectName: "Crystal Springs Gated Community", visitDate: "2nd July, 2024", phone: "+1 555-789-0123", status: "Scheduled" },
  { projectName: "Palm Grove Apartments", visitDate: "5th July, 2024", phone: "+1 555-890-1234", status: "Visited" },
  { projectName: "Horizon Parklands", visitDate: "8th July, 2024", phone: "+1 555-901-2345", status: "Cancelled" },
  { projectName: "Bluewater Beachfront Villas", visitDate: "10th July, 2024", phone: "+1 555-012-3456", status: "Scheduled" },
  { projectName: "Cedarwood Acres", visitDate: "15th July, 2024", phone: "+1 555-123-4567", status: "Visited" },
  { projectName: "Silverstone Plaza", visitDate: "18th July, 2024", phone: "+1 555-234-5678", status: "Cancelled" },
  { projectName: "Golden Valley Ranch", visitDate: "22nd July, 2024", phone: "+1 555-345-6789", status: "Scheduled" },
  { projectName: "Highland Meadows", visitDate: "25th July, 2024", phone: "+1 555-456-7890", status: "Visited" },
  { projectName: "Orchard Grove Estates", visitDate: "28th July, 2024", phone: "+1 555-567-8901", status: "Cancelled" },
  { projectName: "Evergreen Parklands", visitDate: "30th July, 2024", phone: "+1 555-678-9012", status: "Scheduled" },
];

export const customerProjects = [
    { name: "Green Valley Estate", location: "New York, NY", startDate: "1st Jan, 2024", endDate: "31st Dec, 2024", status: "In Progress" },
    { name: "Lakeside Villas", location: "Miami, FL", startDate: "15th Feb, 2024", endDate: "15th Nov, 2024", status: "Not Started" },
    { name: "Mountain View Apartments", location: "Denver, CO", startDate: "10th Mar, 2024", endDate: "10th Dec, 2024", status: "In Progress" },
    { name: "Sunset Plaza", location: "Los Angeles, CA", startDate: "5th Apr, 2024", endDate: "5th Oct, 2024", status: "Completed" },
    { name: "Riverside Complex", location: "Austin, TX", startDate: "20th May, 2024", endDate: "20th Nov, 2024", status: "In Progress" },
    { name: "Harbor View Towers", location: "San Francisco, CA", startDate: "1st Jun, 2024", endDate: "1st Dec, 2024", status: "Not Started" },
    { name: "Crystal Lake Homes", location: "Orlando, FL", startDate: "15th Jun, 2024", endDate: "15th Dec, 2024", status: "In Progress" },
    { name: "Pine Ridge Estates", location: "Atlanta, GA", startDate: "1st Jul, 2024", endDate: "1st Jan, 2025", status: "Completed" },
    { name: "Canyon View Residences", location: "Phoenix, AZ", startDate: "10th Jul, 2024", endDate: "10th Feb, 2025", status: "In Progress" },
    { name: "Seaside Retreat", location: "San Diego, CA", startDate: "20th Aug, 2024", endDate: "20th Feb, 2025", status: "Not Started" },
    { name: "Oakwood Heights", location: "Dallas, TX", startDate: "1st Sep, 2024", endDate: "1st Apr, 2025", status: "In Progress" },
    { name: "Meadowbrook Gardens", location: "Chicago, IL", startDate: "10th Sep, 2024", endDate: "10th May, 2025", status: "Completed" },
    { name: "Lone Star Plaza", location: "Houston, TX", startDate: "15th Oct, 2024", endDate: "15th Jun, 2025", status: "In Progress" },
    { name: "Sunrise Villas", location: "Las Vegas, NV", startDate: "1st Nov, 2024", endDate: "1st Jul, 2025", status: "Not Started" },
    { name: "Royal Oaks", location: "Seattle, WA", startDate: "10th Nov, 2024", endDate: "10th Aug, 2025", status: "In Progress" },
    { name: "Westfield Apartments", location: "Boston, MA", startDate: "20th Dec, 2024", endDate: "20th Sep, 2025", status: "Completed" },
    { name: "Grandview Heights", location: "Philadelphia, PA", startDate: "1st Jan, 2025", endDate: "1st Oct, 2025", status: "In Progress" },
    { name: "Bay Area Residences", location: "San Jose, CA", startDate: "15th Jan, 2025", endDate: "15th Nov, 2025", status: "Not Started" },
    { name: "Parkside Estates", location: "Portland, OR", startDate: "1st Feb, 2025", endDate: "1st Dec, 2025", status: "In Progress" },
    { name: "Clearwater Towers", location: "Tampa, FL", startDate: "10th Feb, 2025", endDate: "10th Jan, 2026", status: "Completed" },
    { name: "Silver Springs", location: "Minneapolis, MN", startDate: "20th Mar, 2025", endDate: "20th Jan, 2026", status: "In Progress" },
    { name: "Urban Oasis", location: "Baltimore, MD", startDate: "1st Apr, 2025", endDate: "1st Feb, 2026", status: "Not Started" },
    { name: "Hilltop Haven", location: "Columbus, OH", startDate: "10th Apr, 2025", endDate: "10th Mar, 2026", status: "In Progress" }
];

export const customerSiteVisits = [
    { projectName: "Green Valley Estate", visitDate: "10th Jan, 2024", phone: "+1 555-123-4567", status: "Scheduled" },
    { projectName: "Lakeside Villas", visitDate: "12th Jan, 2024", phone: "+1 555-234-5678", status: "Visited" },
    { projectName: "Mountain View Apartments", visitDate: "15th Jan, 2024", phone: "+1 555-345-6789", status: "Cancelled" },
    { projectName: "Sunset Plaza", visitDate: "20th Jan, 2024", phone: "+1 555-456-7890", status: "Visited" },
    { projectName: "Riverside Complex", visitDate: "25th Jan, 2024", phone: "+1 555-567-8901", status: "Scheduled" },
    { projectName: "Harbor View Towers", visitDate: "30th Jan, 2024", phone: "+1 555-678-9012", status: "Cancelled" },
    { projectName: "Crystal Lake Homes", visitDate: "5th Feb, 2024", phone: "+1 555-789-0123", status: "Visited" },
    { projectName: "Pine Ridge Estates", visitDate: "10th Feb, 2024", phone: "+1 555-890-1234", status: "Scheduled" },
    { projectName: "Canyon View Residences", visitDate: "15th Feb, 2024", phone: "+1 555-901-2345", status: "Visited" },
    { projectName: "Seaside Retreat", visitDate: "20th Feb, 2024", phone: "+1 555-012-3456", status: "Cancelled" },
    { projectName: "Oakwood Heights", visitDate: "25th Feb, 2024", phone: "+1 555-123-4567", status: "Scheduled" },
    { projectName: "Meadowbrook Gardens", visitDate: "1st Mar, 2024", phone: "+1 555-234-5678", status: "Visited" },
    { projectName: "Lone Star Plaza", visitDate: "5th Mar, 2024", phone: "+1 555-345-6789", status: "Cancelled" },
    { projectName: "Sunrise Villas", visitDate: "10th Mar, 2024", phone: "+1 555-456-7890", status: "Scheduled" },
    { projectName: "Royal Oaks", visitDate: "15th Mar, 2024", phone: "+1 555-567-8901", status: "Visited" },
    { projectName: "Westfield Apartments", visitDate: "20th Mar, 2024", phone: "+1 555-678-9012", status: "Cancelled" },
    { projectName: "Grandview Heights", visitDate: "25th Mar, 2024", phone: "+1 555-789-0123", status: "Scheduled" },
    { projectName: "Bay Area Residences", visitDate: "1st Apr, 2024", phone: "+1 555-890-1234", status: "Visited" },
    { projectName: "Parkside Estates", visitDate: "5th Apr, 2024", phone: "+1 555-901-2345", status: "Cancelled" },
    { projectName: "Clearwater Towers", visitDate: "10th Apr, 2024", phone: "+1 555-012-3456", status: "Scheduled" }
];


export const chatData = [
  {
    id: 1,
    sender: 'J. Panaito',
    message: 'Hello! How are you doing today?',
    time: '10:05 AM',
    date: '2024-08-22',
    type: 'text',
  },
  {
    id: 2,
    sender: 'You',
    message: 'I’m doing great, thank you! How about you?',
    time: '10:07 AM',
    date: '2024-08-22',
    type: 'text',
  },
  {
    id: 3,
    sender: 'J. Panaito',
    message: 'Here is the file you asked for.',
    time: '10:10 AM',
    date: '2024-08-22',
    type: 'file',
    fileName: 'document.pdf',
  },
  {
    id: 4,
    sender: 'You',
    message: 'Thanks! I’ll check it out.',
    time: '10:15 AM',
    date: '2024-08-22',
    type: 'text',
  },
  {
    id: 5,
    sender: 'J. Panaito',
    message: 'voice_note.mp3',
    time: '10:20 AM',
    date: '2024-08-22',
    type: 'voice',
  },
];

export const payments = [
  {
    id: 1,
    date: '2024-09-01',
    time: '10:30 AM',
    transactionId: 'MPESA12345',
    paidBy: 'John Doe',
    account: 'Account1',
    amount: '1000 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 2,
    date: '2024-09-01',
    time: '11:00 AM',
    transactionId: 'MPESA67890',
    paidBy: 'Jane Smith',
    account: 'Account2',
    amount: '1500 KES',
    ceoAction: 'Pending',
    financeAction: 'Pending'
  },
  {
    id: 3,
    date: '2024-09-01',
    time: '12:15 PM',
    transactionId: 'MPESA23456',
    paidBy: 'Peter Mwangi',
    account: 'Account3',
    amount: '500 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 4,
    date: '2024-09-01',
    time: '01:45 PM',
    transactionId: 'MPESA78901',
    paidBy: 'Alice Wambui',
    account: 'Account4',
    amount: '2000 KES',
    ceoAction: 'Approved',
    financeAction: 'Pending'
  },
  {
    id: 5,
    date: '2024-09-01',
    time: '02:30 PM',
    transactionId: 'MPESA34567',
    paidBy: 'David Otieno',
    account: 'Account5',
    amount: '3000 KES',
    ceoAction: 'Pending',
    financeAction: 'Processed'
  },
  {
    id: 6,
    date: '2024-09-01',
    time: '03:15 PM',
    transactionId: 'MPESA89012',
    paidBy: 'Grace Achieng',
    account: 'Account6',
    amount: '1200 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 7,
    date: '2024-09-01',
    time: '04:00 PM',
    transactionId: 'MPESA45678',
    paidBy: 'Michael Kamau',
    account: 'Account7',
    amount: '2200 KES',
    ceoAction: 'Pending',
    financeAction: 'Pending'
  },
  {
    id: 8,
    date: '2024-09-01',
    time: '04:45 PM',
    transactionId: 'MPESA90123',
    paidBy: 'Emily Njeri',
    account: 'Account8',
    amount: '800 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 9,
    date: '2024-09-01',
    time: '05:30 PM',
    transactionId: 'MPESA56789',
    paidBy: 'Charles Kiptoo',
    account: 'Account9',
    amount: '1000 KES',
    ceoAction: 'Pending',
    financeAction: 'Pending'
  },
  {
    id: 10,
    date: '2024-09-01',
    time: '06:00 PM',
    transactionId: 'MPESA01234',
    paidBy: 'Rose Nyambura',
    account: 'Account10',
    amount: '1500 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 11,
    date: '2024-09-02',
    time: '09:00 AM',
    transactionId: 'MPESA67891',
    paidBy: 'Tom Mboya',
    account: 'Account11',
    amount: '2500 KES',
    ceoAction: 'Pending',
    financeAction: 'Pending'
  },
  {
    id: 12,
    date: '2024-09-02',
    time: '09:45 AM',
    transactionId: 'MPESA12346',
    paidBy: 'Lydia Wafula',
    account: 'Account12',
    amount: '900 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 13,
    date: '2024-09-02',
    time: '10:15 AM',
    transactionId: 'MPESA23457',
    paidBy: 'Samuel Kariuki',
    account: 'Account13',
    amount: '1100 KES',
    ceoAction: 'Pending',
    financeAction: 'Processed'
  },
  {
    id: 14,
    date: '2024-09-02',
    time: '10:30 AM',
    transactionId: 'MPESA34568',
    paidBy: 'Christine Ndegwa',
    account: 'Account14',
    amount: '1300 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 15,
    date: '2024-09-02',
    time: '11:00 AM',
    transactionId: 'MPESA45679',
    paidBy: 'Patrick Njenga',
    account: 'Account15',
    amount: '2000 KES',
    ceoAction: 'Pending',
    financeAction: 'Pending'
  },
  {
    id: 16,
    date: '2024-09-02',
    time: '11:30 AM',
    transactionId: 'MPESA56780',
    paidBy: 'Ann Wanjiru',
    account: 'Account16',
    amount: '1700 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 17,
    date: '2024-09-02',
    time: '12:00 PM',
    transactionId: 'MPESA67892',
    paidBy: 'Jacob Maina',
    account: 'Account17',
    amount: '2400 KES',
    ceoAction: 'Pending',
    financeAction: 'Processed'
  },
  {
    id: 18,
    date: '2024-09-02',
    time: '12:30 PM',
    transactionId: 'MPESA78903',
    paidBy: 'Lucy Akinyi',
    account: 'Account18',
    amount: '1900 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 19,
    date: '2024-09-02',
    time: '01:00 PM',
    transactionId: 'MPESA89014',
    paidBy: 'George Omondi',
    account: 'Account19',
    amount: '2200 KES',
    ceoAction: 'Pending',
    financeAction: 'Pending'
  },
  {
    id: 20,
    date: '2024-09-02',
    time: '01:30 PM',
    transactionId: 'MPESA90125',
    paidBy: 'Martha Nduta',
    account: 'Account20',
    amount: '1600 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 21,
    date: '2024-09-02',
    time: '02:00 PM',
    transactionId: 'MPESA01236',
    paidBy: 'Henry Mwiti',
    account: 'Account21',
    amount: '1400 KES',
    ceoAction: 'Pending',
    financeAction: 'Pending'
  },
  {
    id: 22,
    date: '2024-09-02',
    time: '02:30 PM',
    transactionId: 'MPESA12347',
    paidBy: 'Betty Chebet',
    account: 'Account22',
    amount: '1800 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 23,
    date: '2024-09-02',
    time: '03:00 PM',
    transactionId: 'MPESA23458',
    paidBy: 'Simon Kibet',
    account: 'Account23',
    amount: '2100 KES',
    ceoAction: 'Pending',
    financeAction: 'Pending'
  },
  {
    id: 24,
    date: '2024-09-02',
    time: '03:30 PM',
    transactionId: 'MPESA34569',
    paidBy: 'Mary Njeri',
    account: 'Account24',
    amount: '2600 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 25,
    date: '2024-09-02',
    time: '04:00 PM',
    transactionId: 'MPESA45680',
    paidBy: 'James Karanja',
    account: 'Account25',
    amount: '1500 KES',
    ceoAction: 'Pending',
    financeAction: 'Pending'
  },
  {
    id: 26,
    date: '2024-09-02',
    time: '04:30 PM',
    transactionId: 'MPESA56781',
    paidBy: 'Jane Mwikali',
    account: 'Account26',
    amount: '2300 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 27,
    date: '2024-09-02',
    time: '05:00 PM',
    transactionId: 'MPESA67893',
    paidBy: 'Anthony Muriithi',
    account: 'Account27',
    amount: '1000 KES',
    ceoAction: 'Pending',
    financeAction: 'Pending'
  },
  {
    id: 28,
    date: '2024-09-02',
    time: '05:30 PM',
    transactionId: 'MPESA78904',
    paidBy: 'Esther Wanjiku',
    account: 'Account28',
    amount: '1700 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  },
  {
    id: 29,
    date: '2024-09-02',
    time: '06:00 PM',
    transactionId: 'MPESA89015',
    paidBy: 'Daniel Muthoni',
    account: 'Account29',
    amount: '2200 KES',
    ceoAction: 'Pending',
    financeAction: 'Processed'
  },
  {
    id: 30,
    date: '2024-09-02',
    time: '06:30 PM',
    transactionId: 'MPESA90126',
    paidBy: 'Joyce Atieno',
    account: 'Account30',
    amount: '1900 KES',
    ceoAction: 'Approved',
    financeAction: 'Processed'
  }
];

export const visaMastercardPayments = [
  {
    id: 1,
    entryDate: '2024-09-01',
    paymentDate: '2024-09-01',
    paidBy: 'Alice Wanjiku',
    amount: '5000 KES',
    mode: 'NCBA Bank',
    reference: 'NCBA12345',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 2,
    entryDate: '2024-09-01',
    paymentDate: '2024-09-01',
    paidBy: 'Brian Otieno',
    amount: '7500 KES',
    mode: 'KCB',
    reference: 'KCB67890',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 3,
    entryDate: '2024-09-02',
    paymentDate: '2024-09-02',
    paidBy: 'Catherine Njeri',
    amount: '3000 KES',
    mode: 'Stanchart',
    reference: 'SC123456',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 4,
    entryDate: '2024-09-02',
    paymentDate: '2024-09-02',
    paidBy: 'David Kimani',
    amount: '4500 KES',
    mode: 'NCBA Bank',
    reference: 'NCBA98765',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 5,
    entryDate: '2024-09-03',
    paymentDate: '2024-09-03',
    paidBy: 'Esther Mwangi',
    amount: '6000 KES',
    mode: 'KCB',
    reference: 'KCB24680',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 6,
    entryDate: '2024-09-03',
    paymentDate: '2024-09-03',
    paidBy: 'Francis Njuguna',
    amount: '3500 KES',
    mode: 'Stanchart',
    reference: 'SC789012',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 7,
    entryDate: '2024-09-04',
    paymentDate: '2024-09-04',
    paidBy: 'Grace Wanjiru',
    amount: '8000 KES',
    mode: 'NCBA Bank',
    reference: 'NCBA54321',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 8,
    entryDate: '2024-09-04',
    paymentDate: '2024-09-04',
    paidBy: 'Henry Mwangi',
    amount: '9000 KES',
    mode: 'KCB',
    reference: 'KCB13579',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 9,
    entryDate: '2024-09-05',
    paymentDate: '2024-09-05',
    paidBy: 'Irene Wambui',
    amount: '4000 KES',
    mode: 'Stanchart',
    reference: 'SC345678',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 10,
    entryDate: '2024-09-05',
    paymentDate: '2024-09-05',
    paidBy: 'James Otieno',
    amount: '5500 KES',
    mode: 'NCBA Bank',
    reference: 'NCBA43210',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 11,
    entryDate: '2024-09-06',
    paymentDate: '2024-09-06',
    paidBy: 'Katherine Karanja',
    amount: '6500 KES',
    mode: 'KCB',
    reference: 'KCB98765',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 12,
    entryDate: '2024-09-06',
    paymentDate: '2024-09-06',
    paidBy: 'Lydia Mworia',
    amount: '3000 KES',
    mode: 'Stanchart',
    reference: 'SC456789',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 13,
    entryDate: '2024-09-07',
    paymentDate: '2024-09-07',
    paidBy: 'Mark Nderitu',
    amount: '5500 KES',
    mode: 'NCBA Bank',
    reference: 'NCBA56789',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 14,
    entryDate: '2024-09-07',
    paymentDate: '2024-09-07',
    paidBy: 'Nina Nyambura',
    amount: '7000 KES',
    mode: 'KCB',
    reference: 'KCB67891',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 15,
    entryDate: '2024-09-08',
    paymentDate: '2024-09-08',
    paidBy: 'Owen Njoroge',
    amount: '8000 KES',
    mode: 'Stanchart',
    reference: 'SC567890',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 16,
    entryDate: '2024-09-08',
    paymentDate: '2024-09-08',
    paidBy: 'Pauline Wairimu',
    amount: '4500 KES',
    mode: 'NCBA Bank',
    reference: 'NCBA67890',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 17,
    entryDate: '2024-09-09',
    paymentDate: '2024-09-09',
    paidBy: 'Quincy Wambua',
    amount: '5500 KES',
    mode: 'KCB',
    reference: 'KCB78901',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 18,
    entryDate: '2024-09-09',
    paymentDate: '2024-09-09',
    paidBy: 'Ruth Muriithi',
    amount: '6200 KES',
    mode: 'Stanchart',
    reference: 'SC678901',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 19,
    entryDate: '2024-09-10',
    paymentDate: '2024-09-10',
    paidBy: 'Samuel Mwangi',
    amount: '7500 KES',
    mode: 'NCBA Bank',
    reference: 'NCBA78901',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 20,
    entryDate: '2024-09-10',
    paymentDate: '2024-09-10',
    paidBy: 'Tina Wairimu',
    amount: '4000 KES',
    mode: 'KCB',
    reference: 'KCB89012',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 21,
    entryDate: '2024-09-11',
    paymentDate: '2024-09-11',
    paidBy: 'Ursula Muthoni',
    amount: '5500 KES',
    mode: 'Stanchart',
    reference: 'SC789012',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 22,
    entryDate: '2024-09-11',
    paymentDate: '2024-09-11',
    paidBy: 'Victor Njenga',
    amount: '6700 KES',
    mode: 'NCBA Bank',
    reference: 'NCBA89012',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 23,
    entryDate: '2024-09-12',
    paymentDate: '2024-09-12',
    paidBy: 'Wendy Nyambura',
    amount: '3500 KES',
    mode: 'KCB',
    reference: 'KCB90123',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 24,
    entryDate: '2024-09-12',
    paymentDate: '2024-09-12',
    paidBy: 'Xavier Njeri',
    amount: '5200 KES',
    mode: 'Stanchart',
    reference: 'SC890123',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 25,
    entryDate: '2024-09-13',
    paymentDate: '2024-09-13',
    paidBy: 'Yvonne Muthoni',
    amount: '6000 KES',
    mode: 'NCBA Bank',
    reference: 'NCBA90123',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 26,
    entryDate: '2024-09-13',
    paymentDate: '2024-09-13',
    paidBy: 'Zachary Otieno',
    amount: '7500 KES',
    mode: 'KCB',
    reference: 'KCB01234',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 27,
    entryDate: '2024-09-14',
    paymentDate: '2024-09-14',
    paidBy: 'Alice Wanjiru',
    amount: '5500 KES',
    mode: 'Stanchart',
    reference: 'SC901234',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 28,
    entryDate: '2024-09-14',
    paymentDate: '2024-09-14',
    paidBy: 'Brian Otieno',
    amount: '3000 KES',
    mode: 'NCBA Bank',
    reference: 'NCBA01234',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
  {
    id: 29,
    entryDate: '2024-09-15',
    paymentDate: '2024-09-15',
    paidBy: 'Catherine Njeri',
    amount: '4000 KES',
    mode: 'KCB',
    reference: 'KCB12345',
    ceoAction: 'Approved',
    financeAction: 'Processed',
  },
  {
    id: 30,
    entryDate: '2024-09-15',
    paymentDate: '2024-09-15',
    paidBy: 'David Kimani',
    amount: '6500 KES',
    mode: 'Stanchart',
    reference: 'SC012345',
    ceoAction: 'Pending',
    financeAction: 'Pending',
  },
];


// data.js

export const permissions = {
  dashboard: [
    { key: 'view_dashboard_analytics', label: 'View Dashboard Analytics' },
    { key: 'view_reports', label: 'View Reports' },
  ],
  users: [
    { key: 'create_users', label: 'Create Users' },
    { key: 'list_users', label: 'List Users' },
    { key: 'view_users', label: 'View Users' },
    { key: 'edit_users', label: 'Edit Users' },
    { key: 'delete_users', label: 'Delete Users' },
    { key: 'create_user_roles', label: 'Create User Roles' },
    { key: 'list_user_roles', label: 'List User Roles' },
    { key: 'view_user_roles', label: 'View User Roles' },
    { key: 'edit_user_roles', label: 'Edit User Roles' },
    { key: 'delete_user_roles', label: 'Delete User Roles' },
  ],
  customers: [
    { key: 'create_customer', label: 'Create Customer' },
    { key: 'list_customer', label: 'List Customer' },
    { key: 'view_customer', label: 'View Customer' },
    { key: 'edit_customer', label: 'Edit Customer' },
    { key: 'delete_customer', label: 'Delete Customer' },
  ],
  projects: [
    { key: 'create_project', label: 'Create Project' },
    { key: 'list_project', label: 'List Project' },
    { key: 'view_project', label: 'View Project' },
    { key: 'edit_project', label: 'Edit Project' },
    { key: 'delete_project', label: 'Delete Project' },
  ],
  properties: [
    { key: 'create_property', label: 'Create Property' },
    { key: 'list_property', label: 'List Property' },
    { key: 'view_property', label: 'View Property' },
    { key: 'edit_property', label: 'Edit Property' },
    { key: 'delete_property', label: 'Delete Property' },
  ],
  contracts: [
    { key: 'create_contract', label: 'Create Contract' },
    { key: 'list_contract', label: 'List Contract' },
    { key: 'view_contract', label: 'View Contract' },
    { key: 'edit_contract', label: 'Edit Contract' },
    { key: 'delete_contract', label: 'Delete Contract' },
  ],
  humanResources: [
    { key: 'create_human_resource', label: 'Create Human Resource' },
    { key: 'list_human_resource', label: 'List Human Resource' },
    { key: 'view_human_resource', label: 'View Human Resource' },
    { key: 'edit_human_resource', label: 'Edit Human Resource' },
    { key: 'delete_human_resource', label: 'Delete Human Resource' },
  ],
  orders: [
    { key: 'create_orders', label: 'Create Orders' },
    { key: 'list_orders', label: 'List Orders' },
    { key: 'view_orders', label: 'View Orders' },
    { key: 'edit_orders', label: 'Edit Orders' },
    { key: 'delete_orders', label: 'Delete Orders' },
  ],
  payments: [
    { key: 'create_payments', label: 'Create Payments' },
    { key: 'list_payments', label: 'List Payments' },
    { key: 'view_payments', label: 'View Payments' },
    { key: 'edit_payments', label: 'Edit Payments' },
    { key: 'delete_payments', label: 'Delete Payments' },
  ],
  leads: [
    { key: 'create_leads', label: 'Create Leads' },
    { key: 'list_leads', label: 'List Leads' },
    { key: 'view_leads', label: 'View Leads' },
    { key: 'edit_leads', label: 'Edit Leads' },
    { key: 'delete_leads', label: 'Delete Leads' },
  ],
  bookings: [
    { key: 'create_bookings', label: 'Create Bookings' },
    { key: 'list_bookings', label: 'List Bookings' },
    { key: 'view_bookings', label: 'View Bookings' },
    { key: 'edit_bookings', label: 'Edit Bookings' },
    { key: 'delete_bookings', label: 'Delete Bookings' },
  ],
  tickets: [
    { key: 'create_tickets', label: 'Create Tickets' },
    { key: 'list_tickets', label: 'List Tickets' },
    { key: 'view_tickets', label: 'View Tickets' },
    { key: 'edit_tickets', label: 'Edit Tickets' },
    { key: 'delete_tickets', label: 'Delete Tickets' },
  ],
  communication: [
    { key: 'create_communication', label: 'Create Communication' },
    { key: 'list_communication', label: 'List Communication' },
    { key: 'view_communication', label: 'View Communication' },
    { key: 'edit_communication', label: 'Edit Communication' },
    { key: 'delete_communication', label: 'Delete Communication' },
  ],
  tasks: [
    { key: 'create_tasks', label: 'Create Tasks' },
    { key: 'list_tasks', label: 'List Tasks' },
    { key: 'view_tasks', label: 'View Tasks' },
    { key: 'edit_tasks', label: 'Edit Tasks' },
    { key: 'delete_tasks', label: 'Delete Tasks' },
  ],
  events: [
    { key: 'create_events', label: 'Create Events' },
    { key: 'list_events', label: 'List Events' },
    { key: 'view_events', label: 'View Events' },
    { key: 'edit_events', label: 'Edit Events' },
    { key: 'delete_events', label: 'Delete Events' },
  ],
  settings: [
    { key: 'create_settings', label: 'Create Settings' },
    { key: 'view_settings', label: 'View Settings' },
    { key: 'edit_settings', label: 'Edit Settings' },
    { key: 'delete_settings', label: 'Delete Settings' },
  ],
};
