import React, { useEffect, useState } from 'react'
import { IoMdAdd } from 'react-icons/io'
import { MdOutlineFilterAlt } from 'react-icons/md'
import { useParams } from 'react-router-dom';
import { AddProjectIssues, fetchProjectIssues } from '../../utils/api/api';
import toast from 'react-hot-toast';
import Modal from '../../elements/Modal';

const ProjectIssues = () => {
    const [issues, setIssues] = useState([]);
    const [filter, setFilter] = useState(''); 
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState(null);
    const [formData, setFormData] = useState({
        project_id: "",
        resolution_plan: "",
        description: "",
        status: "",
    });
    const { id } = useParams();
    const project_id = id;

    const getissues = async () => {
        setLoading(true);
        try {
            const data = await fetchProjectIssues(project_id);
            setIssues(data.issues);
        } catch (err) {
            toast.error('Request failed.');
        } finally {
            setLoading(false);
        }
    };


    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const issuesData = {
            ...formData,
            project_id: project_id,
        };
        setLoading(true);
        try {
            await AddProjectIssues(issuesData);
            toast.success('Issue added.');
            setOpenModal(false);
            getissues();
            setFormData('')
        } catch (err) {
            toast.error('Failed to add expense.');
        } finally {
            setLoading(false);
        }
    };

    const filteredIssues = issues.filter(issue => {
        if (!filter) return true; 
        return issue.status === filter;
    });

    const handleIssueClick = (issue) => {
        setSelectedIssue(issue);
        setOpenDetailModal(true);
    };

    useEffect(() => {
        getissues();
    }, []);

  return (
      <div className='mt-4 flex bg-[#fff] rounded-lg p-4 flex-col'>
          <div className="w-full flex items-center justify-between">
              <p className="text-black font-semibold text-[22px]">Issues</p>
              <div className="flex items-center gap-4">
                  <button
                      onClick={() => setOpenModal(true)}
                      className="px-4 py-1.5 flex items-center gap-2 text-[14px] text-secondary rounded-md border border-secondary">
                      <IoMdAdd size={18} />
                      Add New
                  </button>
              </div>
          </div>

          <div className="w-full bg-white rounded-lg mt-4">
              <div className="overflow-x-auto rounded-lg">
                  <table className="min-w-full bg-white rounded-lg border border-gray-200 table-auto">
                      <thead className="bg-[#F0F0F0]">
                          <tr className="text-black border-b text-[14px] py-3 font-medium leading-normal rounded-t-lg">
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Description</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Resolution plan</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Status</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Action</th>
                          </tr>
                      </thead>
                      <tbody className="text-primary text-[14px] font-light">
                          {issues.length === 0 ? (
                              <tr>
                                  <td colSpan="4" className="py-4 px-6 text-center text-gray-500">
                                      {loading ? 'Loading, please wait' : 'No data found'}
                                  </td>
                              </tr>
                          ) : (
                              issues.map((issue, index) => (
                                  <tr
                                      key={index}
                                      className={`border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                                      onClick={() => handleIssueClick(issue)}
                                  >
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">
                                          {issue.description.split(' ').slice(0, 6).join(' ')}...
                                      </td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">
                                          {issue.resolution_plan.split(' ').slice(0, 6).join(' ')}...
                                      </td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">
                                          {issue.status}
                                      </td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">
                                          <button
                                              onClick={(e) => { e.stopPropagation(); handleDelete(issue.id); }} // Add delete button
                                              className="text-red-500 hover:text-red-700"
                                          >
                                          </button>
                                      </td>
                                  </tr>
                              ))
                          )}
                      </tbody>
                  </table>
              </div>
          </div>

          {openModal && (
              <Modal onClose={() => setOpenModal(false)} title="Add Issue">
                  <form onSubmit={handleFormSubmit} className="flex flex-col gap-4">
                      <div>
                          <label className="block text-black text-[14px] font-medium mb-1">Status</label>
                          <select
                              name="status"
                              value={formData.status}
                              onChange={handleInputChange}
                              className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              required
                          >
                              <option value="">Select Status</option>
                              <option value="resolved">Resolved</option>
                              <option value="not_resolved">Unresolved</option>
                          </select>
                      </div>

                      <div>
                          <label className="block text-black text-[14px] font-medium mb-1">Description</label>
                          <textarea
                              type="text"
                              name="description"
                              value={formData.description}
                              placeholder='Enter risk description'
                              className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              onChange={handleInputChange}
                              required
                          />
                      </div>
                      <div>
                          <label className="block text-black text-[14px] font-medium mb-1">Resolution plan</label>
                          <input
                              type="text"
                              name="resolution_plan"
                              value={formData.resolution_plan}
                              onChange={handleInputChange}
                              placeholder='Enter reslolution plan'
                              className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              required
                          />
                      </div>

                      <div className="flex w-full items-center justify-end">
                          <button type='button' onClick={() => setOpenModal(false)} className='text-secondary font-medium text-[14px] mr-2'>Cancel</button>
                          <button
                              type="submit"
                              disabled={loading}
                              className="bg-secondary text-white py-1.5 px-6 rounded-md text-[14px] font-medium"
                          >
                              {loading ? 'Loading, please wait...' : 'Submit'}
                          </button>
                      </div>
                  </form>
              </Modal>
          )}
          {openDetailModal && (
              <Modal onClose={() => setOpenDetailModal(false)} title="Issue Details">
                  <p><strong>Description:</strong> {selectedIssue.description}</p>
                  <p><strong>Resolution Plan:</strong> {selectedIssue.resolution_plan}</p>
                  <p><strong>Status:</strong> {selectedIssue.status}</p>
                  <button onClick={() => setOpenDetailModal(false)} className='text-secondary font-medium text-[14px]'>Close</button>
              </Modal>
          )}

      </div>
  )
}

export default ProjectIssues
