import React, { useEffect } from 'react';
import TopMenu from '../components/TopMenu';
import Footer from '../components/Footer';
import SideMenu from '../components/SideMenu';
import { useLocation } from 'react-router-dom';
import BottomNav from './BottomNav';
import { motion } from 'framer-motion';

const Layout = ({ children, text, backgroundColor = 'bg-gray-100' }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const excludedPaths = ['/home', '/accounts/sign-in', '/signup'];

  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.4 } },
  };

  useEffect(() => {
    const container = document.querySelector('.scroll-container');
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [location]);

  return (
    <div className="flex flex-col h-[100vh] w-full">
      <TopMenu header={text} />
      <div className="flex w-full h-[88vh]">
        <div className="w-[18%] pl-[3%] bg-primary hidden md:flex">
          <SideMenu />
        </div>
        <div className={`flex flex-col flex-grow overflow-auto w-full md:w-[82%] py-8 mb-6 md:mb-0 px-[5%] md:pl-8 md:pr-[3%] h-auto ${backgroundColor}`}>
          {children}
        </div>
      </div>
      <Footer />

      {!excludedPaths.includes(currentPath) && <BottomNav />}
    </div>
  );
};

export default Layout;
