import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const BarChart = ({ customer_engagements }) => {
    if (!customer_engagements || customer_engagements.length === 0) {
        return (
            <div className="p-6 bg-gray-50 rounded-lg border border-gray-200">
                <p className="text-[#05004E] font-medium text-[18px] mb-4">Customer Engagements</p>
                <p className="text-[#222B45]">No data available</p>
            </div>
        );
    }

    const labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const lastWeekData = [0, 0, 0, 0, 0, 0, 0];
    const thisWeekData = [0, 0, 0, 0, 0, 0, 0];

    customer_engagements.forEach(engagement => {
        const dayIndex = labels.indexOf(engagement.day);
        if (dayIndex !== -1) {
            thisWeekData[dayIndex] = parseInt(engagement.count, 10);
        }
    });

    const data = {
        labels,
        datasets: [
            {
                label: 'Last Week',
                data: lastWeekData,
                backgroundColor: '#0E113C',
                stack: 'stack1',
                barThickness: 12,
                borderRadius: 4,
            },
            {
                label: 'This Week',
                data: thisWeekData,
                backgroundColor: '#F8A81B',
                stack: 'stack2',
                barThickness: 12,
                borderRadius: 4,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.dataset.label + ': ' + tooltipItem.raw;
                    },
                },
            },
        },
        scales: {
            x: {
                stacked: false,
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                },
                categoryPercentage: 0.8,
                barPercentage: 0.6,
            },
            y: {
                stacked: false,
                grid: {
                    display: true,
                },
                ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                },
            },
        },
    };

    return (
        <div className="p-6 bg-white rounded-lg">
            <p className="text-[#05004E] font-medium text-[18px] mb-2">Customer Engagements</p>
            <Bar data={data} options={options} />
            <div className="mt-4 flex w-full justify-center space-x-4">
                <div className="flex items-center space-x-2">
                    <div className="w-3 h-3 rounded-full" style={{ backgroundColor: '#0E113C' }}></div>
                    <span className='text-[12px] font-medium text-[#222B45]'>Last Week</span>
                </div>
                <div className="flex items-center space-x-2">
                    <div className="w-3 h-3 rounded-full" style={{ backgroundColor: '#F8A81B' }}></div>
                    <span className='text-[12px] font-medium text-[#222B45]'>This Week</span>
                </div>
            </div>
        </div>
    );
};

export default BarChart;
