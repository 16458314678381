import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../elements/Layout';
import { IoIosSearch } from 'react-icons/io';
import { MdAdd, MdOutlineFilterAlt, MdOutlineMail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Modal from '../../elements/Modal'
import { addCustomer, fetchCustomers } from '../../utils/api/api';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import axios from 'axios';
import { canAccess } from '../../utils/canAccess';
import { AuthContext } from '../../utils/context/AuthContext';

const Customers = () => {
  const { user } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [searchQuery, setSearchQuery] = useState('');
  const [countryCode, setCountryCode] = useState('+254');

  const [countryCodes, setCountryCodes] = useState([]);
  const [filters, setFilters] = useState({
    gender: '',
    status: '',
    city: '',
  });
  const [appliedFilters, setAppliedFilters] = useState({
    gender: '',
    status: '',
    city: '',
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    gender: '',
    phone_number: '',
    id_number: '',
    dob: '',
    country: '',
    nationality: '',
    city: '',
    state: '',
  });

  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countryData = response.data.map(country => ({
          name: country.name.common,
          code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ''),
          flag: country.flags.svg,
        }));

        const sortedCountryData = countryData.sort((a, b) => a.name.localeCompare(b.name));

        setCountryCodes(sortedCountryData);
      } catch (error) {
        console.error('Error fetching country codes:', error);
      }
    };

    fetchCountryCodes();
  }, []);

  const handlePhoneNumber = (number) => {
    const lastNineDigits = number.slice(-9);
    return countryCode + lastNineDigits;
  };

  const processedPhoneNumber = handlePhoneNumber(formData.phone_number);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getCustomers = async () => {
    setLoading(true)
    try {
      const data = await fetchCustomers();
      setCustomers(data.customers);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch customers.');
      setLoading(false);
    }
  };

  const applyFilters = () => {
    setAppliedFilters(filters);
    setShowFilters(false);
  };

  const filteredCustomers = customers
    .filter((customer) => {
      const matchesSearch = customer?.first_name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        || customer?.last_name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        || customer?.email?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        || customer?.phone_number?.toLowerCase()?.includes(searchQuery?.toLowerCase());

      const matchesGender = appliedFilters.gender ? customer.gender === appliedFilters.gender : true;
      const matchesStatus = appliedFilters.status ? calculateStatus(customer.last_login) === appliedFilters.status : true;
      const matchesCity = appliedFilters.city ? customer.city === appliedFilters.city : true;

      return matchesSearch && matchesGender && matchesStatus && matchesCity;
    });

  useEffect(() => {
    getCustomers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newCustomer = {
      ...formData,
      phone_number: processedPhoneNumber 
    };

    setLoading(true);
    try {
      const result = await addCustomer(newCustomer);

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        gender: '',
        phoneNumber: '',
        id_number: '',
        dob: '',
      });

      setShowAddCustomer(false);
      toast.success("Customer added.")
      window.location.reload();
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An error occurred.';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  const handleAddClick = () => setShowAddCustomer(true);


  const totalCustomers = customers.length;
  const male = customers.filter(customer => customer?.gender?.toLowerCase() === 'male').length;
  const female = customers.filter(customer => customer?.gender?.toLowerCase() === 'female').length;
  const unknownGender = customers.filter(customer => !customer?.gender).length;
  const recentlyAdded = customers.filter(customer => {
    const addedDate = new Date(customer.createdAt);
    const today = new Date();
    const differenceInDays = (today - addedDate) / (1000 * 3600 * 24);
    return differenceInDays <= 7; 
  }).length;

  const customersPerPage = 20;
  const indexOfLastCustomer = currentPage * customersPerPage;
  const currentCustomers = filteredCustomers.slice(
    indexOfLastCustomer - customersPerPage,
    indexOfLastCustomer
  );

  const navigate = useNavigate();
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(customers.length / customersPerPage);

  const calculateStatus = (lastLoginDate) => {
    const lastLogin = dayjs(lastLoginDate);
    const today = dayjs();
    const daysDifference = today.diff(lastLogin, 'day');

    return daysDifference > 90 ? 'inactive' : 'active';
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleCountrySelect = (country, iscountry) => {
    setCountryCode(country.code);
    setFormData({
      ...formData,
      country: country.name,
    });
    setIsDropdownOpen(false);
  };

  return (
    <Layout text={"Customers"}>
      <div className="flex items-center w-full justify-between">
        <div className="w-fit bg-white rounded-md text-[13px] py-2 px-2 riunded-md flex gap-2 items-center">
          <IoIosSearch className='text-gray-600' />
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="bg-transparent h-full outline-none text-gray-600 w-[250px]"
          />
        </div>
        <button 
          onClick={() => setShowFilters(true)}
          className="px-4 py-1.5 hidden md:flex items-center gap-2 text-[14px] text-secondary rounded-md border border-secondary"
        >
          <MdOutlineFilterAlt size={18} />
          Filter
        </button>
      </div>
        <div className='mt-4 w-full grid grid-cols-2 md:grid-cols-4 gap-4'>
          <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
            <p className="text-primary text-[13px]">Total Customers</p>
            <hr />
            <p className="text-primary text-[16px]">{totalCustomers}</p>
          </div>
          <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
            <p className="text-primary text-[13px]">Male Customers</p>
            <hr />
            <p className="text-primary text-[16px]">{male}</p>
          </div>
          <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
            <p className="text-primary text-[13px]">Female Customers</p>
            <hr />
            <p className="text-primary text-[16px]">{female}</p>
          </div>
          <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
            <p className="text-primary text-[13px]">Unknown gender</p>
            <hr />
          <p className="text-primary text-[16px]">{unknownGender}</p>
          </div>
        </div>
      <div className="flex items-center justify-between bg-white mt-4 rounded-md p-3">
        <div className="flex flex-col">
          <p className="text-secondary text-[16px] font-medium">Customers</p>
          <p className="text-[14px] text-lead font-light">Add customers to the system</p>
        </div>
        {canAccess(user, 'create_customer') && (
          <button
            onClick={handleAddClick}
            className="flex items-center gap-2 px-4 py-1.5 bg-secondary text-white text-[14px] rounded-md outline-none"
          >
            <MdAdd />
            Add Customer
          </button>
        )}        
      </div>

      <div className="w-full bg-white rounded-lg mt-4">
        <div className="overflow-x-auto rounded-lg">
          <table className="min-w-full bg-white h-full flex-grow rounded-lg border border-gray-200 table-auto">
            <thead>
              <tr className="text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap gap-2 flex">
                  Name
                </th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Id</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Phone number</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Email</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Gender</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">City</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Status</th>
              </tr>
            </thead>
            <tbody className="text-primary text-[14px] font-light">
              {loading ? (
                <tr>
                  <td colSpan="7" className="py-4 px-6 text-center text-gray-500">
                    Loading, Please wait...
                  </td>
                </tr>
              ) : currentCustomers.length === 0 ? (
                <tr>
                  <td colSpan="7" className="py-4 px-6 text-center text-gray-500">
                    No data found.
                  </td>
                </tr>
              ) : (
                currentCustomers.map((customer, index) => {
                  const status = calculateStatus(customer.last_login);

                  return (
                    <tr
                      key={index}
                      onClick={() => canAccess(user, 'view_customer') && navigate(`/dashboard/customers/${customer.id}/view`)}
                      className={`border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${canAccess(user, 'view_customer') ? '' : 'bg-opacity-50 cursor-not-allowed'}`}
                    >
                      <td className="py-4 px-6 text-left whitespace-nowrap text-[14px] font-light text-black">
                        <div className="flex gap-2 items-center uppercase font-medium">
                          <span>{customer.first_name} {customer.last_name}</span>
                        </div>
                      </td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        <span>{customer.id_number}</span>
                      </td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        <span>{customer.phone_number}</span>
                      </td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        <span>{customer.email}</span>
                      </td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        <span>{customer.gender}</span>
                      </td>
                      <td className="py-4 px-6 text-left whitespace-nowrap uppercase">
                        <span>{customer.city}</span>
                      </td>
                      <td
                        className={`py-4 px-6 text-left whitespace-nowrap`}
                      >
                        <span className={`px-2 py-1 rounded-full ${status === 'active' ? 'text-green-500 bg-green-100' : 'text-red-500 bg-red-100'}`}>{status}</span>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>

          {showFilters && (
            <Modal title="Filter" onClose={() => setShowFilters(false)}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label>Gender</label>
                  <select
                    name="gender"
                    value={filters.gender}
                    onChange={handleFilterChange}
                    className="block w-full border rounded-md p-2"
                  >
                    <option value="">All</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
                <div>
                  <label>Status</label>
                  <select
                    name="status"
                    value={filters.status}
                    onChange={handleFilterChange}
                    className="block w-full border rounded-md p-2"
                  >
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div>
                  <label>City</label>
                  <input
                    type="text"
                    name="city"
                    value={filters.city}
                    onChange={handleFilterChange}
                    className="block w-full border rounded-md p-2"
                  />
                </div>
              </div>

              <div className="mt-4 flex justify-end">
                <button
                  onClick={applyFilters}
                  className="px-4 py-2 bg-secondary text-white rounded-md"
                >
                  Apply Filters
                </button>
              </div>
            </Modal>
          )}

          {currentCustomers.length > 0 && (
            <div className="w-full flex justify-end p-4 mt-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="text-[14px] flex mr-1 items-center text-primary font-light gap-2"
              >
                Prev
              </button>
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i + 1}
                  onClick={() => paginate(i + 1)}
                  className={`px-3 py-1.5 mx-1 text-[14px] font-medium ${currentPage === i + 1 ? 'bg-primary text-white' : 'text-primary'} rounded-md`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="text-[14px] flex ml-1 items-center text-primary font-light gap-2"
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>

      {showAddCustomer && (
        <Modal onClose={() => setShowAddCustomer(false)} title={"Add New Customer"}>
          <form onSubmit={handleSubmit}>
            <div>
              <div className="flex mb-4 items-center gap-4">
                <div className='w-full'>
                  <label htmlFor="first_name" className="block text-black text-[14px] font-medium mb-1">First Name</label>
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    placeholder="E.g John"
                    className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
                </div>
                <div className='w-full'>
                  <label htmlFor="last_name" className="block text-black text-[12px] font-medium mb-1">Last Name</label>
                  <input
                    id="last_name"
                    name="last_name"
                    type="text"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    placeholder="E.g Doe"
                    className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-black text-[14px] font-medium mb-1">Email address</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="E.g johndoe@example.com"
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>
              <div className="relative w-full">
                <label htmlFor="gender" className="block text-black text-[14px] font-medium mb-1">Country of residenceu</label>
                <button
                  type="button"
                  className="border rounded-mmd mb-4 w-full border-gray-200 bg-transparent text-left px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                  onClick={toggleDropdown}
                >
                  {countryCodes.find(country => country.code === countryCode)?.name}
                  <img src={countryCodes.find(country => country.code === countryCode)?.flag} alt="flag" className="inline-block w-5 h-5 ml-2" />
                </button>
                {isDropdownOpen && (
                  <div className="absolute z-10 bg-white border border-gray-200 rounded mt-1 shadow-md max-h-[80vh] overflow-y-auto w-[300px]">
                    {countryCodes.map(country => (
                      <div
                        key={country.code}
                        className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
                        onClick={() => handleCountrySelect(country)}
                      >
                        <img src={country.flag} alt={`${country.name} flag`} className="w-5 h-5 mr-2" />
                        <span className='flex items-center gap-2'> {country.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label htmlFor="gender" className="block text-black text-[14px] font-medium mb-1">Gender</label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700">Phone Number</label>
                <div className="flex gap-2 items-center">
                  <div className="relative w-[35%]">
                    <button
                      type="button"
                      className="border-b w-full border-gray-200 bg-transparent text-left px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                      onClick={toggleDropdown}
                    >
                      {countryCode}
                      <img src={countryCodes.find(country => country.code === countryCode)?.flag} alt="flag" className="inline-block w-5 h-5 ml-2" />
                    </button>
                  </div>
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                    value={formData.phone_number}
                    placeholder='712345678'
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="flex w-full items-center gap-4">
                <div className='w-full'>
                  <label htmlFor="id_number" className="block text-black text-[14px] font-medium mb-1">ID number</label>
                  <input
                    id="id_number"
                    name="id_number"
                    type="text"
                    value={formData.id_number}
                    onChange={handleInputChange}
                    placeholder="E.g 73882289"
                    className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className='mt-4 w-full'>
                <label htmlFor="dob" className="block text-black text-[14px] font-medium mb-1">Date of birth</label>
                <input
                  id="dob"
                  name="dob"
                  type="date"
                  value={formData.dob}
                  onChange={handleInputChange}
                  placeholder="E.g 23/02/2003"
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>              
              <div className="w-full mt-4">
                <label htmlFor="nationality" className="block text-black text-[14px] font-medium mb-1">Nationality</label>
                <input
                  id="nationality"
                  name="nationality"
                  type="text"
                  value={formData.nationality}
                  onChange={handleInputChange}
                  placeholder="E.g Kenyan"
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>
              <div className="flex items-center w-full gap-4 mt-4">
                <div className="w-full">
                  <label htmlFor="city" className="block text-black text-[14px] font-medium mb-1">City</label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    value={formData.city}
                    onChange={handleInputChange}
                    placeholder="E.g Nairobi"
                    className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="state" className="block text-black text-[14px] font-medium mb-1">State</label>
                  <input
                    id="state"
                    name="state"
                    type="text"
                    value={formData.state}
                    onChange={handleInputChange}
                    placeholder="E.g Shiloh"
                    className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
                </div>
              </div>
              <div className="w-full mt-4 flex items-center gap-2 justify-end">
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={loading}
                  className="rounded-md bg-secondary text-white text-[13px] font-medium px-6 py-1.5"
                >
                  {loading ? 'Please wait...' : 'Submit'}
                </button>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </Layout>
  );
};

export default Customers;
