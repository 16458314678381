import React from 'react'

const ProjectMilestones = () => {
  return (
    <div>
        Project milestones
    </div>
  )
}

export default ProjectMilestones