import React, { useState, useEffect } from 'react';
import Layout from '../../elements/Layout';
import { fetchBookings } from '../../utils/api/api';
import Loading from '../../elements/Loading';
import { IoIosSearch, IoMdAdd } from 'react-icons/io';
import { FiPrinter } from "react-icons/fi";
import AddNewBooking from './AddNewBooking';

const SiteBookings = () => {
  const [activeTab, setActiveTab] = useState('actual');
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedBooking, setExpandedBooking] = useState(null); // To track expanded row

  const loadBookings = async () => {
    setLoading(true);
    setError(null);

    try {
      const data = await fetchBookings();
      setBookings(data.bookings);
    } catch (err) {
      setError('Failed to load bookings');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBookings();
  }, []);

  const handleModalClose = () => {
    setOpen(false);
    loadBookings();
  };

  useEffect(() => {
    const filtered = bookings.filter((booking) =>
      booking.project?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      booking.description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBookings(filtered);
  }, [searchQuery, bookings]);

  const actualBookings = bookings.filter((booking) => !booking.is_custom);
  const customBookings = bookings.filter((booking) => booking.is_custom);

  const handlePrint = () => {
    window.print();
  };

  // Helper function to truncate description to a specific word limit
  const truncateText = (text, limit) => {
    const words = text.split(' ');
    return words.length > limit ? words.slice(0, limit).join(' ') + '...' : text;
  };

  return (
    <Layout text="Site bookings">
      <div className="flex flex-col md:flex-row w-full justify-between items-center ">
        <div className="flex w-fit bg-gray-200 rounded-md">
          <button
            className={`px-6 py-1.5 transition-colors duration-300 rounded-md ${activeTab === 'actual'
              ? 'bg-secondary text-white'
              : 'text-gray-600 hover:bg-gray-300'
              }`}
            onClick={() => setActiveTab('actual')}
          >
            Actual
          </button>
          <button
            className={`px-6 py-1.5 transition-colors duration-300 rounded-md ${activeTab === 'custom'
              ? 'bg-secondary text-white'
              : 'text-gray-600 hover:bg-gray-300'
              }`}
            onClick={() => setActiveTab('custom')}
          >
            Custom
          </button>
        </div>
        <div className="flex gap-3 items-center">
          <div className="w-full md:w-fit bg-white rounded-md py-2 px-3 text-[14px] flex gap-2 items-center">
            <IoIosSearch className='text-gray-600' />
            <input
              type="text"
              placeholder='Search'
              className='bg-transparent h-full outline-none text-gray-600 w-[250px]'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <button
            onClick={() => setOpen(true)}
            className="flex gap-2 items-center px-6 bg-secondary py-1.5 rounded-md text-white text-[14px] font-medium"
          >
            <IoMdAdd /> Add Booking
          </button>
          <button
            onClick={handlePrint}
            className="flex gap-2 items-center px-6 border border-secondary bg-transparent py-1.5 rounded-md text-secondary text-[14px] font-medium"
          >
            <FiPrinter /> Print
          </button>
        </div>
      </div>

      <div className="mt-4">
        {loading && <Loading />}
        {error && <p className="text-red-500">{error}</p>}
        {activeTab === 'actual' && !loading && !error && actualBookings.length === 0 && <p>No actual bookings available.</p>}
        {activeTab === 'custom' && !loading && !error && customBookings.length === 0 && <p>No custom bookings available.</p>}

        {!loading && !error && filteredBookings.length > 0 && (
          <div className="w-full bg-white rounded-lg mt-4">
            <div className="overflow-x-auto rounded-lg">
              <table className="min-w-full bg-white h-full flex-grow rounded-lg border border-gray-200 table-auto">
                <thead>
                  <tr className='text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal'>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Project</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Description</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Date</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Time</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {(activeTab === 'actual' ? actualBookings : customBookings).map((booking, index) => (
                    <tr
                      key={booking.id}
                      className={`text-[14px] font-light text-black border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                      onClick={() => setExpandedBooking(booking.id === expandedBooking ? null : booking.id)}
                    >
                      <td className="py-4 px-6 text-left whitespace-nowrap">{booking?.project?.title}</td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        {expandedBooking === booking.id ? booking.description : truncateText(booking.description, 6)}
                      </td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">{booking.date}</td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">{booking.time}</td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">{booking?.project?.location}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {open && <AddNewBooking onClose={handleModalClose} />}
    </Layout>
  );
};

export default SiteBookings;
