import React from 'react';

const Tabs = ({ activeTab, setActiveTab }) => {
    return (
        <div className="flex mb-4 border-b">
            {['createRole', 'existingRoles', 'users'].map((tab) => (
                <button
                    key={tab}
                    className={`px-4 py-2 capitalize ${activeTab === tab ? 'border-b-2 border-secondary text-secondary' : 'text-gray-600'}`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab.replace(/([A-Z])/g, ' $1').trim()}
                </button>
            ))}
        </div>
    );
};

export default Tabs;
