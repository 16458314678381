import React from 'react'
import Layout from '../../elements/Layout'
import RevenueComparisonChart from './RevenueComparison'
import CampaignCostsPieChart from './CampaignCostsPieChart'

const Reports = () => {
  return (
    <Layout text="Reports">
      <div className="flex w-full gap-4 flex-col md:flex-row">
        <div className="flex w-full md:w-[60%]">
          <RevenueComparisonChart />
        </div>
        <div className="flex w-full md:w-[40%]">
          <CampaignCostsPieChart />
        </div>
      </div>      
    </Layout>
  )
}

export default Reports
