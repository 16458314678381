import React, { useState } from 'react';
import UserModal from './UserModal';
import ConfirmationModal from './ConfirmationModal';
import { deleteUser } from '../../utils/api/api';
import toast from 'react-hot-toast';

const UsersList = ({ users, setUsers }) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setIsEditMode(true);
        setIsModalOpen(true);
    };

    const handleDeleteUser = (userId) => {
        setIsConfirmationOpen(true); 
        setSelectedUser(userId); 
    };

    const confirmDeleteUser = async () => {
        try {
            await deleteUser(selectedUser);
            toast.success('User deleted successfully!');
            setUsers((prevUsers) => prevUsers.filter(user => user.id !== selectedUser));
        } catch (error) {
            toast.error('Failed to delete user');
        } finally {
            setIsConfirmationOpen(false); 
        }
    };

    const handleAddNewUser = () => {
        setSelectedUser(null);
        setIsEditMode(false);
        setIsModalOpen(true);
    };

    return (
        <div>
            <div className="flex w-full items-center justify-between mb-4">
                <h2 className="text-xl font-semibold mb-2">Users</h2>
                <button
                    onClick={handleAddNewUser}
                    className="w-fit flex justify-center py-1.5 px-6 border border-transparent text-[14px] items-center font-medium rounded-md text-white bg-secondary focus:outline-none"
                >
                    Add New User
                </button>
            </div>
            <table className="min-w-full bg-white rounded-lg p-2">
                <thead className='p-2'>
                    <tr className='p-2'>
                        <th className="border-b text-start py-2 border-primary text-black pl-4">ID</th>
                        <th className="border-b text-start py-2 border-primary text-black pl-4">Name</th>
                        <th className="border-b text-start py-2 border-primary text-black pl-4">Email</th>
                        <th className="border-b text-start py-2 border-primary text-black pl-4">Phone</th>
                        <th className="border-b text-start py-2 border-primary text-black pl-4">Role</th>
                        <th className="border-b text-start py-2 border-primary text-black pl-4">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id} className="hover:bg-gray-100 text-gray-600 text-[14px]">
                            <td className="border-b px-4 py-2">{user.id}</td>
                            <td className="border-b px-4 py-2">{user.first_name}</td>
                            <td className="border-b px-4 py-2">{user.email}</td>
                            <td className="border-b px-4 py-2">{user.phone_number}</td>
                            <td className="border-b px-4 py-2">{user?.role?.title}</td>
                            <td className="border-b px-4 py-2">
                                <button
                                    onClick={() => handleUserClick(user)}
                                    className="text-blue-600 hover:underline mr-2"
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={() => handleDeleteUser(user.id)}
                                    className="text-red-600 hover:underline"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {isModalOpen && (
                <UserModal
                    user={selectedUser}
                    onClose={() => setIsModalOpen(false)}
                    isEditMode={isEditMode}
                    setUsers={setUsers}
                />
            )}

            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={confirmDeleteUser}
            />
        </div>
    );
};

export default UsersList;
