import React from 'react';

const AssignRoleForm = ({ users, selectedUser, setSelectedUser, roles, selectedRole, setSelectedRole, onSubmit }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <div>
            <h2 className="text-xl font-semibold mb-2">Assign Role to User</h2>
            <form onSubmit={handleSubmit} className="bg-white p-6 shadow-md rounded-lg mb-6">
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1">Select User</label>
                    <select
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                        className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    >
                        <option value="">Choose a user</option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id}>{user.username}</option>
                        ))}
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1">Select Role</label>
                    <select
                        value={selectedRole}
                        onChange={(e) => setSelectedRole(e.target.value)}
                        className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    >
                        <option value="">Choose a role</option>
                        {roles.map((role) => (
                            <option key={role.id} value={role.id}>{role.title}</option>
                        ))}
                    </select>
                </div>

                <div className="flex justify-end">
                    <button type="submit" className="bg-secondary text-[14px] font-medium text-white py-1.5 px-6 rounded-md">
                        Assign Role
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AssignRoleForm;
