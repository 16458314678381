import React from 'react';
import { Button, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const EditRoleForm = ({ roleTitle, setRoleTitle, rolePermissions, setRolePermissions, onSubmit }) => {
    return (
        <div className="space-y-4">
            <TextField
                label="Role Title"
                variant="outlined"
                fullWidth
                value={roleTitle} 
                onChange={(e) => setRoleTitle(e.target.value)} 
            />
            <div>
                <strong>Permissions:</strong>
                <FormGroup>
                    {Object.entries(rolePermissions).map(([key, value]) => (
                        <FormControlLabel
                            key={key}
                            control={
                                <Checkbox
                                    checked={!!value}
                                    onChange={(e) => setRolePermissions({ ...rolePermissions, [key]: e.target.checked })}
                                />
                            }
                            label={key.replace('_', ' ')}
                        />
                    ))}
                </FormGroup>
            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => onSubmit({ title: roleTitle, permissions: rolePermissions })}
            >
                Update Role
            </Button>
        </div>
    );
};

export default EditRoleForm;
