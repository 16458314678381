import React, { useEffect, useState } from 'react';
import { addSubtask, fetchUsers } from '../../utils/api/api';
import toast from 'react-hot-toast';

const SubtaskForm = ({ taskId, onSubtaskAdded }) => {
    const [loading, setLoading] = useState(false);
    const [subtaskData, setSubtaskData] = useState({
        assigned_to: '',
        title: '',
        description: '',
        due_date: '',
        status: 'not_started',
    });
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await fetchUsers();
                setUsers(response.users);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        getUsers();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSubtaskData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const fullSubtaskData = {
            ...subtaskData,
            task_id: taskId,
        };

        try {
            const result = await addSubtask(fullSubtaskData);

            if (onSubtaskAdded) {
                onSubtaskAdded(result);
            }

            setSubtaskData({
                assigned_to: '',
                title: '',
                description: '',
                due_date: '',
                status: 'not_started',
            });
            toast.success('Subtask added')
        } catch (error) {
            toast.error('failed to add subtask')
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="">
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Assigned To</label>
                <select
                    name="assigned_to"
                    value={subtaskData.assigned_to}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                >
                    <option value="">Select Manager</option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.first_name} {user.last_name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Title</label>
                <input
                    type="text"
                    name="title"
                    value={subtaskData.title}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Description</label>
                <textarea
                    name="description"
                    value={subtaskData.description}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Due Date</label>
                <input
                    type="date"
                    name="due_date"
                    value={subtaskData.due_date}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Status</label>
                <select
                    name="status"
                    value={subtaskData.status}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                >
                    <option value="not_started">Not Started</option>
                    <option value="in_progress">In Progress</option>
                    <option value="completed">Completed</option>
                    <option value="on_hold">On Hold</option>
                </select>
            </div>
            <button 
                type="submit"
                disabled={loading}
                className="bg-secondary text-[14px] font-medium text-white px-6 py-1.5 rounded-md"
            >    
                    {loading ? 'Processing, wait..., ' : ' Add Subtask'}
            </button>
        </form>
    );
};

export default SubtaskForm;
