import React, { useState, useEffect } from 'react';
import Modal from '../../elements/Modal';
import { createBooking, getProjects } from '../../utils/api/api';
import toast from 'react-hot-toast';

const AddNewBooking = ({ onClose }) => {
    const [properties, setProperties] = useState([]);
    const [formData, setFormData] = useState({
        project_id: '',
        description: '',
        date: '',
        time: ''
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const loadProperties = async () => {
            setLoading(true);
            try {
                const data = await getProjects();
                setProperties(data.projects);
            } catch (error) {
                toast.error('Failed to fetch properties.');
            } finally {
                setLoading(false);
            }
        };

        loadProperties();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await createBooking(formData);
            toast.success('Booking created');
            setFormData({
                project_id: '',
                description: '',
                date: '',
                time: ''
            });
            onClose();
        } catch (error) {
            toast.error('Failed to create booking');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal onClose={onClose} title="Add new booking">
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="property" className="block text-black text-[14px] font-medium mb-1">Property</label>
                    {loading ? (
                        <p>Loading properties...</p>
                    ) : (
                        <select
                            name="project_id"
                            value={formData.project_id}
                            onChange={handleInputChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        >
                            <option value="">Select Property</option>
                            {properties.map((property) => (
                                <option key={property.id} value={property.id}>
                                    {property.title}
                                </option>
                            ))}
                        </select>
                    )}
                </div>

                <div className="mb-4">
                    <label htmlFor="description" className="block text-black text-[14px] font-medium mb-1">Description</label>
                    <textarea
                        name="description"
                        placeholder='Enter description'
                        value={formData.description}
                        onChange={handleInputChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        rows="4"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="date" className="block text-black text-[14px] font-medium mb-1">Date</label>
                    <input
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={handleInputChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="time" className="block text-black text-[14px] font-medium mb-1">Time</label>
                    <input
                        type="time"
                        name="time"
                        value={formData.time}
                        onChange={handleInputChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    />
                </div>

                <div className="flex w-full items-center gap-2 justify-end">
                    <button type='button' onClick={onClose} className="text-secondary font-medium text-[14px] ">Cancel</button>
                    <button
                        type="submit"
                        disabled={loading}
                        className="bg-secondary text-white px-6 py-1.5 rounded-md text-[14px] font-medium"
                    >
                        {loading ? 'Processing...' : 'Create Booking'}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default AddNewBooking;
