import React, { useEffect, useState } from 'react'
import Layout from '../../elements/Layout'
import { IoIosSearch, IoMdAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { getEmployees } from '../../utils/api/api'
import { FaList } from 'react-icons/fa'
import { IoGrid } from 'react-icons/io5'
import { CSVLink } from 'react-csv'

const Hr = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState('list');
  const navigate = useNavigate();

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const data = await getEmployees();
      setEmployees(data.employees);
      setFilteredEmployees(data.employees);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const toggleViewMode = (mode) => {
    setViewMode(mode);
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setSearchTerm(searchQuery);

    if (searchQuery.trim() === '') {
      setFilteredEmployees(employees);
    } else {
      const filtered = employees.filter(employee =>
        employee.full_name.toLowerCase().includes(searchQuery) ||
        employee.email.toLowerCase().includes(searchQuery) ||
        employee.phone_number.includes(searchQuery) ||
        employee.department.toLowerCase().includes(searchQuery)
      );
      setFilteredEmployees(filtered);
    }
  };

  const csvHeaders = [
    { label: 'Full Name', key: 'full_name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone Number', key: 'phone_number' },
    { label: 'Office Location', key: 'office_location' },
    { label: 'Employment Status', key: 'employment_status' },
    { label: 'Role', key: 'job' },
    { label: 'Department', key: 'department' }
  ];
  return (
    <Layout text="Human resource">
      <div className='w-full grid grid-cols-2 md:grid-cols-4 gap-4'>
        <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
          <p className="text-primary text-[13px]">Total Staff</p>
          <hr />
          <p className="text-primary text-[16px]">4378</p>
        </div>
        <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
          <p className="text-primary text-[13px]">Managers</p>
          <hr />
          <p className="text-primary text-[16px]">7854</p>
        </div>
        <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
          <p className="text-primary text-[13px]">Full time</p>
          <hr />
          <p className="text-primary text-[16px]">872</p>
        </div>
        <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
          <p className="text-primary flex items-center gap-2 text-[13px]">Others <span className="hidden md:flex"></span></p>
          <hr />
          <p className="text-primary text-[16px]">548</p>
        </div>
      </div>
      <div className="flex flex-col  bg-white rounded-lg w-full p-4 mt-4 ">
        <div className="flex w-full items-center justify-between">
          <div className="w-full md:w-fit border border-gray-300 bg-[#F7F7F7] rounded-md text-[13px] py-2 px-2 riunded-md flex gap-2 items-center">
            <IoIosSearch className='text-gray-600' />
            <input
              type="text"
              placeholder='Search'
              className='bg-transparent h-full outline-none text-gray-600 w-[250px]'
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="flex items-center gap-4 ">
            <CSVLink
              data={filteredEmployees}
              headers={csvHeaders}
              filename="employees_report.csv"
              className="border border-secondary px-4 py-1.5 text-secondary rounded-md text-[14px] font-medium"
            >
              Export report
            </CSVLink>
            <button
              onClick={() => navigate('/dashboard/hr/employees/add-new')}
              className="bg-secondary text-white rounded-md px-4 py-1.5 border border-secondary flex items-center gap-2 text-[14px]"><IoMdAdd /> Add Employee</button>
            <button
              className={`p-1.5 ${viewMode === 'list' ? 'bg-secondary text-white' : 'bg-gray-200'} rounded-md`}
              onClick={() => toggleViewMode('list')}
            >
              <FaList />
            </button>
            <button
              className={`p-1.5 ${viewMode === 'grid' ? 'bg-secondary text-white' : 'bg-gray-200'} rounded-md`}
              onClick={() => toggleViewMode('grid')}
            >
              <IoGrid />
            </button>
          </div>

        </div>
        {viewMode === 'list' ? (
          <div className="w-full bg-white rounded-lg mt-4">
            <div className="overflow-x-auto rounded-lg">
              <table className="min-w-full bg-white h-full flex-grow rounded-lg border border-gray-200 table-auto">
                <thead>
                  <tr className="text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Name</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Email</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Phone</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Office</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Type</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Role</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Department</th>
                    <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody className="text-primary text-[14px] font-light">
                  {filteredEmployees.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="py-4 px-6 text-center text-gray-500">
                        {loading ? 'Loading, please wait' : 'No data found'}
                      </td>
                    </tr>
                  ) : (
                    filteredEmployees.map((employee, index) => {
                      const details = employee?.employee_details || {};

                      return (
                        <tr
                          key={index}
                          className={`text-[14px] font-light text-black border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                            }`}
                        >
                          <td className="py-4 px-6 text-left whitespace-nowrap">
                            <div className="flex gap-2 items-center">
                              <img
                                src={details?.photo || 'https://imgs.search.brave.com/fbxInw05M6mkNEHaT64Qm3dNEzTIVXrwCD4lxk4ve3A/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAyLzE3LzM0LzY3/LzM2MF9GXzIxNzM0/Njc4Ml83WHBDVHQ4/YkxOSnF2VkFhRFpK/d3Zaam0wZXBRbWo2/ai5qcGc'}
                                className="w-[40px] h-[40px] rounded-full"
                                alt={`${employee.first_name} ${employee.last_name}`}
                              />
                              {employee.first_name} {employee.last_name}
                            </div>
                          </td>

                          <td className="py-4 px-6 text-left whitespace-nowrap">
                            <span>{details.email || '--'}</span>
                          </td>

                          <td className="py-4 px-6 text-left whitespace-nowrap">
                            <span>{details.phone_number || '--'}</span>
                          </td>

                          <td className="py-4 px-6 text-left whitespace-nowrap">
                            <span>{details.office_location || '--'}</span>
                          </td>

                          <td className="py-4 px-6 text-left capitalize whitespace-nowrap">
                            <span>{details.employment_status || '--'}</span>
                          </td>

                          <td className="py-4 px-6 text-left whitespace-nowrap">
                            <span>{details.job || '--'}</span>
                          </td>

                          <td className="py-4 px-6 text-left whitespace-nowrap">
                            <span>{details.department || '--'}</span>
                          </td>

                          <td className="py-4 px-6 text-left whitespace-nowrap">
                            {!employee.employee_details ? (
                              <button
                                onClick={() => navigate(`/dashboard/employees/${employee.id}/detail`)}
                                className="text-white bg-red-500 hover:bg-red-600 font-semibold py-2 px-4 rounded-md transition-all duration-300 ease-in-out shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                              >
                                Update Info
                              </button>
                            ) : (
                              <button
                                  onClick={() => navigate(`/dashboard/employees/${employee.employee_details.id}/view`)}
                                className="text-white bg-blue-500 hover:bg-blue-600 font-semibold py-2 px-4 rounded-md transition-all duration-300 ease-in-out shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                              >
                                View
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
            {filteredEmployees.length === 0 ? (
              <p className="text-center text-gray-500 col-span-4">{loading ? 'Loading, please wait' : 'No data found'}</p>
            ) : (
              employees.map((employee, index) => (
                <div
                  key={index}
                  className="border border-gray-100 bg-white rounded-lg shadow-md pb-4 cursor-pointer hover:shadow-lg transition-shadow"
                  onClick={() => navigate(`/dashboard/employees/${employee.id}/view`)}
                >
                  <div className="flex w-full h-[50px] bg-secondary rounded-t-lg"></div>
                  <img src={employee.photo} className='w-[60px] h-[60px] -mt-[30px] rounded-full mx-auto' alt="" />
                  <p className="text-center mt-2 text-[16px] font-medium">{employee.full_name}</p>
                  <p className="text-center text-gray-500 text-[14px]">{employee.job}</p>
                  <p className="text-center text-gray-500 text-[14px]">{employee.department}</p>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Hr