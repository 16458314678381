import React, { useEffect, useState } from 'react'
import Layout from '../../elements/Layout'
import Progress from './Progress'
import ActualPlanned from './ActualPlanned'
import SalesByProperty from './SalesByProperty'
import SalesRevenue from '../../components/SalesRevenue'
import { fetchSalesReports } from '../../utils/api/api'
import toast from 'react-hot-toast'

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSales = async () => {
    setLoading(true)
    try {
      const response = await fetchSalesReports();
      setSales(response.sales_reports);
    } catch (error) {
      toast.error("Failed to get report")
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSales();
  }, [])

  return (
    <Layout text="Sales">
      <div className="flex flex-col gap-4 w-full md:flex-row">
       <div className="flex w-full md:w-[60%] ">
          <ActualPlanned />
       </div>
       <div className="flex w-full md:w-[40%]">
        <SalesByProperty text="Month" />
       </div>
      </div>
      <div className="flex flex-col mt-4 gap-4 w-full md:flex-row">
        <div className="flex w-full md:w-[60%] ">
          <SalesRevenue salesReports={sales} text="Sales Revenue Over Time" />
        </div>
        <div className="flex w-full md:w-[40%]">
          <SalesByProperty text="year" />
        </div>
      </div>
    </Layout>
  )
}

export default Sales