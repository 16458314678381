import React, { useEffect, useState } from 'react'
import Layout from '../../elements/Layout'
import { FaAngleRight } from 'react-icons/fa'
import toast from 'react-hot-toast';
import { addProject, fetchUsers } from '../../utils/api/api';
import { useNavigate } from 'react-router-dom';
import Loading from '../../elements/Loading';

const AddNewProject = () => {
    const [projectData, setProjectData] = useState({
        title: '',
        description: '',
        location: '',
        project_type: '',
        price: '',
        size: '',
        start_date: '',
        amenities: '',
        end_date: '',
        status: 'not_started',
        progress: '',
        installment_price: '',
        manager_id: '',
        deposit: '',
        monthly_installment: '',
        months: '',
        project_map: null
    });

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getUsers = async () => {
            try {
                const data = await fetchUsers();
                setUsers(data.users);
                setLoading(false);
            } catch (err) {
                toast.error('Failed to fetch users.');
                setLoading(false);
            }
        };

        getUsers();
    }, []);

    const handleManagerChange = (event) => {
        const selectedManagerId = event.target.value;
        setProjectData((prevData) => ({
            ...prevData,
            manager_id: selectedManagerId,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProjectData({
            ...projectData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();

        for (const key in projectData) {
            if (projectData[key]) {
                formData.append(key, projectData[key]);
            }
        }

        try {
            await addProject(formData);
            toast.success("Project added.");
            setProjectData({
                title: '',
                description: '',
                location: '',
                project_type: '',
                amenities: '',
                price: '',
                size: '',
                start_date: '',
                end_date: '',
                status: 'not_started',
                progress: '',                
                manager_id: '',
                deposit: '',
                monthly_installment: '',
                months: '',
                project_map: null
            });
            navigate('/dashboard/projects/land');
        } catch (error) {
            toast.error("An error occurred.");
        } finally {
            setLoading(false);
        }
    };

  return (
    <Layout text="New Project">
        {loading && <Loading />}
        <div className="flex w-full items-center gap-2 border-b border-gray-300">
            <p className="text-black font-medium text-[20px]">Projects</p>
              <FaAngleRight />
            <p className="text-[12px] font-light text-gray-700">Add new project</p>
        </div>
        <div className="w-full md:max-w-2xl mx-auto rounded-lg p-4 mt-4 bg-white flex flex-col gap-3">
            <p className="text-[22px] text-primary font-medium ">
                Add new project
            </p>

              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                  <div>
                      <div className="flex w-full gap-4 items-center">
                          <div className='w-full flex flex-col mb-4'>
                              <label className='block text-black text-[14px] font-medium mb-1' htmlFor="manager">Select Manager</label>
                              <select
                                  className='border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary'
                                  id="manager"
                                  value={projectData.manager_id}
                                  onChange={handleManagerChange}
                              >
                                  <option value="">Select Project Manager</option>
                                  {users.map(user => (
                                      <option key={user.id} value={user.id}>
                                          {user.first_name} {user.last_name}
                                      </option>
                                  ))}
                              </select>
                          </div>

                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="title" className="block text-black text-[14px] font-medium mb-1">Name</label>
                              <input
                                  type="text"
                                  name="title"
                                  value={projectData.title}
                                  onChange={handleInputChange}
                                  placeholder="Enter Project name"
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              />
                          </div>
                      </div>

                      <div className="w-full flex flex-col mb-4">
                          <label htmlFor="description" className="block text-black text-[14px] font-medium mb-1">Description</label>
                          <textarea
                              name="description"
                              value={projectData.description}
                              onChange={handleInputChange}
                              placeholder="Project Description"
                              className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              rows="4"
                              required
                          />
                      </div>                      

                      <div className="flex flex-col md:flex-row items-center justify-center w-full gap-4">
                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="location" className="block text-black text-[14px] font-medium mb-1">Location</label>
                              <input
                                  type="text"
                                  name="location"
                                  value={projectData.location}
                                  onChange={handleInputChange}
                                  placeholder="Project Location"
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              />
                          </div>
                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="start_date" className="block text-black text-[14px] font-medium mb-1">Start Date</label>
                              <input
                                  type="date"
                                  name="start_date"
                                  value={projectData.start_date}
                                  onChange={handleInputChange}
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              />
                          </div>

                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="end_date" className="block text-black text-[14px] font-medium mb-1">End Date</label>
                              <input
                                  type="date"
                                  name="end_date"
                                  value={projectData.end_date}
                                  onChange={handleInputChange}
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              />
                          </div>
                      </div>

                      <div className="flex w-full items-center gap-4">
                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="status" className="block text-black text-[14px] font-medium mb-1">Status</label>
                              <select
                                  name="status"
                                  value={projectData.status}
                                  onChange={handleInputChange}
                                  className="bg-transparent border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              >
                                  <option value="">Select project status</option>
                                  <option value="not_started">Not Started</option>
                                  <option value="in_progress">In Progress</option>
                                  <option value="completed">Completed</option>
                                  <option value="coming_soon">Coming soon</option>
                                  <option value="sold_out">Sold Out</option>
                                  <option value="on_hold">On Hold</option>
                              </select>
                          </div>

                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="project_type" className="block text-black text-[14px] font-medium mb-1">Project type</label>
                              <select
                                  name="project_type"
                                  value={projectData.project_type}
                                  onChange={handleInputChange}
                                  className="bg-transparent border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              >
                                  <option value="">Select project type</option>
                                  <option value="land">Land</option>
                                  <option value="housing">Housing</option>
                              </select>
                          </div>
                      </div>
                      <div className="flex flex-col md:flex-row items-center justify-center w-full gap-4 ">
                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="price" className="block text-black text-[14px] font-medium mb-1">Cash Price</label>
                              <input
                                  type="number"
                                  name="price"
                                  value={projectData.price}
                                  onChange={handleInputChange}
                                  placeholder="Price"
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              />
                          </div>
                      </div>

                      <div className="flex items-center gap-4 w-full">
                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="installment_price" className="block text-black text-[14px] font-medium mb-1">Installment price</label>
                              <input
                                  type="number"
                                  name="installment_price"
                                  value={projectData.installment_price}
                                  onChange={handleInputChange}
                                  placeholder="Inatallment price"
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              />
                          </div>

                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="deposit" className="block text-black text-[14px] font-medium mb-1">Deposit</label>
                              <input
                                  type="number"
                                  name="deposit"
                                  value={projectData.deposit}
                                  onChange={handleInputChange}
                                  placeholder="Deposit"
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              />
                          </div>

                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="monthly_installment" className="block text-black text-[14px] font-medium mb-1">Monthly Installment</label>
                              <input
                                  type="number"
                                  name="monthly_installment"
                                  value={projectData.monthly_installment}
                                  onChange={handleInputChange}
                                  placeholder="Monthly Installment"
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              />
                          </div>
                      </div>                      

                      <div className="flex flex-col md:flex-row items-center justify-center w-full gap-4">                                                   
                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="progress" className="block text-black text-[14px] font-medium mb-1">Progress</label>
                              <input
                                  type="number"
                                  name="progress"
                                  value={projectData.progress}
                                  onChange={handleInputChange}
                                  placeholder="Progress"
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              />
                          </div>
                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="months" className="block text-black text-[14px] font-medium mb-1">Months</label>
                              <input
                                  type="number"
                                  name="months"
                                  value={projectData.months}
                                  onChange={handleInputChange}
                                  placeholder="Months"
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                  required
                              />
                          </div>
                      </div>
                      

                      <div className="w-full items-center flex justify-center gap-4 ">                 
                          {/* Size */}
                          <div className="w-full flex flex-col mb-4">
                              <label htmlFor="size" className="block text-black text-[14px] font-medium mb-1">Size</label>
                              <input
                                  type="text"
                                  name="size"
                                  value={projectData.size}
                                  onChange={handleInputChange}
                                  placeholder="Size"
                                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              />
                          </div>
                      </div>

                      {/* Amenities */}
                      <div className="w-full flex flex-col mb-4">
                          <label htmlFor="amenities" className="block text-black text-[14px] font-medium mb-1">Amenities</label>
                          <input
                              type="text"
                              name="amenities"
                              value={projectData.amenities}
                              onChange={handleInputChange}
                              placeholder="Amenities"
                              className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                          />
                      </div>
                      <div className="w-full flex flex-col mb-4">
                          <label htmlFor="project_map" className="block text-black text-[14px] font-medium mb-1">Project Map</label>
                          <input
                              type="file"
                              name="project_map"
                              accept=".png, .jpg, .jpeg, .pdf"
                              onChange={(e) => setProjectData({
                                  ...projectData,
                                  project_map: e.target.files[0]
                              })}
                              className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              required
                          />
                      </div>

                      <div className="flex w-full items-center gap-4 justify-end">
                          <button
                              type="button"
                              className="text-secondary font-medium text-[14px]"
                          >
                              
                          </button>
                          <button
                              type="submit"
                              disabled={loading}
                              className="rounded-md bg-secondary text-white text-[13px] font-medium px-6 py-1.5"
                          >
                              {loading ? 'Submitting...' : 'Add Project'}
                          </button>
                      </div>
                  </div>
              </form>
        </div>
    </Layout>
  )
}

export default AddNewProject