import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const RevenueComparisonChart = () => {
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const data = {
        labels,
        datasets: [
            // {
            //     label: '2024 Revenue',
            //     data: [12000, 15000, 13000, 17000, 18000, 20000, 22000, 24000, 21000, 23000, 25000, 27000],
            //     borderColor: '#4ade80',
            //     borderWidth: 2,
            //     fill: false,
            //     tension: 0.4,
            //     pointRadius: 0.2,
            // },
            {
                label: '2023 Revenue',
                data: [10000, 13000, 12000, 14000, 16000, 17000, 19000, 20000, 18000, 21000, 22000, 24000],
                borderColor: '#fbbf24',
                backgroundColor: 'transparent',
                borderWidth: 2,
                fill: false,
                tension: 0.4,
                pointRadius: 0.2,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#000',
                },
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    color: '#000',
                },
                grid: {
                    borderDash: [5, 5],
                    color: '#f8f8f8',
                },
            },
        },
    };

    return (
        <div className="bg-white p-6 rounded-lg w-full">
            <h2 className="text-xl font-bold mb-4">Revenue Comparison</h2>
            <Line data={data} options={options} />
        </div>
    );
};

export default RevenueComparisonChart;
