import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../elements/Layout';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { fetchContractDocs, getContract, submitContractDocument } from '../../utils/api/api';
import { FaDownload } from 'react-icons/fa';
import Loading from '../../elements/Loading';
import { MdAdd, MdOutlineAttachment } from 'react-icons/md';
import seal from '../../assets/MILESCOOP_SEAL.webp'
import Modal from '../../elements/Modal';
import OfferLetter from './OfferLetter';
import CompletonCertificate from './CompletonCertificate';
import SalesAgreement from './SalesAgreement';
import html2pdf from 'html2pdf.js';
import AuditTrail from './AuditTrail';

const ViewContract = () => {
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [note, setNote] = useState('');
  const [doc, setDoc] = useState(null);
  const { id } = useParams();

  const fetchContract = async () => {
    setLoading(true);
    try {
      const response = await getContract(id);
      setContract(response.contract);
    } catch (error) {
      toast.error("Failed to get contract");
    } finally {
      setLoading(false);
    }
  };

  const pdfRef = useRef();

  const handleDownload = () => {
    const element = pdfRef.current;

    if (element) {
      const options = {
        margin: 0.5,
        filename: `_${contract?.customer?.first_name}_${contract?.customer?.last_name}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };

      html2pdf().from(element).set(options).save();
    } else {
      console.error("PDF Ref is not set");
    }
  };

  useEffect(() => {
    fetchContract();
    getDocs();
  }, [id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDoc(file);
    }
  };

  const handleAddDocument = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('contract_id', id);
    formData.append('title', title);
    formData.append('note', note);
    formData.append('doc', doc);
    setLoading(true);

    try {
      await submitContractDocument(formData);
      setTitle('');
      setNote('');
      setDoc(null);
      setOpen(false);
      getDocs();
      toast.success("Document added.")
      setLoading(false);
    } catch (error) {
      toast.error('Failed to add document.');
      setLoading(false);
    }
  };

  const getDocs = async () => {
    try {
      const data = await fetchContractDocs(id);
      setDocuments(data.documents);
    } catch (err) {
      toast.error('Failed to get docs.');
    }
  };

  if (loading) return <Loading />

  if (!contract) return <div>No contract found</div>;

  return (
    <Layout text={"View Contract"}>
      <div className="flex justify-end w-full items-center mb-4">
        <div className="space-x-2 bg-white px-4 py-1 rounded-md shadow-md">
          <button onClick={handleDownload} className="text-black">
            <FaDownload className="inline-block mr-2" />
          </button>
          {/* <button onClick={handlePrint} className="text-black">
            <FaPrint className="inline-block mr-2" />
          </button> */}
        </div>
      </div>      

      <div ref={pdfRef}>
        {contract?.type === 'offer_letter' && <OfferLetter contract={contract} />}
        {contract?.type === 'sales_agreement' && <SalesAgreement contract={contract} />}
        {contract?.type === 'completion_letter' && <CompletonCertificate contract={contract} />}
      </div>

      <div className="w-full flex flex-col mt-4 border-gray-400 bg-white p-4 rounded-lg">
        <div className="w-full flex items-center justify-between">
          <div className="flex flex-col">
            <p className="text-black text-[16px] font-medium">Documents</p>
          </div>
          <button
            className="flex items-center gap-2 px-4 py-1.5 bg-secondary text-white text-[14px] rounded-md outline-none"
            onClick={() => setOpen(true)}
          >
            <MdAdd />
            Add note & Doc
          </button>
        </div>

        <div className="w-full bg-white rounded-lg mt-4">
          <div className="overflow-x-auto rounded-lg">
            <table className="min-w-full bg-white h-full flex-grow rounded-lg border border-gray-200 table-auto">
              <thead>
                <tr className="text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
                  <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Title</th>
                  <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Note</th>
                  <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Date</th>
                  <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Attachment</th>
                </tr>
              </thead>
              <tbody className="text-gray-700 text-[13px]">
                {documents.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="py-4 px-6 text-center text-gray-500">
                      {loading ? 'Loading, please wait' : 'No data found'}
                    </td>
                  </tr>
                ) : (
                  documents.map((document, index) => (
                    <tr
                      key={index}
                      className={`border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                        }`}
                    >
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        <span>{document.title}</span>
                      </td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        {document.note.split(' ').slice(0, 5).join(' ')}...
                      </td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        <span>{new Date(document.createdAt).toLocaleDateString()}</span>
                      </td>
                      <td className="py-4 px-6 text-left whitespace-nowrap">
                        <a href={document.file} target='_blank' rel="noopener noreferrer"><MdOutlineAttachment size={24} className='' /></a>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {open && (
          <Modal title="Add Document" onClose={() => setOpen(false)}>
            <form onSubmit={handleAddDocument}>
              <div className="w-full mb-4">
                <label htmlFor="title" className="block text-black text-[14px] font-medium mb-1">Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Document Title"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>
              <div className="w-full mb-4">
                <label htmlFor="note" className="block text-black text-[14px] font-medium mb-1">Note</label>
                <textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Note"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>
              <input
                type="file"
                onChange={handleFileChange}
                className="block w-full mb-2"
              />
              <button
                type="submit"
                className="mt-4 bg-secondary text-white px-4 py-1.5 text-[14px] font-medium rounded-md"
              >
                Add Document
              </button>
            </form>
          </Modal>
        )}
      </div>
      <AuditTrail contractAudits={contract?.contract_audits} />
    </Layout>
  );
};

export default ViewContract;
