import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { deleteRole, getRole, updateRole } from '../../utils/api/api';
import EditRoleForm from './EditRoleForm';
import toast from 'react-hot-toast';
import moment from 'moment';
import Loading from '../../elements/Loading';

const ExistingRolesList = ({ roles }) => {
    const [selectedRole, setSelectedRole] = useState(null);
    const [isViewingDetails, setIsViewingDetails] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleViewDetails = async (roleId) => {
        setLoading(true);
        setError(null);
        try {
            const response = await getRole(roleId.toString());
            const roleData = response.role;

            const flattenedRoleData = {
                id: roleData.id,
                title: roleData.title,
                create_users: roleData.create_users,
                list_users: roleData.list_users,
                view_users: roleData.view_users,
                edit_users: roleData.edit_users,
                delete_users: roleData.delete_users,
                create_user_roles: roleData.create_user_roles,
                list_user_roles: roleData.list_user_roles,
                view_user_roles: roleData.view_user_roles,
                edit_user_roles: roleData.edit_user_roles,
                delete_user_roles: roleData.delete_user_roles,
                create_customer: roleData.create_customer,
                list_customer: roleData.list_customer,
                view_customer: roleData.view_customer,
                edit_customer: roleData.edit_customer,
                delete_customer: roleData.delete_customer,
                view_dashboard_analytics: roleData.view_dashboard_analytics,
                view_reports: roleData.view_reports,
                create_project: roleData.create_project,
                list_project: roleData.list_project,
                view_project: roleData.view_project,
                edit_project: roleData.edit_project,
                delete_project: roleData.delete_project,
                create_property: roleData.create_property,
                list_property: roleData.list_property,
                view_property: roleData.view_property,
                edit_property: roleData.edit_property,
                delete_property: roleData.delete_property,
                create_contract: roleData.create_contract,
                list_contract: roleData.list_contract,
                view_contract: roleData.view_contract,
                edit_contract: roleData.edit_contract,
                delete_contract: roleData.delete_contract,
                create_human_resource: roleData.create_human_resource,
                list_human_resource: roleData.list_human_resource,
                view_human_resource: roleData.view_human_resource,
                edit_human_resource: roleData.edit_human_resource,
                delete_human_resource: roleData.delete_human_resource,
                create_orders: roleData.create_orders,
                list_orders: roleData.list_orders,
                view_orders: roleData.view_orders,
                edit_orders: roleData.edit_orders,
                delete_orders: roleData.delete_orders,
                create_payments: roleData.create_payments,
                list_payments: roleData.list_payments,
                view_payments: roleData.view_payments,
                edit_payments: roleData.edit_payments,
                delete_payments: roleData.delete_payments,
                create_leads: roleData.create_leads,
                list_leads: roleData.list_leads,
                view_leads: roleData.view_leads,
                edit_leads: roleData.edit_leads,
                delete_leads: roleData.delete_leads,
                create_bookings: roleData.create_bookings,
                list_bookings: roleData.list_bookings,
                view_bookings: roleData.view_bookings,
                edit_bookings: roleData.edit_bookings,
                delete_bookings: roleData.delete_bookings,
                create_tickets: roleData.create_tickets,
                list_tickets: roleData.list_tickets,
                view_tickets: roleData.view_tickets,
                edit_tickets: roleData.edit_tickets,
                delete_tickets: roleData.delete_tickets,
                create_communication: roleData.create_communication,
                list_communication: roleData.list_communication,
                view_communication: roleData.view_communication,
                edit_communication: roleData.edit_communication,
                delete_communication: roleData.delete_communication,
                create_tasks: roleData.create_tasks,
                list_tasks: roleData.list_tasks,
                view_tasks: roleData.view_tasks,
                edit_tasks: roleData.edit_tasks,
                delete_tasks: roleData.delete_tasks,
                create_events: roleData.create_events,
                list_events: roleData.list_events,
                view_events: roleData.view_events,
                edit_events: roleData.edit_events,
                delete_events: roleData.delete_events,
                create_settings: roleData.create_settings,
                view_settings: roleData.view_settings,
                edit_settings: roleData.edit_settings,
                delete_settings: roleData.delete_settings,
            };

            setSelectedRole(flattenedRoleData);
            setIsViewingDetails(true);
        } catch (err) {
            toast.error('Failed to fetch role details. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = async (roleId, updatedRole) => {
        if (!updatedRole || !updatedRole.title) {
            toast.error('Role title is required');
            return;
        }

        const payload = {
            id: roleId,
            title: updatedRole.title,
            ...updatedRole.permissions,
        };
        setLoading(true);

        try {
            await updateRole(payload);
            setIsEditing(false);
            toast.success('Updated.')
        } catch (err) {
            toast.error('Failed to update role. Please try again.');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = () => {
        deleteRole(selectedRole.id);
        setIsViewingDetails(false);
    };

    return (
        <div className="p-4">
            {loading && <Loading />}
            <h2 className="text-2xl font-semibold mb-4">Existing Roles</h2>
            <ul className="list-none space-y-4">
                {roles.map((role) => (
                    <li key={role.id} className="flex justify-between items-center p-4 bg-white shadow rounded-md">
                        <span className="text-lg text-gray-700">{role.title}</span>
                        <div className="flex items-center space-x-2">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<Visibility />}
                                onClick={() => handleViewDetails(role.id)}
                            >
                                View Details
                            </Button>
                        </div>
                    </li>
                ))}
            </ul>

            <Dialog open={isViewingDetails} onClose={() => setIsViewingDetails(false)} maxWidth="md" fullWidth>
                <DialogTitle>Role Details</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <div className="flex justify-center items-center p-4">
                            <CircularProgress />
                        </div>
                    ) : error ? (
                        <div className="text-red-500 p-4 text-center font-semibold">
                            {error}
                        </div>
                    ) : selectedRole && !isEditing ? (
                        <div className="p-6 bg-white rounded-lg shadow-md space-y-6">
                            <div className="flex justify-between items-center">
                                <div>
                                    <h3 className="text-2xl font-bold mb-2 text-gray-800">{selectedRole.title}</h3>
                                    <p className="text-gray-500 text-sm">Role ID: {selectedRole.id}</p>
                                </div>
                                <div className="text-right">
                                    <p className="text-sm text-gray-500">
                                        <strong>Created At:</strong> {moment(selectedRole.createdAt).format('MMM Do YYYY, h:mm A')}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        <strong>Last Updated:</strong> {moment(selectedRole.updatedAt).format('MMM Do YYYY, h:mm A')}
                                    </p>
                                </div>
                            </div>

                            <div className="bg-gray-50 p-4 rounded-lg">
                                <h4 className="text-lg font-semibold text-gray-700 mb-4">Permissions</h4>
                                <ul className="list-none pl-0 grid grid-cols-2 gap-4">
                                    {Object.entries(selectedRole).filter(([key]) => key !== 'id' && key !== 'title' && key !== 'createdAt' && key !== 'updatedAt').map(([key, value]) => (
                                        <li key={key} className="flex items-center text-gray-700">
                                            {value ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 0 0-1.414 0L9 11.586 5.707 8.293a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0 0-1.414z" clipRule="evenodd" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-9a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1zm2 3a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1h1a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z" clipRule="evenodd" />
                                                </svg>
                                            )}
                                            <span className="capitalize">{key.replace(/_/g, ' ')}: {value ? 'Granted' : 'Not Granted'}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ) : null}

                    {isEditing && selectedRole && (
                        <EditRoleForm
                            roleTitle={selectedRole.title}
                            setRoleTitle={(newTitle) => setSelectedRole({ ...selectedRole, title: newTitle })}
                            rolePermissions={Object.fromEntries(
                                Object.entries(selectedRole).filter(([key]) => key !== 'id' && key !== 'title')
                            )}
                            setRolePermissions={(updatedPermissions) => setSelectedRole({ ...selectedRole, ...updatedPermissions })}
                            onSubmit={(updatedData) => handleUpdate(selectedRole.id, updatedData)}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    {!isEditing && !loading && (
                        <>
                            <Button
                                onClick={() => setIsEditing(true)}
                                color="primary"
                                startIcon={<Edit />}
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={handleDelete}
                                color="secondary"
                                startIcon={<Delete />}
                            >
                                Delete
                            </Button>
                        </>
                    )}
                    {isEditing && (
                        <Button
                            onClick={() => setIsEditing(false)}
                            color="primary"
                        >
                            Cancel
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default ExistingRolesList;
