import React, { useEffect, useState } from 'react';
import Layout from '../../elements/Layout';
import LeadsDocuments from './LeadsDocuments';
import LeadsInteractions from './LeadsInteractions';
import { useParams } from 'react-router-dom';
import { fetchLeadById } from '../../utils/api/api';
import toast from 'react-hot-toast';
import { MdOutlineMailOutline, MdOutlineMessage } from 'react-icons/md';
import { CiEdit } from 'react-icons/ci';
import LeadsInfo from './LeadsInfo';
import { FaRegUser } from 'react-icons/fa';
import { IoMdPaper } from 'react-icons/io';
import { LuPointer } from 'react-icons/lu';
import EditLeadModal from './EditLeadModal';
import Loading from '../../elements/Loading';

const ViewLead = () => {
    const [activeTab, setActiveTab] = useState('Info');
    const { id } = useParams();
    const [lead, setLead] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const leadId = id;

    const getLead = async () => {
        try {
            const data = await fetchLeadById(leadId);
            setLead(data.lead);
            setLoading(false);
        } catch (err) {
            toast.error("Request failed.");
            setLoading(false);
        }
    };

    useEffect(() => {       
        getLead();
    }, [leadId]);

    return (
        <Layout text="Lead Profile">
            {loading && <Loading />}

            <div className="flex flex-col items-center bg-white rounded-lg shadow-sm p-6 mb-6">
                <div className="w-[100px] h-[100px] rounded-full overflow-hidden mb-4">
                    <img
                        src="https://imgs.search.brave.com/fbxInw05M6mkNEHaT64Qm3dNEzTIVXrwCD4lxk4ve3A/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAyLzE3LzM0LzY3/LzM2MF9GXzIxNzM0/Njc4Ml83WHBDVHQ4/YkxOSnF2VkFhRFpK/d3Zaam0wZXBRbWo2/ai5qcGc"
                        alt="Lead Profile"
                        className="w-full h-full object-cover"
                    />
                </div>

                <h2 className="text-2xl font-semibold text-gray-800">{lead?.title}</h2>
                <p className="text-sm text-gray-500 mb-4">{lead?.status}</p>

                <div className="flex gap-4">
                    <button className="bg-primary text-white px-4 py-2 rounded-md flex items-center gap-2">
                        <MdOutlineMessage size={18} />
                        Message
                    </button>
                    <button className="bg-secondary text-white px-4 py-2 rounded-md flex items-center gap-2">
                        <MdOutlineMailOutline size={18} />
                        Email
                    </button>
                    <button onClick={() => setIsEditModalOpen(true)} className="bg-gray-100 text-gray-800 px-4 py-2 rounded-md flex items-center gap-2">
                        <CiEdit size={18} />
                        Edit
                    </button>
                </div>
            </div>

            <div className="w-full flex flex-col md:flex-row gap-6">
                <div className="w-full md:w-[25%] bg-white p-4 rounded-lg h-fit shadow-sm">
                    <div className="flex flex-col gap-4">
                        <button
                            className={`py-2 px-4 text-sm font-medium rounded-md ${activeTab === 'Info' ? 'bg-primary text-white' : 'bg-gray-100 text-gray-800'}`}
                            onClick={() => setActiveTab('Info')}
                        >
                            <FaRegUser size={18} className="mr-2 inline-block" />
                            Info
                        </button>
                        <button
                            className={`py-2 px-4 text-sm font-medium rounded-md ${activeTab === 'Documents' ? 'bg-primary text-white' : 'bg-gray-100 text-gray-800'}`}
                            onClick={() => setActiveTab('Documents')}
                        >
                            <IoMdPaper size={18} className="mr-2 inline-block" />
                            Documents
                        </button>
                        <button
                            className={`py-2 px-4 text-sm font-medium rounded-md ${activeTab === 'Interactions' ? 'bg-primary text-white' : 'bg-gray-100 text-gray-800'}`}
                            onClick={() => setActiveTab('Interactions')}
                        >
                            <LuPointer size={18} className="mr-2 inline-block" />
                            Interactions
                        </button>
                    </div>
                </div>

                <div className="w-full md:w-[75%] bg-white rounded-lg shadow-sm">
                    {activeTab === 'Info' && <LeadsInfo lead={lead} />}
                    {activeTab === 'Documents' && <LeadsDocuments />}
                    {activeTab === 'Interactions' && <LeadsInteractions />}
                </div>
            </div>

            {isEditModalOpen && (
                <EditLeadModal
                    lead={lead}
                    isOpen={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        getLead();
                    }}
                />
            )}
        </Layout>
    );
};

export default ViewLead;
