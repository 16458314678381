import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { addLead, fetchUsers } from '../../utils/api/api';

const AddLeadsForm = ({ onClose }) => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [leadDetails, setLeadDetails] = useState({
        title: '',
        username: 'username',
        source: '',
        assigned_to: '',
        phone_number: '',
        email: '',
        status: '',
        priority: '',
        last_activity_date: '',
        next_follow_up: '',
    });

    const handleChange = (e) => {
        setLeadDetails({
            ...leadDetails,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        const getUsers = async () => {
            try {
                const data = await fetchUsers();
                setUsers(data.users);
            } catch (err) {
                toast.error('Failed to fetch users.');
            }
        };
        getUsers();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await addLead(leadDetails);
            toast.success("Lead added.");
            setLeadDetails({
                title: '',
                source: '',
                assigned_to: '',
                phone_number: '',
                email: '',
                status: '',
                priority: '',
                last_activity_date: '',
                next_follow_up: '',
            });
            onClose(); 
        } catch (error) {
            toast.error("An error occurred.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex flex-col">
                <label className="block text-black text-[14px] font-medium mb-1">Name</label>
                <input
                    type="text"
                    name="title"
                    value={leadDetails.title}
                    onChange={handleChange}
                    placeholder='Name of Lead'
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="w-full flex flex-col">
                <label className="block text-black text-[14px] font-medium mb-1">Email</label>
                <input
                    type="text"
                    name="email"
                    value={leadDetails.email}
                    onChange={handleChange}
                    placeholder='E.g. john@demo.com'
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="flex items-center gap-4">
                <div className="w-full flex flex-col">
                    <label className="block text-black text-[14px] font-medium mb-1">Source</label>
                    <input
                        type="text"
                        name="source"
                        value={leadDetails.source}
                        onChange={handleChange}
                        placeholder='E.g. Referral'
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    />
                </div>
                <div className="w-full flex flex-col">
                    <label className="block text-black text-[14px] font-medium mb-1">Phone</label>
                    <input
                        type="text"
                        name="phone_number"
                        value={leadDetails.phone_number}
                        onChange={handleChange}
                        placeholder='E.g. Referral'
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    />
                </div>
            </div>
            <div className='w-full flex flex-col'>
                <label className='block text-black text-[14px] font-medium mb-1' htmlFor="manager">Select assignee</label>
                <select
                    name="assigned_to"
                    value={leadDetails.assigned_to}
                    onChange={handleChange}
                    className='border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary'
                >
                    <option value="">Select assignee</option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.first_name} {user.last_name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="flex w-full items-center gap-4">
                <div className="flex flex-col w-full">
                    <label className="block text-black text-[14px] font-medium mb-1">Status</label>
                    <select
                        name="status"
                        value={leadDetails.status}
                        onChange={handleChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    >
                        <option value="new">New</option>
                        <option value="contacted">Contacted</option>
                        <option value="converted">Converted</option>
                        <option value="qualified">Qualified</option>
                        <option value="disqualified">Disqualified</option>
                    </select>
                </div>
                <div className="flex flex-col w-full">
                    <label className="block text-black text-[14px] font-medium mb-1">Priority</label>
                    <select
                        name="priority"
                        value={leadDetails.priority}
                        onChange={handleChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    >
                        <option value="">Select Priority</option>
                        <option value="hot">Hot</option>
                        <option value="warm">Warm</option>
                        <option value="cold">Cold</option>
                    </select>
                </div>
            </div>
            <div className="flex flex-col">
                <label className="block text-black text-[14px] font-medium mb-1">Last Activity Date</label>
                <input
                    type="date"
                    name="last_activity_date"
                    value={leadDetails.last_activity_date}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="flex flex-col">
                <label className="block text-black text-[14px] font-medium mb-1">Next Follow Up</label>
                <input
                    type="date"
                    name="next_follow_up"
                    value={leadDetails.next_follow_up}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="w-full mt-4 flex items-center gap-2 justify-end">
                <button
                    type="button"
                    onClick={onClose} // Use onClose from props
                    className="text-secondary font-medium text-[15px]"
                >
                    Back
                </button>
                <button
                    type="submit"
                    disabled={loading}
                    className="rounded-md bg-secondary text-white text-[14px] font-medium px-6 py-1.5"
                >
                    {loading ? 'Processing...' : 'Save Lead'}
                </button>
            </div>
        </form>
    );
};

export default AddLeadsForm;
