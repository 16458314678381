import React, { useEffect, useState } from 'react';
import Layout from '../../elements/Layout';
import { format, startOfWeek, startOfMonth, endOfMonth, endOfWeek, eachDayOfInterval, isSameMonth, subWeeks, addWeeks, subMonths, addMonths, subDays, addDays, isSameDay } from 'date-fns';
import { MdClose, MdEdit } from 'react-icons/md';
import AddNewEvent from './AddNewEvent';
import toast from 'react-hot-toast';
import { fetchEvents } from '../../utils/api/api';
import { IoLocationSharp } from 'react-icons/io5';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { RiMenuUnfoldFill } from "react-icons/ri";
import { TbUsersGroup } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import api from '../../utils/api/axiosInstance';

const Calendar = () => {
    const [view, setView] = useState('week');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openEvent, setOPenEvent] = useState(false);
    const [open, setOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const goToToday = () => {
        setCurrentDate(new Date());
    };

    const goToPrevious = () => {
        if (view === 'week') setCurrentDate(subWeeks(currentDate, 1));
        if (view === 'month') setCurrentDate(subMonths(currentDate, 1));
        if (view === 'day') setCurrentDate(subDays(currentDate, 1));
    };
    const navigate = useNavigate();

    const goToNext = () => {
        if (view === 'week') setCurrentDate(addWeeks(currentDate, 1));
        if (view === 'month') setCurrentDate(addMonths(currentDate, 1));
        if (view === 'day') setCurrentDate(addDays(currentDate, 1));
    };

    const getDisplayDateRange = () => {
        if (view === 'week') {
            const start = startOfWeek(currentDate, { weekStartsOn: 1 });
            const end = addDays(start, 6);
            return `${format(start, 'MMM d')} - ${format(end, 'MMM d, yyyy')}`;
        }
        if (view === 'month') {
            return format(currentDate, 'MMMM yyyy');
        }
        return format(currentDate, 'MMMM d, yyyy');
    };

    const getEvents = async () => {
        setLoading(true);
        try {
            const response = await fetchEvents();
            setEvents(response.events || []);
        } catch (error) {
            toast.error("Failed to get events");
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    const getDatesForView = () => {
        if (view === 'week') {
            const start = startOfWeek(currentDate, { weekStartsOn: 1 });
            return Array.from({ length: 7 }, (_, i) => addDays(start, i));
        }
        if (view === 'month') {
            const start = startOfWeek(startOfMonth(currentDate), { weekStartsOn: 1 });
            const end = endOfWeek(endOfMonth(currentDate), { weekStartsOn: 1 });
            return eachDayOfInterval({ start, end });
        }
        return [currentDate];
    };

    const dates = getDatesForView();

    const getEventsForDate = (date) => {
        return events.filter(event => {
            const eventStart = new Date(event.start_date);
            const eventEnd = new Date(event.end_date);

            return isSameDay(eventStart, date) || isSameDay(eventEnd, date) ||
                (eventStart < date && eventEnd > date);
        });
    };

    const handleEventClick = (event) => {
        setSelectedEvent(event);
        setOPenEvent(true);
    };

    const hours = Array.from({ length: 24 }, (_, i) => format(new Date().setHours(i, 0), 'h:00 a'));

    const handleAddParticipant = async (e) => {
        e.preventDefault();
        if (newEmail.trim() === '') return;

        const payload = {
            event_id: selectedEvent.id, 
            participant: newEmail
        };

        setLoading(true);

        try {
            const response = await api.post('/events/create-participant', payload);

            selectedEvent.participants.push({ email: newEmail });

            setNewEmail('');
            setShowForm(false);
            window.location.reload();
        } catch (err) {
            toast.error("Failed to send invite")
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout text="Calendar">
            <div className="flex w-full items-center justify-between mb-4">
                <p className="text-xl font-semibold"></p>
                <div className="flex gap-4 items-center">
                    <button onClick={() => navigate('/dashboard/calendar/site-visits')} className="border border-secondary text-secondary text-[14px] font-medium rounded-md py-1.5 px-6">Site bookings</button>
                    <div className="relative">
                        <button onClick={() => setOpen(!open)} className="border border-secondary font-medium text-white bg-secondary rounded-md px-6 py-1.5 text-[14px] relative">Add new</button>
                        {open && (
                            <div className="absolute top-full right-0 w-fit flex flex-col bg-gray-50 bg-opacity-50 rounded-md">
                                <button onClick={() => { setIsModalOpen(true) , setOpen(false)}} className="bg-white hover:bg-secondary hover:text-white px-6 mt-2 py-1.5 rounded-md text-[14px]">Event</button>
                                <button className="bg-white hover:bg-secondary hover:text-white px-6 mt-0.5 py-1.5 rounded-md text-[14px]">Task</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-4">
                    <button onClick={goToPrevious} className="border border-secondary text-secondary text-[14px] font-medium rounded-md py-1.5 px-4">&lt; Back</button>
                    <button onClick={goToToday} className="border border-secondary text-secondary text-[14px] font-medium rounded-md py-1.5 px-4">Today</button>
                    <button onClick={goToNext} className="border border-secondary text-secondary text-[14px] font-medium rounded-md py-1.5 px-4">Next &gt;</button>
                </div>

                <p className="text-[18px] text-black font-medium">{getDisplayDateRange()}</p>

                <div className="w-fit bg-gray-200 rounded-md flex text-[14px] font-medium">
                    <button onClick={() => setView('day')} className={`border rounded-md py-1 px-4 ${view === 'day' ? 'bg-secondary text-white' : 'text-secondary'}`}>Day</button>
                    <button onClick={() => setView('week')} className={`border rounded-md py-1 px-4 ${view === 'week' ? 'bg-secondary text-white' : 'text-secondary'}`}>Week</button>
                    <button onClick={() => setView('month')} className={`border rounded-md py-1 px-4 ${view === 'month' ? 'bg-secondary text-white' : 'text-secondary'}`}>Month</button>
                </div>
            </div>
            <div className="w-full bg-white rounded-lg">
                <div className="flex">
                    <div className="flex-shrink-0 w-24 border-r border-gray-200">
                        {hours.map((time, idx) => (
                            <div key={idx} className="h-16 flex items-center justify-center border-b border-gray-200">
                                {time}
                            </div>
                        ))}
                    </div>

                    <div className="flex-1 overflow-x-auto">
                        <div className="grid grid-flow-col auto-cols-min">
                            {dates.map((date, idx) => (
                                <div key={idx} className="w-32 h-16 flex items-center justify-center border-b border-gray-200">
                                    <div className={`p-2 ${isSameMonth(date, currentDate) ? '' : 'text-gray-400'}`}>
                                        {format(date, 'EEE d')}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="grid grid-flow-col auto-cols-min border-t relative">
                            {dates.map((date, idx) => (
                                <div key={idx} className="w-32 relative">
                                    {hours.map((_, tIdx) => (
                                        <div key={tIdx} className="h-16 border-b border-r border-gray-200"></div>
                                    ))}

                                    {getEventsForDate(date).map(event => {
                                        const eventStart = new Date(event.start_date);
                                        const eventEnd = new Date(event.end_date);

                                        const startHour = eventStart.getHours() - 1;
                                        const startMinute = eventStart.getMinutes();
                                        const endHour = eventEnd.getHours() - 1;
                                        const endMinute = eventEnd.getMinutes();

                                        const topPosition = (startHour * 64) + (startMinute / 60) * 64;
                                        const eventDuration = ((endHour + endMinute / 60) - (startHour + startMinute / 60));
                                        const height = eventDuration * 64;

                                        return (
                                            <div
                                                key={event.id}
                                                onClick={() => handleEventClick(event)}
                                                className="absolute bg-[#9E1212] font-light text-[14px]  text-white p-2 rounded cursor-pointer left-0 right-0"
                                                style={{
                                                    top: `${topPosition}px`,
                                                    height: `${height}px`,
                                                }}
                                            >
                                                {event.title} ({format(new Date(event.start_date), 'p')} - {format(new Date(event.end_date), 'p')})
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-start z-50">
                    <div className="bg-white rounded-lg shadow-lg w-full md:w-[60%] xl:[60%] mt-[30px] max-h-[92vh] overflow-y-auto">
                        <div className="flex justify-between items-center p-4 border-b border-gray-200">
                            <h3 className="text-lg font-semibold">Add new event</h3>
                            <button
                                className="text-gray-500 hover:text-gray-800"
                                onClick={() => setIsModalOpen(false)}
                            >
                                <MdClose size={24} />
                            </button>
                        </div>
                        <div className="p-4">
                            <AddNewEvent />
                        </div>
                    </div>
                </div>
            )}

            {openEvent && selectedEvent && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-start z-50">
                    <div className="bg-white rounded-lg shadow-lg w-full md:w-[50%] mt-[30px] xl:w-[40%] p-6">
                        <div className="flex justify-between items-center border-b border-gray-200">
                            <button className="text-gray-500 hover:text-gray-800" onClick={() => setOPenEvent(false)}>
                                <MdClose size={24} />
                            </button>
                            <button className="text-gray-500 hover:text-gray-800" onClick={() => setOPenEvent(false)}>
                                <MdEdit size={24} />
                            </button>
                        </div>
                        <div className="mt-4">
                            <div className="flex flex-col">
                                <div className="flex items-start gap-4">
                                    <div className="bg-primary p-3 rounded-md"></div>
                                    <div className="flex flex-col">
                                        <p className="text-[20px] font-medium text-black -mt-1">{selectedEvent.title}</p>
                                        <p className="text-[14px] text-black">
                                            {`${format(new Date(selectedEvent.start_date), 'EEEE, MMM d')} · ${format(new Date(selectedEvent.start_date), 'HH:mm')} - ${format(new Date(selectedEvent.end_date), 'HH:mm')}`}
                                        </p>
                                        <p className="text-[14px] font-medium">
                                            Repeats {selectedEvent.recurrence}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-4 mt-2">
                                    <IoLocationSharp size={20} />
                                    <p className="text-[14px] ">{selectedEvent.location}</p>
                                </div>
                                <div className="flex items-center gap-4 mt-2">
                                    <IoMdNotificationsOutline size={20} />
                                    <p className="text-[14px]">{selectedEvent.reminders}</p>
                                </div>
                                <div className="flex items-start gap-4 mt-2">
                                    <RiMenuUnfoldFill size={20} />
                                    <p className="text-[14px] w-fit font-light">{selectedEvent.description}</p>
                                </div>
                                <div className="w-full flex items-start gap-4 mt-2">
                                    <div className="flex w-full justify-between items-start">
                                        <div className="flex gap-4 ">
                                            <TbUsersGroup size={20} />
                                            <div className="text-[14px] w-fit flex flex-col gap-1.5 font-light">
                                                {selectedEvent.participants.map((participant, index) => (
                                                    <p className='px-2 py-0.5 rounded-full font-medium bg-green-100 text-green-600' key={index}>{participant.participant}</p>
                                                ))}
                                            </div>
                                        </div>
                                        <button
                                            className="bg-secondary px-3 py-1 text-white rounded-md text-[12px] font-medium"
                                            onClick={() => setShowForm(!showForm)}
                                        >
                                            {showForm ? 'X' : 'Invite'}
                                        </button>
                                    </div>

                                    {showForm && (
                                        <form
                                            onSubmit={handleAddParticipant}
                                            className="w-full flex flex-col justify-end gap-2 mt-4"
                                        >
                                            <input
                                                type="email"
                                                value={newEmail}
                                                onChange={(e) => setNewEmail(e.target.value)}
                                                placeholder="Enter email address"
                                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                                required
                                            />
                                            <button type="submit" className="w-fit bg-secondary text-[14px] font-medium  text-white py-1.5 px-4 rounded">
                                                Submit
                                            </button>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Calendar;
