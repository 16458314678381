import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../elements/Layout';
import moment from 'moment';
import TaskForm from './TaskForm';
import Modal from '../../elements/Modal';
import { fetchSubtasks, fetchTasks } from '../../utils/api/api';
import { IoIosSearch } from 'react-icons/io';
import toast from 'react-hot-toast';
import SubtaskForm from './SubtaskForm';
import Loading from '../../elements/Loading';
import { AuthContext } from '../../utils/context/AuthContext';
import { canAccess } from '../../utils/canAccess';

const Tasks = () => {
    const { user } = useContext(AuthContext);
    const [tasks, setTasks] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [openSubtaskId, setOpenSubtaskId] = useState(null);
    const [subtaskModal, setSubtaskModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [subtasks, setSubtasks] = useState({});

    useEffect(() => {
        setLoading(true);
        const getTasks = async () => {
            try {
                const response = await fetchTasks();
                setTasks(response.tasks);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            } finally {
                setLoading(false);
            }
        };

        getTasks();
    }, []);

    const handleAddTask = (taskData) => {
        setTasks((prevTasks) => [...prevTasks, taskData]);
        setShowModal(false);
    };

    const handleSubtaskAdded = (taskId, newSubtask) => {
        setSubtasks((prevSubtasks) => ({
            ...prevSubtasks,
            [taskId]: [...(prevSubtasks[taskId] || []), newSubtask],
        }));
        setSubtaskModal(false);
    };

    const handleToggleSubtasks = async (taskId) => {
        if (openSubtaskId !== taskId) {
            setOpenSubtaskId(taskId);
        } else {
            setOpenSubtaskId(null);
            return;
        }

        try {
            setLoading(true);
            const response = await fetchSubtasks({ id: taskId });
            const fetchedSubtasks = response.task.sub_tasks;

            setSubtasks((prevSubtasks) => ({
                ...prevSubtasks,
                [taskId]: fetchedSubtasks,
            }));
        } catch (error) {
            toast.error(error.message || 'Failed to fetch subtasks');
        } finally{
            setLoading(false);
        }
    };

    return (
        <Layout text="Tasks" backgroundColor='bg-white'>
            {loading && <Loading />}
            <div className="w-full flex items-center gap-4 justify-between">
                <div className="w-full md:w-fit border border-gray-300 rounded-md bg-[#EBEBEB] text-[13px] py-2 px-2 flex gap-2 items-center">
                    <IoIosSearch className="text-gray-600" />
                    <input
                        type="text"
                        placeholder="Search"
                        className="bg-transparent h-full outline-none text-gray-600 w-[200px] md:w-[250px]"
                    />
                </div>
                {canAccess(user, 'create_tasks') && (
                    <button
                        onClick={() => setShowModal(true)}
                        className="bg-secondary flex items-center gap-2 w-fit px-6 py-1.5 text-[14px] rounded-md font-medium text-white"
                    >
                        Add <span className="hidden md:block">Task</span>
                    </button>
                )}                
            </div>

            {showModal && (
                <Modal onClose={() => setShowModal(false)} title="Add new task">
                    <TaskForm onAddTask={handleAddTask} />
                </Modal>
            )}

            <div className="w-full bg-white rounded-lg mt-4">
                <div className="overflow-x-auto rounded-lg">
                    <table className="min-w-full bg-white h-full flex-grow rounded-lg border border-gray-200 table-auto">
                        <thead>
                            <tr className="text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
                                <th className="border-b py-4 text-start border-gray-300 p-2">Title</th>
                                <th className="border-b py-4 text-start border-gray-300 p-2">Description</th>
                                <th className="border-b py-4 text-start border-gray-300 p-2">Due Date</th>
                                <th className="border-b py-4 text-start border-gray-300 p-2">Assigned To</th>
                                <th className="border-b py-4 text-start border-gray-300 p-2">Status</th>
                                <th className="border-b py-4 text-start border-gray-300 p-2">Priority</th>
                                <th className="border-b py-4 text-start border-gray-300 p-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasks.map((task) => (
                                <React.Fragment key={task.id}>
                                    <tr
                                        className={`border-b text-black font-light text-[14px] border-gray-200 cursor-pointer hover:bg-gray-50`}
                                        onClick={() => handleToggleSubtasks(task.id)}
                                    >
                                        <td className="border-b py-3 border-gray-300 p-2">{task.title}</td>
                                        <td className="border-b py-3 border-gray-300 p-2">{task.description}</td>
                                        <td className="border-b py-3 border-gray-300 p-2">
                                            {moment(task.due_date).format('Do MMM, YYYY')}
                                        </td>
                                        <td className="border-b py-3 border-gray-300 p-2">{task?.assigned_user?.first_name} {task?.assigned_user?.last_name}</td>
                                        <td className="border-b py-3 border-gray-300 p-2">
                                            <span className={`inline-block px-2 py-1 rounded-full ${task.status === 'completed' ? 'bg-green-100 text-green-500' : task.status === 'in_progress' ? 'bg-yellow-100 text-secondary' : 'bg-red-100 text-red-500'}`}>
                                                {task.status.replace('_', ' ')}
                                            </span>
                                        </td>
                                        <td className="border-b py-3 border-gray-300 p-2">
                                            <span className={`inline-block px-2 py-1 rounded-full ${task.priority === 'hot' ? 'bg-red-100 text-red-500' : task.priority === 'warm' ? 'bg-orange-100 text-secondary' : 'bg-gray-100'}`}>
                                                {task.priority}
                                            </span>
                                        </td>
                                        <td className="border-b py-3 border-gray-300 p-2">
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedTask(task.id);
                                                    setSubtaskModal(true);
                                                }}
                                                className="text-blue-500"
                                            >
                                                Add Subtask
                                            </button>
                                        </td>
                                    </tr>

                                    {openSubtaskId === task.id && (
                                        <tr className="bg-gray-50">
                                            <td colSpan="7">
                                                <div className="p-4">
                                                    <h4 className="font-medium">Subtasks</h4>
                                                    <table className="min-w-full mt-2 bg-gray-100">
                                                        <thead>
                                                            <tr className="bg-gray-200">
                                                                <th className="border-b p-2 text-[13px] text-start">Title</th>
                                                                <th className="border-b p-2 text-[13px] text-start">Due Date</th>
                                                                <th className="border-b p-2 text-[13px] text-start">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {subtasks[task.id]?.map((subtask) => (
                                                                <tr key={subtask.id} className="border-b">
                                                                    <td className="p-2 text-gray-600 text-[12px]">{subtask.title}</td>
                                                                    <td className="p-2 text-gray-600 text-[12px]">{moment(subtask.due_date).format('Do MMM, YYYY')}</td>
                                                                    <td className="p-2 text-gray-600 text-[12px]">{subtask.status.replace('_', ' ')}</td>
                                                                </tr>
                                                            ))}
                                                            {subtasks[task.id]?.length === 0 && (
                                                                <tr>
                                                                    <td colSpan="3" className="p-2 text-center text-gray-600 text-[12px]">No subtasks available.</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {subtaskModal && (
                <Modal onClose={() => setSubtaskModal(false)} title="Add Subtask">
                    <SubtaskForm
                        taskId={selectedTask}
                        onSubtaskAdded={handleSubtaskAdded}
                    />
                </Modal>
            )}
        </Layout>
    );
};

export default Tasks;
