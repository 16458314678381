import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getProjectProperties } from '../../utils/api/api';
import toast from 'react-hot-toast';
import AssignPropertyModal from '../properties/AssignPropertyModal';

const ProjectProperties = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(10);

  const getProperties = async () => {
    try {
      setLoading(true);
      const response = await getProjectProperties(id);
      setProperties(response.properties);
    } catch (error) {
      toast.error(error.message || "Request failed");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProperties();
  }, [id]);

  const handleAssignProperty = (property) => {
    setSelectedProperty(property);
    setShowModal(true);
  };

  const handleAssignSuccess = () => {
    getProperties();
  };

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = properties.slice(indexOfFirstProperty, indexOfLastProperty);
  const totalPages = Math.ceil(properties.length / propertiesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="w-full bg-white rounded-lg mt-4">
      <p className="mb-2 text-black font-medium uppercase text-[16px]">Project Properties</p>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white h-full flex-grow table-auto">
          <thead>
            <tr className="text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Title Number</th>
              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Property number</th>
              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Assigned to</th>
              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Status</th>
              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Size</th>
              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Actions</th>
            </tr>
          </thead>
          <tbody className="text-primary text-[14px] font-light">
            {loading ? (
              <tr>
                <td colSpan="6" className="py-4 px-6 text-center text-gray-500">Loading, please wait</td>
              </tr>
            ) : currentProperties.length === 0 ? (
              <tr>
                <td colSpan="6" className="py-4 px-6 text-center text-gray-500">No data found</td>
              </tr>
            ) : (
              currentProperties.map((property, index) => (
                <tr
                  key={index}
                  className={`text-[14px] font-light text-black border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                >
                  <td className="py-4 px-6 text-left whitespace-nowrap">{property.title_number}</td>
                  <td className="py-4 px-6 text-left whitespace-nowrap">{property.property_number}</td>
                  <td className="py-4 px-6 text-left whitespace-nowrap">
                    {property.user_assigned ? property.user_assigned.first_name : "Unassigned"}
                  </td>
                  <td className="py-4 px-6 text-left whitespace-nowrap">
                    <span
                      className={`${property.status === 'not_started' ? 'text-gray-500 bg-gray-100 py-1 px-2 rounded' :
                        property.status === 'under_contract' ? 'text-yellow-500 bg-yellow-100 p-1 px-2 rounded-full' :
                          property.status === 'available' ? 'text-green-500 bg-green-100 py-1 px-2 rounded-full' :
                            property.status === 'sold' ? 'text-red-500 bg-red-100 py-1 px-2 rounded-full' : 'text-black bg-white'
                        }`}
                    >
                      {property.status.replace('_', ' ')}
                    </span>
                  </td>
                  <td className="py-4 px-6 text-left whitespace-nowrap">{property.size}</td>
                  <td className="py-4 px-6 text-left whitespace-nowrap">
                    {property.status === "available" && (
                      <button
                        onClick={() => handleAssignProperty(property)}
                        className="bg-secondary text-white py-1 px-2 rounded-md text-sm"
                      >
                        Assign to Customer
                      </button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {properties.length > 0 && (
        <div className="w-full flex justify-end mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="text-[14px] mr-1 text-primary font-light"
          >
            Prev
          </button>
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i + 1}
              onClick={() => paginate(i + 1)}
              className={`px-3 py-1.5 mx-1 text-[14px] font-medium ${currentPage === i + 1 ? 'bg-primary text-white' : 'text-primary'} rounded-md`}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="text-[14px] ml-1 text-primary font-light"
          >
            Next
          </button>
        </div>
      )}

      {showModal && (
        <AssignPropertyModal
          property={selectedProperty}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onAssignSuccess={handleAssignSuccess}
        />
      )}
    </div>
  );
};

export default ProjectProperties;
