import React, { useEffect, useState } from 'react';
import { createTask, fetchUsers } from '../../utils/api/api';
import toast from 'react-hot-toast';

const TaskForm = () => {
    const [taskData, setTaskData] = useState({
        assigned_to: '',
        title: '',
        description: '',
        due_date: '',
        completion_date: '',
        priority: '',
        status: '',
    });
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await fetchUsers();
                setUsers(response.users);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        getUsers();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTaskData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await createTask(taskData);
            toast.success("Task added");
            setTaskData({
                assigned_to: '',
                title: '',
                description: '',
                due_date: '',
                completion_date: '',
                priority: 'not_started',
                status: 'not_started',
            });
            window.location.reload();
        } catch (error) {
            toast.error(error.message || "Failed to add task");
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="">
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Assigned To</label>
                <select
                    name="assigned_to"
                    value={taskData.assigned_to}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                >
                    <option value="">Select Manager</option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.first_name} {user.last_name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Title</label>
                <input
                    type="text"
                    name="title"
                    value={taskData.title}
                    onChange={handleChange}
                    placeholder='Enter task title'
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Description</label>
                <textarea
                    name="description"
                    value={taskData.description}
                    onChange={handleChange}
                    placeholder='Enter description of task'
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="w-full flex items-center gap-4">
                <div className="mb-4 w-full">
                    <label className="block text-black text-[14px] font-medium mb-1">Due Date</label>
                    <input
                        type="date"
                        name="due_date"
                        value={taskData.due_date}
                        onChange={handleChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    />
                </div>
                <div className="mb-4 w-full">
                    <label className="block text-black text-[14px] font-medium mb-1">Completion Date</label>
                    <input
                        type="date"
                        name="completion_date"
                        value={taskData.completion_date}
                        onChange={handleChange}                        
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Priority</label>
                <select
                    name="priority"
                    value={taskData.priority}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                >
                    <option value="">Select priority</option>
                    <option value="hot">Hot</option>
                    <option value="warm">Warm</option>
                    <option value="cold">Cold</option>
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Status</label>
                <select
                    name="status"
                    value={taskData.status}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                >
                    <option value="">Select status</option>
                    <option value="not_started">Not Started</option>
                    <option value="in_progress">In Progress</option>
                    <option value="completed">Completed</option>
                    <option value="on_hold">On Hold</option>
                </select>
            </div>
            <button type="submit" className="bg-secondary text-[14px] font-medium text-white px-6 py-1.5 rounded-md">
                Create Task
            </button>
        </form>
    );
};

export default TaskForm;
