import React, { useEffect, useState } from 'react';
import { addTask, fetchUsers } from '../../utils/api/api';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const TaskForm = () => {
    const { id } = useParams(); // Get the project ID from the URL parameters
    const [taskData, setTaskData] = useState({
        assigned_to: '', // User assigned to the task
        title: '', // Task title
        description: '', // Task description
        project_id: id, // Project ID associated with the task
        due_date: '', // Task due date
        priority: '', // Task priority
        status: '', // Task status
        comments: '', // Comments field for additional notes
    });
    const [loading, setLoading] = useState(false); // Loading state for the form submission
    const [users, setUsers] = useState([]); // State to hold users fetched from the API

    useEffect(() => {
        // Fetch users when the component mounts
        const getUsers = async () => {
            try {
                const response = await fetchUsers();
                setUsers(response.users); // Set the users in the state
            } catch (error) {
                console.error('Error fetching users:', error); // Log any errors
            }
        };

        getUsers(); // Call the function to fetch users
    }, []); // Empty dependency array means this effect runs once on mount

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target; // Destructure the input name and value
        setTaskData((prevData) => ({ ...prevData, [name]: value })); // Update the corresponding task data
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setLoading(true); // Set loading state to true

        try {
            const response = await addTask(taskData); // Call the addTask API with the task data
            toast.success("Task added"); // Show success message
            // Reset form data after successful submission
            setTaskData({
                assigned_to: '',
                title: '',
                description: '',
                due_date: '',
                priority: 'not_started',
                status: 'not_started',
                comments: '', // Reset comments field
            });
            window.location.reload(); // Reload the page to reflect changes
        } catch (error) {
            // Show error message if the task could not be added
            toast.error(error.message || "Failed to add task");
            setLoading(false); // Stop loading state
        }
    };

    return (
        <form onSubmit={handleSubmit} className="">
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Assigned To</label>
                <select
                    name="assigned_to"
                    value={taskData.assigned_to}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                >
                    <option value="">Select Manager</option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.first_name} {user.last_name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Title</label>
                <input
                    type="text"
                    name="title"
                    value={taskData.title}
                    onChange={handleChange}
                    placeholder='Enter task title'
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Description</label>
                <textarea
                    name="description"
                    value={taskData.description}
                    onChange={handleChange}
                    placeholder='Enter description of task'
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>
            <div className="w-full flex items-center gap-4">
                <div className="mb-4 w-full">
                    <label className="block text-black text-[14px] font-medium mb-1">Due Date</label>
                    <input
                        type="date"
                        name="due_date"
                        value={taskData.due_date}
                        onChange={handleChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    />
                </div>
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Priority</label>
                <select
                    name="priority"
                    value={taskData.priority}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                >
                    <option value="">Select priority</option>
                    <option value="hot">Hot</option>
                    <option value="warm">Warm</option>
                    <option value="cold">Cold</option>
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Status</label>
                <select
                    name="status"
                    value={taskData.status}
                    onChange={handleChange}
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                >
                    <option value="">Select status</option>
                    <option value="not_started">Not Started</option>
                    <option value="in_progress">In Progress</option>
                    <option value="completed">Completed</option>
                    <option value="on_hold">On Hold</option>
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Comments</label>
                <textarea
                    name="comments"
                    value={taskData.comments}
                    onChange={handleChange}
                    placeholder='Add any comments'
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
            </div>
            <button type="submit" className="bg-secondary text-[14px] font-medium text-white px-6 py-1.5 rounded-md">
                Create Task
            </button>
        </form>
    );
};

export default TaskForm;
