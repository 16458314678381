import React, { useEffect, useState } from 'react';
import { AddNewExpense, fetchProjectExpenses } from '../../utils/api/api';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { MdOutlineAttachment, MdOutlineFilterAlt } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import Modal from '../../elements/Modal';

const ProjectExpenses = () => {
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [openModalDetail, setOpenModalDetail] = useState(false);

    const handleExpenseClick = (expense) => {
        setSelectedExpense(expense);
        setOpenModalDetail(true);
    };
    const [formData, setFormData] = useState({
        description: '',
        amount: '',
        date: '',
        receipt: '',
    });
    const { id } = useParams();
    const project_id = id;

    const getExpenses = async () => {
        setLoading(true);
        try {
            const data = await fetchProjectExpenses(project_id);
            setExpenses(data.expenses);
        } catch (err) {
            toast.error('Request failed.');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            receipt: e.target.files[0],
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const expenseData = new FormData();
        expenseData.append('description', formData.description);
        expenseData.append('amount', formData.amount);
        expenseData.append('date', formData.date);
        expenseData.append('receipt', formData.receipt);
        expenseData.append('project_id', project_id);

        setLoading(true);
        try {
            await AddNewExpense(expenseData);
            toast.success('Expense added.');
            setOpenModal(false);
            getExpenses();
            setFormData({
                description: '',
                amount: '',
                date: '',
                receipt: null,
            });
        } catch (err) {
            toast.error('Failed to add expense.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getExpenses();
    }, []);

    return (
        <div className="mt-4 flex bg-[#fff] rounded-lg p-4 flex-col">
            <div className="w-full flex items-center justify-between">
                <p className="text-black font-semibold text-[22px]">Expenses</p>
                <div className="flex items-center gap-4">
                    <button
                        onClick={() => setOpenModal(true)}
                        className="px-4 py-1.5 flex items-center gap-2 text-[14px] text-secondary rounded-md border border-secondary">
                        <IoMdAdd size={18} />
                        Add New
                    </button>
                </div>
            </div>

            <div className="w-full bg-white rounded-lg mt-4">
                <div className="overflow-x-auto rounded-lg">
                    <table className="min-w-full bg-white rounded-lg border border-gray-200 table-auto">
                        <thead className="bg-[#F0F0F0]">
                            <tr className="text-black border-b text-[14px] py-3 font-medium leading-normal rounded-t-lg">
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Date</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Description</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Amount</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Receipt</th>
                            </tr>
                        </thead>
                        <tbody className="text-primary text-[14px] font-light">
                            {expenses.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className="py-4 px-6 text-center text-gray-500">
                                        {loading ? 'Loading, please wait' : 'No data found'}
                                    </td>
                                </tr>
                            ) : (
                                expenses.map((expense, index) => (
                                    <tr
                                        key={index}
                                        className={`border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                                        onClick={() => handleExpenseClick(expense)} // Add click handler
                                    >
                                        <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">
                                            {new Date(expense.date).toLocaleDateString()}
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">
                                            {expense.description.length > 50 ? `${expense.description.substring(0, 50)}...` : expense.description}
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">
                                            {expense.amount}
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">
                                            {expense.receipt && <MdOutlineAttachment size={20} />}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {openModal && (
                <Modal onClose={() => setOpenModal(false)} title="Add Expense">
                    <form onSubmit={handleFormSubmit} className="flex flex-col gap-4">
                        <div>
                            <label htmlFor="description" className="block text-black text-[14px] font-medium mb-1">Description</label>
                            <input
                                type="text"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                required
                                placeholder='Add expense description'
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>

                        <div>
                            <label htmlFor="amount" className="block text-black text-[14px] font-medium mb-1">Amount</label>
                            <input
                                type="number"
                                name="amount"
                                value={formData.amount}
                                onChange={handleInputChange}
                                required
                                placeholder='Enter amount'
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>

                        <div>
                            <label htmlFor="date" className="block text-black text-[14px] font-medium mb-1">Date</label>
                            <input
                                type="date"
                                name="date"
                                value={formData.date}
                                onChange={handleInputChange}
                                required
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>

                        <div className="">
                            <label
                                htmlFor="receipt"
                                className="block text-gray-700 text-sm font-semibold mb-2"
                            >
                                Receipt (optional)
                            </label>
                            <input
                                type="file"
                                name="receipt"
                                onChange={handleFileChange}
                                className="block w-full text-sm text-gray-600 border border-gray-300 rounded-lg shadow-sm focus:ring-secondary focus:border-secondary file:mr-4 file:py-2 file:px-4 file:border file:border-gray-300 file:rounded-lg file:text-sm file:font-semibold file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100"
                                accept="image/*,application/pdf"
                            />
                        </div>
                        <p className="-mt-2 text-gray-600 text-[14px] font-light">Max file size: 2MB</p>

                        <div className="flex w-full items-center justify-end">
                            <button type='button' onClick={() => setOpenModal(false)} className='text-secondary font-medium text-[14px] mr-2'>Cancel</button>
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-secondary text-white py-1.5 px-6 rounded-md text-[14px] font-medium"
                            >
                                {loading ? 'Loading, please wait...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </Modal>
            )}
            {openModalDetail && selectedExpense && (
                <Modal onClose={() => setOpenModalDetail(false)} title="Expense Details">
                    <p><strong>Date:</strong> {new Date(selectedExpense.date).toLocaleDateString()}</p>
                    <p><strong>Description:</strong> {selectedExpense.description}</p>
                    <p><strong>Amount:</strong> {selectedExpense.amount}</p>
                    <div>
                        <strong>Receipt:</strong>
                        {selectedExpense.receipt ? (
                            <a href={selectedExpense.receipt} target="_blank" rel="noopener noreferrer">View Receipt</a>
                        ) : (
                            'No receipt available'
                        )}
                    </div>
                    <button onClick={() => setOpenModal(false)} className='text-secondary font-medium text-[14px]'>Close</button>
                </Modal>
            )}

        </div>
    );
};

export default ProjectExpenses;
