import React, { useEffect, useState } from 'react';
import { fetchLeadInteractions, submitInteraction, submitLeadInteraction } from '../../utils/api/api'; // Ensure this is correctly imported
import { useParams } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import Modal from '../../elements/Modal';
import toast from 'react-hot-toast';

const interactionTypes = ["Phone", "Call", "Meeting", "Email"];

const LeadsInteractions = () => {
  const [interactions, setInteractions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [newInteraction, setNewInteraction] = useState({
    lead_id: id,
    type: '',
    date: '',
    summary: '',
    follow_action: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await submitLeadInteraction(newInteraction);
      setShowModal(false);
      setNewInteraction({ lead_id: id, type: '', date: '', summary: '', follow_action: '' }); 
      toast.success("Interaction added.");
      getInteractions();
      setLoading(false);
    } catch (error) {
      toast.error("An error occurred.");
      setLoading(false)
    }
  };

  const getInteractions = async () => {
    try {
      const data = await fetchLeadInteractions(id);
      setInteractions(data.interactions);
    } catch (err) {
      toast.error('Failed to get interactions.');
    }
  };

  useEffect(() => {
    getInteractions();
  }, [id]);

  return (
    <div className='w-full'>
      <div className="w-full flex items-center justify-between">
        <div className="flex flex-col">
          <p className="text-secondary text-[16px] font-medium">Interactions</p>
          <p className="text-[14px] text-lead font-light hidden md:flex">Add interactions in the system</p>
        </div>
        <button
          className="flex items-center gap-2 px-4 py-1.5 bg-secondary text-white text-[14px] rounded-md outline-none"
          onClick={() => setShowModal(true)}
        >
          <MdAdd />
          Log Interaction
        </button>
      </div>

      <div className="w-full mt-4 overflow-x-auto rounded-md">
        <table className="w-full text-left bg-white border border-gray-200 rounded-md shadow-sm">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3 px-4 border-b border-gray-300 text-[13px] font-medium text-black">Interaction Type</th>
              <th className="py-3 px-4 border-b border-gray-300 text-[13px] font-medium text-black">Date</th>
              <th className="py-3 px-4 border-b border-gray-300 text-[13px] font-medium text-black">Summary</th>
              <th className="py-3 px-4 border-b border-gray-300 text-[13px] font-medium text-black">Follow-Up Action</th>
            </tr>
          </thead>
          <tbody className='rounded-b-md'>
            {interactions.map((interaction, index) => (
              <tr
                key={index}
                className={`cursor-pointer border-t border-gray-100 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} whitespace-nowrap`}
                // onClick={() => handleInteractionClick(interaction)}
              >
                <td className="py-3 px-4 text-[14px] text-gray-600">{interaction.type}</td>
                <td className="py-3 px-4 text-[14px] text-gray-600">{interaction.date}</td>
                <td className="py-3 px-4 text-[14px] text-gray-600">
                  {interaction.summary.split(' ').slice(0, 5).join(' ')}...
                </td>
                <td className="py-3 px-4 text-[14px] text-gray-600">
                  {interaction.follow_action.split(' ').slice(0, 5).join(' ')}...
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <form onSubmit={handleSubmit}>
            <div>
              <div className="flex items-center gap-2">
                <div className="flex w-full flex-col">
                  <label className='block text-black text-[14px] font-medium mb-1' htmlFor="Type">Interaction Type</label>
                  <select
                    id="Type"
                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                    value={newInteraction.type}
                    onChange={(e) => setNewInteraction({ ...newInteraction, type: e.target.value })}
                  >
                    <option value="">Select Type</option>
                    {interactionTypes.map((type) => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </select>
                </div>
                <div className="flex w-full flex-col">
                  <label className='block text-black text-[14px] font-medium mb-1' htmlFor="Date">Date</label>
                  <input
                    type="date"
                    id="Date"
                    placeholder="Date"
                    className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                    value={newInteraction.date}
                    onChange={(e) => setNewInteraction({ ...newInteraction, date: e.target.value })}
                  />
                </div>
              </div>
              <div className="flex w-full flex-col mt-2">
                <label className='block text-black text-[14px] font-medium mb-1' htmlFor="summary">Interaction Summary</label>
                <textarea
                  id="summary"
                  placeholder="Summary"
                  className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                  value={newInteraction.summary}
                  onChange={(e) => setNewInteraction({ ...newInteraction, summary: e.target.value })}
                />
              </div>
              <div className="flex w-full flex-col mt-2">
                <label className='block text-black text-[14px] font-medium mb-1' htmlFor="follow_action">Follow up action</label>
                <textarea
                  id="follow_action"
                  placeholder="Follow-Up Action"
                  className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                  value={newInteraction.follow_action}
                  onChange={(e) => setNewInteraction({ ...newInteraction, follow_action: e.target.value })}
                />
              </div>
              <div className="flex justify-end gap-4 mt-4">
                <button type="button" className="text-secondary font-medium text-[14px]" onClick={() => setShowModal(false)}>Cancel</button>
                <button type="submit" className="text-white bg-secondary px-6 py-1.5 font-medium text-[13px] rounded-md">{loading ? 'Please wait' : 'Submit'}</button>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default LeadsInteractions;
