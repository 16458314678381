import React, { useState } from 'react';
import Modal from '../../elements/Modal';
import toast from 'react-hot-toast';
import { submitInteraction } from '../../utils/api/api';

const interactionTypes = ['Phone', 'Call', 'Email', 'Meeting'];

const InteractionModal = ({ selectedInteraction, onClose, onInteractionAdded, id }) => {
    const [newInteraction, setNewInteraction] = useState({
        customer_id: selectedInteraction?.customer_id || id,
        type: '',
        date: '',
        summary: '',
        follow_action: ''
    });
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const interactionToSubmit = { ...newInteraction, customer_id: id };
        setLoading(true);
        try {
            await submitInteraction(interactionToSubmit);
            onInteractionAdded(interactionToSubmit);
            onClose();
            toast.success("Interaction added.");
            setLoading(false);
        } catch (error) {
            toast.error("An error occurred.");
            setLoading(false);
        }
    };

    return (
        <Modal onClose={onClose} title={selectedInteraction ? "Interaction" : "Log Interaction"}>
            {selectedInteraction ? (
                <div>
                    <div className="mb-4 flex items-center justify-between">
                        <p className="text-black text-[13px]">
                            Interaction type:
                            <span className="text-[14px] text-gray-700">{selectedInteraction.type}</span>
                        </p>
                        <p className="text-black text-[13px]">
                            Date:
                            <span className="text-[14px] text-gray-700 ">{selectedInteraction.date}</span>
                        </p>
                    </div>
                    <div className="mb-4 flex flex-col">
                        <p className="text-black text-[13px]">Interaction Summary:</p>
                        <span className="text-[14px] text-gray-700">{selectedInteraction.summary}</span>
                    </div>
                    <div className="mb-4 flex flex-col">
                        <p className="text-black text-[13px]">Follow up Action:</p>
                        <span className="text-[14px] text-gray-700">{selectedInteraction.follow_action}</span>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="flex items-center gap-2">
                        <div className="flex w-full flex-col">
                            <label className='block text-black text-[14px] font-medium mb-1' htmlFor="Type">Interaction Type</label>
                            <select
                                id="Type"
                                    className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                                value={newInteraction.type}
                                onChange={(e) => setNewInteraction({ ...newInteraction, type: e.target.value })}
                            >
                                <option value="">Select Type</option>
                                {interactionTypes.map((type) => (
                                    <option key={type} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>
                        <div className="flex w-full flex-col">
                            <label className='block text-black text-[14px] font-medium mb-1' htmlFor="Date">Date</label>
                            <input
                                type="date"
                                id="Date"
                                placeholder="Date"
                                className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                                value={newInteraction.date}
                                onChange={(e) => setNewInteraction({ ...newInteraction, date: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="flex w-full flex-col mt-2">
                        <label htmlFor="summary">Interaction Summary</label>
                        <textarea
                            id="summary"
                            placeholder="Summary"
                            className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                            value={newInteraction.summary}
                            onChange={(e) => setNewInteraction({ ...newInteraction, summary: e.target.value })}
                        />
                    </div>
                    <div className="flex w-full flex-col mt-2">
                        <label htmlFor="follow_action">Follow up action</label>
                        <textarea
                            id="follow_action"
                            placeholder="Follow-Up Action"
                            className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                            value={newInteraction.follow_action}
                            onChange={(e) => setNewInteraction({ ...newInteraction, follow_action: e.target.value })}
                        />
                    </div>
                    <div className="flex justify-end gap-4 mt-4">
                        <button className="text-secondary font-medium text-[14px]" onClick={onClose}>Cancel</button>
                        <button disabled={loading} className="text-white bg-secondary px-6 py-1.5 font-medium text-[13px] rounded-md" onClick={handleSubmit}>
                            {loading ? 'Please wait' : 'Submit'}
                        </button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default InteractionModal;
