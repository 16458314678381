import './App.css';
import AppRoutes from './routes';

function App() {
  return (
    <div className='font-inter tracking-wide scroll-smooth focus:scroll-auto'>
      <AppRoutes />
    </div>
  );
}

export default App;
