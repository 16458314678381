import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../elements/Layout';
import { IoMdSearch } from 'react-icons/io';
import { MdOutlineFilterAlt, MdOutlinePrint } from 'react-icons/md';
import { FaExclamationCircle, FaPlus } from 'react-icons/fa';
import logo from '../../assets/miles_logo.svg'
import ReactToPrint from 'react-to-print';
import Modal from '../../elements/Modal';
import Loading from '../../elements/Loading'
import AddNewPayment from './AddNewPayment';
import moment from 'moment';
import { getMpesaPayments, getPaymentDetails } from '../../utils/api/api';
import toast from 'react-hot-toast';
import api from '../../utils/api/axiosInstance';
import { FaEye } from 'react-icons/fa6';
import { IoReceiptOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const Payments = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [itemsPerPage] = useState(25);
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredPayments, setFilteredPayments] = useState(payments);
    const [add, setAdd] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [viewReceipt, setViewReceipt] = useState(false);
    const printRef = useRef();
    const navigate = useNavigate();

    const getPayments = async () => {
        setLoading(true);
        try {
            const response = await getMpesaPayments();
            setPayments(response.payments);
            setFilteredPayments(response.payments);
        } catch (error) {
            toast.error(error.message || 'Request failed');
        } finally {
            setLoading(false);
        }
    };

    const handleViewDetails = async (id) => {
        try {
            setLoading(true);
            const response = await getPaymentDetails(id.toString());
            setSelectedPayment(response.payment);
            setViewReceipt(true);
        } catch (error) {
            toast.error('Failed to fetch payment details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPayments();
    }, []);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        const query = e.target.value.toLowerCase();
        const newFilteredPayments = payments.filter(payment =>
            payment.transaction_id.toLowerCase().includes(query) ||
            payment.customer.first_name.toLowerCase().includes(query) ||
            payment.customer.last_name.toLowerCase().includes(query)
        );
        setFilteredPayments(newFilteredPayments);
        setCurrentPage(1); // Reset to page 1 after search
    };

    const indexOfLastPayment = currentPage * itemsPerPage;
    const indexOfFirstPayment = indexOfLastPayment - itemsPerPage;
    const currentPayments = filteredPayments.slice(indexOfFirstPayment, indexOfLastPayment);
    const totalPages = Math.ceil(filteredPayments.length / itemsPerPage);

    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handleFinanceApprove = async (paymentId) => {
        try {
            const data = {
                id: paymentId,
                is_ceo: false, 
                approve: true 
            };

            const response = await api.post('/payments/approve', data);

            setPayments((prevPayments) =>
                prevPayments.map(payment =>
                    payment.id === paymentId ? { ...payment, finance_approved: true } : payment
                )
            );
            toast.success('Payment approved by Finance');
        } catch (error) {
            toast.error('Failed to approve payment');
        }
    };

    const handleCEOApprove = async (paymentId) => {
        setLoading(true);
        try {
            const data = {
                id: paymentId.toString(),
                is_ceo: true,  
                approve: true
            };

            const response = await api.post('/payments/approve', data);

            setPayments((prevPayments) =>
                prevPayments.map(payment =>
                    payment.id === paymentId ? { ...payment, ceo_approved: true } : payment
                )
            );
            toast.success('Payment approved by CEO');
            setLoading(false);
        } catch (error) {
            toast.error('Failed to approve payment');
            setLoading(false);
        }
    };

    return (
        <Layout text="Mpesa payments" backgroundColor='bg-white'>
            {loading && <Loading />}
            <div className="flex w-full md:items-center justify-between">
                <div className="flex items-center gap-2 text-gray-600 py-1.5 px-4 border border-gray-200 bg-white rounded-md ">
                    <IoMdSearch />
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                        className="outline-none text-[14px] text-gray-600"
                    />
                </div>
                <div className="flex flex-col md:flex-row md:items-center gap-3">
                    <div className="flex justify-end md:items-center gap-3">
                        <button
                            className="flex items-center border border-secondary px-4 py-1.5 text-[14px] rounded-md text-secondary gap-2">
                            <MdOutlineFilterAlt size={18} />
                            Filter
                        </button>
                        <button
                            onClick={() => setAdd(true)}
                            className="flex items-center border border-secondary px-4 py-1.5 text-[14px] rounded-md text-secondary gap-2">
                            <FaPlus size={18} />
                            Add Payment
                        </button>
                        {/* <ReactToPrint
                            trigger={() => (
                                <button className="flex items-center border border-secondary bg-secondary px-4 py-1.5 text-[14px] rounded-md text-white gap-2">
                                    <MdOutlinePrint size={18} />
                                    Print
                                </button>
                            )}
                            content={() => printRef.current}
                        /> */}
                    </div>
                </div>
            </div>

            <div className="w-full bg-white mt-4">
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white h-full flex-grow table-auto">
                        <thead>
                            <tr className="text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
                                <th className="py-3 px-6 text-left w-1/5">Timestamp</th>
                                <th className="py-3 px-6 text-left w-1/5">Transaction ID</th>
                                <th className="py-3 px-6 text-left w-1/5">Paid By</th>
                                <th className="py-3 px-6 text-left w-1/5">Amount</th>
                                <th className="py-3 px-6 text-left w-1/5">Channel</th>
                                <th className="py-3 px-6 text-left w-1/5">Finance</th>
                                <th className="py-3 px-6 text-left w-1/5">CEO</th>
                                <th className="py-3 px-6 text-left w-1/5">Details</th>
                                <th className="py-3 px-6 text-left w-1/5">Receipt</th>
                            </tr>
                        </thead>

                        <tbody className="text-primary text-[14px] font-light">
                            {currentPayments.length > 0 ? (
                                currentPayments.map((payment, index) => (
                                    <tr
                                        key={payment.id}
                                        className={`border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                                    >
                                        <td className="py-2 px-6">{moment(payment.createdAt).format('MMM Do, YYYY hh:mm A')}</td>
                                        <td className="py-2 px-6">{payment.transaction_id}</td>
                                        <td className="py-2 px-6 uppercase">{payment?.customer?.first_name} {payment?.customer?.last_name}</td>
                                        <td className="py-2 px-6">Kes {Number(payment?.amount).toLocaleString()}</td>
                                        <td className="py-2 px-6">{payment.method}</td>
                                        <td className={`py-2 px-6 ${payment.finance_approved ? 'text-green-600' : 'text-red-600'}`}>
                                            {payment.finance_approved === 'pending' ? (
                                                <button
                                                    disabled
                                                    className="ml-2 text-primary underline hover:cursor-not-allowed"
                                                >
                                                    Approve
                                                </button>
                                            ) : (
                                                <button className=""></button>
                                            )}
                                        </td>

                                        <td className={`py-2 px-6 ${payment.ceo_approved ? 'text-green-600' : 'text-red-600'}`}>
                                            {payment.ceo_approved === 'pending' ? (
                                                <button
                                                    className={`ml-2 text-black underline ${payment.finance_approved === 'pending' ? 'cursor-not-allowed' : ''}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleCEOApprove(payment.id);
                                                    }}
                                                >
                                                    Approve
                                                </button>
                                            ) : (
                                                <p className="text-green-500">Approved</p>
                                            )}
                                        </td>
                                        <td className="py-2 px-6" onClick={() => handleViewDetails(payment.id)}>
                                            <FaEye className='text-secondary' size={24} />
                                        </td>
                                        <td className="py-2 px-6" onClick={() => navigate(`/dashboard/payments/${payment.id}/receipt`)}>
                                            <IoReceiptOutline className='text-secondary' size={24} />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="9" className="text-center py-6">
                                        <div className="flex flex-col items-center text-gray-500">
                                            <FaExclamationCircle className='text-secondary mb-4' size={64} />
                                            <p className="text-lg font-medium">No payments available</p>
                                            <p className="text-sm">It looks like there are no transactions to display right now.</p>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {filteredPayments.length > 0 && (
                        <div className="w-full flex justify-end p-4 mt-2">
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="text-[14px] flex mr-1 items-center text-primary font-light gap-2"
                            >
                                Prev
                            </button>
                            {[...Array(totalPages)].map((_, i) => (
                                <button
                                    key={i + 1}
                                    onClick={() => paginate(i + 1)}
                                    className={`px-3 py-1.5 mx-1 text-[14px] font-medium ${currentPage === i + 1 ? 'bg-primary text-white' : 'text-primary'} rounded-md`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="text-[14px] flex ml-1 items-center text-primary font-light gap-2"
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {viewReceipt && selectedPayment && (
                <Modal title="Payment Receipt" onClose={() => setViewReceipt(false)}>
                    <div ref={printRef} className="p-6 bg-white rounded-lg shadow-lg border border-gray-200">
                        <div className="flex justify-between items-center mb-6">
                            <img src={logo} alt="Logo" className="h-12" />
                            <h2 className="text-xl font-bold text-gray-800">Payment Details</h2>
                        </div>
                        <div className="mb-6">
                            <p className="text-gray-600 text-sm">Transaction ID</p>
                            <h3 className="text-2xl font-bold text-gray-900">{selectedPayment.transaction_id}</h3>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="text-gray-600 text-sm">Paid By</p>
                                <h3 className="text-lg font-medium text-gray-800">
                                    {selectedPayment.customer.first_name} {selectedPayment.customer.last_name} (#{selectedPayment.customer.unique_number})
                                </h3>
                            </div>

                            <div className="text-right">
                                <p className="text-gray-600 text-sm">Amount</p>
                                <h3 className="text-2xl font-bold text-green-600">
                                    Kes {Number(selectedPayment.amount).toLocaleString()}
                                </h3>
                            </div>

                            <div>
                                <p className="text-gray-600 text-sm">Date</p>
                                <h3 className="text-lg font-medium text-gray-800">
                                    {moment(selectedPayment.createdAt).format('MMMM Do, YYYY')}
                                </h3>
                            </div>

                            <div className="text-right">
                                <p className="text-gray-600 text-sm">Time</p>
                                <h3 className="text-lg font-medium text-gray-800">
                                    {moment(selectedPayment.createdAt).format('h:mm A')}
                                </h3>
                            </div>

                            <div>
                                <p className="text-gray-600 text-sm">Payment Method</p>
                                <h3 className="text-lg font-medium text-gray-800">{selectedPayment.method}</h3>
                            </div>

                            <div className="text-right">
                                <p className="text-gray-600 text-sm">Transaction Number</p>
                                <h3 className="text-lg font-medium text-gray-800">{selectedPayment.transaction_number}</h3>
                            </div>
                        </div>

                        <div className="mt-6">
                            <div className="flex items-center justify-between w-full">
                                <div className="flex flex-col">
                                    <p className="text-gray-600 text-sm">Payment Status</p>
                                    <h3 className={`text-lg font-medium ${selectedPayment.status === 'completed' ? 'text-green-600' : 'text-red-600'}`}>
                                        {selectedPayment.status.charAt(0).toUpperCase() + selectedPayment.status.slice(1)}
                                    </h3>
                                </div>
                                {selectedPayment?.createdBy && (
                                    <div className="text-right">
                                        <p className="text-gray-600 text-sm">Created by</p>
                                        <h3 className="text-lg font-medium text-gray-800">{selectedPayment.createdBy.first_name} {selectedPayment.createdBy.last_name}</h3>
                                    </div>
                                )}
                            </div>

                            <div className="mt-4 flex justify-between">
                                <div>
                                    <p className="text-gray-600 text-sm">Finance Approval</p>
                                    <h3 className={`text-lg font-medium ${selectedPayment.finance_approved !== 'pending' ? 'text-green-600' : 'text-red-600'}`}>
                                        {selectedPayment.finance_approved !== 'pending' ? 'Approved' : 'Pending'}
                                    </h3>
                                </div>

                                <div>
                                    <p className="text-gray-600 text-sm">CEO Approval</p>
                                    <h3 className={`text-lg font-medium ${selectedPayment.ceo_approved !== 'pending' ? 'text-green-600' : 'text-red-600'}`}>
                                        {selectedPayment.ceo_approved !== 'pending' ? 'Approved' : 'Pending'}
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <hr className="my-4 border-gray-300" />

                        <div className="text-sm text-center text-gray-600">
                            <p>These are the details of the selceted transaction. Please verify to ensure there are not descrepancies recorded.</p>
                        </div>

                        <ReactToPrint
                            trigger={() => (
                                <button className="mt-6 w-full bg-secondary text-white py-2 rounded-lg shadow-md hover:bg-secondary-dark transition">
                                    Print Receipt
                                </button>
                            )}
                            content={() => printRef.current}
                        />
                    </div>
                </Modal>
            )}

            {add && (
                <Modal title="Add new payment" onClose={() => setAdd(false)}>
                    <AddNewPayment onClose={() => setAdd(false)} />
                </Modal>
            )}
        </Layout>
    );
};

export default Payments;
