import React, { useState } from 'react';
import { customerInvoices } from '../../utils/api/data';
import { IoMdSearch } from 'react-icons/io';
import { IoEyeSharp } from 'react-icons/io5';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';

const CustomerInvoices = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [invoicesPerPage] = useState(5);
    const totalPages = Math.ceil(customerInvoices.length / invoicesPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Get current invoices
    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    const currentInvoices = customerInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

    return (
        <div className="overflow-x-auto h-full bg-white rounded-lg mt-4">
            <div className="w-full flex justify-start mt-2">
                <div className="w-[300px] ml-4 mt-2 rounded-md bg-[#F5F5F5] flex items-center gap-2 px-6 py-2 text-gray-600 text-[14px]">
                    <IoMdSearch size={20} />
                    <input type="text" className='outline-none bg-transparent text-gray-600' placeholder='Search invoices' />
                </div>
            </div>
            <table className="min-w-full bg-white rounded-lg">
                <thead>
                    <tr className="text-[#848484] border-b pb-4 text-[16px] leading-normal">
                        <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Invoice Number</th>
                        <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Billing Cycle</th>
                        <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Amount</th>
                        <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Created Date</th>
                        <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Action</th>
                    </tr>
                </thead>
                <tbody className="text-primary text-[14px] font-light">
                    {currentInvoices.map((invoice, index) => (
                        <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                            <td className="py-3 px-6 text-left whitespace-nowrap">{invoice.invoiceNumber}</td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">{invoice.billingCycle}</td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">${invoice.amount}</td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">{invoice.createdDate}</td>
                            <td className="py-3 px-6 text-left text-white flex items-center gap-2">
                                <button className="bg-secondary p-1.5 flex items-center justify-center text-center rounded-full"><IoEyeSharp size={20} /></button>
                                <button className="bg-primary p-1.5 flex items-center justify-center text-center rounded-full"><FaRegEdit size={20} /></button>
                                <button className="bg-red-500 p-1.5 flex items-center justify-center text-center rounded-full"><RiDeleteBin6Line size={20} /></button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex justify-end p-4 mt-2">
                <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="text-[14px] flex mr-1 items-center text-primary font-light gap-2"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    Previous
                </button>
                {[...Array(totalPages)].map((_, i) => (
                    <button
                        key={i + 1}
                        onClick={() => paginate(i + 1)}
                        className={`px-3 py-1.5 mx-1 text-[14px] font-medium ${currentPage === i + 1 ? 'bg-primary text-white' : 'text-primary'} rounded-md`}
                    >
                        {i + 1}
                    </button>
                ))}
                <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="text-[14px] flex ml-1 items-center text-primary font-light gap-2"
                >
                    Next
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default CustomerInvoices;
