import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../../assets/miles_logo.svg';
import { useNavigate } from 'react-router-dom';

const EnterOTP = () => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [error, setError] = useState('');
    const [countdown, setCountdown] = useState(60);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (countdown === 0) return;

        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [countdown]);

    const handleChange = (element, index) => {
        const value = element.value.replace(/\D/g, '');
        const newOtp = [...otp];

        if (value) {
            newOtp[index] = value;
            setOtp(newOtp);

            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        } else if (element.previousSibling) {
            element.previousSibling.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        const element = e.target;

        if (e.key === 'Backspace') {
            if (otp[index] === '') {
                if (element.previousSibling) {
                    element.previousSibling.focus();
                }
            }
        } else if (e.key === 'Delete') {
            if (otp[index] !== '') {
                setOtp(prev => {
                    const newOtp = [...prev];
                    newOtp[index] = '';
                    return newOtp;
                });
            }
        }
    };

    const handleResendCode = async () => {
        setLoading(true);
        try {
            await axios.post('https://milescoop.webmasters.co.ke/api/v1/auth/resend-code', {}, {
                headers: {
                    'api-key': '14CA4D6A5CE4D441CFBFB23CCB699',
                },
            });
            setCountdown(60);
            setError('');
        } catch (err) {
            console.error(err);
            setError('Failed to resend OTP. Please try again.');
        }
        setLoading(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const enteredOtp = otp.join('');
        if (enteredOtp.length !== 6) {
            setError('Please enter a 6-digit OTP code.');
            return;
        }

        try {
            setLoading(true);
            await axios.post('https://milescoop.webmasters.co.ke/api/v1/auth/confirm-user-password-reset-code', {
                code: enteredOtp,
            }, {
                headers: {
                    'api-key': '14CA4D6A5CE4D441CFBFB23CCB699',
                },
            });
            navigate('/accounts/password/update');
        } catch (err) {
            console.error(err);
            setError('Failed to verify OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-lg rounded-md">
                <img src={logo} onClick={() => {navigate('/')}} className='mx-auto cursor-pointer -mb-4' alt="" />
                <p className="text-center font-light -mt-6 text-gray-600 text-[14px]">Enter the 6-digit OTP</p>
                <form onSubmit={handleSubmit} className="space-y-4">
                    {error && <p className="text-red-500 text-[13px]">{error}</p>}
                    <div className="flex justify-between gap-2">
                        {otp.map((data, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={data}
                                onChange={(e) => handleChange(e.target, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                className="w-full p-2 border border-gray-300 rounded-md text-center text-lg shadow-sm focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                            />
                        ))}
                    </div>
                    <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        disabled={loading}
                    >
                        {loading ? 'Verifying...' : 'Verify OTP'}
                    </button>
                </form>
                <div className="text-center gap-2 mt-2 flex items-center justify-end">
                    <p className="text-sm text-gray-600">Resend code in {countdown}s</p>
                    <button
                        className="text-sm text-secondary"
                        onClick={handleResendCode}
                        disabled={loading || countdown > 0}
                    >
                        Resend Code
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EnterOTP;
