import React, { useEffect, useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import axios from 'axios';
import Cookies from 'js-cookie';
import logo from '../../assets/miles_logo.svg';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import image from '../../assets/login.jpg';

const AdminSignin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookies.get('access_token');
        if (token) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('https://api.milescoop.com/api/v1/auth/user-signin', {
                email,
                password,
            }, {
                headers: {
                    'api-key': '14CA4D6A5CE4D441CFBFB23CCB699',
                },
            });

            const { token, user } = response.data;
            const userId = user.id;

            Cookies.set('access_token', token, { expires: 1 });
            Cookies.set('user_id', userId, { expires: 1 });
            localStorage.setItem('user', JSON.stringify(user));

            navigate('/dashboard');
            window.location.reload();
        } catch (err) {
            console.error(err);
            setError(error?.message || 'Request failed.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen w-full">
            <div className="h-screen w-full md:w-[50%] my-auto flex flex-col items-center justify-center overflow-y-auto">
                <div className="bg-white p-8 rounded mx-auto w-full md:w-[60%]">
                    <img src={logo} className='mx-auto' alt="" />
                    <p className="text-center font-light text-gray-600 text-[14px]">Sign in to your account</p>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {error && <p className="text-red-500 text-[13px]">{error}</p>}
                        <div>
                            <label htmlFor="email" className="block text-black text-[14px] font-medium mb-1">Email Address</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="johndoe@example.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                                required
                                disabled={loading}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="block text-black text-[14px] font-medium mb-1">Password</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    placeholder="********"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                                    required
                                    disabled={loading}
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-600"
                                    onClick={() => setShowPassword((prev) => !prev)}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className={`w-full flex justify-center py-2 px-4 border border-transparent text-[13px] font-medium rounded-md text-white bg-secondary focus:outline-none ${loading ? 'cursor-not-allowed bg-opacity-50' : ''}`}
                            disabled={loading}
                        >
                            {loading ? 'Signing In...' : 'Sign In'}
                        </button>
                    </form>
                    <div className="flex items-center justify-between mt-6">
                        <hr className="w-full border-gray-300" />
                        <span className="px-3 text-sm text-gray-600">or</span>
                        <hr className="w-full border-gray-300" />
                    </div>
                    <button
                        className="w-full mt-2 flex items-center justify-center gap-2 p-2 text-[14px] rounded-full border bg-gray-100 hover:bg-gray-200"
                    >
                        <FaGoogle className="text-red-500 text-xl" />
                        Continue with Google
                    </button>
                </div>
            </div>
            <div className="w-1/2 h-screen hidden md:flex">
                <img src={image} className='object-cover' alt="Side banner" />
            </div>
        </div>
    );
};

export default AdminSignin;
