import React, { useState } from 'react';
import { MdClose, MdCloudUpload } from 'react-icons/md';
import api from '../../utils/api/axiosInstance';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const ImageUploadModal = ({ onClose }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const projectId = id;

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };

    const handleUpload = async () => {
        if (selectedFiles.length === 0) {
            return;
        }

        const formData = new FormData();
        formData.append('project_id', projectId);

        selectedFiles.forEach((file) => {
            formData.append('images', file);
        });

        try {
            setLoading(true);
            const response = await api.post('/projects/add-images', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            toast.success("Files uploaded successfully");
            window.location.reload();
            console.log(response.data);
        } catch (error) {
            toast.error("Failed to upload files");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl">
                <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h3 className="text-lg font-semibold">Upload Images</h3>
                    <button className="text-gray-500 hover:text-gray-800" onClick={onClose}>
                        <MdClose size={24} />
                    </button>
                </div>

                <div className="p-4 max-w-xl mx-auto">
                    <h2 className="text-xl font-semibold mb-4">Upload Images</h2>

                    <input
                        type="file"
                        id="file-upload"
                        multiple
                        accept="image/*"
                        onChange={handleFileChange}
                        className="hidden"
                    />

                    <label
                        htmlFor="file-upload"
                        className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                    >
                        <MdCloudUpload size={48} className="text-gray-400" />
                        <p className="mt-2 text-gray-600">Click or drag to upload images</p>
                        <p className="text-sm text-gray-500">You can upload multiple images</p>
                    </label>

                    <div className="mt-4 grid grid-cols-3 gap-2">
                        {selectedFiles.length > 0 &&
                            selectedFiles.map((file, index) => (
                                <div key={index} className="relative">
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={`Preview ${index}`}
                                        className="w-full h-auto object-cover"
                                    />
                                    <p className="text-sm text-center mt-2">{file.name}</p>
                                </div>
                            ))}
                    </div>

                    <button
                        onClick={handleUpload}
                        disabled={loading}
                        className={`mt-6 w-full py-2 px-4 rounded bg-secondary text-white font-medium ${loading ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                    >
                        {loading ? 'Uploading...' : 'Upload Images'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ImageUploadModal;
