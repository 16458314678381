import React from 'react';
import Loading from '../../elements/Loading';

const LeadsInfo = ({ lead }) => {
    if (!lead) {
        return <Loading />;
    }

    return (
        <div className="flex flex-col gap-6 w-full bg-white p-6 rounded-md shadow-lg">
            <div className="p-6 rounded-md border border-gray-200">
                <p className="text-xl font-semibold text-primary mb-4">Lead Details</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Title</p>
                        <p className="text-black font-semibold text-lg">{lead.title}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Name</p>
                        <p className="text-black font-semibold text-lg">{lead.username}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Phone Number</p>
                        <p className="text-black font-semibold text-lg">{lead.phone_number}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Email</p>
                        <p className="text-black font-semibold text-lg">{lead.email}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Source</p>
                        <p className="text-secondary font-semibold text-lg">{lead.source || 'N/A'}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Status</p>
                        <p className={`font-semibold text-lg ${lead.status === 'new' ? 'text-green-600' : 'text-gray-600'}`}>
                            {lead.status || 'N/A'}
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Priority</p>
                        <p className={`font-semibold text-lg ${lead.priority === 'hot' ? 'text-red-500' : 'text-yellow-500'}`}>
                            {lead.priority || 'N/A'}
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Next Follow Up</p>
                        <p className="text-black font-semibold text-lg">{lead.next_follow_up || 'N/A'}</p>
                    </div>
                </div>
            </div>

            <div className="p-6 rounded-md border border-gray-200">
                <p className="text-xl font-semibold text-primary mb-4">Assigned User</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Name</p>
                        <p className="text-black font-semibold text-lg">{lead?.assigned_user?.first_name} {lead?.assigned_user?.last_name}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Email</p>
                        <p className="text-black font-semibold text-lg">{lead?.assigned_user?.email}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Phone</p>
                        <p className="text-black font-semibold text-lg">{lead?.assigned_user?.phone_number}</p>
                    </div>
                </div>
            </div>

            <div className="p-6 rounded-md border border-gray-200">
                <p className="text-xl font-semibold text-primary mb-4">Project Information</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Project Title</p>
                        <p className="text-black font-semibold text-lg">{lead?.project?.title}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-gray-500 text-sm font-medium">Location</p>
                        <p className="text-black font-semibold text-lg">{lead?.project?.location}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeadsInfo;
