import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const CustomerSales = ({ monthlyCustomerSales }) => {
    const labels = monthlyCustomerSales.map(item => item.month);
    const dataPoints = monthlyCustomerSales.map(item => parseFloat(item.customerSales));

    const data = {
        labels,
        datasets: [
            {
                label: 'Customer Sales',
                data: dataPoints,
                borderColor: '#fbbf24',
                backgroundColor: 'transparent',
                borderWidth: 2,
                fill: false,
                tension: 0.4,
                pointRadius: 0.2,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#000', 
                },
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    color: '#000', 
                },
                grid: {
                    borderDash: [5, 5],
                    color: '#f8f8f8',
                },
            },
        },
    };

    return (
        <div className="bg-white p-4 rounded-lg">
            <h2 className="text-xl font-semibold text-black mb-4">Monthly Customer Sales</h2>
            <Line data={data} options={options} />
        </div>
    );
};

export default CustomerSales;
