import React, { useEffect, useState } from 'react';
import Layout from '../../elements/Layout';
import CustomerOrders from './CustomerOrders';
import CustomerKYC from './CustomerKYC';
import CustomerInvoices from './CustomerInvoices';
import CustomerInteractions from './CustomerInteractions';
import CustomerCommunication from './CustomerCommunication';
import { useParams } from 'react-router-dom';
import { fetchCustomerById, sendEmail, updateCustomer } from '../../utils/api/api';
import Loading from '../../elements/Loading';
import banner from '../../assets/banner.png'
import { MdOutlineAttachment, MdOutlineMailOutline, MdOutlineMarkUnreadChatAlt, MdOutlineMessage } from 'react-icons/md';
import { CiEdit } from 'react-icons/ci';
import { FaRegUser } from 'react-icons/fa';
import { IoMdPaper } from 'react-icons/io';
import { LuPointer } from "react-icons/lu";
import CustomerInfo from './CustomerInfo';
import Modal from '../../elements/Modal';
import toast from 'react-hot-toast';
import CustomerPayments from './CustomerPayments';

const CustomerView = () => {
  const [activeTab, setActiveTab] = useState('Info');
  const [customer, setCustomer] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [error, setError] = useState(null);
  const { id } = useParams();
  const customerId = id;
  const [showEditModal, setShowEditModal] = useState(false);
  const [editCustomerData, setEditCustomerData] = useState({
    id: customer?.id || "",
    email: customer?.email || "",
    first_name: customer?.first_name || "",
    last_name: customer?.last_name || "",
    phone_number: customer?.phone_number || "",
    id_number: customer?.id_number || "",
    dob: customer?.dob || "",
    gender: customer?.gender || "",
    nationality: customer?.nationality || "",
    city: customer?.city || "",
    state: customer?.state || "",
    country: customer?.country || "",
    address: customer?.address || ""
  });

  useEffect(() => {
    if (customer) {
      setEditCustomerData({
        id: customer.id || "",
        email: customer.email || "",
        first_name: customer.first_name || "",
        last_name: customer.last_name || "",
        phone_number: customer.phone_number || "",
        id_number: customer.id_number || "",
        dob: customer.dob || "",
        gender: customer.gender || "",
        nationality: customer.nationality || "",
        city: customer.city || "",
        state: customer.state || "",
        country: customer.country || ""
      });
    }
  }, [customer]);

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const getCustomer = async () => {
      try {
        const data = await fetchCustomerById(customerId);
        setCustomer(data.customer);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch customer.');
        setLoading(false);
      }
    };

    getCustomer();
  }, [customerId]);

  const handleEmailSend = async (e) => {
    e.preventDefault();

    const emailData = {
      user_id: id,
      user_type: 'customer',
      title,
      body,
    };

    try {
      setLoading(true);
      await sendEmail(emailData);
      toast.success("Email sent");
      setShowEmailModal(false);
    } catch (error) {
      toast.error("Failed to send")
    } finally {
      setLoading(false);
    }
  };

  const handleCustomerUpdate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await updateCustomer(editCustomerData);
      toast.success("Customer updated successfully");
      setShowEditModal(false);
    } catch (error) {
      const errorMessage = error.message || 'An error occurred.';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Info':
        return <CustomerInfo customer={customer} />;
      case 'Orders':
        return <CustomerOrders />;
      case 'Documents':
        return <CustomerKYC />;
      case 'Invoices':
        return <CustomerInvoices />;
      case 'Interaction':
        return <CustomerInteractions />;
      case 'Payments':
        return <CustomerPayments />;
      default:
        return <CustomerOrders />;
    }
  };

  return (
    <Layout text={"Customer"} backgroundColor='bg-[#FCFCFC]'>
      {loading && <Loading />}
      <div className="flex w-full h-fit">
        <img src={banner} className='rounded-t-md w-full' alt="" />
      </div>
      <div className="flex md:px-10 gap-4 items-center">
        <div className="w-[150px] h-[150px] -mt-[45px] md:-mt-[75px] rounded-full overflow-hidden">
          <img
            className='rounded-full w-full h-full object-cover'
            src="https://imgs.search.brave.com/fbxInw05M6mkNEHaT64Qm3dNEzTIVXrwCD4lxk4ve3A/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAyLzE3LzM0LzY3/LzM2MF9GXzIxNzM0/Njc4Ml83WHBDVHQ4/YkxOSnF2VkFhRFpK/d3Zaam0wZXBRbWo2/ai5qcGc"
            alt="Customer Profile"
          />
        </div>
        <div className="flex justify-between w-full">
          <p className="text-2xl font-semibold text-primary">{customer?.first_name} {customer?.last_name}</p>
          <div className="flex gap-4 -mt-2">
            <button
              className="flex items-center gap-1 bg-primary text-white px-4 py-2 rounded-md transition hover:bg-secondary-dark"
              onClick={() => setShowMessageModal(true)}
            >
              <MdOutlineMessage size={20} />
              <span>Message</span>
            </button>
            <button
              className="flex items-center gap-1 bg-secondary text-white px-4 py-2 rounded-md transition hover:bg-secondary-dark"
              onClick={() => setShowEmailModal(true)}
            >
              <MdOutlineMailOutline size={20} />
              <span>Email</span>
            </button>
            <button
              className="flex items-center gap-1 bg-gray-100 text-primary px-4 py-2 rounded-md transition hover:bg-secondary-dark"
              onClick={() => setShowEditModal(true)}
            >
              <CiEdit size={20} />
              <span>Edit</span>
            </button>
          </div>
        </div>
      </div>

      <div className="w-full gap-4 md:mt-4 flex flex-col md:flex-row mb-4">
        <div className="bg-white w-full p-4 rounded-lg md:w-[25%] flex-col md:flex-row">
          <div className="flex w-full justify-between items-center gap-4 md:hidden">
            <FaRegUser
              size={24}
              className={`${activeTab === 'Info' ? 'text-secondary' : ''}`}
              onClick={() => setActiveTab('Info')}
            />
            <IoMdPaper
              size={24}
              className={`${activeTab === 'Orders' ? 'text-secondary' : ''}`} 
              onClick={() => setActiveTab('Orders')}
            />
            <LuPointer
              size={24}
              className={`${activeTab === 'Interaction' ? 'text-secondary' : ''}`}
              onClick={() => setActiveTab('Interaction')}
            />
            <MdOutlineAttachment
              size={24}
              className={`${activeTab === 'Documents' ? 'text-secondary' : ''}`}
              onClick={() => setActiveTab('Documents')}
            />
            <MdOutlineAttachment
              size={24}
              className={`${activeTab === 'Payments' ? 'text-secondary' : ''}`}
              onClick={() => setActiveTab('Payments')}
            />
          </div>
          <div className="w-full rounded-md hidden md:flex flex-col gap-4">
            <button
              className={`py-1.5 px-6 text-[14px] font-medium rounded-md  ${activeTab === 'Info' ? 'bg-secondary text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab('Info')}
            >
              Customer Info
            </button>
            <button
              className={`py-1.5 px-6 text-[14px] font-medium rounded-md  ${activeTab === 'Orders' ? 'bg-secondary text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab('Orders')}
            >
              Order history
            </button>
            <button
              className={`py-1.5 px-6 text-[14px] font-medium rounded-md  ${activeTab === 'Interaction' ? 'bg-secondary text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab('Interaction')}
            >
              Interaction
            </button>
            <button
              className={`py-1.5 px-6 text-[14px] font-medium rounded-md  ${activeTab === 'Documents' ? 'bg-secondary text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab('Documents')}
            >
              Documents
            </button>
            <button
              className={`py-1.5 px-6 text-[14px] font-medium rounded-md  ${activeTab === 'Payments' ? 'bg-secondary text-white' : 'bg-gray-200'}`}
              onClick={() => setActiveTab('Payments')}
            >
              Payments
            </button>
          </div>
        </div>
        <div className="w-full flex md:w-[75%]">
          {renderTabContent()}
        </div>
      </div>

      {showMessageModal && (
        <Modal onClose={() => setShowMessageModal(false)} title="Send SMS">
          <form>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="body">
                Body <span className='text-secondary'>*</span>
              </label>
              <textarea
                id="body"
                placeholder="Enter body"
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                rows="5"
              ></textarea>
            </div>
            <div className="w-full flex items-center gap-2 justify-end">
              <button className="text-secondary font-medium text-[14px]">Cancel</button>
              <button type="submit" className="rounded-md bg-secondary text-white text-[13px] font-medium px-6 py-1.5 ">
                Done
              </button>
            </div>
          </form>
        </Modal>
      )}
      {showEmailModal && (
        <Modal onClose={() => setShowEmailModal(false)} title="Send Email">
          <form onSubmit={handleEmailSend}>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="title">
                Title <span className='text-secondary'>*</span>
              </label>
              <input
                id="title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter email title"
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="body">
                Body <span className='text-secondary'>*</span>
              </label>
              <textarea
                id="body"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Enter body"
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                rows="5"
              ></textarea>
            </div>
            <div className="w-full flex items-center gap-2 justify-end">
              <button className="text-secondary font-medium text-[14px]">Cancel</button>
              <button type="submit" className="rounded-md bg-secondary text-white text-[13px] font-medium px-6 py-1.5 ">
                Done
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showEditModal && (
        <Modal onClose={() => setShowEditModal(false)} title="Edit Customer">
          <form onSubmit={handleCustomerUpdate}>
            <div className="flex w-full items-center gap-4">
              <div className="mb-4 w-full">
                <label className="block text-black text-[14px] font-medium mb-1" htmlFor="first_name">
                  First Name <span className='text-secondary'>*</span>
                </label>
                <input
                  id="first_name"
                  type="text"
                  name="first_name"
                  value={editCustomerData.first_name || ''}
                  onChange={handleEditInputChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-black text-[14px] font-medium mb-1" htmlFor="last_name">
                  Last Name <span className='text-secondary'>*</span>
                </label>
                <input
                  id="last_name"
                  type="text"
                  name="last_name"
                  value={editCustomerData.last_name || ''}
                  onChange={handleEditInputChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="email">
                Email <span className='text-secondary'>*</span>
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={editCustomerData.email || ''}
                onChange={handleEditInputChange}
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
            </div>
            <div className="w-full flex items-center gap-4">
              <div className="mb-4 w-full">
                <label className="block text-black text-[14px] font-medium mb-1" htmlFor="phone_number">
                  Phone Number <span className='text-secondary'>*</span>
                </label>
                <input
                  id="phone_number"
                  type="text"
                  name="phone_number"
                  value={editCustomerData.phone_number || ''}
                  onChange={handleEditInputChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>
              <div className="mb-4 w-full">
                <label className="block text-black text-[14px] font-medium mb-1" htmlFor="id_number">
                  ID Number <span className='text-secondary'>*</span>
                </label>
                <input
                  id="id_number"
                  type="text"
                  name="id_number"
                  value={editCustomerData.id_number || ''}
                  onChange={handleEditInputChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="dob">
                Date of Birth <span className='text-secondary'>*</span>
              </label>
              <input
                id="dob"
                type="date"
                name="dob"
                value={editCustomerData.dob || ''}
                onChange={handleEditInputChange}
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="gender">
                Gender <span className='text-secondary'>*</span>
              </label>
              <select
                id="gender"
                name="gender"
                value={editCustomerData.gender || ''}
                onChange={handleEditInputChange}
                className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="nationality">
                Nationality <span className='text-secondary'>*</span>
              </label>
              <input
                id="nationality"
                type="text"
                name="nationality"
                value={editCustomerData.nationality || ''}
                onChange={handleEditInputChange}
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="city">
                City <span className='text-secondary'>*</span>
              </label>
              <input
                id="city"
                type="text"
                name="city"
                value={editCustomerData.city || ''}
                onChange={handleEditInputChange}
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="state">
                State <span className='text-secondary'>*</span>
              </label>
              <input
                id="state"
                type="text"
                name="state"
                value={editCustomerData.state || ''}
                onChange={handleEditInputChange}
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
            </div>
            <div className="mb-4">
              <label className="block text-black text-[14px] font-medium mb-1" htmlFor="country">
                Country <span className='text-secondary'>*</span>
              </label>
              <input
                id="country"
                type="text"
                name="country"
                value={editCustomerData.country || ''}
                onChange={handleEditInputChange}
                className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
            </div>
            <div className="w-full flex items-center gap-2 justify-end">
              <button className="text-secondary font-medium text-[14px]" onClick={() => setShowEditModal(false)}>Cancel</button>
              <button type="submit" className="rounded-md bg-secondary text-white text-[13px] font-medium px-6 py-1.5">
                Save
              </button>
            </div>
          </form>
        </Modal>
      )}

    </Layout>
  );
};

export default CustomerView;
