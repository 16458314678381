import React, { useEffect, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import Modal from '../../elements/Modal';
import TaskForm from './TaskForm';
import { getProjectTask } from '../../utils/api/api';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const ProjectTasks = () => {
  const [tasks, setTasks] = useState([]); 
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();

  const handleAddTask = (newTask) => {
    setTasks([...tasks, newTask]);
    setOpenModal(false);
  };

  const getTasks = async () => {
    try {
      const response = await getProjectTask(id);
      setTasks(response.tasks);
    } catch (error) {
      toast.error(error.message || 'Request failed')
    }
  }

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <div className="mt-4 flex bg-[#fff] rounded-lg p-4 flex-col">
      <div className="w-full flex items-center justify-between">
        <p className="text-black font-semibold text-[22px]">Tasks</p>
        <div className="flex items-center gap-4">
          <button
            onClick={() => setOpenModal(true)}
            className="px-4 py-1.5 flex items-center gap-2 text-[14px] text-secondary rounded-md border border-secondary"
          >
            <IoMdAdd size={18} />
            Add New
          </button>
        </div>
      </div>

      <div className="mt-4 overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-100 border-b">
              <th className="py-2 px-4 text-left font-medium text-gray-700">Title</th>
              <th className="py-2 px-4 text-left font-medium text-gray-700">Description</th>
              <th className="py-2 px-4 text-left font-medium text-gray-700">Due Date</th>
              <th className="py-2 px-4 text-left font-medium text-gray-700">Status</th>
            </tr>
          </thead>
          <tbody>
            {tasks.length > 0 ? (
              tasks.map((task, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4">{task.title}</td>
                  <td className="py-2 px-4">{task.description}</td>
                  <td className="py-2 px-4">{new Date(task.due_date).toLocaleDateString('')}</td>
                  <td className="py-2 px-4">
                    <span
                      className={`inline-block px-2 py-1 rounded-full text-sm ${task.status === 'Completed' ? 'bg-green-100 text-green-500' : 'bg-yellow-100 text-yellow-500'
                        }`}
                    >
                      {task.status}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="py-4 text-center text-gray-500">
                  No tasks available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal for Adding New Task */}
      {openModal && (
        <Modal onClose={() => setOpenModal(false)} title="Add New Task">
          <TaskForm onAddTask={handleAddTask} />
        </Modal>
      )}
    </div>
  );
};

export default ProjectTasks;
