import React, { useEffect, useState } from 'react'
import { AddProjectResource, fetchProjectResources } from '../../utils/api/api';
import { useParams } from 'react-router-dom';
import { MdOutlineFilterAlt } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import Modal from '../../elements/Modal';
import toast from 'react-hot-toast';

const ProjectResources = () => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resources, setResources] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        type: '',
        quantity: '',
        status: '',
        allocated_budget: '',
        spent_amount: '',
        remaining_budget: '',
    });
    const { id } = useParams();
    const project_id = id;

    const handleResourceClick = (resource) => {
        setSelectedResource(resource);
    };

    const truncateToSixWords = (text) => {
        return text.split(" ").slice(0, 6).join(" ") + (text.split(" ").length > 6 ? "..." : "");
    };

    const getResources = async () => {
        setLoading(true);
        try {
            const data = await fetchProjectResources(project_id);
            setResources(data.resources);
        } catch (err) {
            toast.error('Request failed.');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const resourceData = {
            ...formData,
            project_id: project_id,
        };
        setLoading(true);
        try {
            await AddProjectResource(resourceData);
            toast.success('Resource added.');
            setOpenModal(false);
            getResources();
            setFormData({
                title: '',
                type: '',
                quantity: '',
                status: '',
                allocated_budget: '',
                spent_amount: '',
                remaining_budget: '',
            })
        } catch (err) {
            toast.error('Failed to add resource.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getResources();
    }, []);

  return (
      <div className='mt-4 flex bg-[#fff] rounded-lg p-4 flex-col'>
          <div className="w-full flex items-center justify-between">
              <p className="text-black font-semibold text-[22px]">Resources</p>
              <div className="flex items-center gap-4">
                  <button
                      onClick={() => setOpenModal(true)}
                      className="px-4 py-1.5 flex items-center gap-2 text-[14px] text-secondary rounded-md border border-secondary">
                      <IoMdAdd size={18} />
                      Add New
                  </button>
              </div>
          </div>

          <div className="w-full bg-white rounded-lg mt-4">
              <div className="overflow-x-auto rounded-lg">
                  <table className="min-w-full bg-white rounded-lg border border-gray-200 table-auto">
                      <thead className="bg-[#F0F0F0]">
                          <tr className="text-black border-b text-[14px] py-3 font-medium leading-normal rounded-t-lg">
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Title</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Type</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Quantity</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Status</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Budget allocated</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Amount Spend</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Remaining budget</th>
                          </tr>
                      </thead>
                      <tbody className="text-primary text-[14px] font-light">
                          {resources.length === 0 ? (
                              <tr>
                                  <td colSpan="4" className="py-4 px-6 text-center text-gray-500">
                                      {loading ? 'Loading, please wait' : 'No data found'}
                                  </td>
                              </tr>
                          ) : (
                              resources.map((resource, index) => (
                                  <tr
                                      key={index}
                                      className={`border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                      }`}
                                  >
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">
                                          {truncateToSixWords(resource.title)}
                                      </td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">{resource.type}</td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">{resource.quantity}</td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black capitalize">{resource?.status?.replace('_', ' ')}</td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">{resource.allocated_budget}</td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">{resource.spent_amount}</td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">{resource.remaining_budget || 'N/A'}</td>
                                  </tr>
                              ))
                          )}
                      </tbody>
                  </table>
              </div>
          </div>

          {openModal && (
              <Modal onClose={() => setOpenModal(false)} title="Add Resource">
                  <form onSubmit={handleFormSubmit} className="flex flex-col gap-4">

                      <div>
                          <label htmlFor="title" className="block text-black text-[14px] font-medium mb-1">Title</label>
                          <input
                              type="text"
                              name="title"
                              value={formData.title}
                              onChange={handleInputChange}
                              required
                              placeholder='Enter resource title'
                              className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                          />
                      </div>

                      <div>
                          <label htmlFor="type" className="block text-black text-[14px] font-medium mb-1">Type</label>
                          <select
                              name="type"
                              value={formData.type}
                              onChange={handleInputChange}
                              required
                              className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                          >
                              <option value="" disabled>Select resource type</option>
                              <option value="personnel">Personnel</option>
                              <option value="equipment">Equipment</option>
                              <option value="materials">Materials</option>
                          </select>
                      </div>

                      <div className="flex items-center w-full gap-4">
                          <div className='w-full'>
                              <label htmlFor="quantity" className="block text-black text-[14px] font-medium mb-1">Quantity</label>
                              <input
                                  name="quantity"
                                  value={formData.quantity}
                                  onChange={handleInputChange}
                                  required
                                  placeholder='Enter quantity'
                                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              />
                          </div>

                          <div className='w-full'>
                              <label htmlFor="status" className="block text-black text-[14px] font-medium mb-1">Status</label>
                              <select
                                  name="status"
                                  value={formData.status}
                                  onChange={handleInputChange}
                                  required
                                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              >
                                  <option value="" disabled>Select resource status</option>
                                  <option value="available">Available</option>
                                  <option value="in_use">In use</option>
                              </select>
                          </div>
                      </div>

                      <div className='w-full'>
                          <label htmlFor="allocated_budget" className="block text-black text-[14px] font-medium mb-1">Allocated Budget</label>
                          <input
                              type="number"
                              name="allocated_budget"
                              value={formData.allocated_budget}
                              onChange={handleInputChange}
                              required
                              placeholder='Enter allocated budget'
                              className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                          />
                      </div>

                      <div className="flex items-center gap-4 w-full">                         
                          <div className='w-full'>
                              <label htmlFor="spent_amount" className="block text-black text-[14px] font-medium mb-1">Spent Amount</label>
                              <input
                                  type="number"
                                  name="spent_amount"
                                  value={formData.spent_amount}
                                  onChange={handleInputChange}
                                  required
                                  placeholder='Enter spent amount'
                                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              />
                          </div>

                          <div className='w-full'>
                              <label htmlFor="remaining_budget" className="block text-black text-[14px] font-medium mb-1">Remaining Budget</label>
                              <input
                                  type="number"
                                  name="remaining_budget"
                                  value={formData.remaining_budget}
                                  onChange={handleInputChange}
                                  required
                                  placeholder='Enter remaining budget'
                                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                              />
                          </div>
                      </div>                      

                      <div className="flex w-full items-center justify-end">
                          <button type='button' onClick={() => setOpenModal(false)} className='text-secondary font-medium text-[14px] mr-2'>Cancel</button>
                          <button
                              type="submit"
                              disabled={loading}
                              className="bg-secondary text-white py-1.5 px-6 rounded-md text-[14px] font-medium"
                          >
                              {loading ? 'Loading, please wait...' : 'Submit'}
                          </button>
                      </div>
                  </form>
              </Modal>
          )}
      </div>
  )
}

export default ProjectResources