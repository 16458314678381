import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Customers from './pages/customer/Customers';
import CreateNewCustomer from './pages/CreateNewCustomer';
import CustomerView from './pages/customer/CustomerView';
import Projects from './pages/projects/Projects';
import Orders from './pages/Orders';
import Bookings from './pages/Bookings';
import Accounting from './pages/Accounting';
import AdminSignin from './pages/auth/AdminSignin';
import PasswordReset from './pages/auth/PasswordReset';
import EnterOTP from './pages/auth/EnterOTP';
import Contracts from './pages/contracts/Contracts';
import Accounts from './pages/Accounts';
import CardPayments from './pages/payments/CardPayments';
import Leads from './pages/leads/Leads';
import ViewLead from './pages/leads/ViewLead';
import ViewContract from './pages/contracts/ViewContract';
import ProjectView from './pages/projects/ProjectView';
import AddNewProject from './pages/projects/AddNewProject';
import AddNewProperty from './pages/properties/AddNewProperty';
import Hr from './pages/hr/Hr';
import AddNew from './pages/hr/AddNew';
import ViewEmployee from './pages/hr/ViewEmployee';
import Calendar from './pages/calendar/Calendar';
import SiteBookings from './pages/calendar/SiteBookings';
import Settings from './pages/settings/Settings';
import LandProjects from './pages/projects/LandProjects';
import Sales from './pages/sales/Sales';
import CustomerSalesReports from './pages/sales/CustomerSalesReports';
import InvoiceReports from './pages/sales/InvoiceReports';
import Tickets from './pages/Tickets';
import Tasks from './pages/tasks/Tasks';
import Reports from './pages/reports/Reports';
import Properties from './pages/properties/Properties';
import SetPassword from './pages/auth/SetPassword';
import Unauthorized from './pages/Unauthorized';
import { canAccess } from './utils/canAccess';
import { AuthContext } from './utils/context/AuthContext';
import Loading from './elements/Loading';
import Payments from './pages/payments/Payments';
import PropertyCalculator from './pages/Calucator';
import Receipt from './pages/payments/Receipt';
import NotFoundPage from './pages/NotFoundPage';
import ViewInvoice from './pages/sales/ViewInvoice';
import Messaging from './pages/Messaging';

const AppRoutes = () => {
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return <Loading />
    }

    return (
        <Routes>
            <Route path='/accounts/sign-in' element={<AdminSignin />} />
            <Route path='/accounts/request-password-reset' element={<PasswordReset />} />
            <Route path='/accounts/reset-code' element={<EnterOTP />} />
            <Route path='/user/set-password' element={<SetPassword />} />

            <Route path='/dashboard/calculator' element={<PropertyCalculator />} />

            <Route path='/' element={<Navigate to="/accounts/sign-in" />} />
            
            <Route path='/dashboard' element={<Dashboard /> } />
            <Route path='/dashboard/invoices/:id' element={<ViewInvoice />} />
            <Route path='/dashboard/customers' element={canAccess(user, 'list_customer') ? <Customers /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/customers/add-new' element={canAccess(user, 'create_customer') ? <CreateNewCustomer /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/customers/:id/view' element={canAccess(user, 'view_customer') ? <CustomerView /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/sales' element={canAccess(user, 'view_reports') ? <Sales /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/sales/customers' element={canAccess(user, 'view_reports') ? <CustomerSalesReports /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/sales/invoices' element={canAccess(user, 'view_reports') ? <InvoiceReports /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/projects/housing' element={canAccess(user, 'list_project') ? <Projects /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/projects/land' element={canAccess(user, 'list_project') ? <LandProjects /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/projects/new' element={canAccess(user, 'create_project') ? <AddNewProject /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/projects/:id/view' element={canAccess(user, 'view_project') ? <ProjectView /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/properties' element={canAccess(user, 'view_property') ? <Properties /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/properties/new' element={canAccess(user, 'create_property') ? <AddNewProperty /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/leads' element={canAccess(user, 'view_leads') ? <Leads /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/leads/:id/view' element={canAccess(user, 'view_leads') ? <ViewLead /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/payments' element={canAccess(user, 'view_payments') ? <Payments /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/payments/:id/receipt' element={<Receipt />}  />
            <Route path='/dashboard/payments/visa-mastercard' element={canAccess(user, 'view_payments') ? <CardPayments /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/reports' element={canAccess(user, 'view_reports') ? <Reports /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/accounts' element={canAccess(user, 'view_accounts') ? <Accounts /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/contracts' element={canAccess(user, 'list_contract') ? <Contracts /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/contracts/:id/view' element={canAccess(user, 'view_contract') ? <ViewContract /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/orders' element={canAccess(user, 'view_orders') ? <Orders /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/bookings' element={canAccess(user, 'list_booking') ? <Bookings /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/accounting' element={canAccess(user, 'view_accounting') ? <Accounting /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/settings' element={canAccess(user, 'view_settings') ? <Settings /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/hr' element={<Hr />} />
            <Route path='/dashboard/employees/:id/view' element={<ViewEmployee />} />
            <Route path='/dashboard/employees/:id/detail' element={<AddNew />} />
            {/* <Route path='/dashboard/hr/employees/add-new' element={canAccess(user, '') ? <AddNew /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/employees/:id/view' element={canAccess(user, '') ? <ViewEmployee /> : <Navigate to="/unauthorized" />} /> */}
            <Route path='/dashboard/calendar' element={canAccess(user, 'list_events') ? <Calendar /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/calendar/site-visits' element={canAccess(user, 'view_bookings') ? <SiteBookings /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/tickets' element={canAccess(user, 'list_tickets') ? <Tickets /> : <Navigate to="/unauthorized" />} />
            <Route path='/dashboard/tasks' element={canAccess(user, 'list_tasks') ? <Tasks /> : <Navigate to="/unauthorized" />} />

            <Route path='/dashboard/chat' element={<Messaging />} />
            <Route path='/unauthorized' element={<Unauthorized />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}

export default AppRoutes;
