import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const SalesByProperty = ({text}) => {
    const data = {
        labels: ['Housing', 'Land'],
        datasets: [
            {
                data: [0, 500],
                backgroundColor: ['#F8A81B', '#00AB9A'],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const dataset = tooltipItem.dataset;
                        const total = dataset.data.reduce((acc, value) => acc + value, 0);
                        const value = dataset.data[tooltipItem.dataIndex];
                        const percentage = ((value / total) * 100).toFixed(2);
                        return `${data.labels[tooltipItem.dataIndex]}: ${percentage}%`;
                    },
                },
            },
        },
    };

    return (
        <div className='w-full flex flex-col items-start bg-[#fff] rounded-lg p-2'>
            <p className="text-[#05004E] font-medium text-[18px] pt-4 px-4">Sales by property ({text})</p>
            <div className="w-full flex h-full items-center">
                <div className="flex w-[50%] h-[50%]">
                    <Pie data={data} options={options} />
                </div>
                <div className='w-full'>
                    <CustomLegend data={data} />
                </div>
            </div>
        </div>
    );
};

const CustomLegend = ({ data }) => {
    const total = data.datasets[0].data.reduce((acc, value) => acc + value, 0);
    return (
        <div className='w-full'>
            {data.labels.map((label, index) => {
                const value = data.datasets[0].data[index];
                const percentage = ((value / total) * 100).toFixed(0);
                return (
                    <div key={index} className='w-full mb-2 bg-[#F2F2F2] p-2 rounded-md border border-gray-100 text-[12px] text-gray-600 flex items-center'>
                        <div
                            className='w-3 h-3 rounded-full mr-2'
                            style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
                        ></div>
                        <div className="flex items-center w-full justify-between">
                            {label} <span className="">{percentage}%</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SalesByProperty;
