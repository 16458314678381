import React, { useState, useEffect } from 'react';
import Layout from '../elements/Layout';
import { fetchTickets, updateTicketStatus } from '../utils/api/api';
import Loading from '../elements/Loading';
import { IoIosSearch } from 'react-icons/io';
import Modal from '../elements/Modal';
import toast from 'react-hot-toast';
import moment from 'moment/moment';

const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
};

const Tickets = () => {
    const [tickets, setTickets] = useState([]);
    const [activeTab, setActiveTab] = useState('pending');
    const [loading, setLoading] = useState(true);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const loadTickets = async () => {
            try {
                const data = await fetchTickets();
                setTickets(data.tickets);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch tickets', error);
                setLoading(false);
            }
        };
        loadTickets();
    }, []);

    const pendingTickets = tickets.filter(ticket => !ticket.resolved);
    const resolvedTickets = tickets.filter(ticket => ticket.resolved);

    const openTicketDetails = (ticket) => {
        setSelectedTicket(ticket);
        setModalOpen(true);
    };

    const closeModal = () => {
        setSelectedTicket(null);
        setModalOpen(false);
    };

    const markTicketAsResolved = async () => {
        if (selectedTicket) {
            setLoading(true);
            try {
                await updateTicketStatus(selectedTicket.id);
                setTickets(prevTickets =>
                    prevTickets.map(ticket =>
                        ticket.id === selectedTicket.id ? { ...ticket, resolved: true } : ticket
                    )
                );
                closeModal();
                toast.success("Ticket marked as resolved.");
            } catch (error) {
                toast.error('Failed to mark ticket as resolved.');
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Layout text="Tickets" backgroundColor='bg-white'>
            {loading && <Loading />}
            <div className="flex bg-white p-4 rounded-md mb-4">
                <div className="flex items-center border border-gray-200 rounded-md bg-[#F7F7F7] w-fit">
                    <IoIosSearch className='text-gray-600 ml-2' />
                    <input type="text" placeholder='Search' className='bg-transparent h-full outline-none text-gray-600 w-full py-2 px-2' />
                </div>
            </div>

            <div className="flex justify-start my-3">
                <button
                    className={`px-6 py-2 mr-2 rounded-md ${activeTab === 'pending' ? 'bg-secondary text-white' : 'bg-gray-300 text-black'}`}
                    onClick={() => setActiveTab('pending')}
                >
                    Pending
                </button>
                <button
                    className={`px-6 py-2 rounded-md ${activeTab === 'resolved' ? 'bg-secondary text-white' : 'bg-gray-300 text-black'}`}
                    onClick={() => setActiveTab('resolved')}
                >
                    Resolved
                </button>
            </div>

            <div className="w-full bg-white rounded-lg mt-4">
                <table className="min-w-full bg-white table-auto rounded-lg border border-gray-200">
                    <thead>
                        <tr className="text-primary font-medium">
                            <th className="py-3 px-4 text-left border-b border-gray-300 border-t">Ticket ID</th>
                            <th className="py-3 px-4 text-left border-b border-gray-300 border-t">Title</th>
                            <th className="py-3 px-4 text-left border-b border-gray-300 border-t">Description</th>
                            <th className="py-3 px-4 text-left border-b border-gray-300 border-t">Date</th>
                            <th className="py-3 px-4 text-left border-b border-gray-300 border-t">Status</th>
                        </tr>
                    </thead>
                    <tbody className='text-gray-700'>
                        {(activeTab === 'pending' ? pendingTickets : resolvedTickets).map(ticket => (
                            <tr
                                key={ticket.id}
                                className='border-b border-gray-200 cursor-pointer hover:bg-gray-50'
                                onClick={() => openTicketDetails(ticket)}
                            >
                                <td className="py-4 px-4 text-gray-600 text-[14px]">{ticket.id}</td>
                                <td className="py-4 px-4 text-gray-600 text-[14px]">{ticket.title}</td>
                                <td className="py-4 px-4 text-gray-600 text-[14px]">{truncateText(ticket.description, 5)}</td>
                                <td className="py-4 px-4 text-gray-600 text-[14px]">{moment(ticket.createdAt).format('MMMM Do YYYY, h:mm A')}</td>
                                <td className="py-4 px-4 text-gray-600 text-[14px]">
                                    <span className={`inline-block py-1 px-2 rounded-full ${ticket.resolved ? 'bg-green-200 text-green-600' : 'bg-yellow-200 text-yellow-600'}`}>
                                        {ticket.resolved ? 'Resolved' : 'Pending'}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {modalOpen && selectedTicket && (
                <Modal title="Ticket Details" onClose={closeModal}>
                    <div className="p-4">
                        <div className="grid grid-cols-2 gap-4 mb-6">
                            <p className="text-gray-600 font-semibold"><strong>ID:</strong></p>
                            <p className="text-gray-900">{selectedTicket.id}</p>

                            <p className="text-gray-600 font-semibold"><strong>Customer:</strong></p>
                            <p className="text-gray-900">{selectedTicket?.customer?.first_name} {selectedTicket?.customer?.last_name}</p>

                            <p className="text-gray-600 font-semibold"><strong>Title:</strong></p>
                            <p className="text-gray-900">{selectedTicket.title}</p>

                            <p className="text-gray-600 font-semibold"><strong>Description:</strong></p>
                            <p className="text-gray-600 text-[13px]">{selectedTicket.description}</p>

                            <p className="text-gray-600 font-semibold"><strong>Date:</strong></p>
                            <p className="text-gray-900">{moment(selectedTicket.createdAt).format('MMMM Do YYYY, h:mm A')}</p>

                            <p className="text-gray-600 font-semibold"><strong>Status:</strong></p>
                            <p className={`font-medium ${selectedTicket.resolved ? 'text-green-600' : 'text-yellow-600'}`}>
                                {selectedTicket.resolved ? 'Resolved' : 'Pending'}
                            </p>
                        </div>

                        {!selectedTicket.resolved && (
                            <button
                                className="mt-4 w-full bg-secondary text-white font-medium py-1.5 rounded-md shadow hover:bg-secondary-dark transition duration-300 ease-in-out"
                                onClick={markTicketAsResolved}
                            >
                                Mark as Solved
                            </button>
                        )}

                        <button
                            className="mt-4 w-full text-gray-600 font-medium py-2 rounded-md hover:bg-gray-100 transition duration-300 ease-in-out"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
            )}
        </Layout>
    );
};

export default Tickets;
