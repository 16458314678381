import React, { useState, useEffect } from 'react';
import { fetchCustomers, fetchProjects, getProjects, submitContract } from '../../utils/api/api';
import toast from 'react-hot-toast';

const AddContractForm = ({ onClose }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [customers, setCustomers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        type: '',
        customer_id: '',
        project_type: '',
        status: '',
        start_date: '',
        end_date: '',
        value: '',
        terms: '',
        signatories: '',
        is_draft: false
    });

    useEffect(() => {
        const getCustomers = async () => {
            try {
                const data = await fetchCustomers();
                setCustomers(data.customers);
            } catch (err) {
                // Handle error
            }
        };

        const fetchProjects = async () => {
            try {
                const data = await getProjects();
                setProjects(data.projects);
            } catch (err) {
                // Handle error
            }
        };

        getCustomers();
        fetchProjects();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleCustomerChange = (e) => {
        const customerId = e.target.value;
        setSelectedCustomer(customerId);
        setFormData(prevState => ({
            ...prevState,
            customer_id: customerId
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await submitContract(formData);
            onClose();
        } catch (error) {
            toast.error("Request failed.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            {currentStep === 1 && (
                <>
                    <div>
                        <label htmlFor="title" className="block text-black text-[14px] font-medium mb-1">Name</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            placeholder='Enter agreement name'
                            value={formData.title}
                            onChange={handleChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>

                    <div>
                        <label htmlFor="project" className="block text-black text-[14px] font-medium mb-1">Project Type</label>
                        <select
                            id="project_type"
                            name="project_type"
                            value={formData.project_type}
                            onChange={handleChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        >
                            <option value="">Select a project type</option>
                            <option value="land">Land</option>
                            <option value="housing">Housing</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="type" className="block text-black text-[14px] font-medium mb-1">Type</label>
                        <select
                            id="type"
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        >
                            <option value="">Select a type</option>
                            <option value="service_agreement">Service Agreement</option>
                            <option value="lease_agreement">Lease Agreement</option>
                            <option value="sales_contract">Sales Contract</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="customer_id" className="block text-black text-[14px] font-medium mb-1">Customer</label>
                        <select
                            name="customer_id"
                            value={selectedCustomer}
                            onChange={handleCustomerChange}
                            id="customer_id"
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        >
                            <option value="">Select customer</option>
                            {customers.map(customer => (
                                <option key={customer.id} value={customer.id}>
                                    {customer.first_name} {customer.last_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="status" className="block text-black text-[14px] font-medium mb-1">Status</label>
                        <select
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        >
                            <option value="">Select a status</option>
                            <option value="active">Active</option>
                            <option value="expired">Expired</option>
                            <option value="terminated">Terminated</option>
                            <option value="pending">Pending</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">project</label>
                        <select
                            name="project_id"
                            value={formData.project_id}
                            onChange={handleChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        >
                            <option value="">Select project</option>
                            {projects.map((project) => (
                                <option key={project.id} value={project.id}>
                                    {project.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="bg-secondary text-white py-1.5 px-6 rounded-md text-[14px] font-medium"
                            onClick={() => setCurrentStep(2)}
                        >
                            Next
                        </button>
                    </div>
                </>
            )}

            {currentStep === 2 && (
                <>
                    <div className="flex w-full items-center gap-4">
                        <div className='w-full'>
                            <label htmlFor="start_date" className="block text-black text-[14px] font-medium mb-1">Start Date</label>
                            <input
                                type="date"
                                id="start_date"
                                name="start_date"
                                value={formData.start_date}
                                onChange={handleChange}
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>
                        <div className='w-full'>
                            <label htmlFor="end_date" className="block text-black text-[14px] font-medium mb-1">End Date</label>
                            <input
                                type="date"
                                id="end_date"
                                name="end_date"
                                value={formData.end_date}
                                onChange={handleChange}
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="value" className="block text-black text-[14px] font-medium mb-1">Value</label>
                        <input
                            type="number"
                            id="value"
                            name="value"
                            value={formData.value}
                            onChange={handleChange}
                            placeholder='Enter contract value'
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>

                    <div>
                        <label htmlFor="terms" className="block text-black text-[14px] font-medium mb-1">Terms</label>
                        <textarea
                            id="terms"
                            name="terms"
                            value={formData.terms}
                            onChange={handleChange}
                            rows={3}
                            placeholder='Enter terms of contract'
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>

                    <div>
                        <label htmlFor="signatories" className="block text-black text-[14px] font-medium mb-1">Signatories</label>
                        <textarea
                            id="signatories"
                            name="signatories"
                            value={formData.signatories}
                            onChange={handleChange}
                            rows={3}
                            placeholder='Signatories if this contract'
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>

                    <div>
                        <label htmlFor="is_draft" className="flex items-center">
                            <input
                                type="checkbox"
                                id="is_draft"
                                name="is_draft"
                                checked={formData.is_draft}
                                onChange={handleChange}
                                className="h-4 w-4 text-secondary focus:ring-secondary border-gray-300 rounded"
                            />
                            <span className="ml-2 text-sm text-gray-600">Draft</span>
                        </label>
                    </div>

                    <div className="flex justify-end gap-3">
                        <button
                            type="button"
                            className="text-[14px] text-secondary font-medium"
                            onClick={() => setCurrentStep(1)}
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-secondary text-white py-1.5 px-6 rounded-md text-[14px] font-medium"
                        >
                            {loading ? 'Saving...' : 'Save Contract'}
                        </button>
                    </div>
                </>
            )}
        </form>
    );
};

export default AddContractForm;
