import React, { useContext, useState } from 'react';
import {
  MdOutlineDashboardCustomize,
  MdPeople,
  MdAttachMoney,
  MdWorkOutline,
  MdTrendingUp,
  MdHelpOutline,
  MdSettings,
  MdCalendarToday,
  MdPayment,
  MdExpandMore,
  MdExpandLess,
  MdOutlineAssignment,
  MdHome
} from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';
import { LiaFileContractSolid } from 'react-icons/lia'; 
import logo from '../assets/miles_logo.svg';
import { BiLogOutCircle } from 'react-icons/bi';
import { removeAccessToken } from '../utils/api/authToken';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { AuthContext } from '../utils/context/AuthContext';
import { FaRegCircleUser } from 'react-icons/fa6';
import { motion } from 'framer-motion';
import { canAccess } from '../utils/canAccess';
import { FaCalculator } from 'react-icons/fa';

const TopMenu = ({ header }) => {
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);
  const [isSalesOpen, setIsSalesOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProjects = () => {
    setIsProjectsOpen(!isProjectsOpen);
  };

  const toggleSales = () => {
    setIsSalesOpen(!isSalesOpen);
  };

  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
    setIsProfileOpen(false);
  };

  const toggleProfile = () => {
    setIsProfileOpen(!isProfileOpen);
    setIsNotificationsOpen(false);
  };

  const fakeNotifications = [
    { id: 1, message: 'New message from John Doe' },
    { id: 2, message: 'Your report is ready' },
    { id: 3, message: 'Meeting scheduled for tomorrow' },
  ];

  const isActive = (paths) => paths.includes(location.pathname)
    ? 'bg-secondary text-primary'
    : 'text-white';

  const isSubmenuActive = (subPaths) => subPaths.some(path => location.pathname.startsWith(path))
    ? 'bg-secondary text-primary'
    : 'text-white';

  const sidebarVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
  };

  return (
    <div>
      <div className="h-[7vh] flex items-center px-[3%] bg-white border-b border-gray-100">
        <div className="w-[18%]">
          <img src={logo} className="w-[50px] my-2" alt="Logo" />
        </div>
        <div className="w-[94%] h-full pl-8 flex items-center justify-between">
          <p className="text-primary font-medium text-[16px]">{header}</p>
          <div className="flex justify-end h-full items-center gap-2 relative">
            <IoMdNotificationsOutline size={24} onClick={toggleNotifications} className="cursor-pointer" />
            <FaRegCircleUser size={24} className="text-gray-700 cursor-pointer" onClick={toggleProfile} />
            <p onClick={toggleProfile} className="-ml-1 text-[14px] text-gray-800 hidden md:flex items-center gap-2 ">{user?.first_name} {user?.last_name}</p>
            {isNotificationsOpen && (
              <div className="absolute right-10 top-[100%] mt-2 w-[250px] bg-white shadow-lg rounded-md p-4 z-40">
                <h3 className="text-gray-800 font-semibold mb-2">Notifications</h3>
                <ul className="text-gray-700 text-sm">
                  {fakeNotifications.map((notif) => (
                    <li key={notif.id} className="p-2 hover:bg-gray-100 rounded-md">{notif.message}</li>
                  ))}
                </ul>
              </div>
            )}

            {isProfileOpen && (
              <div className="absolute right-0 top-[100%] mt-2 w-[200px] bg-white shadow-lg rounded-md p-4 z-20">
                <h3 className="text-gray-800 font-semibold mb-2">Profile</h3>
                <ul className="text-gray-700 text-sm">
                  <li className="p-2 hover:bg-gray-100 rounded-md">Account Settings</li>
                  <li className="p-2 hover:bg-gray-100 rounded-md">Logout</li>
                </ul>
              </div>
            )}
          </div>
        </div>        
        <button onClick={toggleSidebar} className="md:hidden ml-4 text-2xl">
          ☰
        </button>
      </div>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={toggleSidebar}
        ></div>
      )}

      <motion.div
        className={`fixed top-0 left-0 h-full bg-primary z-20`}
        initial="closed"
        animate={isSidebarOpen ? "open" : "closed"}
        variants={sidebarVariants}
        transition={{ duration: 0.3 }}
        style={{ width: '70%' }}
      >
        <div className='bg-primary h-full w-full text-white p-6 flex flex-col overflow-y-auto'>
          <div className="flex items-center mb-6">
            <img src={logo} alt="Logo" className="h-12" />
            <h1 className="text-xl font-bold ml-2">Milescoop</h1>
          </div>

          <p className="border-b mb-3 border-gray-500 text-white text-[15px] font-medium pb-2">General</p>

          <a href={`/dashboard`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard'])}`}>
            <MdOutlineDashboardCustomize />
            Dashboard
          </a>

          {canAccess(user, 'list_customer') && (
            <Link
              to="/dashboard/customers"
              className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/customers', `/dashboard/customers/${id}/view`])}`}
            >
              <MdPeople />
              Customers
            </Link>
          )}

          <>
            <button
              onClick={toggleSales}
              className={`flex items-center justify-between mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white w-full ${isSubmenuActive(['/dashboard/sales', '/dashboard/sales/customers', '/dashboard/sales/invoices'])}`}
            >
              <div className="flex items-center gap-2">
                <MdAttachMoney />
                Trends
              </div>
              <div>
                {isSalesOpen ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
              </div>
            </button>
            {isSalesOpen && (
              <div className="ml-6 mt-2 flex flex-col">
                <Link
                  to="/dashboard/sales"
                  className={`flex items-center gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/sales'])}`}
                >
                  Sales
                </Link>
                <Link
                  to="/dashboard/sales/customers"
                  className={`flex items-center gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/sales/customers'])}`}
                >
                  Customers
                </Link>
                <Link
                  to="/dashboard/sales/invoices"
                  className={`flex items-center gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/sales/invoices'])}`}
                >
                  Invoices
                </Link>
              </div>
            )}
          </>

          {canAccess(user, 'view_property') && (
            <Link
              to="/dashboard/properties"
              className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/properties', '/dashboard/properties/new'])}`}
            >
              <MdHome />
              Properties
            </Link>
          )}

          {canAccess(user, 'list_project') && (
            <>
              <button
                onClick={toggleProjects}
                className={`flex items-center justify-between mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white w-full ${isSubmenuActive(['/dashboard/projects', 'dashboard/projects/new'])}`}
              >
                <div className="flex items-center gap-2">
                  <MdWorkOutline />
                  Projects
                </div>
                <div>
                  {isProjectsOpen ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
                </div>
              </button>
              {isProjectsOpen && (
                <div className="ml-6 mt-2 flex flex-col">
                  {canAccess(user, 'list_project') && (
                    <Link
                      to="/dashboard/projects/land"
                      className={`flex items-center gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/projects/land'])}`}
                    >
                      Land
                    </Link>
                  )}
                  {canAccess(user, 'list_project') && (
                    <Link
                      to="/dashboard/projects/housing"
                      className={`flex items-center gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/projects/housing'])}`}
                    >
                      Housing
                    </Link>
                  )}
                </div>
              )}
            </>
          )}

          {canAccess(user, 'list_leads') && (
            <Link to={`/dashboard/leads`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/leads', `/dashboard/leads/${id}/view`])}`}>
              <MdTrendingUp />
              Leads
            </Link>
          )}

          {canAccess(user, 'view_reports') && (
            <Link to={`/dashboard/reports`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/reports'])}`}>
              <MdHelpOutline />
              Reports
            </Link>
          )}

          {canAccess(user, 'list_contract') && (
            <Link to={`/dashboard/contracts`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/contracts', `/dashboard/contracts/${id}/view`])}`}>
              <LiaFileContractSolid />
              Contracts
            </Link>
          )}

          {canAccess(user, 'list_payments') && (
            <Link
              to="/dashboard/payments"
              className={`flex items-center justify-between mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white w-full ${isSubmenuActive(['/dashboard/payments'])}`}
            >
              <div className="flex items-center gap-2">
                <MdPayment />
                Payments
              </div>
            </Link>
          )}
          <Link to={`/dashboard/calendar`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/calendar', '/dashboard/calendar/site-visits'])}`}>
            <MdCalendarToday />
            Calendar
          </Link>

          <Link to={`/dashboard/hr`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/hr', '/dashboard/hr/employees/add-new', `/dashboard/employees/${id}/view`])}`}>
            <MdPeople />
            Human Resource
          </Link>

          {canAccess(user, 'list_tasks') && (
            <Link to={`/dashboard/tasks`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/tasks'])}`}>
              <MdOutlineAssignment />
              Tasks
            </Link>
          )}

          {canAccess(user, 'list_tickets') && (
            <Link to={`/dashboard/tickets`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/tickets'])}`}>
              <LiaFileContractSolid />
              Tickets
            </Link>
          )}

          <Link to={`/dashboard/calculator`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/calculator'])}`}>
            <FaCalculator />
            Calculator
          </Link>

          {canAccess(user, 'view_settings') && (
            <Link to={`/dashboard/settings`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/settings'])}`}>
              <MdSettings />
              Settings
            </Link>
          )}

          <button onClick={removeAccessToken} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white`}>
            <BiLogOutCircle size={18} />
            Log out
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default TopMenu;
