import React from 'react';
import moment from 'moment';
import Loading from '../../elements/Loading';

const CustomerInfo = ({ customer }) => {
    if (!customer) {
        return <Loading />;
    }

    return (
        <div className="flex flex-col gap-6 w-full p-6 bg-white rounded-lg shadow-lg">
            <div className="bg-gray-100 p-6 rounded-md shadow-sm">
                <p className="text-xl text-gray-800 font-semibold mb-4">Personal Details</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">Full Name</p>
                        <p className="text-lg font-medium text-gray-900 uppercase">{customer.first_name} {customer.last_name}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">Gender</p>
                        <p className="text-lg font-medium text-gray-900">{customer.gender || 'N/A'}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">Date of Birth</p>
                        <p className="text-lg font-medium text-gray-900">{customer.dob || 'N/A'}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">ID Number</p>
                        <p className="text-lg font-medium text-gray-900">{customer.id_number || 'N/A'}</p>
                    </div>
                </div>
            </div>

            <div className="bg-gray-100 p-6 rounded-md shadow-sm">
                <p className="text-xl text-gray-800 font-semibold mb-4">Address</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">City</p>
                        <p className="text-lg font-medium text-gray-900">{customer.city || 'N/A'}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">State</p>
                        <p className="text-lg font-medium text-gray-900">{customer.state || 'N/A'}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">Country</p>
                        <p className="text-lg font-medium text-gray-900">{customer.country || 'N/A'}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">Nationality</p>
                        <p className="text-lg font-medium text-gray-900">{customer.nationality || 'N/A'}</p>
                    </div>
                </div>
            </div>

            <div className="bg-gray-100 p-6 rounded-md shadow-sm">
                <p className="text-xl text-gray-800 font-semibold mb-4">Contact Details</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">Phone Number</p>
                        <p className="text-lg font-medium text-gray-900">{customer.phone_number}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">Email</p>
                        <p className="text-lg font-medium text-gray-900">{customer.email || 'N/A'}</p>
                    </div>
                </div>
            </div>

            <div className="bg-gray-100 p-6 rounded-md shadow-sm">
                <p className="text-xl text-gray-800 font-semibold mb-4">Additional Information</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">Unique Number</p>
                        <p className="text-lg font-medium text-gray-900">{customer.unique_number}</p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-sm text-gray-600">Referral Code</p>
                        <p className="text-lg font-medium text-gray-900">{customer.referral_code || 'N/A'}</p>
                    </div>
                </div>
            </div>

            <div className="bg-gray-100 p-6 rounded-md shadow-sm">
                <p className="text-xl text-gray-800 font-semibold mb-4">Login Details</p>
                <div className="flex flex-col">
                    <p className="text-sm text-gray-600">Last Login</p>
                    <p className="text-lg font-medium text-gray-900">
                        {moment(customer.last_login).format('MMM Do YYYY, h:mm A')}
                    </p>
                </div>
                <div className="flex flex-col mt-2">
                    <p className="text-sm text-gray-600">Last Password Reset</p>
                    <p className="text-lg font-medium text-gray-900">{customer.last_password_reset ? new Date(customer.last_password_reset).toLocaleString() : 'Never'}</p>
                </div>
            </div>
        </div>
    );
};

export default CustomerInfo;
