import React, { useState, useEffect } from 'react';
import Modal from '../../elements/Modal';
import { createUser, fetchRoles, updateUser } from '../../utils/api/api';
import toast from 'react-hot-toast';
import Loading from '../../elements/Loading';

const UserModal = ({ user, onClose, isEditMode, setUsers }) => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        role_id: '',
        is_staff: false, 
    });
    const [rolesOptions, setRolesOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getRoles = async () => {
            try {
                const response = await fetchRoles();
                setRolesOptions(response.userRoles);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };

        getRoles();
    }, []);

    useEffect(() => {
        if (isEditMode && user) {
            setFormData({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone_number: user.phone_number,
                role_id: user.role_id,
                is_staff: user.is_staff, // Load is_staff for edit mode
            });
        } else {
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                role_id: '',
                is_staff: false, // Set default value to false
            });
        }
    }, [user, isEditMode]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value, // Handle checkbox for is_staff
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (isEditMode && user) {
                const updatedUserData = { ...formData, id: user.id };
                await updateUser(updatedUserData);
            } else {
                const newUser = await createUser(formData);
                setUsers((prevUsers) => [...prevUsers, newUser]);
            }
            onClose();
        } catch (error) {
            toast.error("Request failed");
            setLoading(false);
        } finally {
            setLoading(false);
            window.location.reload();
        }
    };

    return (
        <Modal onClose={onClose} title={isEditMode ? 'Edit User' : 'Add User'}>
            {loading && <Loading />}
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="first_name">First Name</label>
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                        placeholder='Enter first name'
                        className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="last_name">Last Name</label>
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                        placeholder='Enter last name'
                        className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        placeholder='Enter email address'
                        className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="phone_number">Phone</label>
                    <input
                        type="text"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        placeholder='Enter phone number'
                        className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="role_id">Role</label>
                    <select
                        name="role_id"
                        value={formData.role_id}
                        onChange={handleChange}
                        className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    >
                        <option value="">Select a role</option>
                        {rolesOptions.map((role) => (
                            <option key={role.id} value={role.id}>
                                {role.title}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-black text-[14px] font-medium mb-1" htmlFor="is_staff">Staff Member</label>
                    <input
                        type="checkbox"
                        name="is_staff"
                        checked={formData.is_staff}
                        onChange={handleChange}
                        className="mr-2"
                    />
                    <span>{formData.is_staff ? 'Yes' : 'No'}</span>
                </div>
                <div className="flex justify-end gap-3">
                    <button type="button" onClick={onClose} className="text-[15px] font-medium text-secondary">
                        Cancel
                    </button>
                    <button type="submit" className="w-fit flex justify-center py-1.5 px-6 border border-transparent text-[14px] items-center font-medium rounded-md text-white bg-secondary focus:outline-none">
                        {isEditMode ? 'Update User' : 'Add User'}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default UserModal;
