import React, { useState, useContext } from 'react';
import {
    MdOutlineDashboardCustomize,
    MdPeople,
    MdAttachMoney,
    MdWorkOutline,
    MdTrendingUp,
    MdHelpOutline,
    MdSettings,
    MdCalendarToday,
    MdPayment,
    MdExpandMore,
    MdExpandLess,
    MdOutlineAssignment,
    MdHome
} from 'react-icons/md';
import { FaCalculator } from "react-icons/fa";
import { Link, useLocation, useParams } from 'react-router-dom';
import { removeAccessToken } from '../utils/api/authToken';
import { BiLogOutCircle } from 'react-icons/bi';
import { LiaFileContractSolid } from 'react-icons/lia';
import { AuthContext } from '../utils/context/AuthContext';
import { canAccess } from '../utils/canAccess';

const SideMenu = () => {
    const { user } = useContext(AuthContext);
    const location = useLocation();
    const { id } = useParams();
    const [isProjectsOpen, setIsProjectsOpen] = useState(false);
    const [isSalesOpen, setIsSalesOpen] = useState(false);

    const toggleProjects = () => {
        setIsProjectsOpen(!isProjectsOpen);
    };

    const toggleSales = () => {
        setIsSalesOpen(!isSalesOpen);
    };

    const isActive = (paths) => paths.includes(location.pathname)
        ? 'bg-secondary text-primary'
        : 'text-white';

    const isSubmenuActive = (subPaths) => subPaths.some(path => location.pathname.startsWith(path))
        ? 'bg-secondary text-primary'
        : 'text-white';

    return (
        <div className='bg-primary h-full w-full text-white pr-8 hidden md:flex py-8 flex-col overflow-y-auto'>
            <p className="border-b mb-3 border-gray-500 text-white text-[15px] font-medium pb-2">General</p>

            <a href={`/dashboard`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white w-full ${isActive(['/dashboard'])}`}>
                <MdOutlineDashboardCustomize />
                Dashboard
            </a>

            {canAccess(user, 'list_customer') && (
                <Link
                    to="/dashboard/customers"
                    className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/customers', `/dashboard/customers/${id}/view`])}`}
                >
                    <MdPeople />
                    Customers
                </Link>
            )}

            <>
                <button
                    onClick={toggleSales}
                    className={`flex items-center justify-between mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white w-full ${isSubmenuActive(['/dashboard/sales', '/dashboard/sales/customers', '/dashboard/sales/invoices'])}`}
                >
                    <div className="flex items-center gap-2">
                        <MdAttachMoney />
                        Trends
                    </div>
                    <div>
                        {isSalesOpen ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
                    </div>
                </button>
                {isSalesOpen && (
                    <div className="ml-6 mt-2 flex flex-col">
                        <Link
                            to="/dashboard/sales"
                            className={`flex items-center gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/sales'])}`}
                        >
                            Sales
                        </Link>
                        <Link
                            to="/dashboard/sales/customers"
                            className={`flex items-center gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/sales/customers'])}`}
                        >
                            Customers
                        </Link>
                        <Link
                            to="/dashboard/sales/invoices"
                            className={`flex items-center gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/sales/invoices'])}`}
                        >
                            Invoices
                        </Link>
                    </div>
                )}
            </>

            {canAccess(user, 'view_property') && (
                <Link
                    to="/dashboard/properties"
                    className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/properties', '/dashboard/properties/new'])}`}
                >
                    <MdHome />
                    Properties
                </Link>
            )}

            {canAccess(user, 'list_project') && (
                <>
                    <button
                        onClick={toggleProjects}
                        className={`flex items-center justify-between mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white w-full ${isSubmenuActive(['/dashboard/projects', 'dashboard/projects/new'])}`}
                    >
                        <div className="flex items-center gap-2">
                            <MdWorkOutline />
                            Projects
                        </div>
                        <div>
                            {isProjectsOpen ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
                        </div>
                    </button>
                    {isProjectsOpen && (
                        <div className="ml-6 mt-2 flex flex-col">
                            {canAccess(user, 'list_project') && (
                                <Link
                                    to="/dashboard/projects/land"
                                    className={`flex items-center gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/projects/land'])}`}
                                >
                                    Land
                                </Link>
                            )}
                            {canAccess(user, 'list_project') && (
                                <Link
                                    to="/dashboard/projects/housing"
                                    className={`flex items-center gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/projects/housing'])}`}
                                >
                                    Housing
                                </Link>
                            )}
                        </div>
                    )}
                </>
            )}

            {canAccess(user, 'list_leads') && (
                <Link to={`/dashboard/leads`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/leads', `/dashboard/leads/${id}/view`])}`}>
                    <MdTrendingUp />
                    Leads
                </Link>
            )}

            {canAccess(user, 'view_reports') && (
                <Link to={`/dashboard/reports`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/reports'])}`}>
                    <MdHelpOutline />
                    Reports
                </Link>
            )}

            {canAccess(user, 'list_contract') && (
                <Link to={`/dashboard/contracts`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/contracts', `/dashboard/contracts/${id}/view`])}`}>
                    <LiaFileContractSolid />
                    Contracts
                </Link>
            )}

            {canAccess(user, 'list_payments') && (
                <Link
                    to="/dashboard/payments"
                    className={`flex items-center justify-between mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white w-full ${isSubmenuActive(['/dashboard/payments'])}`}
                >
                    <div className="flex items-center gap-2">
                        <MdPayment />
                        Payments
                    </div>
                </Link>
            )}
            <Link to={`/dashboard/calendar`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/calendar', '/dashboard/calendar/site-visits'])}`}>
                <MdCalendarToday />
                Calendar
            </Link>

            <Link to={`/dashboard/hr`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/hr', '/dashboard/hr/employees/add-new', `/dashboard/employees/${id}/view`])}`}>
                <MdPeople />
                Human Resource
            </Link>

            {canAccess(user, 'list_tasks') && (
                <Link to={`/dashboard/tasks`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/tasks'])}`}>
                    <MdOutlineAssignment />
                    Tasks
                </Link>
            )}

            {canAccess(user, 'list_tickets') && (
                <Link to={`/dashboard/tickets`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/tickets'])}`}>
                    <LiaFileContractSolid />
                    Tickets
                </Link>
            )}

            <Link to={`/dashboard/calculator`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/calculator'])}`}>
                <FaCalculator />
                Calculator
            </Link>

            {canAccess(user, 'view_settings') && (
                <Link to={`/dashboard/settings`} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white ${isActive(['/dashboard/settings'])}`}>
                    <MdSettings />
                    Settings
                </Link>
            )}

            <button onClick={removeAccessToken} className={`flex items-center mt-4 gap-2 p-2 rounded-md text-[14px] font-medium hover:bg-gray-600 hover:text-white`}>
                <BiLogOutCircle size={18} />
                Log out
            </button>
        </div>
    );
};

export default SideMenu;