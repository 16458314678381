import toast from "react-hot-toast";
import api from "./axiosInstance";


export const createUserRole = async (roleData) => {
  try {
    const response = await api.post('/roles/create', roleData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRole = async (id) => {
  try {
    const response = await api.post('/roles/delete', {id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateRole = async (data) => {
  try {
    const response = await api.post('/roles/update', data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getRole = async (id) => {
  try {
    const response = await api.post('/roles/get', {id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createUser = async (userData) => {
  try {
    const response = await api.post('/users/create', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (userData) => {
  try {
    const response = await api.post('/users/update', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteUser = async (id) => {
  try {
    const response = await api.post('/users/delete', {id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchRoles = async () => {
  try {
    const response = await api.get('/roles/list');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const assignRoleToUser = async (userId, roleId) => {
  try {
    const response = await api.post(`/users/${userId}/assign-role`, { roleId });
    return response.data;
  } catch (error) {
    throw error; 
  }
};

export const addCustomer = async (customerData) => {
  try {
    const response = await api.post('/customers/create', customerData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCustomer = async (editCustomerData) => {
  try {
    const response = await api.post('/customers/update', editCustomerData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchCustomers = async () => {
  try {
    const response = await api.post('/customers/list');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCustomerById = async (id) => {
  try {
    const response = await api.post('/customers/get', { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addProperty = async (propertyData) => {
  try {
    const response = await api.post('/property/create', propertyData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProperty = async (propertyData) => {
  try {
    const response = await api.post('/property/update', propertyData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchProperties = async () => {
  try {
    const response = await api.post('/property/list');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getProjectProperties = async (project_id) => {
  try {
    const response = await api.post('/property/list', {project_id});
    return response.data;
  } catch {
    throw error;
  }
}

export const assignPropertyToCustomer = async (data) => {
  try {
    const response = await api.post('/property/assign-customer', data);
    return response.data;
  } catch (error) {
    throw error
  }
}

export const addProject = async (projectData) => {
  try {
    const response = await api.post('/projects/create', projectData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editProject = async (projectId, updatedData) => {
  try {
    const response = await api.put(`/projects/edit/${projectId}`, updatedData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProject = async (id) => {
  try {
    const response = await api.post(`/projects/delete/`, { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCustomerOrders = async (customer_id) => {
  try {
    const response = await api.post('/orders/list-customer', {customer_id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchProjectById = async (id) => {
  try {
    const response = await api.post('/projects/get', { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProjects = async (project_type = '') => {
  try {
    const response = await api.post('/projects/list', {
      project_type: project_type
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProject = async (projectData) => {
  try {
    const response = await api.post('/projects/update', projectData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getProjects = async () => {
  try {
    const response = await api.post('/projects/list');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const assignUserToProject = async (userData) => {
  try {
    const response = await api.post('/projects/assign-user', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const unassignUserFromProject = async (userData) => {
  try {
    const response = await api.post('/projects/unassign-user', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const addLead = async (leadsData) => {
  try {
    const response = await api.post('/leads/create', leadsData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchLeads = async () => {
  try {
    const response = await api.post('/leads/list');
    return response.data;
  } catch (error) {
    toast.error('An error occured');
    throw error;
  }
};

export const fetchLeadById = async (id) => {
  try {
    const response = await api.post('/leads/get', { id });
    return response.data;
  } catch (error) {
    console.error('Error fetching customer:', error);
    throw error;
  }
};

export const updateLead = async (leadData) => {
  try {
    const response = await api.post('/leads/update', leadData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchUsers = async () => {
  try {
    const response = await api.post('/users/list');
    return response.data;
  } catch (error) {
    toast.error('An error occured');
    throw error;
  }
};

export const fetchAdminAnalytics = async () => {
  try {
    const response = await api.post('/analytics/admin-dashboard');
    return response.data;
  } catch (error) {
    toast.error('An error occured');
    throw error;
  }
};

export const submitContract = async (contractData) => {
  try {
    const response = await api.post('/contracts/create', contractData);
    return response.data;
  } catch (error) {
    console.error('Error adding contract:', error);
    throw error;
  }
};

export const fetchContracts = async () => {
  try {
    const response = await api.post('/contracts/list');
    return response.data;
  } catch (error) {
    toast.error('An error occured');
    throw error;
  }
};

export const getContract = async (id) => {
  try {
    const response = await api.post('/contracts/get', {id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateContractStatus = async (contractData) => {
  try {
    const response = await api.post('/contracts/update-status', contractData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const submitContractDocument = async (formData) => {
  try {
    const response = await api.post('/contracts/add-docs', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchContractDocs = async (contract_id) => {
  try {
    const response = await api.post('/contracts/get-docs', { contract_id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitDocument = async (formData) => {
  try {
    const response = await api.post('/customers/add-docs', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerPayments = async(customer_id) => {
  try {
    const response = await api.post('/payments/list-customer', {customer_id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteDocument = async (id) => {
  try {
    const response = await api.post('/customers/delete-doc', { id });
    return response.data;
  } catch (error) {
    console.error('Error deleting document:', error);
    throw error;
  }
};

export const deleteInteraction = async (id) => {
   try {
    const response = await api.post('/customers/delete-interaction', { id });
    return response.data;
  } catch (error) {
    console.error('Error deleting:', error);
    throw error;
  }
};

export const fetchCustomerDocs = async (customer_id) => {
  try {
    const response = await api.post('/customers/get-docs', { customer_id });
    return response.data;
  } catch (error) {
    console.error('Error fetching customer:', error);
    throw error;
  }
};

export const fetchLeadDocs = async (lead_id) => {
  try {
    const response = await api.post('/leads/get-docs', { lead_id });
    return response.data;
  } catch (error) {
    console.error('Error fetching customer:', error);
    throw error;
  }
};

export const submitLeadDocument = async (formData) => {
  try {
    const response = await api.post('/leads/add-doc', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding document:', error);
    throw error;
  }
};

export const fetchCustomerInteractions = async (customer_id) => {
  try {
    const response = await api.post('/customers/get-interactions', { customer_id });
    return response.data;
  } catch (error) {
    console.error('Error fetching customer:', error);
    throw error;
  }
};

export const submitInteraction = async (interactionData) => {
    try {
        const response = await api.post('/customers/add-interaction', interactionData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const submitLeadInteraction = async (interactionData) => {
    try {
        const response = await api.post('/leads/add-interaction', interactionData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchLeadInteractions = async (lead_id) => {
  try {
    const response = await api.post('/leads/get-interactions', { lead_id });
    return response.data;
  } catch (error) {
    console.error('Error fetching lead:', error);
    throw error;
  }
};


export const uploadProjectImages = async (projectId, formData) => {
    try {
        formData.append('project_id', projectId);

        const response = await api.post('/projects/add-images', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error uploading project images:', error);
        toast.error('Error uploading project images');
        throw error;
    }
};

export const fetchProjectExpenses = async (project_id) => {
  try {
    const response = await api.post('/expenses/list', { project_id });
    return response.data;
  } catch (error) {
    console.error('Error fetching lead:', error);
    throw error;
  }
};

export const AddNewExpense = async (expensesData) => {
    try {
        const response = await api.post('/expenses/create', expensesData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const DeleteExpense = async (id) => {
    try {
        const response = await api.post('/expenses/delete', id);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Project resources
export const AddProjectResource = async (resourceData) => {
    try {
        const response = await api.post('/resources/create', resourceData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchProjectResources = async (project_id) => {
  try {
    const response = await api.post('/resources/list', { project_id });
    return response.data;
  } catch (error) {
    console.error('An error occured :', error);
    throw error;
  }
};

// Project risks
export const AddProjectRisks = async (riskData) => {
    try {
        const response = await api.post('/risks/create', riskData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchProjectRisks = async (project_id) => {
  try {
    const response = await api.post('/risks/list', { project_id });
    return response.data;
  } catch (error) {
    console.error('An error occured :', error);
    throw error;
  }
};

// Project Issues
export const AddProjectIssues = async (issuesData) => {
    try {
        const response = await api.post('/issues/create', issuesData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchProjectIssues = async (project_id) => {
  try {
    const response = await api.post('/issues/list', { project_id });
    return response.data;
  } catch (error) {
    console.error('An error occured :', error);
    throw error;
  }
};

export const createEmployeeDetails = async (employeeData) => {
  try {
    const formData = new FormData();

    Object.keys(employeeData).forEach((key) => {
      formData.append(key, employeeData[key]);
    });

    const response = await api.post('/hr/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateEmployeeDetails = async (employeeData) => {
  try {
    const formData = new FormData();

    Object.keys(employeeData).forEach((key) => {
      formData.append(key, employeeData[key]);
    });

    const response = await api.post('/hr/update', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployees = async () => {
  try {
    const response = await api.post('/hr/list');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getSingleEmployee = async (id) => {
  try {
    const response = await api.post('/hr/get', { id });
    return response.data;
  } catch (error) {
    console.error('Error fetching customer:', error);
    throw error;
  }
};

export const submitForm = async (formData) => {
  try {
    const response = await api.post('/events/create', formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};


// Events
export const fetchEventCategories = async () => {
  try {
    const response = await api.post('/events/list-categories');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchEvents = async () => {
  try {
    const response = await api.post('/events/list');
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Bookings
export const fetchBookings = async () => {
  try {
    const response = await api.post('/bookings/list');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchCustomBookings = async () => {
  try {
    const response = await api.post('/bookings/list-custom');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createBooking = async (formData) => {
  try {
    const response = await api.post('/bookings/create', formData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createCustomBooking = async (customBookingData) => {
  try {
    const response = await api.post('/bookings/create-custom', {customBookingData});
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Settings
export const fetchSettings = async () => {
  try {
    const response = await api.post('/settings/fetch');
    return response.data;
  } catch (error) {
    console.error('Error fetching settings:', error);
    throw error;
  }
};

// reports
export const fetchSalesReports = async () => {
  try {
    const response = await api.post('/reports/sales');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchCustomerSalesReport = async () => {
  try {
    const response = await api.post('/reports/sales/customers');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchInvoiceReport = async () => {
  try {
    const response = await api.post('/reports/sales/invoices');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const sendEmail = async (emailData) => {
  try {
    const response = await api.post('/communication/send-email', emailData);
    return response.data;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

// tickets
export const fetchTickets = async () => {
  try {
    const response = await api.post('/tickets/list');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateTicketStatus = async (id) => {
  try {
    const response = await api.post('/tickets/mark-resolved', { id });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// tasks
export const createTask = async (taskData) => {
  try {
    const response = await api.post('/tasks/create', taskData);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const addTask = async (taskData) => {
  try {
    const response = await api.post('/project-tasks/create', taskData);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const getProjectTask = async (project_id) => {
  try {
    const response = await api.post('/project-tasks/list', {project_id});
    return response.data
  } catch (error) {
    throw error;
  }
}

export const fetchSubtasks = async (id) => {
  try {
    const response = await api.post('/tasks/get', id);
    return response.data
  } catch (error) {
    throw error;
  }

}
export const fetchTasks = async () =>{
  try {
    const response = await api.post('/tasks/list');
    return response.data;
  } catch (error) {
    throw error;
  }
}

// fetch

export const addSubtask = async (subtaskData) => {
    try {
        const response = await api.post(`/tasks/create-subtask`, subtaskData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchInvoices = async () => {
  try {
    const response = await api.post('/invoices/list');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const fetchInvoice = async (id) => {
  try {
    const response = await api.post('/invoices/get', {id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const createPayment = async (paymentData) => {
  try {
    const response = await api.post('/payments/create', paymentData);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getMpesaPayments = async () => {
  try {
    const response = await api.post('/payments/list');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getPaymentDetails = async (id) => {
  try {
    const response = await api.post('/payments/get', {id});
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const checkPaymentStatus = async (id) => {
  try {
    const response = await api.post('/payments/get', {id});
    return response.data;
  } catch (error) {
    throw error;
  }
}