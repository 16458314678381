import React, { useState, useEffect } from 'react';
import { MdAdd, MdDelete, MdRemoveRedEye } from 'react-icons/md'; // Import the eye icon
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { fetchCustomerInteractions, deleteInteraction } from '../../utils/api/api';
import InteractionModal from './InteractionModal';
import ConfirmationModal from '../../elements/ConfirmationModal';

const CustomerInteractions = () => {
    const [interactions, setInteractions] = useState([]);
    const [selectedInteraction, setSelectedInteraction] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [interactionToDelete, setInteractionToDelete] = useState(null);
    const { id } = useParams();

    const handleInteractionClick = (interaction) => {
        setSelectedInteraction(interaction);
        setShowModal(true); // Open modal to view interaction details
    };

    const handleDeleteClick = (interaction) => {
        setInteractionToDelete(interaction);
        setShowConfirmation(true);
    };

    const getInteractions = async () => {
        try {
            const data = await fetchCustomerInteractions(id);
            setInteractions(data.interactions);
        } catch (err) {
            toast.error('Failed to get interactions.');
        }
    };

    const handleDelete = async () => {
        if (!interactionToDelete) return;

        try {
            await deleteInteraction(interactionToDelete.id);
            setInteractions(interactions.filter(interaction => interaction.id !== interactionToDelete.id));
            toast.success('Interaction deleted successfully.');
        } catch (err) {
            toast.error('Failed to delete interaction.');
        } finally {
            setShowConfirmation(false);
            setInteractionToDelete(null);
        }
    };

    useEffect(() => {
        getInteractions();
    }, [id]);

    return (
        <div className="space-y-4 w-full">
            <div className="flex items-center justify-between">
                <div className="flex flex-col">
                    <p className="text-secondary text-[16px] font-medium">Interactions</p>
                    <p className="text-[14px] text-lead font-light hidden md:flex">Add interactions in the system</p>
                </div>
                <button
                    className="flex items-center gap-2 px-4 py-1.5 bg-secondary text-white text-[14px] rounded-md outline-none"
                    onClick={() => setShowModal(true)}
                >
                    <MdAdd />
                    Log Interaction
                </button>
            </div>
            <div className="w-full overflow-x-auto rounded-md">
                <table className="w-full text-left bg-white border border-gray-200 rounded-md shadow-sm">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="py-3 px-4 border-b border-gray-300 text-[13px] font-medium text-black">Interaction Type</th>
                            <th className="py-3 px-4 border-b border-gray-300 text-[13px] font-medium text-black">Date</th>
                            <th className="py-3 px-4 border-b border-gray-300 text-[13px] font-medium text-black">Summary</th>
                            <th className="py-3 px-4 border-b border-gray-300 text-[13px] font-medium text-black">Follow-Up Action</th>
                            <th className="py-3 px-4 border-b border-gray-300 text-[13px] font-medium text-black">Actions</th>
                        </tr>
                    </thead>
                    <tbody className='rounded-b-md'>
                        {interactions.map((interaction, index) => (
                            <tr
                                key={index}
                                className={`cursor-pointer border-t border-gray-100 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} whitespace-nowrap`}
                            >
                                <td className="py-3 px-4 text-[14px] text-gray-600">{interaction.type}</td>
                                <td className="py-3 px-4 text-[14px] text-gray-600">{interaction.date}</td>
                                <td className="py-3 px-4 text-[14px] text-gray-600">
                                    {interaction.summary.split(' ').slice(0, 5).join(' ')}...
                                </td>
                                <td className="py-3 px-4 text-[14px] text-gray-600">
                                    {interaction.follow_action.split(' ').slice(0, 5).join(' ')}...
                                </td>
                                <td className="py-3 px-4 text-[14px] text-gray-600 flex gap-2">
                                    <button
                                        className="text-green-600"
                                        onClick={() => handleInteractionClick(interaction)}
                                    >
                                        <MdRemoveRedEye /> {/* Eye Icon for viewing */}
                                    </button>
                                    <button
                                        className="text-red-600"
                                        onClick={() => handleDeleteClick(interaction)}
                                    >
                                        <MdDelete />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showModal && (
                <InteractionModal
                    selectedInteraction={selectedInteraction}
                    id={id}
                    onClose={() => {
                        setShowModal(false);
                        setSelectedInteraction(null);
                        getInteractions();
                    }}
                    onInteractionAdded={(newInteraction) => setInteractions([...interactions, newInteraction])}
                />
            )}

            {showConfirmation && (
                <ConfirmationModal
                    onConfirm={handleDelete}
                    onCancel={() => setShowConfirmation(false)}
                />
            )}
        </div>
    );
};

export default CustomerInteractions;
