import React from 'react'
import Layout from '../elements/Layout'
import { FaAngleRight } from 'react-icons/fa'

const CreateNewCustomer = () => {
  return (
    <Layout>
      <div className="flex border-b border-gray-300 w-full pb-1">
        <p className="text-primary flex items-center gap-2 font-medium text-[16px]">Customers <FaAngleRight /> <span className='font-light text-black text-[15px] mt-0.5'>Create New</span></p>
      </div>
      <div className="bg-white mt-4 rounded-lg p-6 flex flex-col">
        <p className="text-[17px] font-medium text-primary mb-4">Create New Customer</p>
        <div className="flex w-full items-center flex-col gap-4 md:flex-row">
          <div className="mb-3 flex flex-col w-full">
            <label htmlFor="first_name" className='text-[14px] text-[#454745] mb-0.5'>First Name</label>
            <input
              type="text"
              className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
              placeholder='Enter first name'
            />
          </div>
          <div className="mb-3 flex flex-col w-full">
            <label htmlFor="last_name" className='text-[14px] text-[#454745] mb-0.5'>Last Name</label>
            <input
              type="text"
              className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
              placeholder='Enter last name'
            />
          </div>
        </div>
        <div className="flex w-full items-center flex-col gap-4 md:flex-row">
          <div className="mb-3 flex flex-col w-full">
            <label htmlFor="email" className='text-[14px] text-[#454745] mb-0.5'>Email</label>
            <input
              type="email"
              className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
              placeholder='Enter email address'
            />
          </div>
          <div className="mb-3 flex flex-col w-full">
            <label htmlFor="company" className='text-[14px] text-[#454745] mb-0.5'>Company Name</label>
            <input
              type="text"
              className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
              placeholder='Enter name of Company'
            />
          </div>
        </div>
        <div className="flex w-full items-center flex-col gap-4 md:flex-row">
          <div className="mb-3 flex flex-col w-full">
            <label htmlFor="phone" className='text-[14px] text-[#454745] mb-0.5'>Mobile number</label>
            <input
              type="number"
              className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
              placeholder='Enter phone number'
            />
          </div>
          <div className="mb-3 flex flex-col w-full">
            <label htmlFor="id_number" className='text-[14px] text-[#454745] mb-0.5'>ID Number</label>
            <input
              type="number"
              className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
              placeholder='National ID number'
            />
          </div>
        </div>
        <div className='mb-3'>
          <label htmlFor="address" className='text-[14px] text-[#454745] mb-0.5'>Address</label>
          <input
            type="text"
            className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
            placeholder='E.g. Street name, e.t.c'
          />
        </div>
        <div className="flex w-full items-center flex-col gap-4 md:flex-row">
          <div className="mb-3 flex flex-col w-full">
            <label htmlFor="asigned" className='text-[14px] text-[#454745] mb-0.5'>Asigned</label>
            <input
              type="date"
              className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
              placeholder=''
            />
          </div>
          <div className="mb-3 flex flex-col w-full">
            <label htmlFor="date_added" className='text-[14px] text-[#454745] mb-0.5'>Date Added</label>
            <input
              type="date"
              className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
              placeholder=''
            />
          </div>
        </div>
        <div className="flex w-full items-center flex-col gap-4 md:flex-row">
          <div className="mb-3 flex flex-col w-full">
            <label htmlFor="source" className='text-[14px] text-[#454745] mb-0.5'>Source</label>
            <input
              type="text"
              className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
              placeholder='Enter Source'
            />
          </div>
          <div className="mb-3 flex flex-col w-full">
            <label htmlFor="status" className='text-[14px] text-[#454745] mb-0.5'>Status</label>
            <input
              type="text"
              name='status'
              className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
              placeholder='Enter Status'
            />
          </div>
        </div>
        <div className="mb-3 flex flex-col w-full">
          <label htmlFor="note" className='text-[14px] text-[#454745] mb-0.5'>Note</label>
          <textarea
            type="text"
            name='note'
            className='bg-transparent py-2 px-3 border w-full focus:border-primary text-primary text-[14px] rounded-md outline-none '
            placeholder='Leave a note...'
          />
        </div>
        <button className="text-white font-medium text-[14px] bg-secondary rounded-md py-1.5 ">Submit</button>
      </div>
    </Layout>
  )
}

export default CreateNewCustomer