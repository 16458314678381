import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, LinearScale, Title, Tooltip, Legend, PointElement } from 'chart.js';

ChartJS.register(LineElement, LinearScale, Title, Tooltip, Legend, PointElement);

const ActualPlanned = () => {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Actual',
        data: [300000, 500000, 200000, 800000, 700000, 400000, 600000, 900000, 500000, 800000, 600000, 1000000],
        borderColor: '#F8A81B',
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradient.addColorStop(0, '#F7F7F7');
          gradient.addColorStop(1, '#F8B236'); 

          return gradient;
        },
        fill: true, 
        tension: 0.4,
        pointRadius: 0, 
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, 
    scales: {
      x: {
        grid: {
          display: false, 
        },
      },
      y: {
        grid: {
          color: '', 
        },
        ticks: {
          callback: function (value) {
            return `${(value / 1000).toLocaleString()}k`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false, 
      },
      title: {
        display: true,
        text: 'Annual sales',
        font: {
          size: 18,
          weight: '500',
        },
        color: '#0E113C', 
        align: 'start', 
      },
    },
  };

  return (
    <div style={{ height: '300px' }} className='w-full bg-[#fff] rounded-lg p-2 flex flex-col'>
      <Line data={data} options={options} />
    </div>
  );
};

export default ActualPlanned;
