import React, { useState } from 'react';
import moment from 'moment';
import Layout from '../elements/Layout';

const PropertyCalculator = () => {
    const [price, setPrice] = useState('');
    const [deposit, setDeposit] = useState('');
    const [months, setMonths] = useState('');
    const [installment, setInstallment] = useState('');
    const [startDate, setStartDate] = useState('');
    const [calculationType, setCalculationType] = useState('months');
    const [schedule, setSchedule] = useState([]);

    const handleCalculation = () => {
        const remainingAmount = price - deposit;

        if (calculationType === 'months') {
            const monthlyInstallment = (remainingAmount / months).toFixed(2);
            setInstallment(monthlyInstallment);
            generateSchedule(monthlyInstallment, months, remainingAmount);
        } else {
            const totalMonths = Math.ceil(remainingAmount / installment);
            setMonths(totalMonths);
            generateSchedule(installment, totalMonths, remainingAmount);
        }
    };

    const generateSchedule = (monthlyInstallment, totalMonths, remainingAmount) => {
        const scheduleArr = [];
        let balance = remainingAmount;
        let currentDate = moment(startDate);

        for (let i = 0; i < totalMonths; i++) {
            const paymentDate = currentDate.format('MMM Do, YYYY');
            balance = (balance - monthlyInstallment).toFixed(2);

            scheduleArr.push({
                installmentNumber: i + 1,
                paymentDate: paymentDate,
                installmentAmount: monthlyInstallment,
                balance: balance > 0 ? balance : 0,
            });

            currentDate.add(1, 'months');
        }

        setSchedule(scheduleArr);
    };

    return (
        <Layout>
            <div className="w-full md:w-[50%] mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">Live Calculator</h1>

                <div className="flex flex-col gap-4">
                    <div>
                        <label className="block text-gray-600 text-sm font-medium">Property Price (KES)</label>
                        <input
                            type="number"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg"
                            placeholder="Enter property price"
                        />
                    </div>

                    <div>
                        <label className="block text-gray-600 text-sm font-medium">Deposit Amount (KES)</label>
                        <input
                            type="number"
                            value={deposit}
                            onChange={(e) => setDeposit(e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg"
                            placeholder="Enter deposit amount"
                        />
                    </div>

                    <div>
                        <label className="block text-gray-600 text-sm font-medium">Start Date</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg"
                        />
                    </div>

                    <div className="flex gap-4 mt-4">
                        <label className="flex items-center text-sm font-medium text-gray-600">
                            <input
                                type="radio"
                                value="months"
                                checked={calculationType === 'months'}
                                onChange={() => setCalculationType('months')}
                                className="mr-2"
                            />
                            Calculate Installments
                        </label>

                        <label className="flex items-center text-sm font-medium text-gray-600">
                            <input
                                type="radio"
                                value="installments"
                                checked={calculationType === 'installments'}
                                onChange={() => setCalculationType('installments')}
                                className="mr-2"
                            />
                            Calculate Months
                        </label>
                    </div>

                    {calculationType === 'months' ? (
                        <div>
                            <label className="block text-gray-600 text-sm font-medium">Number of Months</label>
                            <input
                                type="number"
                                value={months}
                                onChange={(e) => setMonths(e.target.value)}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-lg"
                                placeholder="Enter number of months"
                            />
                        </div>
                    ) : (
                        <div>
                            <label className="block text-gray-600 text-sm font-medium">Installment Amount (KES)</label>
                            <input
                                type="number"
                                value={installment}
                                onChange={(e) => setInstallment(e.target.value)}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-lg"
                                placeholder="Enter installment amount"
                            />
                        </div>
                    )}

                    <button
                        onClick={handleCalculation}
                        className="bg-secondary text-white py-2 px-4 rounded-lg mt-4"
                    >
                        Calculate
                    </button>
                </div>

                {schedule.length > 0 && (
                    <div className="mt-8">
                        <h2 className="text-xl font-semibold text-gray-700 mb-4">Payment Schedule</h2>

                        <table className="min-w-full bg-white rounded-lg shadow-lg">
                            <thead>
                                <tr className="bg-gray-200 text-gray-700">
                                    <th className="py-3 px-4">No.</th>
                                    <th className="py-3 px-4">Date</th>
                                    <th className="py-3 px-4">Amount (KES)</th>
                                    <th className="py-3 px-4">Balance (KES)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {schedule.map((item, index) => (
                                    <tr key={index} className="border-t">
                                        <td className="py-3 px-4 text-center">{item.installmentNumber}</td>
                                        <td className="py-3 px-4 text-center">{item.paymentDate}</td>
                                        <td className="py-3 px-4 text-center">{Number(item.installmentAmount).toLocaleString("KES")}</td>
                                        <td className="py-3 px-4 text-center">{Number(item.balance).toLocaleString("KES")}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default PropertyCalculator;
