import React, { useState } from 'react'
import Layout from '../../elements/Layout'
import GeneralSettings from './GeneralSettings';
import UserManagement from './UserManagement';
import SecuritySettings from './SecuritySettings';
import NotificationSettings from './NotificationSettings';
import IntegrationSettings from './IntegrationSettings';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('general');

  return (
    <Layout text="Settings">
      <div className="h-full flex w-full flex-col md:flex-row gap-4 items-start">
        <div className="w-full md:w-[25%] flex flex-col bg-white p-4 h-[100%] rounded-md">
          <button
            className={`py-1.5 rounded-md text-[14px] font-medium ${activeTab === 'general' ? 'bg-secondary text-white' : 'border rounded-md border-secondary text-secondary'}`}
            onClick={() => setActiveTab('general')}
          >
            General Settings
          </button>
          <button
            className={`mt-2 py-1.5 rounded-md text-[14px] font-medium ${activeTab === 'user' ? 'bg-secondary text-white' : 'border rounded-md border-secondary text-secondary'}`}
            onClick={() => setActiveTab('user')}
          >
            User Management
          </button>
          <button
            className={`mt-2 py-1.5 rounded-md text-[14px] font-medium ${activeTab === 'notification' ? 'bg-secondary text-white' : 'border rounded-md border-secondary text-secondary'}`}
            onClick={() => setActiveTab('notification')}
          >
            Notification Settings
          </button>
        </div>

        <div className="flex flex-col w-full md:w-[75%] rounded-md">
          {activeTab === 'general' && <GeneralSettings />}
          {activeTab === 'user' && <UserManagement />}
          {activeTab === 'security' && <SecuritySettings />}
          {activeTab === 'integration' && <IntegrationSettings />}
          {activeTab === 'notification' && <NotificationSettings />}
        </div>
      </div>
    </Layout>
  );
};

export default Settings;