import React, { useState } from 'react';
import axios from 'axios';
import logo from '../../assets/miles_logo.svg';
import { useNavigate } from 'react-router-dom';

const PasswordReset = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); 

        try {
            const response = await axios.post('https://api.milescoop.com/api/v1/auth/password-reset', {
                email,
            }, {
                headers: {
                    'api-key': '14CA4D6A5CE4D441CFBFB23CCB699',
                },
            });

            setSuccess('Check your email for reset code.');
            setError('');

            setTimeout(() => {
                navigate('/accounts/reset-code');
            }, 4000);
        } catch (err) {
            console.error(err);
            setError('Failed to send reset link. Please check your email and try again.');
            setSuccess('');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-lg rounded-md">
                <img src={logo} className='mx-auto -mb-4' alt="Logo" />
                <p className="text-center font-light -mt-6 text-gray-600 text-[14px]">Reset your password</p>
                <form onSubmit={handleSubmit} className="space-y-4">
                    {error && <p className="text-red-500 text-[13px]">{error}</p>}
                    {success && <p className="text-green-500 text-[13px]">{success}</p>}
                    <div>
                        <label htmlFor="email" className="block text-black text-[14px] font-medium mb-1">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="johndoe@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="border w-full bg-transparent border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className={`w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-secondary focus:outline-none ${loading ? 'bg-opacity-50 cursor-not-allowed' : ''}`}
                    >
                        {loading ? 'Sending...' : 'Send Reset Code'}
                    </button>
                </form>
                <div className="text-center mt-2">
                    <a href="/accounts/sign-in" className="text-[14px] text-secondary">Back to Sign In</a>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;
