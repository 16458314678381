import React, { useState } from 'react';
import ProjectIssues from './ProjectIssues';
import ProjectRisks from './ProjectRisks';
import ProjectResources from './ProjectResources';
import ProjectExpenses from './ProjectExpenses';
import ProjectMilestones from './ProjectMilestones';
import ProjectTasks from './ProjectTasks';

const ProjectTabs = () => {
    const [activeTab, setActiveTab] = useState('issues');

    const renderContent = () => {
        switch (activeTab) {
            case 'issues':
                return <ProjectIssues />;
            case 'risks':
                return <ProjectRisks />;
            case 'resources':
                return <ProjectResources />;
            case 'expenses':
                return <ProjectExpenses />;
            case 'milestones':
                return <ProjectMilestones />;
            case 'tasks':
                return <ProjectTasks />;
            default:
                return <ProjectIssues />;
        }
    };

    return (
        <div>
            <div className="w-fit bg-gray-200 rounded-md flex mt-4">
                <button
                    className={`py-1.5 px-6 text-[14px] font-medium rounded-md ${activeTab === 'issues' ? 'bg-secondary text-white' : ''}`}
                    onClick={() => setActiveTab('issues')}
                >
                    Issues
                </button>
                <button
                    className={`py-1.5 px-6 text-[14px] font-medium rounded-md ${activeTab === 'risks' ? 'bg-secondary text-white' : ''}`}
                    onClick={() => setActiveTab('risks')}
                >
                    Risks
                </button>
                <button
                    className={`py-1.5 px-6 text-[14px] font-medium rounded-md ${activeTab === 'resources' ? 'bg-secondary text-white' : ''}`}
                    onClick={() => setActiveTab('resources')}
                >
                    Resources
                </button>
                <button
                    className={`py-1.5 px-6 text-[14px] font-medium rounded-md ${activeTab === 'expenses' ? 'bg-secondary text-white' : ''}`}
                    onClick={() => setActiveTab('expenses')}
                >
                     Expenses
                </button>
                <button
                    className={`py-1.5 px-6 text-[14px] font-medium rounded-md ${activeTab === 'tasks' ? 'bg-secondary text-white' : ''}`}
                    onClick={() => setActiveTab('tasks')}
                >
                    Tasks
                </button>
            </div>

            <div className="mt-4">
                {renderContent()}
            </div>
        </div>
    );
};

export default ProjectTabs;
