import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const InvoiceStatusPieChart = ({ invoiceStatusData }) => {
    const data = {
        labels: ['Paid', 'Unpaid', 'Overdue', 'Cancelled'],
        datasets: [
            {
                label: 'Invoice Status',
                data: invoiceStatusData,
                backgroundColor: ['#34d399', '#f87171', '#fbbf24', '#60a5fa'],
                hoverBackgroundColor: ['#059669', '#dc2626', '#d97706', '#2563eb'],
                borderWidth: 1,
                cutout: '70%',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            },
        },
    };

    const labels = [
        { text: 'Paid', color: '#34d399' },
        { text: 'Unpaid', color: '#f87171' },
        { text: 'Overdue', color: '#fbbf24' },
        { text: 'Cancelled', color: '#60a5fa' },
    ];

    return (
        <div className="bg-white h-full gap-4 p-4 rounded-lg flex flex-col">
            <p className='text-xl font-semibold text-black'>Invoice amounts</p>
            <div className="flex items-center justify-between gap-4 h-full w-full">
                <div className="w-1/2">
                    <Doughnut data={data} options={options} />
                </div>

                <div className="w-1/2 flex flex-col space-y-2">
                    {labels.map((label, index) => (
                        <div key={index} className="flex items-center space-x-2 bg-gray-50 rounded-md  p-2 justify-between">
                            <div className="w-full flex items-center gap-2">
                                <span
                                    className="inline-block w-4 h-4 rounded-full"
                                    style={{ backgroundColor: label.color }}
                                ></span>
                                <span className="text-gray-600 text-[14px]">{label.text}</span>
                            </div>
                            <span className="ml-auto text-[14px] text-gray-700 font-medium">{invoiceStatusData[index]}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InvoiceStatusPieChart;
