import React, { useEffect, useState } from 'react'
import { fetchProjectById, updateProject } from '../../utils/api/api';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const EditProjectForm = ({ onClose }) => {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const getProject = async () => {
        setLoading(true);
        try {
            const data = await fetchProjectById(id);
            setFormData(data.project);
            setLoading(false);
        } catch (err) {
            toast.error("Request failed.")
            setLoading(false);
        }
    };

    useEffect(() => {
        getProject();
    }, [id]);


    const handleEditChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                project_map: file,
            });
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formDataObj = new FormData();

        formDataObj.append('id', formData.id);
        formDataObj.append('manager_id', formData.manager_id);
        formDataObj.append('title', formData.title);
        formDataObj.append('description', formData.description);
        formDataObj.append('amenities', formData.amenities);
        formDataObj.append('project_type', formData.project_type);
        formDataObj.append('price', formData.price);
        formDataObj.append('deposit', formData.deposit);
        formDataObj.append('installment_price', formData.installment_price);
        formDataObj.append('monthly_installment', formData.monthly_installment);
        formDataObj.append('months', formData.months);
        formDataObj.append('location', formData.location);
        formDataObj.append('size', formData.size);
        formDataObj.append('start_date', formData.start_date);
        formDataObj.append('end_date', formData.end_date);
        formDataObj.append('status', formData.status);
        formDataObj.append('progress', formData.progress);

        if (formData.project_map instanceof File) {
            formDataObj.append('project_map', formData.project_map, formData.project_map.name);
        }

        try {
            await updateProject(formDataObj);
            toast.success("Project updated successfully.");
            onClose();
        } catch (error) {
            toast.error(error.message || "Failed to update project.");
        } finally {
            setLoading(false);
        }
    };

  return (
      <form onSubmit={handleEditSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col">
              <label className="font-medium text-sm">Title</label>
              <input
                  type="text"
                  name="title"
                  value={formData.title || ''}
                  onChange={handleEditChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
          </div>

          <div className="hidden flex-col">
              <input
                  type="text"
                  name="id"
                  value={formData.id || ''}
                  onChange={handleEditChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
          </div>

          <div className="flex flex-col">
              <label className="font-medium text-sm">Description</label>
              <textarea
                  name="description"
                  value={formData.description || ''}
                  onChange={handleEditChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
          </div>

          <div className="hidden">
              <label className="font-medium text-sm">Manager ID</label>
              <input
                  type="number"
                  name="manager_id"
                  value={formData.manager_id || ''}
                  onChange={handleEditChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
          </div>

          <div className="flex gap-4">
              <div className="flex flex-col w-1/2">
                  <label className="font-medium text-sm">Price</label>
                  <input
                      type="number"
                      name="price"
                      value={formData.price || ''}
                      onChange={handleEditChange}
                      className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
              </div>
              <div className="flex flex-col w-1/2">
                  <label className="font-medium text-sm">Deposit</label>
                  <input
                      type="number"
                      name="deposit"
                      value={formData.deposit || ''}
                      onChange={handleEditChange}
                      className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
              </div>
          </div>

          <div className="flex gap-4">
              <div className="flex flex-col w-1/2">
                  <label className="font-medium text-sm">Monthly Installment</label>
                  <input
                      type="number"
                      name="monthly_installment"
                      value={formData.monthly_installment || ''}
                      onChange={handleEditChange}
                      className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
              </div>
              <div className="flex flex-col w-1/2">
                  <label className="font-medium text-sm">Months</label>
                  <input
                      type="number"
                      name="months"
                      value={formData.months || ''}
                      onChange={handleEditChange}
                      className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
              </div>
          </div>

          <div className="flex gap-4">
              <div className="flex flex-col w-1/2">
                  <label className="font-medium text-sm">Location</label>
                  <input
                      type="text"
                      name="location"
                      value={formData.location || ''}
                      onChange={handleEditChange}
                      className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
              </div>
              <div className="flex flex-col w-1/2">
                  <label className="font-medium text-sm">Size</label>
                  <input
                      type="text"
                      name="size"
                      value={formData.size || ''}
                      onChange={handleEditChange}
                      className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
              </div>
          </div>

          <div className="flex gap-4">
              <div className="flex flex-col w-1/2">
                  <label className="font-medium text-sm">Start Date</label>
                  <input
                      type="date"
                      name="start_date"
                      value={formData.start_date || ''}
                      onChange={handleEditChange}
                      className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
              </div>
              <div className="flex flex-col w-1/2">
                  <label className="font-medium text-sm">End Date</label>
                  <input
                      type="date"
                      name="end_date"
                      value={formData.end_date || ''}
                      onChange={handleEditChange}
                      className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
              </div>
          </div>

          <div className="flex flex-col">
              <label className="font-medium text-sm">Status</label>
              <select
                  name="status"
                  value={formData.status || ''}
                  onChange={handleEditChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              >
                  <option value="not_started">Not Started</option>
                  <option value="in_progress">In Progress</option>
                  <option value="completed">Completed</option>
                  <option value="on_hold">On Hold</option>
              </select>
          </div>
          <div className="flex flex-col w-full">
              <label className="font-medium text-sm">Installment price</label>
              <input
                  type="text"
                  name="installment_price"
                  value={formData.installment_price || ''}
                  onChange={handleEditChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
          </div>

          <div className="flex gap-4">
              <div className="flex flex-col w-1/2">
                  <label className="font-medium text-sm">Project Type</label>
                  <input
                      type="text"
                      name="project_type"
                      value={formData.project_type || ''}
                      onChange={handleEditChange}
                      className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  />
              </div>
              <div className="flex flex-col w-1/2">
                  <label className="font-medium text-sm">Amenities</label>
                  <input
                      type="text"
                      name="amenities"
                      value={formData.amenities || ''}
                      onChange={handleEditChange}
                      className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                      placeholder="Comma separated amenities"
                  />
              </div>
          </div>

          <div className="flex flex-col">
              <label className="font-medium text-sm">Progress</label>
              <input
                  type="number"
                  name="progress"
                  value={formData.progress || ''}
                  onChange={handleEditChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
          </div>

          <div className="flex flex-col">
              <label className="font-medium text-sm">Project Map</label>
              <input
                  type="file"
                  name="project_map"
                  onChange={handleFileChange}
                  className="border w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
          </div>

          <div className="flex justify-end">
              <button
                  type="submit"
                  disabled={loading}
                  className={`bg-secondary text-white px-6 py-1.5 rounded-md ${loading ? 'bg-opacity-50 cursor-not-allowed' : ''}`}
              >
                  {loading ? 'Processing...' : 'Update'}
              </button>
          </div>
      </form>
  )
}

export default EditProjectForm