import React from 'react';
import { FaHome, FaWallet, FaFileInvoice, FaUser } from 'react-icons/fa';
import { IoSettingsSharp } from "react-icons/io5";
import { useLocation, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const BottomNav = ({ isModalOpen }) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const navItems = [
        { name: 'Home', icon: FaHome, path: '/dashboard' },
        { name: 'Projects', icon: FaWallet, path: '/dashboard/projects/land' },
        { name: 'Payments', icon: FaFileInvoice, path: '/dashboard/payments' },
        { name: 'Settings', icon: IoSettingsSharp, path: '/dashboard/settings' },
    ];

    const isActive = (path) => currentPath === path;

    const iconVariants = {
        active: { scale: 1.1, transition: { type: "spring", stiffness: 300 } },
        inactive: { scale: 1, transition: { type: "spring", stiffness: 300 } },
    };

    const labelVariants = {
        active: { opacity: 1, y: 0, transition: { duration: 0.3 } },
        inactive: { opacity: 1, y: 0 }
    };

    return (
        <div className={`md:hidden fixed bottom-0 w-full bg-white shadow-lg border-t ${isModalOpen ? 'pointer-events-none opacity-50' : ''}`}>
            <div className="flex justify-around items-center h-16">
                {navItems.map((item) => (
                    <Link
                        to={item.path}
                        key={item.name}
                        className={`relative flex flex-col items-center justify-center text-sm transition-all duration-300 ease-in-out ${isActive(item.path)
                            ? 'text-secondary transform translate-y-[-10px]'
                            : 'text-gray-400'
                            }`}
                    >
                        <motion.div
                            className={`flex items-center justify-center text-2xl transition-transform duration-300 ease-in-out transform ${isActive(item.path)
                                ? 'bg-primary text-white rounded-full p-3 shadow-lg'
                                : 'text-gray-400'
                                }`}
                            variants={iconVariants}
                            initial={false}
                            animate={isActive(item.path) ? 'active' : 'inactive'}
                        >
                            <item.icon />
                        </motion.div>

                        <AnimatePresence>
                            {isActive(item.path) ? (
                                <motion.span
                                    className="mt-1 text-xs bg-primary text-white px-2 py-1 rounded-md shadow-lg"
                                    variants={labelVariants}
                                    initial="inactive"
                                    animate="active"
                                    exit="inactive"
                                >
                                    {item.name}
                                </motion.span>
                            ) : (
                                <motion.span
                                    className="mt-1 text-xs text-gray-400 opacity-75"
                                    variants={labelVariants}
                                    initial="inactive"
                                    animate="inactive"
                                    exit="inactive"
                                >
                                    {item.name}
                                </motion.span>
                            )}
                        </AnimatePresence>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default BottomNav;
