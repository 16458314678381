import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../elements/Layout';
import logo from '../../assets/miles_logo.svg';
import { FaLocationDot } from 'react-icons/fa6';
import { IoIosGlobe } from 'react-icons/io';
import { MdOutlineMailOutline } from 'react-icons/md';
import { IoCallSharp } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { fetchInvoice } from '../../utils/api/api';
import toast from 'react-hot-toast';
import moment from 'moment'
import Loading from '../../elements/Loading';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ViewInvoice = () => {
    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const receiptRef = useRef();

    const getInvoice = async () => {
        setLoading(true);
        try {
            const response = await fetchInvoice(id);
            setInvoice(response.invoice);
        } catch (error) {
            toast.error("Failed to fetch invoice");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getInvoice();
    }, []);

    if (loading || !invoice) {
        return <Loading />;
    }

    const isPaid = invoice.payment_status === 'paid';

    const handleDownloadPdf = () => {
        const input = receiptRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`receipt_${invoice.id}.pdf`);
        });
    };

    return (
        <Layout>
            <div ref={receiptRef} className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
                <header className="flex justify-center items-center mb-6">
                    <img src={logo} alt="Company Logo" className="h-16 w-16 mr-4" />
                    <div>
                        <h2 className="text-2xl font-semibold text-gray-800">Milescoop Ventures.</h2>
                        <p className="text-gray-500 text-[14px]">www.milescoop.com</p>
                    </div>
                </header>

                <div className="flex w-full items-center justify-between">
                    <div className="flex flex-col">
                        <p>
                            Invoice number: <span className='bg-primary text-white px-4 py-1'>{`MVL${invoice.id}${moment(invoice.createdAt).format('YYYY').slice(-3)}`}</span>
                        </p>
                        <p>Date: <span>{moment(invoice.invoice_date).format('MMM Do YYYY, h:mm a')}</span></p>
                        <p>Payment Due Date: <span>{moment(invoice.payment_due_date).format('MMM Do YYYY, h:mm a')}</span></p>
                    </div>
                    <div className={`'bg-red-500 text-white py-2 px-6 -mr-8 ${isPaid ? 'bg-green-500' : 'bg-red-500'}`}>
                        <h2 className={`text-[28px] uppercase text-white font-bold`}>{isPaid ? 'Receipt' : 'Invoice'}</h2>
                    </div>
                </div>

                <section className="border-b pb-6 mb-6">
                    <h3 className="text-[18px] font-semibold text-primary">Billed To:</h3>
                    <p className="text-gray-600 text-[14px]">{`${invoice.customer.first_name} ${invoice.customer.last_name}`}</p>
                    <p className="text-gray-600 text-[14px]">Customer Id: {invoice.customer.unique_number}</p>
                    <p className="text-gray-600 text-[14px]">{invoice.customer.email}</p>
                    <p className="text-gray-600 text-[14px]">{invoice.customer.phone_number}</p>
                </section>

                <section className="flex flex-col w-full">
                    <h3 className="text-lg font-semibold text-gray-700 mb-2">Order Details</h3>
                    <table className="min-w-full h-full flex-grow rounded-lg table-auto">
                        <thead>
                            <tr className="text-primary pb-4 text-[14px] py-3 border-t border-b border-primary font-medium leading-normal">
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Title No.</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Quantity</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Type</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Balance</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="py-4 px-6 text-left whitespace-nowrap text-black font-light text-[13px] border-b border-gray-200">
                                    {invoice.order.property.title_number}
                                </td>
                                <td className="py-4 px-6 text-left whitespace-nowrap text-black font-light text-[13px] border-b border-gray-200">1</td>
                                <td className="py-4 px-6 text-left whitespace-nowrap text-black font-light text-[13px] border-b border-gray-200 capitalize">{invoice?.installment?.type || 'One-time'}</td>
                                <td className="py-4 px-6 text-left whitespace-nowrap text-black font-light text-[13px] border-b border-gray-200">{Number(invoice.remaining_balance).toLocaleString("KES") || 'N/A'}</td>
                                <td className="py-4 px-6 text-left whitespace-nowrap text-black font-light text-[13px] border-b border-gray-200">{Number(invoice.total_amount).toLocaleString("KES")}</td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="py-4 px-6 text-left whitespace-nowrap text-black font-light text-[13px] border-b border-gray-200">
                                    <strong>Project:</strong> {invoice.order?.property?.project?.title || 'N/A'} | <strong>Location:</strong> {invoice?.order?.property?.project?.location || 'N/A'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <div className="flex w-full justify-between border-b border-primary pb-4 mt-2 items-start">
                    <div className="text-primary font-bold text-[24px]">Total KES. {Number(invoice.total_amount).toLocaleString("en-KE")}</div>
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <p className="w-[100px] text-primary font-light text-[13px]">Subtotal</p>
                            <p className="text-primary font-medium text-[14px]">Kes. {invoice.sub_total || 0}</p>
                        </div>
                        <div className="flex items-center">
                            <p className="w-[100px] text-primary font-light text-[13px]">Discount</p>
                            <p className="text-primary font-medium text-[14px]">{invoice.discount || 0} %</p>
                        </div>
                        <div className="flex items-center">
                            <p className="w-[100px] text-primary font-light text-[13px]">Paid</p>
                            <p className="text-primary font-medium text-[14px]">Kes. {Number(invoice.paid_amount).toLocaleString("KES") || 0}</p>
                        </div>
                    </div>
                </div>

                <div className="flex w-full justify-between border-b border-primary pb-4 mt-2 items-start">
                    <Barcode
                        value={invoice?.serial_no}
                        width={2}
                        height={100}
                        displayValue={true}
                        background="#ffffff"
                        lineColor="#000000"
                        margin={10}
                        fontOptions="monospace"
                        textAlign="center"
                    />
                    <QRCode
                        value={invoice?.serial_no}
                        size={100}
                        bgColor="#ffffff"
                        fgColor="#000000"
                    />
                </div>

                <div className="mt-8 bg-gray-50 p-4 rounded-lg">
                    <h4 className="font-semibold text-gray-700">Payment Notes</h4>
                    <p className="text-gray-600 text-[13px] mt-2">{invoice.notes || 'No notes'}</p>
                </div>

                <footer className="mt-6">
                    <div className="flex justify-between text-gray-600 text-[14px]">
                        <div className="flex flex-col gap-2">
                            <p className="flex items-center gap-2">
                                <span className="bg-secondary text-white rounded-full p-1.5"><IoCallSharp /></span>
                                +254 (0) 737 900 424
                            </p>
                            <p className="flex items-center gap-2">
                                <span className="bg-secondary text-white rounded-full p-1.5"><MdOutlineMailOutline /></span>
                                info@milescoop.com
                            </p>
                        </div>
                        <div className="flex flex-col gap-2 md:mx-4">
                            <p className="flex items-center gap-2">
                                <span className="bg-secondary text-white rounded-full p-1.5"><IoIosGlobe /></span>
                                www.milescoop.com
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="flex items-center gap-2">
                                <span className="bg-secondary text-white rounded-full p-1.5"><FaLocationDot /></span>
                                Design Centre, 4th floor, Room 4A
                            </p>
                            <p className="flex items-center gap-2">
                                <span className="bg-secondary text-white rounded-full p-1.5"><FaLocationDot /></span>
                                PIN NUMBER: 052203487E
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
            {isPaid && (
                <div className="mt-6 flex justify-end">
                    <button onClick={handleDownloadPdf} className="bg-blue-500 text-white py-2 px-4 rounded-lg">
                        Print Receipt
                    </button>
                </div>
            )}
            <div className="w-full mx-auto p-6 bg-white rounded-lg mt-10">
                <table className="w-full">
                    <thead>
                        <tr>
                            <th className="border-b border-t border-gray-500 text-black font-medium text-[16px] px-4 py-2 text-start">
                                Transaction ID
                            </th>
                            <th className="border-b border-t border-gray-500 text-black font-medium text-[16px] px-4 py-2 text-start">
                                Amount
                            </th>
                            <th className="border-b border-t border-gray-500 text-black font-medium text-[16px] px-4 py-2 text-start">
                                Type
                            </th>
                            <th className="border-b border-t border-gray-500 text-black font-medium text-[16px] px-4 py-2 text-start">
                                Date & Time
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice?.transactions.map((transaction) => {
                            const year = moment(transaction.createdAt).format('YYYY').slice(-3);
                            const formattedTransactionId = `MVL${transaction.id}${year}`;

                            return (
                                <tr key={transaction.id}>
                                    <td className="border-b border-gray-100 px-4 py-2 text-gray-600 text-[14px]">
                                        <span className="font-bold">{formattedTransactionId}</span>
                                    </td>
                                    <td className="border-b border-gray-100 px-4 py-2 text-gray-600 text-[14px]">
                                        {Number(transaction.amount).toLocaleString()}
                                    </td>
                                    <td className="border-b border-gray-100 px-4 py-2 text-gray-600 text-[14px]">
                                        {transaction.type}
                                    </td>
                                    <td className="border-b border-gray-100 px-4 py-2 text-gray-600 text-[14px]">
                                        {moment(transaction.createdAt).format('MMM Do YYYY, h:mm a')}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Layout>
    );
};

export default ViewInvoice;