import { Link } from 'react-router-dom';
import logo from '../assets/miles_logo.svg';

export default function NotFoundPage() {
    return (
        <div className="min-h-screen flex flex-col justify-between bg-gray-50 text-gray-800">
            {/* Header with logo */}
            <header className="w-full py-6 bg-white shadow-sm flex justify-center">
                <img src={logo} alt="Miles Logo" className="h-12" />
            </header>

            {/* Main Content */}
            <main className="flex-grow flex flex-col items-center justify-center px-4">
                <div className="text-center max-w-xl">
                    <h1 className="text-8xl font-bold text-secondary tracking-wider">404</h1>
                    <h2 className="mt-6 text-3xl font-semibold text-gray-800">
                        Oops! Page Not Found
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                        Sorry, the page you’re looking for doesn’t exist or may have been moved to a different location.
                    </p>

                    <div className="mt-8">
                        <Link
                            to="/"
                            className="inline-block px-8 py-3 bg-secondary text-white font-medium text-lg rounded-full shadow-lg hover:bg-primary transition-all duration-300 ease-in-out"
                        >
                            Return to Homepage
                        </Link>
                    </div>
                </div>
            </main>

            <footer className="w-full py-6 bg-white text-center shadow-inner">
                <p className="text-gray-500 text-sm">
                    © {new Date().getFullYear()} Milescoop ventures ltd. All rights reserved.
                </p>
                <p className="text-gray-400 text-xs mt-1">
                    Crafted with precision.
                </p>
            </footer>
        </div>
    );
}
