import React from 'react'

const DashStats = ({ analytics }) => {
  return (
    <div className='mt-4 w-full grid grid-cols-2 md:grid-cols-4 gap-4'>
      <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
        <p className="text-primary text-[13px]">Properties listed</p>
        <hr />
        <p className="text-primary text-[16px]">{analytics.properties}</p>
      </div>
      <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
        <p className="text-primary text-[13px]">Active Leads</p>
        <hr />
        <p className="text-primary text-[16px]">{analytics.active_leads}</p>
      </div>
      <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
        <p className="text-primary text-[13px]">Projects listed</p>
        <hr />
        <p className="text-primary text-[16px]">{analytics.projects}</p>
      </div>
      <div className="bg-white w-full rounded-lg p-4 flex flex-col gap-1.5">
        <p className="text-primary text-[13px]">Active Contracts</p>
        <hr />
        <p className="text-primary text-[16px]">{analytics.active_contracts}</p>
      </div>
    </div>
  )
}

export default DashStats