import React from 'react';
import { RxDotsVertical } from 'react-icons/rx';

const ProgressTrackerTable = ({ projects_progress }) => {
    return (
        <div className="overflow-x-auto h-full flex flex-col rounded-lg">
            <div className="pb-2 w-full bg-white border-t border-l border-r px-6 pt-2 flex items-center justify-between rounded-t-lg">
                <p className="font-medium text-[18px] text-black">Project Progress Tracker</p>
                <RxDotsVertical />
            </div>
            <table className="min-w-full bg-white border-b border-r border-l border-gray-200 h-full">
                <thead>
                    <tr className="bg-gray-50 text-left border-b border-gray-300 text-sm font-semibold text-black">
                        <th className="py-3 px-6">Project Name</th>
                        <th className="py-3 px-6">Start Date</th>
                        <th className="py-3 px-6">Status</th>
                        <th className="py-3 px-6">Progress</th>
                    </tr>
                </thead>
                <tbody className="text-gray-700 text-sm rounded-b-lg h-full">
                    {(!projects_progress || projects_progress.length === 0) ? (
                        <tr>
                            <td colSpan="4" className="py-4 px-6 text-center text-gray-500">
                                No project data available.
                            </td>
                        </tr>
                    ) : (
                        projects_progress.map((project) => (
                            <tr key={project.id} className="border-b text-black font-light text-[14px] border-gray-200">
                                <td className="py-4 px-6 whitespace-nowrap">{project.title}</td>
                                <td className="py-4 px-6 whitespace-nowrap">{project.start_date}</td>
                                <td className="py-4 px-6 whitespace-nowrap">
                                    <span
                                        className={`px-2 py-1 text-xs font-semibold rounded-full ${project.status === 'not_started'
                                                ? 'bg-gray-100 text-gray-600'
                                                : project.status === 'in_progress'
                                                    ? 'bg-yellow-100 text-yellow-600'
                                                    : project.status === 'completed'
                                                        ? 'bg-green-100 text-green-600'
                                                        : project.status === 'on_hold'
                                                            ? 'bg-red-100 text-red-600'
                                                            : 'bg-gray-200 text-gray-600'
                                            }`}
                                    >
                                        {project.status.replace('_', ' ')}
                                    </span>
                                </td>
                                <td className="py-4 px-6 whitespace-nowrap">
                                    <div className="w-full">
                                        <div className="flex justify-between items-center mb-1 text-xs font-medium text-gray-700">
                                            <span>{project.progress}%</span>
                                        </div>
                                        <div className="w-full bg-gray-200 rounded-full h-1">
                                            <div
                                                className="bg-green-500 h-1 rounded-full"
                                                style={{ width: `${project.progress}%` }}
                                            ></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ProgressTrackerTable;
