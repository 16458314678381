import React, { useEffect, useState } from 'react';
import { fetchInvoices } from '../../utils/api/api';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const InvoiceList = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const invoicesPerPage = 30;

  const getInvoices = async () => {
    setLoading(true);
    try {
      const response = await fetchInvoices();
      setInvoices(response.invoices);
    } catch (error) {
      toast.error('Failed to get invoices');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = invoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total pages
  const totalPages = Math.ceil(invoices.length / invoicesPerPage);

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Invoice List</h2>
      {loading ? (
        <div className="text-center">Loading...</div>
      ) : invoices.length === 0 ? (
        <div className="text-center text-gray-500">No invoices available</div>
      ) : (
        <>
          <table className="w-full border-collapse">
            <thead>
              <tr className="text-[16px] text-black font-normal bg-gray-200 text-left">
                <th className="p-3 border-b">Customer Name</th>
                <th className="p-3 border-b">Invoice Number</th>
                <th className="p-3 border-b">Order ID</th>
                <th className="p-3 border-b">Invoice Date</th>
                <th className="p-3 border-b">Payment Status</th>
                <th className="p-3 border-b">Amount</th>
                <th className="p-3 border-b"></th> {/* View button */}
              </tr>
            </thead>
            <tbody>
              {currentInvoices.map((invoice, index) => (
                <tr
                  key={invoice.id}
                  className={`text-[14px] text-black font-light hover:bg-gray-100 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
                >
                  <td className="p-3 border-b">
                    {invoice.customer.first_name} {invoice.customer.last_name}
                  </td>
                  <td className="p-3 border-b">{invoice.serial_no}</td>
                  <td className="p-3 border-b">{invoice.order_id}</td>
                  <td className="p-3 border-b">{new Date(invoice.invoice_date).toLocaleDateString()}</td>
                  <td
                    className={`p-3 border-b font-semibold ${invoice.payment_status === 'paid' ? 'text-green-600' :
                      invoice.payment_status === 'overdue' ? 'text-red-600' :
                        invoice.payment_status === 'pending' ? 'text-yellow-600' : 'text-gray-600'
                      }`}
                  >
                    {invoice.payment_status.charAt(0).toUpperCase() + invoice.payment_status.slice(1)}
                  </td>
                  <td className="p-3 border-b">KES {Number(invoice.total_amount).toLocaleString("KES")}</td>
                  <td className="p-3 border-b">
                    <Link
                      to={`/dashboard/invoices/${invoice.id}`}
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      Details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          {currentInvoices.length > 0 && (
            <div className="w-full flex justify-end p-4 mt-2">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="text-[14px] flex mr-1 items-center text-primary font-light gap-2"
              >
                Prev
              </button>
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i + 1}
                  onClick={() => paginate(i + 1)}
                  className={`px-3 py-1.5 mx-1 text-[14px] font-medium ${currentPage === i + 1 ? 'bg-primary text-white' : 'text-primary'} rounded-md`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="text-[14px] flex ml-1 items-center text-primary font-light gap-2"
              >
                Next
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InvoiceList;
