import React, { useContext, useEffect, useState } from 'react';
import Layout from '../elements/Layout';
import img from '../assets/sales_icon.png';
import DashStats from '../components/DashStats';
import SalesRevenue from '../components/SalesRevenue';
import { IoAddOutline } from 'react-icons/io5';
import { fetchAdminAnalytics, fetchProjects, fetchUsers } from '../utils/api/api';
import CustomerEngagementBarGraph from '../components/dashboard/CustomerEngagementBarGraph';
import { FaRegClock } from 'react-icons/fa';
import ProgressTrackerTable from '../components/dashboard/ProgressTrackerTable';
import { AuthContext } from '../utils/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const Dashboard = () => {
  const [analytics, setAnalytics] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const getAnalytics = async () => {
    try {
      const data = await fetchAdminAnalytics();
      setAnalytics(data.statistics);
    } catch (err) {
      console.log("an error occured");
    }
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  return (
    <Layout text="Dashboard">
      <div className="flex w-full items-center justify-between">
        <p className="flex items-center gap-12 font-medium text-black text-[15px] truncate">
          Hello, {user?.first_name}
          <span className='text-[14px] hidden md:flex text-gray-600 font-medium'>
            Here is what is going on today
          </span>
        </p>
        <button
          onClick={() => navigate('/dashboard/projects/new')}
          className="bg-secondary rounded-md font-medium text-white capitalize text-[13px] outline-none px-6 py-1.5 flex items-center gap-2"
        >
          <IoAddOutline size={18} className='font-medium' />
          add project
        </button>
      </div>
      <div className="flex mt-4 items-center gap-4 flex-col md:flex-row">
        <div className="bg-primary w-full rounded-lg p-4 flex items-center justify-between text-white">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <div className="bg-white p-1 rounded-md">
                <img src={img} alt="" />
              </div>
              <p className="">Total Sales</p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-light text-[13px]">Kes</span>
              <p className="font-medium text-[15px]">{Number(analytics?.total_sales).toLocaleString("KES")}</p>
            </div>
          </div>
        </div>
        <div className="bg-secondary w-full rounded-lg p-4 flex items-center justify-between text-white">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <div className="bg-white p-1 rounded-md">
                <img src={img} alt="" />
              </div>
              <p className="">Total Income</p>
            </div>
            <div className="flex items-center gap-2">
              <span className="font-light text-[13px]">Kes</span>
              <p className="font-medium text-[15px]">{Number(analytics?.total_income).toLocaleString("KES")}</p>
            </div>
          </div>
        </div>
      </div>
      <DashStats analytics={analytics} />
      <div className="flex flex-col md:flex-row w-full mt-4 gap-4">
        <div className="w-full md:w-[60%]">
          <CustomerEngagementBarGraph customer_engagements={analytics?.customer_engagements} />
        </div>
        <div className="flex flex-col bg-white w-full md:w-[40%] p-6 rounded-lg">
          <div className="flex w-full justify-between items-center">
            <p className="text-[#05004E] font-medium text-[18px]">Recent tasks</p>
            <button className="text-secondary font-semibold text-[16px]">Add Task</button>
          </div>
          <div className="flex flex-col mt-4 gap-4">
            <div className="flex items-center justify-between border-b pb-1 border-gray-200">
              <p className="text-[12px] text-black font-light">Call new lead</p>
              <div className="bg-red-100 rounded-full text-red-500 px-3 py-1 gap-2 flex items-center">
                <FaRegClock />
                <span className='text-[13px]'>Urgent</span>
              </div>
            </div>
            <div className="flex items-center justify-between border-b pb-1 border-gray-200">
              <p className="text-[12px] text-black font-light">Call new lead</p>
              <div className="bg-red-100 rounded-full text-red-500 px-3 py-1 gap-2 flex items-center">
                <FaRegClock />
                <span className='text-[13px]'>Urgent</span>
              </div>
            </div>
            <div className="flex items-center justify-between border-b pb-1 border-gray-200">
              <p className="text-[12px] text-black font-light">Call new lead</p>
              <div className="bg-red-100 rounded-full text-red-500 px-3 py-1 gap-2 flex items-center">
                <FaRegClock />
                <span className='text-[13px]'>Urgent</span>
              </div>
            </div>
            <div className="flex items-center justify-between border-b pb-1 border-gray-200">
              <p className="text-[12px] text-black font-light">Call new lead</p>
              <div className="bg-red-100 rounded-full text-red-500 px-3 py-1 gap-2 flex items-center">
                <FaRegClock />
                <span className='text-[13px]'>Urgent</span>
              </div>
            </div>
            <div className="flex items-center justify-between border-b pb-1 border-gray-200">
              <p className="text-[12px] text-black font-light">Call new lead</p>
              <div className="bg-red-100 rounded-full text-red-500 px-3 py-1 gap-2 flex items-center">
                <FaRegClock />
                <span className='text-[13px]'>Urgent</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full mt-4 gap-4">
        <div className="w-full md:w-[60%]">
          <SalesRevenue text="Sales Analytics" />
        </div>
        <div className="flex flex-col bg-white w-full md:w-[40%] p-6 rounded-lg">
          <div className="flex w-full justify-between items-center">
            <p className="text-[#05004E] font-medium text-[18px]">Recent activities</p>
            <button className="text-secondary underline font-meduim text-[15px]">View all</button>
          </div>

          <div className="overflow-x-auto mt-4">
            <table className="min-w-full bg-white">
              <thead>
                <tr className="bg-gray-100">
                  <th className="text-left py-2 px-4 text-[14px] font-semibold text-gray-700 border-b">Name</th>
                  <th className="text-left py-2 px-4 text-[14px] font-semibold text-gray-700 border-b">Action</th>
                  <th className="text-left py-2 px-4 text-[14px] font-semibold text-gray-700 border-b">Time</th>
                </tr>
              </thead>
              <tbody>
                {analytics?.recent_activities?.slice(0, 6)?.map((activity) => (
                  <tr key={activity.id} className="border-b">
                    <td className="py-2 px-4 text-[13px] text-gray-900">{activity?.user?.first_name} {activity?.user?.last_name}</td>
                    <td className="py-2 px-4 text-[13px] text-gray-700">{activity?.action}</td>
                    <td className="py-2 px-4 text-[13px] text-gray-600">
                      {moment(activity.createdAt).format('MMM DD, YYYY HH:mm')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <div className="flex flex-col h-full flex-grow mt-4">
        <ProgressTrackerTable projects_progress={analytics.projects_progress || []} />
      </div>
    </Layout>
  );
};

export default Dashboard;

