import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { fetchCustomers, fetchCustomerOrders, createPayment } from '../../utils/api/api';
import toast from 'react-hot-toast';

const AddNewPayment = ({ onClose }) => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [amount, setAmount] = useState('');
    const [method, setMethod] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [transactionNumber, setTransactionNumber] = useState('');

    useEffect(() => {
        const loadCustomers = async () => {
            try {
                const response = await fetchCustomers();
                const formattedCustomers = response.customers.map(customer => ({
                    value: customer.id,
                    label: `${customer.first_name} ${customer.last_name}`,
                }));
                setCustomers(formattedCustomers);
            } catch (error) {
                toast.error('Failed to fetch customers.');
            }
        };

        loadCustomers();
    }, []);

    const handleCustomerSelect = async (selectedOption) => {
        setSelectedCustomer(selectedOption);
        try {
            const response = await fetchCustomerOrders(selectedOption.value);
            setOrders(response.orders);
        } catch (error) {
            toast.error('Failed to fetch customer orders.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const paymentData = {
            customer_id: selectedCustomer.value,
            amount: parseFloat(amount),
            method,
            transaction_id: transactionId,
            transaction_number: transactionNumber,
        };

        try {
            const response = await createPayment(paymentData);
            toast.success('Payment added');
            onClose();
        } catch (error) {
            toast.error(error.message || "Failed to create");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label className="block text-gray-700 mb-1">Select Customer</label>
                <Select
                    options={customers}
                    value={selectedCustomer}
                    onChange={handleCustomerSelect}
                    placeholder="Type to search for a customer"
                    className="w-full text-gray-600 font-light text-[14px] rounded-md outline-none focus:border-secondary"
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-1">Amount</label>
                <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Enter amount"
                    className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-1">Payment Method</label>
                <select
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                    className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary "
                    required
                >
                    <option value="">Select method</option>
                    <option value="MPESA">MPESA</option>
                    <option value="VISA">VISA</option>
                    <option value="CHEQUE">CHEQUE</option>
                    <option value="BANK_TRANSFER">BANK TRANSFER</option>
                    <option value="CASH">CASH</option>
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-1">Transaction ID</label>
                <input
                    type="text"
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    placeholder="Enter transaction ID"
                    className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-1">Transaction Number</label>
                <input
                    type="number"
                    value={transactionNumber}
                    onChange={(e) => setTransactionNumber(e.target.value)}
                    placeholder="Enter transaction number"
                    className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                    required
                />
            </div>

            <button
                type="submit"
                disabled={loading}
                className={`bg-secondary text-white font-medium text-[14px] py-2 rounded-md w-full hover:bg-secondary-dark transition ${loading ? 'bg-opacity-50 cursor-not-allowed' : ''}`}
            >
                {loading ? 'Processing...' : 'Add Payment'}
            </button>
        </form>
    );
};

export default AddNewPayment;
