import React, { useEffect, useState } from 'react'
import Layout from '../../elements/Layout'
import { fetchCustomerSalesReport } from '../../utils/api/api';
import toast from 'react-hot-toast';
import CustomerSales from './CustomerSales';
import CustomerPieChart from './CustomerPieChart';

const CustomerSalesReports = () => {
    const [sales, setSales] = useState([]);
    const [loading, setLoading] = useState(false);

    const getSales = async () => {
        setLoading(true)
        try {
            const response = await fetchCustomerSalesReport();
            setSales(response.customer_sales_reports);
        } catch (error) {
            toast.error("Failed to get report")
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getSales();
    }, [])

    const monthly_customer_Sales = [
        { "month": "2024-01", "customerSales": "1500000.00" },
        { "month": "2024-02", "customerSales": "2000000.00" },
        { "month": "2024-03", "customerSales": "2500000.00" },
        { "month": "2024-04", "customerSales": "3000000.00" },
        { "month": "2024-05", "customerSales": "3200000.00" },
        { "month": "2024-06", "customerSales": "2800000.00" },
        { "month": "2024-07", "customerSales": "3500000.00" },
        { "month": "2024-08", "customerSales": "3700000.00" },
        { "month": "2024-09", "customerSales": "3000000.00" },
    ];


    return (
        <Layout text="Customer report">
            <div className="flex w-full flex-col md:flex-row gap-4">
                <div className="w-full md:w-[65%]">
                    <CustomerSales monthlyCustomerSales={monthly_customer_Sales} />
                </div>
                <div className="w-full md:w-[35%]">
                    <CustomerPieChart />
                </div>
            </div>
            <div className="w-full bg-white rounded-lg mt-4">
                <p className="p-4 text-[17px] font-medium text-primary">Customer sales list</p>
                <div className="overflow-x-auto rounded-lg">
                    <table className="min-w-full bg-white h-full flex-grow border border-gray-200 table-auto">
                        <thead>
                            <tr className="text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap gap-2 flex">
                                    Customer Name
                                </th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Property</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Amount</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Paid date</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Status</th>
                                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Location</th>
                            </tr>
                        </thead>
                        <tbody className="text-primary text-[14px] font-light">
                            {!sales.customer_sales_list ? (
                                <tr>
                                    <td colSpan="7" className="py-4 px-6 text-center text-gray-500">
                                        {loading ? 'Loading, please wait' : 'No data found'}
                                    </td>
                                </tr>
                            ) : (
                                sales.customer_sales_list.map((list, index) => (
                                    <tr
                                        key={index}
                                        className={`text-[14px] font-light text-black border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                            }`}
                                    >
                                        <td className="py-4 px-6 text-left whitespace-nowrap">
                                            <div className="flex gap-2 items-center">
                                                {list.customer.first_name} {list.customer.last_name}
                                            </div>
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">
                                            <span>{list.property.title}</span>
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">
                                            <span>{list.amount}</span>
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">
                                            <span>{list.paid_date || 'N/A'}</span>
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">
                                            <span>{list.payment_status}</span>
                                        </td>
                                        <td className="py-4 px-6 text-left whitespace-nowrap">
                                            <span>{list.property.location}</span>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    )
}

export default CustomerSalesReports