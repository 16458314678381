import React from 'react'
import seal from '../../assets/MILESCOOP_SEAL.webp'

const CompletonCertificate = ({ contract }) => {
  return (
      <div className="w-full max-w-4xl mx-auto bg-white p-12 shadow-lg rounded-lg relative">
          <div className="absolute top-0 left-0 w-full h-48 overflow-hidden">
              <svg
                  className="absolute top-0 left-0 w-full h-full"
                  viewBox="0 0 1440 320"
                  xmlns="http://www.w3.org/2000/svg"
              >
                  <path
                      fill="#FFB400"
                      fillOpacity="1"
                      d="M0,64L1440,160L1440,320L0,320Z"
                  />
                  <path
                      fill="#FF3D00"
                      fillOpacity="1"
                      d="M0,128L1440,224L1440,320L0,320Z"
                  />
                  <path
                      fill="#002147"
                      fillOpacity="1"
                      d="M0,192L1440,256L1440,320L0,320Z"
                  />
              </svg>
          </div>

          <div className="relative z-10 mt-16 text-center">
              <h2 className="text-yellow-500 text-4xl font-bold tracking-wide">CERTIFICATE</h2>
              <p className="text-yellow-500 text-sm uppercase tracking-widest font-semibold">of completion</p>

              <p className="text-gray-700 text-lg mt-8">This certificate is presented to</p>
              <h1 className="text-5xl font-serif italic text-gray-900 mt-4">{contract?.customer?.first_name} {contract?.customer?.last_name}</h1>

              <p className="text-gray-600 mt-6 text-base max-w-3xl mx-auto leading-relaxed">
                  In recognition of successfully completing all payments for the purchase of the Milescoop Garden,
                  Plot No. {contract?.property?.title_number} Located in {contract?.property?.project?.location} Your commitment and dedication throughout the process have been
                  exemplary, and this achievement marks the official transfer of ownership. We congratulate you on this
                  significant milestone and wish you the best in your new property.
              </p>
          </div>

          <div className="w-full relative z-10 mt-12 flex justify-between items-center">
              <div className="flex items-center justify-center w-1/4">
                  <div className="rounded-full bg-yellow-500 p-4">
                      <div className="bg-white p-2 rounded-full">
                          <div className="rounded-full border-2 border-yellow-500 p-4">
                              <span className="block w-8 h-8 bg-yellow-500 rounded-full"></span>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="w-3/4 grid grid-cols-2 gap-4 text-center">
                  <div className="flex flex-col items-center">
                      <p className="font-bold text-gray-800">Peter Njagi</p>
                      <p className="text-gray-600">CEO</p>
                      <div className="w-24 h-0.5 bg-black mt-2"></div>
                  </div>
              </div>
              <img
                  src={seal}
                  className='h-auto w-[170px]'
                  alt=""
                  style={{ transform: 'rotate(-150deg)' }}
              />
          </div>
      </div>
  )
}

export default CompletonCertificate