import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../elements/Layout";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import toast from "react-hot-toast";
import { createEmployeeDetails } from "../../utils/api/api";
import Loading from "../../elements/Loading";
import { FaAngleRight } from 'react-icons/fa';

function AddNew() {
  const { id } = useParams();
  const user_id = id;
  const [formData, setFormData] = useState({
    full_name: "",
    dob: "",
    email: "",
    phone_number: "",
    nationality: "",
    gender: "male",
    job: "",
    department: "",
    employment_status: "full-time",
    hire_date: "",
    office_location: "",
    manager_id: "",
    bio: "",
    education: "",
    skills: "",
    achievements: "",
    photo: null,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleRichTextChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      ...formData,
      user_id,
    };

    try {
      await createEmployeeDetails(payload);
      toast.success('Info updated.');
      setFormData('');
      navigate("/dashboard/hr");
    } catch {
      toast.error("Failed to add employee");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout text="Add New">
      <div className="flex w-full items-center gap-2 border-b border-gray-300">
        <p className="text-black font-medium text-[20px]">Employees</p>
        <FaAngleRight />
        <p className="text-[12px] font-light text-gray-700">Add hire's info</p>
      </div>

      {loading && <Loading />}
      <form onSubmit={handleSubmit} className="mt-4 w-full">
        <div className="w-full flex flex-col md:flex-row gap-6 bg-white rounded-lg ">
          <div className="flex flex-col w-full gap-4 rounded-lg bg-white p-4">
            <div className="w-full gap-4 flex flex-col md:flex-row items-center">
              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Full Name</label>
                <input
                  type="text"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  placeholder="e.g. John Agesa"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                />
              </div>

              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Date of Birth</label>
                <input
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                />
              </div>
              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="e.g. email@example.com"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                />
              </div>
            </div>            

            <div className="flex items-center flex-col md:flex-row gap-4 w-full">
              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Phone Number</label>
                <input
                  type="text"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  placeholder="e.g. +254 712345678"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                />
              </div>

              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Nationality</label>
                <input
                  type="text"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  placeholder="e.g. Kenyan"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                />
              </div>
              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Gender</label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                >
                  <option value="">Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>            

            <div className="flex w-full items-center gap-4">
              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Job Title</label>
                <input
                  type="text"
                  name="job"
                  value={formData.job}
                  onChange={handleChange}
                  placeholder="e.g. Site Manager"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                />
              </div>

              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Department</label>
                <input
                  type="text"
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                  placeholder="e.g. Sales department"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                />
              </div>
            </div>

            <div className="flex items-center gap-4 flex-col md:flex-row">
              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Employment Status</label>
                <select
                  name="employment_status"
                  value={formData.employment_status}
                  onChange={handleChange}
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                >
                  <option value="full-time">Full-time</option>
                  <option value="part-time">Part-time</option>
                  <option value="contract">Contract</option>
                  <option value="casual">Casual</option>
                </select>
              </div>

              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Hire Date</label>
                <input
                  type="date"
                  name="hire_date"
                  value={formData.hire_date}
                  onChange={handleChange}
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                />
              </div>
              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Office Location</label>
                <input
                  type="text"
                  name="office_location"
                  value={formData.office_location}
                  onChange={handleChange}
                  placeholder="e.g. Westlands office"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-black text-[14px] font-medium mb-1">Manager ID</label>
              <input
                type="number"
                name="manager_id"
                value={formData.manager_id}
                onChange={handleChange}
                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                required
              />
            </div>
            
            <div className="flex w-full items-center gap-4 flex-col md:flex-row">
              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Skills</label>
                <textarea
                  name="skills"
                  value={formData.skills}
                  onChange={handleChange}
                  placeholder="Enter skill set"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>
              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Bio</label>
                <textarea
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                  placeholder="Enter employee bio"
                  className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                />
              </div>
            </div>

            <div className="flex items-center w-full gap-4 flex-col md:flex-row">
              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Education</label>
                <ReactQuill
                  theme="snow"
                  value={formData.education}
                  onChange={(value) => handleRichTextChange("education", value)}
                  className="mt-1 block w-full rounded-md border-yellow-200 shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                  placeholder="e.g. Kenyatta University"
                />
              </div>

              <div className="w-full">
                <label className="block text-black text-[14px] font-medium mb-1">Achievements</label>
                <ReactQuill
                  theme="snow"
                  value={formData.achievements}
                  onChange={(value) => handleRichTextChange("achievements", value)}
                  className="mt-1 block w-full rounded-md border-yellow-200 shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                  placeholder="e.g. Employee of the Year"
                />
              </div>
            </div>

            <div className="w-full">
              <label className="block text-black text-[14px] font-medium mb-1">Photo</label>
              <input
                type="file"
                name="photo"
                onChange={handleChange}
                accept="image/*"
                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
              />
            </div>
            <button 
              type="submit"
              disabled={loading}
              className="w-full py-1.5 px-4 bg-secondary text-white text-[14px] font-medium rounded-md mt-4"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Layout>
  );
}

export default AddNew;
