import React from 'react';
import Modal from '../../elements/Modal';

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    return (
        isOpen && (
            <Modal onClose={onClose} title="Confirm Deletion">
                <div className="p-4">
                    <p>Are you sure you want to delete this user? This action cannot be undone.</p>
                    <div className="flex justify-between mt-4">
                        <button
                            onClick={onClose}
                            className="bg-gray-300 text-black py-2 px-4 rounded"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onConfirm}
                            className="bg-red-600 text-white py-2 px-4 rounded"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>
        )
    );
};

export default ConfirmationModal;
