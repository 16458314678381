import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SetPassword = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post('https://api.milescoop.com/api/v1/auth/set-user-password', {
                password,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'api-key': '14CA4D6A5CE4D441CFBFB23CCB699',
                },
            });
            navigate('/accounts/sign-in')
            setError('');
        } catch (err) {
            setError('Failed to set password. Please try again.');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-lg rounded-md">
                <h2 className="text-center text-2xl font-semibold mb-4">Set Your Password</h2>
                {error && <p className="text-red-500 text-center">{error}</p>}
                {success && <p className="text-green-500 text-center">Password set successfully!</p>}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="password" className="block text-black text-sm font-medium mb-1">New Password</label>
                        <input
                            type="password"
                            id="password"
                            placeholder="Enter new password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="border w-full bg-transparent border-gray-300 text-gray-600 rounded-md p-2 focus:outline-none focus:ring focus:ring-secondary"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="confirmPassword" className="block text-black text-sm font-medium mb-1">Confirm Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="border w-full bg-transparent border-gray-300 text-gray-600 rounded-md p-2 focus:outline-none focus:ring focus:ring-secondary"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-secondary hover:bg-secondary-dark focus:outline-none"
                    >
                        Set Password
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SetPassword;
