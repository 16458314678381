import React, { useState } from 'react';
import ImageSliderModal from './ImageSliderModal';
import { IoExpand } from "react-icons/io5";

const ProjectImages = ({ images }) => {
    const [showSlider, setShowSlider] = useState(false);

    const placeholderImage = 'https://imgs.search.brave.com/sbGl8O1xuoIf4U_Ew3SnLKYBC2CVwH9Ph5e4JFRygns/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzA2LzU0Lzk0Lzcx/LzM2MF9GXzY1NDk0/NzE0MV9ycmRBZ1Vk/N3pjRGZjSTgyeVli/OUZaSkVOVTZqQkR2/Ri5qcGc';

    const handleOpenSlider = () => {
        setShowSlider(true);
    };

    return (
        <div className="w-full md:w-[70%] flex flex-col">
            {(!images || images.length === 0) ? (
                <div className="mt-4 flex flex-col items-center justify-center h-[350px] w-full bg-gray-200 rounded-lg">
                    <img
                        src={placeholderImage}
                        alt="Placeholder"
                        className="w-full h-[350px] object-cover rounded-lg"
                    />
                </div>
            ) : (
                <>
                    <div className="relative h-full mb-4">
                        <img
                            src={images[0].file}
                            alt="Project Image"
                            className="w-full h-[300px] object-cover rounded-lg cursor-pointer"
                            style={{ objectPosition: 'center' }}
                            onClick={handleOpenSlider}
                        />
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 cursor-pointer"
                            onClick={handleOpenSlider}
                        >
                            <IoExpand className="text-white" size={64} />
                        </div>
                    </div>

                    {/* Show additional images */}
                    <div className="w-full flex items-start justify-normal overflow-x-auto gap-2">
                        {images.slice(1).map((image, index) => (
                            <div key={index} className="w-fit h-auto">
                                <img
                                    src={image.file}
                                    alt={`Image ${index + 2}`}
                                    className="w-[200px] h-auto object-cover rounded-lg"
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}

            {/* Image slider modal */}
            {showSlider && images && images.length > 0 && (
                <ImageSliderModal
                    images={images}
                    onClose={() => setShowSlider(false)}
                />
            )}
        </div>
    );
};

export default ProjectImages;
