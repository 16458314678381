import React, { useState, useEffect } from 'react';
import Modal from '../../elements/Modal';
import toast from 'react-hot-toast';
import { updateLead, fetchUsers, getProjects } from '../../utils/api/api';

const EditLeadModal = ({ lead, isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        id: lead?.id || '',
        title: lead?.title || '',
        source: lead?.source || '',
        username: lead?.username || '',
        phone_number: lead?.phone_number || '',
        email: lead?.email || '',
        project_id: lead?.project_id || '',
        assigned_to: lead?.assigned_to || '',
        status: lead?.status || 'new',
        priority: lead?.priority || 'hot',
        last_activity_date: lead?.last_activity_date || '',
        next_follow_up: lead?.next_follow_up || ''
    });

    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getProjectsAndUsers = async () => {
            try {
                const projectResponse = await getProjects();
                const userResponse = await fetchUsers();
                setProjects(projectResponse.projects);
                setUsers(userResponse.users);
            } catch (error) {
                toast.error("Failed to load data");
            }
        };

        getProjectsAndUsers();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await updateLead(formData);
            toast.success("Lead updated");
            onClose();
        } catch (error) {
            toast.error(error.message || "Failed to update lead");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal onClose={onClose} title="Edit Lead" isOpen={isOpen}>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <div className="flex w-full gap-4 items-center">
                    <div className="flex flex-col w-full">
                        <label className="block text-black text-[14px] font-medium mb-1">Title</label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        />
                    </div>
                    <div className="flex flex-col w-full">
                        <label className="block text-black text-[14px] font-medium mb-1">Name</label>
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>
                </div>

                <div className="flex flex-col">
                    <label className="block text-black text-[14px] font-medium mb-1">Source</label>
                    <input
                        type="text"
                        name="source"
                        value={formData.source}
                        onChange={handleChange}
                        required
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>                

                <div className="flex gap-4 w-full items-center">
                    <div className="flex flex-col w-full">
                        <label className="block text-black text-[14px] font-medium mb-1">Phone Number</label>
                        <input
                            type="text"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            readOnly
                            className="cursor-not-allowed border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <label className="block text-black text-[14px] font-medium mb-1">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>
                </div>

                <div className="flex flex-col">
                    <label className="block text-black text-[14px] font-medium mb-1">Project</label>
                    <select
                        name="project_id"
                        value={formData.project_id}
                        onChange={handleChange}
                        required
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    >
                        <option value="">Select a Project</option>
                        {projects.map((project) => (
                            <option key={project.id} value={project.id}>
                                {project.title}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-col">
                    <label className="block text-black text-[14px] font-medium mb-1">Assigned To</label>
                    <select
                        name="assigned_to"
                        value={formData.assigned_to}
                        onChange={handleChange}
                        required
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    >
                        <option value="">Unassigned</option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.first_name} {user.last_name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex items-center gap-4 w-full">
                    <div className="flex flex-col w-full">
                        <label className="block text-black text-[14px] font-medium mb-1">Status</label>
                        <select
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            required
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        >
                            <option value="new">New</option>
                            <option value="contacted">Contacted</option>
                            <option value="converted">Converted</option>
                            <option value="qualified">Qualified</option>
                            <option value="disqualified">Disqualified</option>
                        </select>
                    </div>

                    <div className="flex flex-col w-full">
                        <label className="block text-black text-[14px] font-medium mb-1">Priority</label>
                        <select
                            name="priority"
                            value={formData.priority}
                            onChange={handleChange}
                            required
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        >
                            <option value="hot">Hot</option>
                            <option value="warm">Warm</option>
                            <option value="cold">Cold</option>
                        </select>
                    </div>
                </div>

                <div className="flex items-center w-full gap-4">
                    <div className="flex flex-col w-full">
                        <label className="block text-black text-[14px] font-medium mb-1">Last Activity Date</label>
                        <input
                            type="date"
                            name="last_activity_date"
                            value={formData.last_activity_date}
                            onChange={handleChange}
                            required
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <label className="block text-black text-[14px] font-medium mb-1">Next Follow Up</label>
                        <input
                            type="date"
                            name="next_follow_up"
                            value={formData.next_follow_up}
                            onChange={handleChange}
                            required
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>
                </div>

                <button
                    type="submit"
                    disabled={loading}
                    className={`bg-secondary text-[14px] font-medium text-white py-2 px-4 rounded-md hover:bg-primary-dark`}
                >
                    {loading ? "Processing...." : `Save Changes`}
                </button>
            </form>
        </Modal>
    );
};

export default EditLeadModal;
