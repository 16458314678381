import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import api from '../../utils/api/axiosInstance';
import toast from 'react-hot-toast';

ChartJS.register(ArcElement, Tooltip, Legend);

const CampaignCostsPieChart = () => {
    const [reports, setReports] = useState([]);

    // Function to fetch the report data
    const getReport = async () => {
        try {
            const response = await api.post('/reports/general/sales');
            setReports(response.data); // Set the fetched reports data
        } catch (error) {
            toast.error(error.message || "Request failed");
        }
    };

    useEffect(() => {
        getReport(); // Fetch report data when the component mounts
    }, []);

    // Prepare data for the pie chart
    const campaignBreakdown = reports.general_sales_reports?.campaignsBreakdown || [];

    const data = {
        labels: campaignBreakdown.map(campaign => campaign.source || 'Unknown'), // Extract campaign sources
        datasets: [
            {
                label: 'Campaign Costs',
                data: campaignBreakdown.map(campaign => parseInt(campaign.total, 10)), // Extract totals and convert to numbers
                backgroundColor: ['#34d399', '#60a5fa', '#fbbf24', '#a78bfa', '#fca5a5'], // Add more colors if needed
                hoverBackgroundColor: ['#059669', '#2563eb', '#d97706', '#6b21a8', '#991b1b'],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Adjust display options based on your needs
            },
            tooltip: {
                enabled: true,
            },
        },
    };

    return (
        <div className="w-full bg-white h-full gap-4 p-4 rounded-lg flex flex-col">
            <p className="text-xl font-semibold text-black">Campaign Breakdown</p>
            <div className="flex items-center justify-between gap-4 h-full w-full">
                <div className="w-1/2">
                    <Pie data={data} options={options} />
                </div>

                <div className="w-1/2 flex flex-col space-y-2">
                    {campaignBreakdown.map((campaign, index) => (
                        <div key={index} className="flex items-center space-x-2 bg-gray-50 rounded-md p-2 justify-between">
                            <div className="w-full flex items-center gap-2">
                                <span
                                    className="inline-block w-4 h-4 rounded-full"
                                    style={{ backgroundColor: ['#34d399', '#60a5fa', '#fbbf24', '#a78bfa', '#fca5a5'][index % 5] }} // Rotate colors
                                ></span>
                                <span className="text-gray-600 text-[14px]">{campaign.source || 'Unknown'}</span>
                            </div>
                            <span className="ml-auto text-[14px] text-gray-700 font-medium">
                                {campaign.total}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CampaignCostsPieChart;
