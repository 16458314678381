import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';

const Modal = ({ onClose, title, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            onClose();
        }, 300);
    };

    const handleOutsideClick = (e) => {
        if (e.target === e.currentTarget) {
            handleClose();
        }
    };

    return (
        <div
            className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleOutsideClick}
        >
            <div className={`bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[92vh] overflow-y-auto transform transition-transform duration-300 ${isVisible ? 'translate-y-0' : 'translate-y-full'}`}>
                <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-black">{title}</h3>
                    <button
                        className="text-gray-500 hover:text-gray-800"
                        onClick={handleClose}
                    >
                        <MdClose size={24} />
                    </button>
                </div>
                <div className="p-4">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
