import React from 'react'

const Footer = () => {
  return (
    <div className="w-full bg-primary hidden md:flex items-center justify-end">
      <div className='h-[5vh] w-full md:w-[82%] text-primary pl-[3%] md:pl-8 pr-[3%] flex items-center justify-between bg-secondary'>
        <p className="font-medium text-[12px]">&copy; 2024 Milescoop. All rights reserved.</p>
        <p className="hidden md:flex font-medium text-[12px]">Powered by Webmasters Kenya</p>
      </div>
    </div>
  )
}

export default Footer