import React, { useEffect, useState } from 'react'
import Layout from '../../elements/Layout'
import { FaAngleRight } from 'react-icons/fa'
import { addProperty, fetchUsers, getProjects } from '../../utils/api/api';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Loading from '../../elements/Loading'

const AddNewProperty = () => {
    const [formData, setFormData] = useState({
        project_id: "",
        property_number: "",
        title_number: "",
        assigned_to: "",
        size: "",
        status: ""
    });

    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchProjects = async () => {
        setLoading(true);
        try {
            const data = await getProjects();
            setProjects(data.projects);
            setLoading(false);
        } catch (err) {
            toast.error('Failed to fetch Projects.');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleManagerChange = (e) => {
        setFormData(prevData => ({ ...prevData, assigned_to: e.target.value }));
    };

    const handleProjectChange = (e) => {
        setFormData(prevData => ({ ...prevData, project_id: e.target.value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            await addProperty(formData);
            toast.success("Property added.");
            setLoading(false);
            navigate('/dashboard/properties');
        } catch (error) {
            toast.error('Request failed.');
            console.log(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const getUsers = async () => {
            try {
                const data = await fetchUsers();
                setUsers(data.users);
            } catch (err) {
                toast.error('Failed to fetch users.');
            }
        };

        getUsers();
    }, []);

    return (
        <Layout text="New property">
            {loading && <Loading />}
            <div className="flex w-full items-center gap-2 border-b border-gray-300">
                <p className="text-black font-medium text-[20px]">Properties</p>
                <FaAngleRight />
                <p className="text-[12px] font-light text-gray-700">Add new property</p>
            </div>
            <div className="w-full md:max-w-2xl mx-auto rounded-lg p-4 mt-4 bg-white flex flex-col gap-3">
                <p className="text-[22px] text-primary font-medium ">
                    Add new property
                </p>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <>
                        <div className='w-full flex flex-col'>
                            <label className='block text-black text-[14px] font-medium mb-1' htmlFor="manager">Select Assignee</label>
                            <select
                                className='border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary'
                                id="manager"
                                value={formData.assigned_to}
                                onChange={handleManagerChange}
                            >
                                <option value="">Select Assignee</option>
                                {users.map(user => (
                                    <option key={user.id} value={user.id}>
                                        {user.first_name} {user.last_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='w-full flex flex-col'>
                            <label className='block text-black text-[14px] font-medium mb-1' htmlFor="project_id">Select Project</label>
                            <select
                                className='border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary'
                                id="project_id"
                                value={formData.project_id}
                                onChange={handleProjectChange}
                            >
                                <option value="">Select Project</option>
                                {projects.map(project => (
                                    <option key={project.id} value={project.id}>
                                        {project.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='w-full'>
                            <label className="block text-black text-[14px] font-medium mb-1">Property Number</label>
                            <input
                                type="text"
                                name="property_number"
                                value={formData.property_number}
                                onChange={handleInputChange}
                                className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                                placeholder="Enter proprty number"
                            />
                        </div>
                        <div className='w-full'>
                            <label className="block text-black text-[14px] font-medium mb-1">Title Number</label>
                            <input
                                type="text"
                                name="title_number"
                                value={formData.title_number}
                                onChange={handleInputChange}
                                className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                                placeholder="Enter title number"
                            />
                        </div>
                        <div className='w-full'>
                            <label className="block text-black text-[14px] font-medium mb-1">Size</label>
                            <input
                                type="text"
                                name="size"
                                value={formData.size}
                                onChange={handleInputChange}
                                className="border w-full border-gray-200 text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                                placeholder="Enter size"
                            />
                        </div>
                        <div className='w-full'>
                            <label className="block text-black text-[14px] font-medium mb-1">Status</label>
                            <select
                                name="status"
                                value={formData.status}
                                onChange={handleInputChange}
                                className="border w-full border-gray-200 bg-transparent text-gray-600 font-light text-[14px] rounded-md p-2 outline-none focus:border-secondary"
                            >
                                <option value="">Select Status</option>
                                <option value="available">Available</option>
                                <option value="under_contract">Under Contract</option>
                                <option value="sold">Sold</option>
                            </select>
                        </div>
                        <div className="flex justify-end gap-2">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-secondary text-white text-[14px] font-medium px-6 py-1.5 rounded-md hover:bg-secondary-dark transition-all"
                            >
                                {loading ? 'Processing ...' : 'Submit'}
                            </button>
                        </div>
                    </>
                </form>
            </div>
        </Layout>
    )
}

export default AddNewProperty