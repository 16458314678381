import React, { useState, useEffect } from 'react';
import Modal from '../../elements/Modal';
import { fetchUsers, getProjects, updateProperty } from '../../utils/api/api';
import toast from 'react-hot-toast';

const PropertyModal = ({ property, onClose }) => {
    const [editedProperty, setEditedProperty] = useState({ ...property });
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await getProjects();
                setProjects(response.projects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        const getUsers = async () => {
            try {
                const response = await fetchUsers();
                setUsers(response.users);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchProjects();
        getUsers();
    }, []);

    const handleChange = (e) => {
        setEditedProperty({
            ...editedProperty,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const updatedPropertyData = {
            id: editedProperty.id,
            project_id: parseInt(editedProperty.project_id, 10), // Ensure this is a number
            property_number: editedProperty.property_number,
            title_number: editedProperty.title_number, // Could be a string or number depending on how it's stored
            assigned_to: editedProperty.assigned_to ? parseInt(editedProperty.assigned_to, 10) : null, // Ensure number or null
            size: editedProperty.size,
            status: editedProperty.status,
        };

        try {
            const response = await updateProperty(updatedPropertyData);
            toast.success("Property updated");
            onClose(); 
        } catch (error) {
            toast.error('Failed to update property');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal onClose={onClose} title="Edit Property">
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label>Title Number</label>
                    <input
                        type="text"
                        name="title_number"
                        value={editedProperty.title_number || ''}
                        onChange={handleChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                {/* Property Number */}
                <div className="mb-4">
                    <label>Property Number</label>
                    <input
                        type="text"
                        name="property_number"
                        value={editedProperty.property_number || ''}
                        onChange={handleChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                {/* Project ID */}
                <div className="mb-4">
                    <label>Project</label>
                    <select
                        name="project_id"
                        value={editedProperty.project_id || ''}
                        onChange={handleChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    >
                        <option value="">Select Project</option>
                        {projects.map((project) => (
                            <option key={project.id} value={project.id}>
                                {project.title}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label>Assigned To</label>
                    <select
                        name="assigned_to"
                        value={editedProperty.assigned_to || ''}
                        onChange={handleChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    >
                        <option value="">Select User</option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user?.first_name} {user?.last_name} - {user?.role?.title}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label>Size</label>
                    <input
                        type="text"
                        name="size"
                        value={editedProperty.size || ''}
                        onChange={handleChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    />
                </div>
                <div className="mb-4">
                    <label>Status</label>
                    <select
                        name="status"
                        value={editedProperty.status || ''}
                        onChange={handleChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                    >
                        <option value="available">Available</option>
                        <option value="sold">Sold</option>
                        <option value="under_contract">Under Contract</option>
                        <option value="booked">Booked</option>
                        <option value="reserved">Reserved</option>
                    </select>
                </div>

                <div className="flex justify-end gap-2">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-gray-300 px-4 py-2 rounded"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={loading}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        {loading ? 'Saving' : 'Save'}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default PropertyModal;
