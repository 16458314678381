import React, { useRef } from 'react';
import logo from '../../assets/miles_logo.svg';
import moment from 'moment';
import QRCode from 'react-qr-code';
import Barcode from 'react-barcode';
import seal from '../../assets/MILESCOOP_SEAL.webp'

const SalesAgreement = ({ contract }) => {

    return (
        <div className="p-8 bg-white max-w-4xl mx-auto border shadow-md">
            <div className="border-4 flex items-center justify-between p-8 border-primary mb-4">
                <div className="flex items-center gap-3">
                    <img src={logo} className='h-auto w-[150px]' alt="" />
                    <div className="flex flex-col">
                        <p className="uppercase text-primary font-semibold text-[20px]">milescoop ventures ltd</p>
                        <p className="text-gray-600 text-[15px] uppercase">p.o. box. 2760 - 00100 nairobi</p>
                        <p className="text-gray-600 text-[15px]">phone: +254 (0) 737 900 424</p>
                        <p className="text-gray-600 text-[15px]">Email: info@milescoop.com</p>
                        <p className="text-gray-600 text-[15px]">PIN NO: 052203487E</p>
                        <p className="text-gray-600 text-[15px]">Website: www.milescoop.com</p>
                    </div>
                </div>
                <div className="flex flex-col">
                    <p className="px-8 uppercase text-primary font-semibold text-[20px]">contract</p>
                    <div className='bg-primary h-[4px]' />
                    <div className="flex items-center justify-between w-full py-2">
                        <p className="">Date</p>
                        <p className="">{moment(new Date).format("MMM DD, YYYY")}</p>
                    </div>
                    <div className='bg-primary h-[3px]' />
                    <div className="flex items-center justify-between w-full py-2">
                        <p className=""></p>
                        <p className=""></p>
                    </div>
                    <div className='bg-primary h-[3px]' />
                    <div className="flex items-center justify-between w-full py-2">
                        <p className="">Division</p>
                        <p className="">HQ - MVL</p>
                    </div>
                    <div className='bg-primary h-[3px]' />
                </div>
            </div>

            <div className="my-8">
                <h2 className="text-xl font-semibold text-center">AGREEMENT FOR SALE</h2>
                <p className="font-semibold text-[20px] text-black text-center mt-10">Dated 2024-05-29</p>
            </div>

            <p className="uppercase text-primary text-[15px] text-center my-10">between</p>
            <div className="flex items-center justify-center text-center mb-20 flex-col">
                <p className="uppercase font-bold text-[24px] text-black">milescoop venture limited</p>
                <p className="text-15px] uppercase font-medium">(the "vendor")</p>
            </div>
            <p className="uppercase text-primary text-[15px] text-center my-10">and</p>
            <div className="flex items-center justify-center text-center mb-20 flex-col">
                <p className="ppercase font-bold text-[24px] text-black uppercase">{contract?.customer?.first_name} {contract?.customer?.last_name}</p>
                <p className="text-15px] uppercase font-medium">(the "purchaser")</p>
            </div>
            <p className="text-center uppercase mb-2 ">In respect of </p>
            <p className="text-center uppercase mb-12 font-bold text-[22px]">plot- {contract?.property?.title_number}</p>
            <div className="flex w-full items-center justify-center gap-12 mb-10">
                <Barcode
                    value={contract?.serial_no}
                    width={2}
                    height={100}
                    displayValue={true}
                    background="#ffffff"
                    lineColor="#000000"
                    margin={10}
                    fontOptions="monospace"
                    textAlign="center"
                />

                <div className="-mt-6">
                    <QRCode
                        value={contract?.serial_no}
                        size={100}
                        bgColor="#ffffff"
                        fgColor="#000000"
                    />
                </div>
            </div>

            <div className="">
                <h1 className="text-2xl font-bold mb-4 text-center">
                    AGREEMENT FOR SALE
                </h1>
                <p className="mb-6 text-gray-700 text-[14px]">
                    <span className="font-semibold text-black mr-2 text-[20px] ">
                        THIS AGREEMENT FOR SALE
                    </span>
                    is made the ........... day of ............... Two Thousand and ...............
                </p>

                <h2 className="text-lg font-semibold mb-2">BETWEEN:</h2>
                <p className="mb-4 ml-[6%] text-gray-700 text-[14px]">
                    <span className="font-medium text-black mr-2 text-[18px]">
                        1. MILESCOOP VENTURES LIMITED
                    </span>
                    of Post Office Box Number in the Republic of (hereinafter referred to as the “Vendor” which expression shall where the context so admits include his personal representatives, and permitted assigns) of the first part; AND
                </p>
                <p className="mb-4 ml-[6%] text-gray-700 text-[14px]">
                    2. <span className="font-medium text-black mr-2 text-[18px] uppercase">{contract?.customer?.first_name} {contract?.customer?.last_name}</span> of National Identification Number <span className="font-semibold underline text-black">{contract?.customer?.id_number}</span> and of Post Office Box Number None None in the Republic of None (hereinafter referred to as the “Purchaser” which expression shall where the context so admits include his personal representatives, and permitted assigns) of the second part.
                </p>

                <h2 className="text-lg font-semibold mb-2">WHEREAS:</h2>
                <p className="mb-4 ml-[6%] text-gray-700 text-[14px]">
                    1. The Vendor is registered as the absolute proprietor of ALL THAT parcel of land known as <span className="uppercase mx-2 text-black font-medium">{contract?.property?.project?.title}</span> together with all the improvements containing by measurement {contract.property.size} or thereabouts (hereinafter referred to as the “Land”).
                </p>
                <p className="mb-4 ml-[6%] text-gray-700 text-[14px]">
                    2. The Vendor has caused the Land to be subdivided into plots as more particularly delineated on the sub-division plan annexed hereto (the “Sub-division Plan”).
                </p>
                <p className="mb-4 ml-[6%] text-gray-700 text-[14px]">
                    3. The Vendor has agreed to sell and the Purchaser has agreed to purchase the subdivided piece of land identified as PLOT-{contract.property.title_number} on the Sub-Division Plan measuring approximately {contract.property.size} or thereabout (hereinafter called the “Property”) upon the terms and conditions hereinafter appearing.
                </p>

                <h2 className="text-lg font-semibold mb-2">NOW THIS AGREEMENT WITNESSETH as follows:</h2>

                <div className="ml-[6%]">
                    <h3 className="text-lg font-semibold mb-2">1. THE PURCHASE PRICE</h3>
                    <p className="mb-4 text-gray-700 text-[14px]">
                        The Vendor shall sell and the Purchaser shall purchase the Property at the agreed the sum of KES {contract?.order?.amount} only. (the “Purchase Price”) {contract?.order?.payment_type === 'installments' && 'as follows:'}
                    </p>
                    {contract?.order?.payment_type === 'installments' && (
                        <div className="ml-6 mb-4 text-gray-700 text-[14px]">
                            <p>1.1. The initial deposit of KES {contract?.order?.amount} only.</p>
                            <p>1.2. The Balance of the Purchase price being KES</p>
                        </div>
                    )}

                    <h3 className="text-lg font-semibold mb-2">2. SALE SUBJECT TO LAW SOCIETY CONDITIONS OF SALE</h3>
                    <p className="mb-4 text-gray-700 text-[14px]">
                        The sale is subject to the Law Society Conditions of Sale (2015 Edition) and all other applicable laws in so far as they are not inconsistent with the conditions contained in this Agreement.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">3. COMPLETION DATE</h3>
                    <p className="mb-4 text-gray-700 text-[14px]">
                        The date of completion shall be Ninety (90) days from the date of the subdivision of the property or such an earlier or later date as the Parties may agree in writing (the “Completion Date”).
                    </p>

                    <h3 className="text-lg font-semibold mb-2">4. INTEREST IN THE PROPERTY</h3>
                    <p className="mb-4">The interest in the property is freehold.</p>

                    <h3 className="text-lg font-semibold mb-2">5. ADVOCATES FOR THE PARTIES</h3>
                    <p className="mb-4 text-gray-700 text-[14px]">
                        The Advocates for the Vendor shall be None (hereinafter referred to as “the Vendor’s Advocates). The Purchaser shall elect and notify the Vendors advocates of his Advocates particulars.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">6. POSSESSION</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        6.1. The Property is sold in vacant possession.<br />
                        6.2. Possession of the Property shall not be entitled to possession until the Vendor receives full payment of the purchase price.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">7. NO ENCUMBRANCES</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        The property is sold subject to:<br />
                        7.1. The covenants, terms, conditions, stipulations, restrictions and rights as provided and reserved in the Certificate of Lease;<br />
                        7.2. All subsisting easements, quasi-easements and rights of way (if any); and<br />
                        7.3. Any provisional liability or assessment for road or sewerage charges BUT otherwise free from encumbrances.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">8. COMPLETION</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        8.1. Completion of the sale and purchase shall take place at the offices of the Vendor’s Advocates or at such place as the Parties may agree.<br />
                        8.2. Upon payment of the Balance of the Purchase Price and provided the Purchaser has made payments of the fees and costs as per the schedule herein below, the Vendor’s Advocates shall undertake registration of the Property in favour of the Purchaser and deliver the following documents to the Purchaser’s Advocates:<br />
                        8.2.1. The Original Title Deed in respect of the Property;<br />
                        8.2.2. One Counterpart of the duly registered Transfer Instruments in respect of the Property;<br />
                        8.2.3. A certified copy of consent to transfer the property from the relevant authorities together with the Application therein;<br />
                        8.2.4. Copies of the Certificate of Incorporation and PIN Certificate of the Vendor;<br />
                        8.2.5. All such other documents relating to the Property in the Vendors possession that the Purchasers or his Advocates may reasonably request from the Vendors for purposes of giving effect to this Agreement.<br />
                        The documents listed above are hereinafter referred to as the “Completion Documents”.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">9. TIME OF THE ESSENCE</h3>
                    <p className="mb-4 text-gray-700 text-[14px]">
                        Time shall be deemed to be of the essence for all the purposes of this Agreement.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">10. PROPERTY CONDITION</h3>
                    <p className="mb-4 text-gray-700 text-[14px]">
                        The Purchaser having been afforded the opportunity of viewing the property prior to signing of this Agreement, and having viewed and inspected the property purchases the property with full knowledge of its actual state and condition and shall take the Property as it stands.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">11. BREACH OF TERMS</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        11.1. It is expressly agreed by the Purchaser that in the event that the Purchaser is unable to pay the Purchase Price as required and in accordance with the terms of this Agreement that upon the expiry of a one hundred Twenty (120) day Completion Notice sent to the Purchaser, the Purchaser shall forfeit twenty percent (20%) of the Purchase Price in favour of the Vendor as liquidated damages plus legal costs and shall have no interest or claim in the Property whatsoever and the Vendor shall be entitled to re-sell the Property.<br />
                        11.2. In the event that the Purchaser forfeits the said twenty percent (20%) of the Purchase Price to the Vendor, the Purchaser shall be entitled to receive a refund of any monies paid to the Vendor as a deposit less the said twenty percent (20%) so forfeited and inclusive of all Costs, Fees and expenses incurred in pursuance of this Agreement.<br />
                        11.3. The Purchaser shall receive the refund (if any) once the Property in question has been re-sold and the sale completed and the Purchaser shall not be entitled to receive or claim any interest whatsoever on any monies refunded to the Purchaser.<br />
                        11.4. If the Vendor is unable to complete the transaction only by reason of force majeure the Vendor will refund the Amount Paid or the Deposit (as the case may be) to the Purchaser.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">12. PERMITTED USER AND BEACONS</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        The Vendor has informed the Purchaser of the permitted user of the Property and the Purchaser is fully satisfied in that regard. The Vendor shall be required to point out the beacons to the Purchaser or replace any that shall be missing at the Vendor’s own cost.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">13. COSTS, DUTIES AND FEES</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        The Purchaser shall bear and pay on demand the Costs including all Stamp Duty, VAT, Registration Fees, legal fees and other Disbursements of and incidental to the preparation, execution and performance of this Agreement notwithstanding that the Purchaser may not complete this Agreement. The legal fees plus V.A.T are payable upon execution of the Sale Agreement.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">14. NOTICE</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        Any notice to be given to any party to this Agreement shall be in writing and shall be deemed to be duly served upon hand delivery to the physical address of the firm of Advocates acting for and on behalf of the party to be served or upon posting by registered post to the address provided herein by a party where such party lacks an Advocate.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">15. WARRANTY</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        The Vendors warrant to the Purchasers that:<br />
                        15.1. the Property is not on a buffer zone, road reserve or public land and its ownership thereof is not subject to any challenge whatsoever from the Government of Kenya, any county government or any third party whatsoever.<br />
                        15.2. there is no law or decree or similar enactment binding on him which would conflict with or prevent him from entering into or performing and observing the terms of this Agreement;<br />
                        15.3. the Vendor is not engaged in nor threatened by any litigation, arbitration or administrative proceedings relating to these Properties;<br />
                        15.4. there is no adverse claim on the Property, dispute regarding ownership, boundary, easement, rights of way or any other such matters;<br />
                        15.5. the Vendor has not given any rights of way, easement or any overriding interest and has no intention of so doing;<br />
                        15.6. the Vendor has to the best of his knowledge disclosed to the Purchaser all material information relating to the Property;<br />
                        15.7. the Vendor has not received any notice from National government or county government or from owners of adjoining property which remain to be complied with and has disclosed all such notices received to the Purchaser;<br />
                        15.8. the Vendors will immediately disclose in writing to the Purchaser any event or circumstance which may arise or become known to him after the date of this Agreement and prior to Completion which is inconsistent with any of the warranties or which had it occurred on or before the date of this Agreement would have constituted a breach of the warranties or which is material to be known by a Purchaser for value of the Property.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">16. SURVIVAL</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        Save with regard to matters which require to be fulfilled and are in fact fulfilled prior to or at the Completion Date, this Agreement shall continue to be in full force and effect.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">17. WAIVER</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        No failure or delay by the Vendor or the Purchaser in exercising any claim, remedy, right, power or privilege under this Agreement shall operate as a waiver nor shall any single or partial exercise of any claim, remedy, right, power or privilege preclude any further exercise thereof or the exercise of any other claim, right or power.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">18. MONEY LAUNDERING</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        The Purchaser confirms that the monies utilized to pay for the Property and any other sums due from the Purchaser under this Agreement are not and shall not be from the proceeds of crime. The Parties herein warrant to the Vendor or the Vendor’s Advocates acting on this transaction herein is at arm’s length and does not in any way contravene the proceeds of crime and Anti-money Laundering Act, 2009 or any other laws in Kenya and hold the said advocates fully indemnified against any claim or action arising pursuant to the same.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">19. INDEMNITY</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        The Advocates responsibility shall be limited to the strict extent of drawing this agreement for sale and facilitating registration of the transfer in favour of the Purchaser. Save for the foregoing, no further responsibility shall accrue on the Advocates notwithstanding any other provision express or implied made herein. Consequently, the parties warrant to hold the Advocates harmless from any claims whatsoever made by the parties or 3rd parties whomsoever and the Vendor and Purchaser shall indemnify and make good any harm or loss suffered by the Vendor’s in or to such regard.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">20. REMEDIES CUMULATIVE</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        Any remedy or right conferred upon the Vendors or the Purchasers for breach of this agreement including the right of rescission shall be in addition to and without prejudice to all other rights and remedies available to them.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">21. ARBITRATION</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        21.1. Should any dispute arise between the parties hereto with regard to the interpretation, rights, obligations and/or implementation of any one or more of the provisions of this Agreement, the parties shall in the first instance attempt to resolve such dispute by amicable negotiation.<br />
                        21.2. Should such negotiations fail to achieve a resolution within Fifteen (15) days, either party may declare a dispute by written notification to the other, whereupon such dispute shall be referred to arbitration under the provisions of the Arbitration Act, 1995 Laws of Kenya (as amended from time to time);
                    </p>

                    <h3 className="text-lg font-semibold mb-2">22. ENTIRE AGREEMENT</h3>
                    <p className="ml-6 mb-4 text-gray-700 text-[14px]">
                        This Agreement constitutes the whole Agreement between the parties hereto and no variations thereof shall be effective unless made in writing and signed by all the parties. This Agreement supersedes and replaces any Agreement whatsoever that may have subsisted between the parties hereto in any way relating to the subject matter hereof.
                    </p>

                    <h3 className="text-lg font-semibold mb-2">IN WITNESS WHEREOF</h3>
                    <p className="mb-4 text-gray-700 text-[14px]">
                        The Vendor and the Purchaser hereto have executed this Agreement the day and year first hereinbefore written.
                    </p>
                </div>
                <div className="mt-6 border-t border-gray-300">
                    <div className="mt-6 space-y-6">
                        <div>
                            <h4 className="font-bold text-lg text-gray-900">Signed by the VENDOR</h4>
                            <p className="text-sm mt-2 text-gray-700">MILESCOOP VENTURES LIMITED</p>
                            <div className="mt-2 space-y-1">
                                <p className="text-sm text-gray-700">in the presence of: <span className="border-b border-dotted border-gray-400">____________________</span></p>
                                <p className="text-sm text-gray-700">National ID: <span className="border-b border-dotted border-gray-400">____________________</span></p>
                                <p className="text-sm text-gray-700">Advocate: <span className="border-b border-dotted border-gray-400">____________________</span></p>
                            </div>

                            <div className="mt-6">
                                <p className="font-semibold text-gray-900">Certificate of Attestation</p>
                                <p className="text-sm text-gray-700 mt-1">
                                    I certify that I was present and saw <span className="border-b border-dotted border-gray-400">________________________________</span> sign this Agreement.
                                </p>
                                <div className="mt-4">
                                    <p className="text-sm text-gray-700 border-b border-dotted border-gray-400">______________________________________</p>
                                    <p className="text-sm text-gray-700">Signature & designation of person certifying</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-10">
                            <h4 className="font-bold text-lg text-gray-900">Signed by the Purchaser</h4>
                            <div className="mt-2">
                                <canvas className="border border-gray-400 rounded-md w-full h-24"></canvas>
                                <p className="text-xs text-gray-500 mt-1">Purchaser's e-signature</p>
                            </div>
                            <div className="mt-4 space-y-1">
                                <p className="text-sm text-gray-700">in the presence of: <span className="border-b border-dotted border-gray-400">____________________</span></p>
                                <p className="text-sm text-gray-700">National ID: <span className="border-b border-dotted border-gray-400">____________________</span></p>
                                <p className="text-sm text-gray-700">Advocate: <span className="border-b border-dotted border-gray-400">____________________</span></p>
                            </div>

                            <div className="mt-6">
                                <p className="font-semibold text-gray-900">Certificate of Attestation</p>
                                <p className="text-sm text-gray-700 mt-1">
                                    I certify that I was present and saw <span className="border-b border-dotted border-gray-400">________________________________</span> sign this Agreement.
                                </p>
                                <div className="mt-4">
                                    <p className="text-sm text-gray-700 border-b border-dotted border-gray-400">______________________________________</p>
                                    <p className="text-sm text-gray-700">Signature & designation of person certifying</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-end">
                    <img
                        src={seal}
                        className='h-auto w-[170px]'
                        alt=""
                        style={{ transform: 'rotate(-150deg)' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SalesAgreement;
