import React, { useState, useEffect, useRef, useContext } from 'react';
import Layout from '../elements/Layout';
import { MdOutlineAttachFile } from 'react-icons/md';
import { AiTwotoneAudio } from 'react-icons/ai';
import { io } from 'socket.io-client';
import { AuthContext } from '../utils/context/AuthContext';

const Messaging = () => {
    const [chats, setChats] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const { user } = useContext(AuthContext);
    const admin_id = user?.id;
    const socketRef = useRef(null);

    useEffect(() => {
        socketRef.current = io('https://api.milescoop.com');

        socketRef.current.on('connect', () => {
            console.log('Socket connected.');

            const registerPayload = {
                request_type: 'register',
                sender_type: 'admin',
                sender_id: admin_id,
            };
            socketRef.current.emit('message', registerPayload);

            const chatsPayload = {
                request_type: 'get_chats',
                sender_type: 'admin',
                sender_id: admin_id,
            };
            socketRef.current.emit('message', chatsPayload);
        });

        socketRef.current.on('message', (data) => {
            try {
                if (typeof data === 'string' && (data.startsWith('{') || data.startsWith('['))) {
                    data = JSON.parse(data);
                    setChatList(data.chats);
                    console.log(data.chats)
                }
            } catch (error) {
                console.error('Error parsing message:', error);
                return;
            }

            if (data.request_type === 'send_text' && selectedChat && data.sender_type && data.message) {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        sender: data.sender_type,
                        text: data.message,
                        time: new Date().toLocaleTimeString(),
                    },
                ]);
            }

            if (data.request_type === 'get_history') {

                const mappedMessages = data.chats.map(chat => ({
                    sender: chat.sender_type,
                    text: chat.message,
                    time: new Date(chat.createdAt).toLocaleTimeString(),
                }));
                setMessages(mappedMessages);
            }
        });

        socketRef.current.on('disconnect', () => {
            console.log('Socket disconnected.');
        });

        return () => {
            socketRef.current.disconnect();
        };
    }, [admin_id]);

    const handleChatSelection = (chat) => {
        setSelectedChat(chat);

        const historyPayload = {
            request_type: 'get_history',
            sender_type: 'admin',
            sender_id: admin_id.toString(),
            user_id: '1',
            receiver_id: chat.customer_id,
        };

        socketRef.current.emit('message', historyPayload);
        console.log(historyPayload)
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (!selectedChat || !newMessage.trim()) return;

        const messagePayload = {
            request_type: 'send_text',
            sender_type: 'admin',
            sender_id: admin_id,
            receiver_id: `${selectedChat.customer_id}`,
            message: newMessage,
        };

        socketRef.current.emit('message', messagePayload);

        setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'admin', text: newMessage, time: new Date().toLocaleTimeString() },
        ]);
        setNewMessage('');
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedChat) {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(selectedFile);
            fileReader.onload = (e) => {
                const arrayBuffer = e.target.result;
                const filePayload = {
                    request_type: 'send_file',
                    sender_type: 'admin',
                    sender_id: admin_id,
                    receiver_id: selectedChat.customer_id,
                    file_type: selectedFile.type,
                    file_name: selectedFile.name,
                    file_data: arrayBuffer,
                };

                socketRef.current.emit('message', filePayload);

                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        sender: 'admin',
                        text: `Sent a file: ${selectedFile.name}`,
                        time: new Date().toLocaleTimeString(),
                    },
                ]);
            };
        }
    };

    const handleRecordAudio = (audioData) => {
        if (!selectedChat) return;
        const audioPayload = {
            request_type: 'send_audio',
            sender_type: 'admin',
            sender_id: admin_id,
            receiver_id: selectedChat.customer_id,
            file_type: 'audio/mp3',
            file_name: 'AudioRecording.mp3',
            file_data: audioData,
        };

        socketRef.current.emit('message', audioPayload);

        setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'admin', text: 'Sent an audio message', time: new Date().toLocaleTimeString() },
        ]);
    };

    return (
        <Layout text="Admin Messaging">
            <div className="flex h-full">
                <div className="w-1/3 border-r overflow-y-auto">
                    <h3 className="text-lg font-semibold p-4">Chats</h3>
                    {chatList.length > 0 ? (
                        <ul className="space-y-2">
                            {chatList.map((chat, index) => (
                                <li
                                    key={chat.id}
                                    className={`p-4 cursor-pointer ${selectedChat && selectedChat.id === chat.id ? 'bg-blue-500 text-white' : 'bg-gray-100'}`}
                                    onClick={() => handleChatSelection(chat)}
                                >
                                    <div className="flex items-center space-x-3">
                                        <div>
                                            <div className="text-lg font-bold">{chat?.customer_id}</div>
                                            <div className="text-sm text-gray-600">{chat?.last_message || 'No messages yet'}</div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="p-4 text-gray-500">No chats available</p>
                    )}
                </div>

                <div className="flex-grow p-4 flex flex-col">
                    {selectedChat ? (
                        <>
                            <div className="flex-grow overflow-y-auto">
                                {messages.length > 0 ? (
                                    messages.map((message, index) => (
                                        <div
                                            key={index}
                                            className={`flex ${message.sender === 'admin' ? 'justify-end' : 'justify-start'}`}
                                        >
                                            <div className={`max-w-xs px-3 py-1 rounded-lg text-sm flex flex-col ${message.sender === 'admin'
                                                ? 'bg-blue-500 text-white'
                                                : 'bg-gray-300 text-gray-900'
                                                }`}
                                            >
                                                {message.file ? (
                                                    message.file_type && message.file_type.startsWith('image/') ? (
                                                        <img src={URL.createObjectURL(new Blob([message.file]))} alt="uploaded" className="mb-2 rounded-lg" />
                                                    ) : (
                                                        <a
                                                            href={URL.createObjectURL(new Blob([message.file]))}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-300 underline mb-2"
                                                        >
                                                            Download File
                                                        </a>
                                                    )
                                                ) : message.text ? (
                                                    <p>{message.text}</p>
                                                ) : null}
                                                <span className="text-xs text-end text-gray-200">{message.time}</span>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No messages to show</p>
                                )}
                            </div>

                            <form onSubmit={handleSendMessage} className="p-3 bg-white flex items-center border-t space-x-3">
                                <input
                                    type="text"
                                    className="flex-grow border border-gray-300 rounded-lg px-3 py-2 outline-none focus:border-blue-500"
                                    placeholder="Type your message..."
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                />
                                <label className="cursor-pointer bg-gray-200 rounded-lg px-3 py-2">
                                    <input
                                        type="file"
                                        className="hidden"
                                        onChange={handleFileChange}
                                    />
                                    <MdOutlineAttachFile />
                                </label>
                                <button
                                    type="button"
                                    onClick={() => handleRecordAudio('your_audio_data_here')}
                                    className="bg-gray-200 rounded-lg px-3 py-2"
                                >
                                    <AiTwotoneAudio />
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                                >
                                    Send
                                </button>
                            </form>
                        </>
                    ) : (
                        <p className="text-gray-500">Select a chat to view messages</p>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default Messaging;
