import React from 'react';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import logo from '../assets/miles_logo.svg';

const Unauthorized = () => {
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate('/dashboard');
    };

    return (
        <div className="flex flex-col justify-between items-center h-screen bg-gray-100 p-6">
            {/* Header with Logo */}
            <div className="w-full flex justify-center mb-10">
                <img src={logo} alt="Company Logo" className="w-40" />
            </div>

            {/* Main content */}
            <div className="text-center flex flex-col items-center flex-grow">
                <LockIcon className="text-red-500" style={{ fontSize: '100px' }} />
                <h1 className="text-4xl font-bold text-gray-800 mt-4 mb-6">
                    Oops! Access Denied
                </h1>
                <p className="text-lg text-gray-600 mb-8 max-w-md">
                    You don’t have the required permissions to view this page. Please contact your administrator if you believe this is an error.
                </p>
                <button
                    onClick={handleBackToHome}
                    className="bg-secondary text-white py-3 px-6 rounded-lg text-lg transition duration-300"
                >
                    Go Back to Dashboard
                </button>
            </div>

            {/* Footer */}
            <div className="mt-10">
                <p className="text-sm text-gray-500">
                    © 2024 Milescoop ltd. All rights reserved.
                </p>
            </div>
        </div>
    );
};

export default Unauthorized;
