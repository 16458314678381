import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SalesRevenue = ({ salesReports, text }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    const isDataAvailable = salesReports && Object.keys(salesReports).length > 0;

    const generateYearlyLabels = () => [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const generateMonthlyLabels = () => {
        const daysInMonth = 31; 
        return Array.from({ length: daysInMonth }, (_, i) => i + 1);
    };

    const generateWeeklyLabels = () => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const generateDailyLabels = () => {
        const hoursInDay = 24;
        return Array.from({ length: hoursInDay }, (_, i) => `${i}:00`);
    };

    const getSalesData = (labels) => {
        if (!isDataAvailable) return new Array(labels.length).fill(0);

        let salesData = [];

        switch (activeTab) {
            case 0:
                salesData = salesReports.dailySales.reduce((acc, sale) => {
                    const hour = new Date(sale.date).getHours();
                    acc[hour] = sale.totalSales;
                    return acc;
                }, new Array(24).fill(0));
                break;
            case 1:
                salesData = salesReports.weeklySales.reduce((acc, sale) => {
                    acc[sale.dayOfWeek - 1] = sale.totalSales;
                    return acc;
                }, new Array(7).fill(0));
                break;
            case 2: 
                salesData = salesReports.monthlySales.reduce((acc, sale) => {
                    const day = new Date(sale.date).getDate();
                    acc[day - 1] = sale.totalSales;
                    return acc;
                }, new Array(31).fill(0));
                break;
            case 3:
                salesData = salesReports.yearlySales.reduce((acc, sale) => {
                    const month = new Date(sale.date).getMonth();
                    acc[month] = sale.totalSales;
                    return acc;
                }, new Array(12).fill(0));
                break;
            default:
                return [];
        }

        return salesData;
    };

    const labels = () => {
        switch (activeTab) {
            case 0: 
                return generateDailyLabels();
            case 1: 
                return generateWeeklyLabels();
            case 2: 
                return generateMonthlyLabels();
            case 3: 
                return generateYearlyLabels();
            default:
                return [];
        }
    };

    const data = {
        labels: labels(),
        datasets: [
            {
                label: 'Sales',
                data: getSalesData(labels()),
                fill: true,
                backgroundColor: 'rgba(248, 168, 27, 0.2)', 
                borderColor: '#F8A81B', 
                pointBackgroundColor: '#F8A81B', 
                pointBorderColor: '#F8A81B',
                pointRadius: 3, 
                tension: 0.4, 
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
        elements: {
            line: {
                borderWidth: 2, 
            },
            point: {
                radius: 3, 
            },
        },
        plugins: {
            legend: {
                display: false, 
            },
        },
    };

    return (
        <div className="w-full h-[450px] pb-16 pt-4 px-4 bg-white rounded-lg">           

            <div className="flex w-full items-center justify-between">
                <p className="text-[#05004E] font-medium text-[18px] mb-2">{text}</p>
                <div className="flex">
                    <button
                        className={`px-4 py-1.5 ${activeTab === 0 ? 'bg-secondary text-white rounded-md text-[14px] font-medium' : 'border-none'}`}
                        onClick={() => handleTabChange(0)}
                    >
                        Day
                    </button>
                    <button
                        className={`px-4 py-1.5 ${activeTab === 1 ? 'bg-secondary text-white rounded-md text-[14px] font-medium' : 'border-none'}`}
                        onClick={() => handleTabChange(1)}
                    >
                        Week
                    </button>
                    <button
                        className={`px-4 py-1.5 ${activeTab === 2 ? 'bg-secondary text-white rounded-md text-[14px] font-medium' : 'border-none'}`}
                        onClick={() => handleTabChange(2)}
                    >
                        Month
                    </button>
                    <button
                        className={`px-4 py-1.5 ${activeTab === 3 ? 'bg-secondary text-white rounded-md text-[14px] font-medium' : 'border-none'}`}
                        onClick={() => handleTabChange(3)}
                    >
                        Year
                    </button>
                </div>
            </div>

            {!isDataAvailable ? (
                <div className="flex justify-center items-center h-full">
                    <p className="text-gray-500">No data available</p>
                </div>
            ) : (
                <Line data={data} options={options} />
            )}
        </div>
    );
};

export default SalesRevenue;
