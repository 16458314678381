export const categorizedPermissions = [
  {
    category: 'Users',
    permissions: [
      { label: 'Create Users', key: 'create_users' },
      { label: 'List Users', key: 'list_users' },
      { label: 'View Users', key: 'view_users' },
      { label: 'Edit Users', key: 'edit_users' },
      { label: 'Delete Users', key: 'delete_users' },
    ],
  },
  {
    category: 'User Roles',
    permissions: [
      { label: 'Create User Roles', key: 'create_user_roles' },
      { label: 'List User Roles', key: 'list_user_roles' },
      { label: 'View User Roles', key: 'view_user_roles' },
      { label: 'Edit User Roles', key: 'edit_user_roles' },
      { label: 'Delete User Roles', key: 'delete_user_roles' },
    ],
  },
  {
    category: 'Dashboard',
    permissions: [
      { label: 'View Dashboard Analytics', key: 'view_dashboard_analytics' },
      { label: 'View Reports', key: 'view_reports' },
    ],
  },

  {
    category: 'Customers',
    permissions: [
      { label: 'Create', key: 'create_customer' },
      { label: 'List', key: 'list_customer' },
      { label: 'View', key: 'view_customer' },
      { label: 'Edit', key: 'edit_customer' },
      { label: 'Delete', key: 'delete_customer' },
    ],
  },
  {
    category: 'Projects',
    permissions: [
      { label: 'Create Project', key: 'create_project' },
      { label: 'List Project', key: 'list_project' },
      { label: 'View Project', key: 'view_project' },
      { label: 'Edit Project', key: 'edit_project' },
      { label: 'Delete Project', key: 'delete_project' },
    ],
  },
  {
    category: 'Properties',
    permissions: [
      { label: 'Create Property', key: 'create_property' },
      { label: 'List Property', key: 'list_property' },
      { label: 'View Property', key: 'view_property' },
      { label: 'Edit Property', key: 'edit_property' },
      { label: 'Delete Property', key: 'delete_property' },
    ],
  },
  {
    category: 'Contracts',
    permissions: [
      { label: 'Create Contract', key: 'create_contract' },
      { label: 'List Contract', key: 'list_contract' },
      { label: 'View Contract', key: 'view_contract' },
      { label: 'Edit Contract', key: 'edit_contract' },
      { label: 'Delete Contract', key: 'delete_contract' },
    ],
  },
  {
    category: 'Human Resources',
    permissions: [
      { label: 'Create Human Resource', key: 'create_human_resource' },
      { label: 'List Human Resource', key: 'list_human_resource' },
      { label: 'View Human Resource', key: 'view_human_resource' },
      { label: 'Edit Human Resource', key: 'edit_human_resource' },
      { label: 'Delete Human Resource', key: 'delete_human_resource' },
    ],
  },
  {
    category: 'Orders',
    permissions: [
      { label: 'Create Orders', key: 'create_orders' },
      { label: 'List Orders', key: 'list_orders' },
      { label: 'View Orders', key: 'view_orders' },
      { label: 'Edit Orders', key: 'edit_orders' },
      { label: 'Delete Orders', key: 'delete_orders' },
    ],
  },
  {
    category: 'Payments',
    permissions: [
      { label: 'Create Payments', key: 'create_payments' },
      { label: 'List Payments', key: 'list_payments' },
      { label: 'View Payments', key: 'view_payments' },
      { label: 'Edit Payments', key: 'edit_payments' },
      { label: 'Delete Payments', key: 'delete_payments' },
    ],
  },
  {
    category: 'Leads',
    permissions: [
      { label: 'Create Leads', key: 'create_leads' },
      { label: 'List Leads', key: 'list_leads' },
      { label: 'View Leads', key: 'view_leads' },
      { label: 'Edit Leads', key: 'edit_leads' },
      { label: 'Delete Leads', key: 'delete_leads' },
    ],
  },
  {
    category: 'Bookings',
    permissions: [
      { label: 'Create Bookings', key: 'create_bookings' },
      { label: 'List Bookings', key: 'list_bookings' },
      { label: 'View Bookings', key: 'view_bookings' },
      { label: 'Edit Bookings', key: 'edit_bookings' },
      { label: 'Delete Bookings', key: 'delete_bookings' },
    ],
  },
  {
    category: 'Tickets',
    permissions: [
      { label: 'Create Tickets', key: 'create_tickets' },
      { label: 'List Tickets', key: 'list_tickets' },
      { label: 'View Tickets', key: 'view_tickets' },
      { label: 'Edit Tickets', key: 'edit_tickets' },
      { label: 'Delete Tickets', key: 'delete_tickets' },
    ],
  },
  {
    category: 'Communications',
    permissions: [
      { label: 'Create Communication', key: 'create_communication' },
      { label: 'List Communication', key: 'list_communication' },
      { label: 'View Communication', key: 'view_communication' },
      { label: 'Edit Communication', key: 'edit_communication' },
      { label: 'Delete Communication', key: 'delete_communication' },
    ],
  },
  {
    category: 'Tasks',
    permissions: [
      { label: 'Create Tasks', key: 'create_tasks' },
      { label: 'List Tasks', key: 'list_tasks' },
      { label: 'View Tasks', key: 'view_tasks' },
      { label: 'Edit Tasks', key: 'edit_tasks' },
      { label: 'Delete Tasks', key: 'delete_tasks' },
    ],
  },
  {
    category: 'Events',
    permissions: [
      { label: 'Create Events', key: 'create_events' },
      { label: 'List Events', key: 'list_events' },
      { label: 'View Events', key: 'view_events' },
      { label: 'Edit Events', key: 'edit_events' },
      { label: 'Delete Events', key: 'delete_events' },
    ],
  },
  {
    category: 'Settings',
    permissions: [
      { label: 'Create Settings', key: 'create_settings' },
      { label: 'View Settings', key: 'view_settings' },
      { label: 'Edit Settings', key: 'edit_settings' },
      { label: 'Delete Settings', key: 'delete_settings' },
    ],
  },
];
