import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const CustomerPieChart = () => {
    const data = {
        labels: ['Repeat Customers', 'One-time Customers'],
        datasets: [
            {
                label: 'Customer Distribution',
                data: [40, 25],
                backgroundColor: ['#F8A81B', '#00AB9A'],
                hoverBackgroundColor: ['#F8A81B', '#91B7D9', '#00AB9A'],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
                align: 'center', 
                labels: {
                    boxWidth: 20,
                    padding: 20, 
                    usePointStyle: true, 
                    color: '#333',
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}%`;
                    },
                },
            },
        },
    };

    return (
        <div className="w-full bg-white rounded-lg h-full p-4">
            <p className="-mb-4 text-xl font-semibold text-black">Customer purchasing pattern</p>
            <Pie data={data} options={options} />
        </div>
    );
};

export default CustomerPieChart;
