import React, { useState, useEffect } from 'react';
import { fetchEventCategories, submitForm } from '../../utils/api/api';
import toast from 'react-hot-toast';

const AddNewEvent = () => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        location: '',
        tag: '',
        start_date: '',
        end_date: '',
        recurrence: '',
        recurrence_patterns: "",
        reminders: '',
        reminder_method: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleRecurrencePatternChange = (day) => {
        setFormData((prevData) => {
            const patternsArray = prevData.recurrence_patterns ? prevData.recurrence_patterns.split(', ') : [];
            const newPatternsArray = patternsArray.includes(day)
                ? patternsArray.filter((d) => d !== day)
                : [...patternsArray, day];

            return {
                ...prevData,
                recurrence_patterns: newPatternsArray.join(', ')
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formPayload = new FormData();

        Object.keys(formData).forEach((key) => {
            formPayload.append(key, formData[key]);
        });

        try {
            const response = await submitForm(formPayload);
            toast.success("Event added.");
            window.location.reload();
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error("Failed to add event.");
        }
    };

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1">Title</label>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                placeholder="Enter title"
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1">Description</label>
                            <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                placeholder="Enter description"
                            ></textarea>
                        </div>

                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1">Location</label>
                            <input
                                type="text"
                                name="location"
                                value={formData.location}
                                onChange={handleInputChange}
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                placeholder="Enter location"
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1">Tag</label>
                            <input
                                type="text"
                                name="tag"
                                value={formData.tag}
                                onChange={handleInputChange}
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                                placeholder="Enter tag"
                            />
                        </div>

                        {/* File upload commented out */}
                        {/* <div className="mb-4">
                            <label className="block text-black text-[14px] font-medium mb-1">Attach Files</label>
                            <input
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            />
                        </div> */}
                    </div>

                    <div className='bg-gray-100 -my-4 p-4'>
                        <div className="w-full mb-4 flex items-center gap-2">
                            <label className="block text-black text-[14px] font-medium w-[30%]">Start Time</label>
                            <input
                                type="datetime-local"
                                name="start_date"
                                value={formData.start_date}
                                onChange={handleInputChange}
                                className="w-[70%] text-gray-600 outline-none focus:border-secondary text-[14px] border border-gray-300 bg-transparent p-2 rounded-md"
                            />
                        </div>

                        <div className="w-full mb-4 flex items-center gap-2">
                            <label className="block text-black text-[14px] font-medium w-[30%]">End Time</label>
                            <input
                                type="datetime-local"
                                name="end_date"
                                value={formData.end_date}
                                onChange={handleInputChange}
                                className="w-[70%] text-gray-600 outline-none focus:border-secondary text-[14px] border border-gray-300 bg-transparent p-2 rounded-md"
                            />
                        </div>

                        <div className="w-full mb-4 flex items-center gap-2">
                            <label className="block text-black text-[14px] font-medium w-[30%]">Recurrence</label>
                            <select
                                name="recurrence"
                                value={formData.recurrence}
                                onChange={handleInputChange}
                                className="w-[70%] text-gray-600 outline-none focus:border-secondary text-[14px] border border-gray-300 bg-transparent p-2 rounded-md"
                            >
                                <option value="">None</option>
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                        </div>

                        <div className="w-full mb-4 flex items-center gap-2">
                            <label className="block text-black text-[14px] font-medium w-[30%]">Reminders</label>
                            <select
                                name="reminders"
                                value={formData.reminders}
                                onChange={handleInputChange}
                                className="w-[70%] text-gray-600 outline-none focus:border-secondary text-[14px] border border-gray-300 bg-transparent p-2 rounded-md"
                            >
                                <option value="">Select Reminder</option>
                                <option value="15 minutes before">15 minutes before</option>
                                <option value="1 hour before">1 hour before</option>
                                <option value="1 day before">1 day before</option>
                            </select>
                        </div>

                        <div className="w-full mb-4 flex items-center gap-2">
                            <label className="block text-black text-[14px] font-medium w-[30%]">Method</label>
                            <select
                                name="reminder_method"
                                value={formData.reminder_method}
                                onChange={handleInputChange}
                                className="w-[70%] text-gray-600 outline-none focus:border-secondary text-[14px] border border-gray-300 bg-transparent p-2 rounded-md"
                            >
                                <option value="">Select </option>
                                <option value="email">Email</option>
                                <option value="sms">SMS</option>
                                <option value="both">Both</option>
                                <option value="in-app">In-app</option>
                            </select>
                        </div>

                        <div className="mb-4 border border-gray-300 p-2 rounded-md">
                            <label className="block text-black text-[14px] font-medium w-full">Recurrence Patterns</label>
                            <div className="flex flex-wrap gap-2">
                                {daysOfWeek.map((day) => (
                                    <button
                                        type="button"
                                        key={day}
                                        onClick={() => handleRecurrencePatternChange(day)}
                                        className={`px-2 py-1 text-[14px] rounded-md border ${formData.recurrence_patterns.includes(day)
                                            ? 'bg-secondary text-white'
                                            : 'bg-white text-gray-700'
                                            }`}
                                    >
                                        {day}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <div className="flex flex-col w-full gap-2 justify-center items-center">
                            <button type="submit" className="w-full bg-secondary text-white px-4 py-1.5 text-[14px] rounded-md">
                                Save & Publish
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddNewEvent;
