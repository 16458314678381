import React, { useEffect, useState } from 'react'
import { fetchLeadDocs, submitLeadDocument } from '../../utils/api/api';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { MdAdd, MdOutlineAttachFile, MdOutlineAttachment } from 'react-icons/md';
import Modal from '../../elements/Modal';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { FaRegFilePdf } from 'react-icons/fa';

const LeadsDocuments = () => {
    const [documents, setDocuments] = useState([]);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState('');
    const [doc, setDoc] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { id } = useParams();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setDoc(file);
        }
    };

    const handleAddDocument = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('lead_id', id);
        formData.append('title', title);
        formData.append('note', note);
        formData.append('doc', doc);
        setLoading(true);

        try {
            await submitLeadDocument(formData);
            setTitle('');
            setNote('');
            setDoc(null);
            setIsModalOpen(false);
            getDocs();
            toast.success("Document added.")
            setLoading(false);
        } catch (error) {
            toast.error('Failed to add document.');
            setLoading(false);
        }
    };

    const getDocs = async () => {
        try {
            const data = await fetchLeadDocs(id);
            setDocuments(data.documents);
        } catch (err) {
            toast.error('Failed to get docs.');
        }
    };

    useEffect(() => {
        getDocs();
    }, [id]);
  return (
    <div className='w-full'>
          <div className="w-full flex items-center justify-between">
              <div className="flex flex-col">
                  <p className="text-secondary text-[16px] font-medium">Documents</p>
                  <p className="text-[14px] text-lead font-light hidden md:flex">Add notes & documents in the system</p>
              </div>
              <button
                  className="flex items-center gap-2 px-4 py-1.5 bg-secondary text-white text-[14px] rounded-md outline-none"
                  onClick={() => setIsModalOpen(true)}
              >
                  <MdAdd />
                  Add note & Doc
              </button>
          </div>

          <div className="w-full bg-white rounded-lg mt-4">
              <div className="overflow-x-auto rounded-lg">
                  <table className="min-w-full bg-white h-full flex-grow rounded-lg border border-gray-200 table-auto">
                      <thead>
                          <tr className="text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Title</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Note</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Date</th>
                              <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Attachment</th>
                          </tr>
                      </thead>
                      <tbody className="text-gray-700 text-[13px]">
                          {documents.length === 0 ? (
                              <tr>
                                  <td colSpan="7" className="py-4 px-6 text-center text-gray-500">
                                      {loading ? 'Loading, please wait' : 'No data found'}
                                  </td>
                              </tr>
                          ) : (
                              documents.map((document, index) => (
                                  <tr
                                      key={index}
                                      className={`border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                          }`}
                                  >
                                      <td className="py-4 px-6 text-left whitespace-nowrap">
                                          <span>{document.title}</span>
                                      </td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap">
                                          {document.note.split(' ').slice(0, 5).join(' ')}...
                                      </td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap">
                                          <span>{new Date(document.createdAt).toLocaleDateString()}</span>
                                      </td>
                                      <td className="py-4 px-6 text-left whitespace-nowrap">
                                          <a href={document.file} target='_blank'><MdOutlineAttachment size={24} className='' /></a>
                                      </td>
                                  </tr>
                              ))
                          )}
                      </tbody>
                  </table>
              </div>
          </div>

          {isModalOpen && (
              <Modal title="Add Document" onClose={() => setIsModalOpen(false)}>
                  <form onSubmit={handleAddDocument}>
                      <div className="w-full mb-4">
                          <label htmlFor="title" className="block text-black text-[14px] font-medium mb-1">Title</label>
                          <input
                              type="text"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              placeholder="Document Title"
                              className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                          />
                      </div>
                      <div className="w-full mb-4">
                          <label htmlFor="note" className="block text-black text-[14px] font-medium mb-1">Note</label>
                          <textarea
                              value={note}
                              onChange={(e) => setNote(e.target.value)}
                              placeholder="Note"
                              className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                          />
                      </div>
                      <input
                          type="file"
                          onChange={handleFileChange}
                          className="block w-full mb-2"
                      />
                      <button
                          type="submit"
                          className="mt-4 bg-secondary text-white px-4 py-1.5 text-[14px] font-medium rounded-md"
                      >
                          Add Document
                      </button>
                  </form>
              </Modal>
          )}
    </div>
  )
}

export default LeadsDocuments