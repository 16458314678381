import React from 'react'
import Barcode from 'react-barcode'
import QRCode from 'react-qr-code'
import seal from '../../assets/MILESCOOP_SEAL.webp'
import sign from '../../assets/General_Signature_4.png';
import logo from '../../assets/miles_logo.svg'
import moment from 'moment'

const OfferLetter = ({ contract }) => {
    return (
        <div className="max-w-4xl mx-auto p-8 bg-white shadow-md rounded-md">
            <div className="border-2 flex items-start justify-between border-primary p-4 mb-4 w-full ">
                <div className="flex items-start gap-2">
                    <img src={logo} alt="Milescoop Ventures" className="w-[170px] h-auto" />
                    <div className="flex flex-col">
                        <p className="font-bold text-[20px] uppercase text-primary">Milescoop Ventures Ltd</p>
                        <p className="uppercase text-red-500 text-[13px]">Your ultimate real estate destination in Kenya</p>
                        <p className="text-gray-600 text-[14px]">Phone: +254 (0) 737 900 424</p>
                        <p className="text-gray-600 text-[14px]">Email: info@milescoop.com</p>
                        <p className="text-gray-600 text-[14px]">PIN Number: 052203487E</p>
                        <p className="text-gray-600 text-[14px]">Website: www.milescoop.com</p>
                    </div>
                </div>
                {contract.serial_no && (
                    <div className="flex justify-center mt-8">
                        <QRCode
                            value={contract?.serial_no}
                            size={100}
                            bgColor="#ffffff"
                            fgColor="#000000"
                        />
                    </div>
                )}
            </div>

            <div className="flex items-center w-full justify-between">
                <div className="mb-4">
                    <p className='text-gray-700 text-[14px]'>
                        <span className="text-black w-[50px]">Date:</span> {moment(contract.createdAt).format('MMM Do, YYYY')}
                    </p>
                    <div className="flex items-start gap-4 w-fit">
                        <p className="w-[50px]">To:</p>
                        <div className="flex flex-col">
                            <p className='text-gray-600 text-[13px] uppercase'>{contract?.customer?.first_name} {contract?.customer?.last_name}</p>
                            <p className='text-gray-600 text-[13px] uppercase'>P.O BOX: {contract?.customer?.first_name} {contract?.customer?.last_name}, Nairobi, Kenya</p>
                        </div>
                    </div>
                    <p className="text-gray-500 text-[14px] mt-4">Dear <span className="uppercase">{contract?.customer?.first_name} {contract?.customer?.last_name}</span>,</p>
                </div>
                {contract.serial_no && (
                    <Barcode
                        value={contract?.serial_no}
                        width={2}
                        height={100}
                        displayValue={true}
                        background="#ffffff"
                        lineColor="#000000"
                        margin={10}
                        fontOptions="monospace"
                        textAlign="center"
                    />
                )}
            </div>

            <h2 className="text-lg font-bold mb-4 uppercase text-black underline">RE: LETTER OF OFFER TO PURCHASE</h2>
            <p className="mb-2"><strong>Plot-MAC-017</strong></p>
            <p className="mb-4 uppercase text-black font-medium text-[14px]">
                Being a portion of Title Numbers: MAGARINI/BARICHO/DAKACHA/4902 in the development known as MILESCOOP AGRI-CITY
            </p>

            <div className="mb-6">
                <p className="mb-4 text-[13px] text-gray-600">
                    We are pleased to offer you the above-captioned property for purchase, subject to contract by way of a formal sale agreement prepared by the Vendor's Advocates. Below are the terms and conditions:
                </p>

                <div className="flex">
                    <h3 className="font-bold mb-2 w-[210px]">1. Vendor's Details:</h3>
                    <p>Milescoop Ventures LTD, <br /> P.O. BOX 2760-00100, <br /> Nairobi</p>
                </div>

                <div className="flex mt-4">
                    <h3 className="font-bold mb-2 w-[210px]">2. Purchaser's Details</h3>
                    <div className="flex flex-col">
                        <p className='uppercase'>{contract?.customer?.first_name} {contract?.customer?.last_name}, <br /> P.O. BOX: <br /> Nairobi, Kenya</p>
                        <p>Email: {contract?.customer?.email}</p>
                    </div>
                </div>

                <div className="flex mt-4">
                    <h3 className="font-bold mb-2 w-[210px]">3. Vendor's Advocates</h3>
                    <div className="flex flex-col">
                        <p>M.W. KIMANI & CO. ADVOCATES,<br /> P.O. BOX: SURAJ PLAZA 6TH FLOOR, LIMURU ROAD, NGARA 61474-00200, <br /> Nairobi, Kenya</p>
                        <p>Email: mercymaina33@gmail.com</p>
                    </div>
                </div>

                <div className="flex mt-4">
                    <h3 className="font-bold mb-2 w-[210px]">4. Development</h3>
                    <p>
                        The vendor has subdivided the property <br />
                        Title Number: {contract?.property?.title_number} into two <br />
                        hundred and eighty-six (286) acre plots comprising <br />
                        residential and/or agricultural plots, known as "{contract?.property?.project?.title}".
                    </p>
                </div>

                <h3 className="font-bold mb-2">5. The Property</h3>
                <p>
                    The property means PLOT-{contract?.property?.property_number} measuring approximately
                    <span className="underline font-medium mx-2">{contract?.property?.size}</span> per plot being a portion of Title Number(s):
                    <span className="underline font-medium mx-2">{contract?.property?.title_number}</span>.
                </p>

                <h3 className="font-bold mt-4 mb-2">6. Tenure</h3>
                <p>Freehold</p>

                <h3 className="font-bold mt-4 mb-2">7. Purchase Offer Price</h3>
                {contract?.order?.payment_type === 'one_time' ? (
                    <p>The plot is offered for purchase at a purchase price of KES {Number(contract?.order.amount).toLocaleString("KES")}.</p>
                ) : (
                    <>
                        <p>The plot is offered for purchase at a purchase price of KES {Number(contract?.order?.amount)}. The payments are to be made as per the schedule below.</p>

                        <div className="overflow-x-auto">
                            <table className="table-auto w-full mt-4 border-collapse">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border p-2 text-left">No</th>
                                        <th className="border p-2 text-left">Payment Amount</th>
                                        <th className="border p-2 text-left">Due Date</th>
                                        <th className="border p-2 text-left">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contract?.order?.installments?.map((installment, index) => (
                                        <tr key={index}>
                                            <td className="border p-2">{index + 1}</td>
                                            <td className="border p-2">KES {Number(installment.amount).toLocaleString("KES")}</td>
                                            <td className="border p-2">{moment(installment.due_date).format('MMM Do, YYYY')}</td>
                                            <td className="border p-2 capitalize">{installment.invoice.payment_status.replace('_', ' ')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
                <h3 className="font-bold mt-4 mb-2">8. Assignment</h3>
                <p>The Purchaser shall not assign the rights, interests, or obligations from this Offer without the Vendor's consent.</p>

                <p className="mt-4"><strong>9. Termination by the Purchaser:</strong></p>
                <div className="mt-4">
                    <p className="font-bold">Refund Policy:</p>
                    <p className="mt-2">We offer a refund of 30% of the purchase value, subject to the following conditions:</p>
                    <ol className="list-decimal list-inside ml-4 mt-2 text-black">
                        <li>You must have made all installment payments without any default.</li>
                        <li>The refund will be paid once we secure a replacement buyer for your purchase.</li>
                        <li>You will be allowed to look for a replacement buyer if you have been making payments on time without defaults.</li>
                        <li>If you have defaulted for more than 90 days, you are not eligible for any refund.</li>
                        <li>All terminations will only be processed via email or our official system termination process.</li>
                    </ol>
                </div>

                <p className="mt-4"><strong>10. Sale Agreement:</strong> A formal Agreement for Sale will incorporate the Law Society of Kenya Conditions of Sale (1989 Edition).</p>
            </div>

            <div className="mt-8 w-full flex items-center justify-between">
                <div className="flex flex-col">
                    <p className="font-bold">Yours Faithfully,</p>
                    <div className="flex items-center gap-4">
                        <div className="flex flex-col">
                            <p className="mt-6">PETER NJAGI ....</p>
                            <p>Director</p>
                        </div>
                        <img src={sign} className='w-[150px] h-auto' alt="Signature" />
                    </div>
                </div>
                <img
                    src={seal}
                    className='h-auto w-[170px]'
                    alt=""
                    style={{ transform: 'rotate(-150deg)' }}
                />
            </div>

            <div className="mt-8">
                <p>I, <span className="uppercase">{contract?.customer?.first_name} {contract?.customer?.last_name}</span>, confirm acceptance of the above terms and conditions.</p>
                <p className="mt-6">{contract?.signed_date ? <p>DATE : {moment(contract.signed_date).format('MMM Do, YYYY')}</p> : <p>DATE : ____________________</p>} <div className="flex items-center">
                    SIGNATURE: <div className="">
                        {contract.signature && (
                            <img src={contract?.signature} alt="User Signature" className="border border-gray-300 w-56 h-auto" />
                        )}
                    </div></div></p>
            </div>
        </div>
    )
}

export default OfferLetter