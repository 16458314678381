import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { AddProjectRisks, fetchProjectRisks, fetchUsers } from '../../utils/api/api';
import { MdOutlineFilterAlt } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import Modal from '../../elements/Modal';
import toast from 'react-hot-toast';

const ProjectRisks = () => {
  const [risks, setRisks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    project_id: "",
    assigned_to: "",
    description: "",
    impact: "",
    probability: "",
    mitigation_plan: "",
  });
  const { id } = useParams();
  const project_id = id;
  const [selectedRisk, setSelectedRisk] = useState(null);

  const handleRiskClick = (risk) => {
    setSelectedRisk(risk);
  };

  const truncateToSixWords = (text) => {
    return text.split(" ").slice(0, 6).join(" ") + (text.split(" ").length > 6 ? "..." : "");
  };

  const getRisks = async () => {
    setLoading(true);
    try {
      const data = await fetchProjectRisks(project_id);
      setRisks(data.risks);
    } catch (err) {
      toast.error('Request failed.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const data = await fetchUsers();
        setUsers(data.users);
      } catch (err) {
        toast.error('Failed to fetch users.');
      }
    };
    getUsers();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const riksData = {
      ...formData,
      project_id: project_id,
    };
    setLoading(true);
    try {
      await AddProjectRisks(riksData);
      toast.success('Risk added.');
      setOpenModal(false);
      getRisks();
      setFormData('')
    } catch (err) {
      toast.error('Failed to add expense.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRisks();
  }, []);

  return (
    <div className='mt-4 flex bg-[#fff] rounded-lg p-4 flex-col'>
      <div className="w-full flex items-center justify-between">
        <p className="text-black font-semibold text-[22px]">Risks</p>
        <div className="flex items-center gap-4">
          <button
            onClick={() => setOpenModal(true)}
            className="px-4 py-1.5 flex items-center gap-2 text-[14px] text-secondary rounded-md border border-secondary">
            <IoMdAdd size={18} />
            Add New
          </button>
        </div>
      </div>

      <div className="w-full bg-white rounded-lg mt-4">
        <div className="overflow-x-auto rounded-lg">
          <table className="min-w-full bg-white rounded-lg border border-gray-200 table-auto">
            <thead className="bg-[#F0F0F0]">
              <tr className="text-black border-b text-[14px] py-3 font-medium leading-normal rounded-t-lg">
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Impact</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Probability</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Description</th>
                <th className="py-3 px-6 text-left w-1/5 whitespace-nowrap">Mitigation plan</th>
              </tr>
            </thead>
            <tbody className="text-primary text-[14px] font-light">
              {risks.length === 0 ? (
                <tr>
                  <td colSpan="4" className="py-4 px-6 text-center text-gray-500">
                    {loading ? 'Loading, please wait' : 'No data found'}
                  </td>
                </tr>
              ) : (
                risks.map((risk, index) => (
                  <tr
                    key={index}
                    onClick={() => handleRiskClick(risk)}
                    className={`border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                      }`}
                  >
                    <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">{risk.impact}</td>
                    <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">{risk.probability}</td>
                    <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black cursor-pointer"
                      >
                      {truncateToSixWords(risk.description)}
                    </td>
                    <td className="py-4 px-6 text-left whitespace-nowrap text-[13px] font-light text-black">{risk.mitigation_plan}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {selectedRisk && (
        <Modal onClose={() => setSelectedRisk(null)} title="Risk Details">
          <p><strong>Impact:</strong> {selectedRisk.impact}</p>
          <p><strong>Probability:</strong> {selectedRisk.probability}</p>
          <p><strong>Description:</strong> {selectedRisk.description}</p>
          <p><strong>Mitigation Plan:</strong> {selectedRisk.mitigation_plan}</p>
        </Modal>
      )}


      {openModal && (
        <Modal onClose={() => setOpenModal(false)} title="Add Risk">
          <form onSubmit={handleFormSubmit} className="flex flex-col gap-4">
            <div>
              <label className="block text-black text-[14px] font-medium mb-1">Assigned To</label>
              <select
                name="assigned_to"
                value={formData.assigned_to}
                onChange={handleInputChange}
                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                required
              >
                <option value="">Select User</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.first_name} {user.last_name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-black text-[14px] font-medium mb-1">Description</label>
              <textarea
                type="text"
                name="description"
                value={formData.description}
                placeholder='Enter risk description'
                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Impact */}
            <div>
              <label className="block text-black text-[14px] font-medium mb-1">Impact</label>
              <input
                type="text"
                name="impact"
                value={formData.impact}
                onChange={handleInputChange}
                placeholder='Risk impact'
                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                required
              />
            </div>

            {/* Probability */}
            <div>
              <label className="block text-black text-[14px] font-medium mb-1">Probability</label>
              <input
                type="text"
                name="probability"
                value={formData.probability}
                onChange={handleInputChange}
                placeholder='Risk probability'
                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                required
              />
            </div>

            {/* Mitigation Plan */}
            <div>
              <label className="block text-black text-[14px] font-medium mb-1">Mitigation Plan</label>
              <input
                type="text"
                name="mitigation_plan"
                value={formData.mitigation_plan}
                onChange={handleInputChange}
                placeholder='Risk mitigation plan'
                className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                required
              />
            </div>

            <div className="flex w-full items-center justify-end">
              <button type='button' onClick={() => setOpenModal(false)} className='text-secondary font-medium text-[14px] mr-2'>Cancel</button>
              <button
                type="submit"
                disabled={loading}
                className="bg-secondary text-white py-1.5 px-6 rounded-md text-[14px] font-medium"
              >
                {loading ? 'Loading, please wait...' : 'Submit'}
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  )
}

export default ProjectRisks