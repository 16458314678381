import React, { useState, useEffect } from 'react';
import api from '../../utils/api/axiosInstance';
import Loading from '../../elements/Loading'
import toast from 'react-hot-toast';

const GeneralSettings = () => {
    const [formData, setFormData] = useState({
        system_title: '',
        system_logo: null,
        time_zone: '',
        telephone: '',
        email: '',
        country: '',
        city: '',
        location: '',
        address: '',
    });

    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await api.post('/settings/fetch');
                const data = response.data.settings;
                setSettings(data);

                setFormData({
                    system_title: data.system_title || '',
                    system_logo: null,
                    time_zone: data.time_zone || '',
                    telephone: data.telephone || '',
                    email: data.email || '',
                    country: data.country || '',
                    city: data.city || '',
                    location: data.location || '',
                    address: data.address || '',
                });

                setLoading(false); 
            } catch (error) {
                console.error('Error fetching settings:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            system_logo: e.target.files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const form = new FormData();
        form.append('system_title', formData.system_title);
        form.append('system_logo', formData.system_logo); 
        form.append('time_zone', formData.time_zone);
        form.append('telephone', formData.telephone);
        form.append('email', formData.email);
        form.append('country', formData.country);
        form.append('city', formData.city);
        form.append('location', formData.location);
        form.append('address', formData.address);

        try {
            const response = await api.post(`/settings/${!settings ? 'create' : 'update'}`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success("settings updated")
        } catch (error) {
            toast.error('Failed to update')
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading />
    }

    return (
        <div className="flex flex-col gap-4 mb-4">
            <p className="text-[20px] text-black font-medium">General Settings</p>
            <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
                <div className="flex items-center gap-4">
                    <div className='w-full'>
                        <label className="block text-black text-[14px] font-medium mb-1">System Title:</label>
                        <input
                            type="text"
                            name="system_title"
                            value={formData?.system_title}
                            onChange={handleInputChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        />
                    </div>
                    <div className='w-full'>
                        <label className="block text-black text-[14px] font-medium mb-1">Time Zone:</label>
                        <input
                            type="text"
                            name="time_zone"
                            value={formData?.time_zone}
                            onChange={handleInputChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        />
                    </div>
                </div>

                <div className="w-full flex items-center gap-4">
                    <div className='w-full'>
                        <label className="block text-black text-[14px] font-medium mb-1">System Logo:</label>
                        <input
                            type="file"
                            name="system_logo"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        />
                    </div>

                    <div className="w-full flex flex-col">
                        <label className="block text-gray-100  text-[14px] font-medium mb-1">.</label>
                        <img src={settings?.system_logo} className='h-[40px] w-fit rounded-md' alt="" />
                    </div>
                </div>

                <div className="flex w-full items-center gap-4">
                    <div className='w-full'>
                        <label className="block text-black text-[14px] font-medium mb-1">Telephone:</label>
                        <input
                            type="text"
                            name="telephone"
                            value={formData.telephone}
                            onChange={handleInputChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        />
                    </div>

                    <div className='w-full'>
                        <label className="block text-black text-[14px] font-medium mb-1">Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        />
                    </div>
                </div>

                <div className="flex items-center w-full gap-4">
                    <div className='w-full'>
                        <label className="block text-black text-[14px] font-medium mb-1">Country:</label>
                        <input
                            type="text"
                            name="country"
                            value={formData.country}
                            onChange={handleInputChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        />
                    </div>

                    <div className='w-full'>
                        <label className="block text-black text-[14px] font-medium mb-1">City:</label>
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        />
                    </div>

                    <div className='w-full'>
                        <label className="block text-black text-[14px] font-medium mb-1">Location:</label>
                        <input
                            type="text"
                            name="location"
                            value={formData.location}
                            onChange={handleInputChange}
                            className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                            required
                        />
                    </div>
                </div>

                <div>
                    <label className="block text-black text-[14px] font-medium mb-1">Address:</label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        className="border bg-transparent w-full border-gray-200 text-gray-600 font-light text-[12px] rounded-md p-2 outline-none focus:border-secondary"
                        required
                    />
                </div>               

                <div className="w-full flex items-center justify-end">
                    <button className='px-6 bg-secondary text-white text-[14px] font-medium rounded-md py-1.5' type="submit">Save Settings</button>
                </div>
            </form>
        </div>
    );
};

export default GeneralSettings;
