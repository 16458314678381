import React, { useState } from 'react';
import Layout from '../../elements/Layout';
import { IoMdSearch } from 'react-icons/io';
import { MdOutlineFilterAlt, MdOutlinePrint } from 'react-icons/md';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { visaMastercardPayments } from '../../utils/api/data';
import ReactToPrint from 'react-to-print'; 

const CardPayments = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState(null);
    const paymentsPerPage = 10;

    const filteredPayments = visaMastercardPayments
        .filter(payment => {
            const searchText = `${payment.entryDate} ${payment.paymentDate} ${payment.paidBy} ${payment.amount} ${payment.mode} ${payment.reference}`;
            return searchText.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .filter(payment => {
            if (filter) {
            }
            return true;
        });

    const indexOfLastPayment = currentPage * paymentsPerPage;
    const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
    const currentPayments = filteredPayments.slice(indexOfFirstPayment, indexOfLastPayment);

    const totalPages = Math.ceil(filteredPayments.length / paymentsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePrevious = () => {
        if (currentPage > 1) {
            paginate(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            paginate(currentPage + 1);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const handleFilter = () => {
    };

    return (
        <Layout text="Visa/Mastercard Payments">
            <div className="flex w-full md:items-center justify-between">
                <div className="flex flex-col">
                    <p className="text-secondary font-medium text-[16px]">Visa/Mastercard</p>
                    <p className="text-gray-600 text-[14px] hidden md:flex">View all transactions made via Visa/Mastercard</p>
                </div>
                <div className="flex flex-col md:flex-row md:items-center gap-3">
                    <div className="flex items-center gap-2 text-gray-600 py-1.5 px-4 border border-gray-200 bg-transparent rounded-md ">
                        <IoMdSearch />
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="outline-none text-[14px] text-gray-600"
                        />
                    </div>
                    <div className="flex justify-end md:items-center gap-3">
                        <button onClick={handleFilter} className="flex items-center border border-secondary px-4 py-1.5 text-[14px] rounded-md text-secondary gap-2">
                            <MdOutlineFilterAlt size={18} />
                            Filter
                        </button>
                            <ReactToPrint
                                trigger={() => (
                                    <button className="flex items-center border border-secondary bg-secondary px-4 py-1.5 text-[14px] rounded-md text-white gap-2">
                                        <MdOutlinePrint size={18} />
                                        Print
                                    </button>
                                )}
                                content={() => window.document.querySelector('.printable-table')}
                            />
                    </div>
                </div>
            </div>

            <div className="w-full bg-white rounded-lg mt-4">
                <div className="overflow-x-auto rounded-lg">
                    <table className="min-w-full bg-white h-full flex-grow rounded-lg border border-gray-200 table-auto printable-table">
                        <thead>
                            <tr className="text-primary border-b pb-4 text-[14px] py-3 bg-[#F7F7F7] font-medium leading-normal">
                                <th className="py-3 px-6 text-left w-1/6 whitespace-nowrap">Entry Date</th>
                                <th className="py-3 px-6 text-left w-1/6 whitespace-nowrap">Payment Date</th>
                                <th className="py-3 px-6 text-left w-1/6 whitespace-nowrap">Paid By</th>
                                <th className="py-3 px-6 text-left w-1/6 whitespace-nowrap">Amount</th>
                                <th className="py-3 px-6 text-left w-1/6 whitespace-nowrap">Mode</th>
                                <th className="py-3 px-6 text-left w-1/6 whitespace-nowrap">Reference</th>                                
                                <th className="py-3 px-6 text-left w-1/6 whitespace-nowrap">Finance</th>
                                <th className="py-3 px-6 text-left w-1/6 whitespace-nowrap">CEO</th>
                            </tr>
                        </thead>
                        <tbody className="text-primary text-[14px] font-light">
                            {currentPayments.map((payment) => (
                                <tr
                                    key={payment.id}
                                    className={`border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${payment.id % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                        }`}
                                >
                                    <td className="py-4 px-6 text-left whitespace-nowrap">{payment.entryDate}</td>
                                    <td className="py-4 px-6 text-left whitespace-nowrap">{payment.paymentDate}</td>
                                    <td className="py-4 px-6 text-left whitespace-nowrap">{payment.paidBy}</td>
                                    <td className="py-4 px-6 text-left whitespace-nowrap">{payment.amount}</td>
                                    <td className="py-4 px-6 text-left whitespace-nowrap">{payment.mode}</td>
                                    <td className="py-4 px-6 text-left whitespace-nowrap">{payment.reference}</td>                                
                                    <td className={`py-4 px-6 text-left whitespace-nowrap ${payment.financeAction === 'Processed' ? 'text-blue-500' : payment.financeAction === 'Pending' ? 'text-yellow-500' : 'text-red-600'}`}>
                                        {payment.financeAction}
                                    </td>
                                    <td className={`py-4 px-6 text-left whitespace-nowrap ${payment.ceoAction === 'Approved' ? 'text-green-600' : payment.ceoAction === 'Pending' ? 'text-yellow-500' : 'text-red-600'}`}>
                                        {payment.ceoAction}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="w-full flex justify-end mt-4 gap-2">
                <button
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                    className={`px-3 py-1.5 border border-gray-300 rounded ${currentPage === 1 ? 'text-gray-400 cursor-not-allowed' : 'text-secondary'
                        }`}
                >
                    <FaChevronLeft />
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => paginate(index + 1)}
                        className={`px-3 py-1.5 border rounded-md ${currentPage === index + 1 ? 'bg-secondary text-white border-secondary' : 'bg-white text-black'
                            }`}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                    className={`px-3 py-1.5 border rounded-md ${currentPage === totalPages ? 'text-gray-400 cursor-not-allowed' : 'text-secondary'
                        }`}
                >
                    <FaChevronRight />
                </button>
            </div>
        </Layout>
    );
};

export default CardPayments;
