import moment from 'moment';
import React from 'react';

const AuditTrail = ({ contractAudits }) => {
    return (
        <div className="w-full flex flex-col mt-4 border-gray-400 bg-white p-4 rounded-lg">
            <p className="">Audit Trail</p>
            <div className="w-full bg-white rounded-lg mt-4">
                <div className="overflow-x-auto rounded-lg">
                    <table className="min-w-full bg-white h-full flex-grow rounded-lg table-auto">
                        <thead>
                            <tr className="text-primary border-b pb-4 text-[14px] bg-[#F7F7F7] font-medium leading-normal">
                                <th className="py-3 px-4 text-left whitespace-nowrap">ID</th>
                                <th className="py-3 px-4 text-left whitespace-nowrap">Description</th>
                                <th className="py-3 px-4 text-left whitespace-nowrap">Auditor</th>
                                <th className="py-3 px-4 text-left whitespace-nowrap">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contractAudits?.map((audit, index) => (
                                <tr key={audit?.id} className={`border-b pl-4 border-gray-200 cursor-pointer hover:bg-gray-50 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                    }`}>
                                    <td className="py-2 px-4 text-gray-600 text-[14px]">{audit?.id}</td>
                                    <td className="py-2 px-4 text-gray-600 text-[14px]">{audit?.description}</td>
                                    <td className="py-2 px-4 text-gray-600 text-[14px]">{audit.auditor?.first_name} {audit?.auditor?.last_name}</td>
                                    <td className="py-2 px-4 text-gray-600 text-[14px]">{moment(audit?.createdAt).format('MMM DD, YYYY')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AuditTrail;
