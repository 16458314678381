import React, { useEffect, useState } from 'react';
import Layout from '../../elements/Layout';
import { CiEdit } from "react-icons/ci";
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { assignUserToProject, deleteProject, fetchProjectById, fetchUsers, unassignUserFromProject, updateProject, uploadProjectImages } from '../../utils/api/api';
import ProjectImages from './ProjectImages';
import ImageUploadModal from './ImageUploadModal';
import ProjectTabs from './ProjectTabs';
import Loading from '../../elements/Loading';
import moment from 'moment'
import { MdClose } from 'react-icons/md';
import { IoAddOutline } from 'react-icons/io5';
import Modal from '../../elements/Modal';
import EditProjectForm from './EditProjectForm';
import ProjectProperties from './ProjectProperties';

const ProjectView = () => {
  const { id } = useParams();
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const project_id = id;
  const navigate = useNavigate();

  const getProject = async () => {
    setLoading(true);
    try {
      const data = await fetchProjectById(project_id);
      setProject(data.project);
    } catch (err) {
      toast.error("Request failed.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProject();
  }, [project_id]);

  const purgeProject = async () => {
    setLoading(true);
    setOpen(false);
    try {
      await deleteProject(id);
      toast.success("Project deleted");
      navigate('/dashboard/projects/land');
    } catch (error) {
      toast.error("Failed to delete");
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (formData) => {
    try {
      await uploadProjectImages(project_id, formData);
      toast.success('Images uploaded successfully');
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Error uploading images');
    }
  };

  const openAssignModal = async () => {
    setIsAssignModalOpen(true);
    try {
      const data = await fetchUsers();
      setUsers(data.users);
    } catch (error) {
      toast.error('Failed to load users');
    }
  };

  const assignUser = async () => {
    if (!selectedUser) return;

    const userData = {
      user_id: selectedUser.id,
      project_id: id
    };

    try {
      setLoading(true);
      await assignUserToProject(userData);
      getProject();
      toast.success('User assigned successfully');
    } catch (error) {
      toast.error('Failed to assign user');
      setLoading(false);
    } finally {
      setLoading(false);
      setIsAssignModalOpen(false);
    }
  };

  const unassignUser = async (userId) => {
    setLoading(true);
    try {
      await unassignUserFromProject({ project_id: id, user_id: userId });
      getProject();
      toast.success('User unassigned successfully');
    } catch (error) {
      toast.error('Failed to unassign user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout text="View" backgroundColor='bg-white'>
      {loading && <Loading />}
      <div className="flex items-center justify-between mb-6 border border-gray-200 p-4 rounded-md">
        <p className="text-primary font-semibold text-xl uppercase">{project.title}</p>
        <div className="flex items-center gap-4">
          <button onClick={() => setIsEditModalOpen(true)} className="flex items-center gap-2 px-4 py-2 border border-secondary text-secondary rounded-md hover:bg-gray-200 transition duration-200">
            <CiEdit size={20} />
            Edit Project
          </button>
          <button onClick={() => setIsModalOpen(true)} className="flex items-center gap-2 px-4 py-2 border border-secondary text-secondary rounded-md hover:bg-gray-200 transition duration-200">
            <CiEdit size={20} />
            Edit Photos
          </button>
          <button onClick={() => setOpen(true)} className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-200">
            Delete
          </button>
        </div>
      </div>

      <div className="flex flex-col md:flex-row w-full items-start gap-6">
        <div className="flex flex-col w-full md:w-1/3 gap-4 bg-white p-4 rounded-lg shadow">
          <section className="mb-6">
            <h2 className="text-lg font-bold text-gray-700 mb-2">Project Details</h2>
            <div className="text-sm text-gray-600 space-y-2">
              <div className="flex justify-between">
                <strong>Size:</strong>
                <span>{project.size}</span>
              </div>
              <div className="flex justify-between">
                <strong>Price:</strong>
                <span>Kes. {Number(project.price).toLocaleString()}</span>
              </div>
              <div className="flex justify-between">
                <strong>Status:</strong>
                <span>{project?.status?.replace('_', ' ')}</span>
              </div>
              <div className="flex justify-between">
                <strong>Location:</strong>
                <span>{project.location}</span>
              </div>
              <div className="flex justify-between">
                <strong>Start Date:</strong>
                <span>{moment(project.start_date).format('MMM Do, YYYY')}</span>
              </div>
              <div className="flex justify-between">
                <strong>End Date:</strong>
                <span>{moment(project.end_date).format('MMM Do, YYYY')}</span>
              </div>
              <div className="flex justify-between">
                <strong>Date Listed:</strong>
                <span>{moment(project.createdAt).format('MMM Do, YYYY')}</span>
              </div>
            </div>
          </section>

          <div className="border-b border-gray-300 my-4"></div>

          <div className="flex flex-col gap-2">
            <h2 className="text-lg font-bold text-gray-700">Project Manager</h2>
            <div className="flex justify-between">
              <span className="text-gray-600">Name:</span>
              <span className="text-gray-800">{project?.manager?.first_name} {project?.manager?.last_name}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Phone No.:</span>
              <span className="text-gray-800">{project?.manager?.phone_number}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Email:</span>
              <span className="text-gray-800">{project?.manager?.email}</span>
            </div>
          </div>

          <div className="border-b border-gray-300 my-4"></div>

          <div className="flex flex-col gap-2">
            <h2 className="text-lg font-bold text-gray-700 flex justify-between items-center">
              Assigned to
              <button onClick={openAssignModal} className="flex items-center justify-center p-1 text-secondary border border-gray-200 bg-gray-50 rounded-full hover:bg-gray-200 transition duration-200">
                <IoAddOutline size={22} />
              </button>
            </h2>
            {project.assigned_to?.map((assignment) => (
              <div key={assignment.user_id} className="flex justify-between">
                <span className="text-gray-800">{assignment?.user?.first_name} {assignment?.user?.last_name}</span>
                <button onClick={() => unassignUser(assignment?.user_id)} className="text-red-600 hover:text-red-800 transition duration-200">
                  <MdClose size={18} />
                </button>
              </div>
            ))}
          </div>
        </div>

        <ProjectImages images={project.images} />
      </div>

      <div className="bg-white p-4 rounded-lg mt-4 shadow">
        <h2 className="text-lg font-bold text-gray-700">Interactive Map</h2>
        <p className="text-gray-600">Full-size interactive map showing property location</p>
        <div className="mt-2">
          <img src={project.project_map} alt="Project Map" className="w-full h-auto rounded-md shadow-md" />
        </div>
      </div>

      <div className="border-b border-gray-300 mt-4 pb-4">
        <h2 className="text-lg font-bold mb-2">About this Property</h2>
        <p className="text-gray-800 font-light text-[14px]">{project?.description}</p>
      </div>

      <div className="border-b border-gray-300 mt-4 pb-4">
        <h2 className="text-lg font-bold mb-2">Amenities</h2>
        <div className="flex flex-wrap gap-2">
          {project?.amenities?.split(',').map((amenity, index) => (
            <div key={index} className="rounded-lg bg-gray-100 px-4 py-2 text-gray-600 text-[14px] font-medium">
              {amenity.trim()}
            </div>
          ))}
        </div>
      </div>

      <div className="border-b border-gray-300 mt-4 pb-4">
        <ProjectProperties />
      </div>
      <ProjectTabs />

      {isModalOpen && (
        <ImageUploadModal onClose={() => setIsModalOpen(false)} onUpload={handleImageUpload} />
      )}

      {open && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-[400px] max-h-[92vh] overflow-y-auto">
            <div className="flex justify-between items-center p-4 border-b border-gray-200">
              <h3 className="text-lg font-semibold text-gray-800">Confirm Deletion</h3>
              <button className="text-gray-500 hover:text-gray-800" onClick={() => setOpen(false)}>
                <MdClose size={24} />
              </button>
            </div>
            <div className="p-4">
              <p className="text-gray-700 text-center">Are you sure you want to delete this project? This action cannot be undone.</p>
              <div className="flex justify-end w-full mt-4">
                <button onClick={() => setOpen(false)} className="py-1.5 text-secondary text-[14px] font-medium">Cancel</button>
                <button onClick={purgeProject} className="ml-2 py-1.5 px-6 bg-red-600 text-white rounded-md text-[14px] font-medium hover:bg-red-700 transition duration-200">Delete</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isAssignModalOpen && (
        <Modal onClose={() => setIsAssignModalOpen(false)} title="Assign User">
          <select
            value={selectedUser?.id || ''}
            onChange={(e) => setSelectedUser(users.find(user => user.id === Number(e.target.value)))}
            className="w-full p-2 border rounded-md mb-4 bg-transparent outline-none focus:border-secondary text-gray-600 text-[14px]"
          >
            <option className='text-gray-600 text-[14px]' value="">Select a user</option>
            {users.map(user => (
              <option className='text-gray-700 text-[14px]' key={user.id} value={user.id}>
                {user.first_name} {user.last_name}
              </option>
            ))}
          </select>
          <div className="flex w-full items-center justify-end">
            <button
              onClick={assignUser}
              className="bg-secondary text-[14px] text-white px-6 py-1.5 rounded-md hover:bg-secondary-dark transition duration-200"
            >
              Assign
            </button>
          </div>
        </Modal>
      )}

      {isEditModalOpen && (
        <Modal onClose={() => {
          setIsEditModalOpen(false);
          getProject();
        }} title="Edit Project">
          <EditProjectForm onClose={() => {
            setIsEditModalOpen(false);
            getProject();
          }} />
        </Modal>
      )}
    </Layout>
  );
};

export default ProjectView;
