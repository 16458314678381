import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { assignPropertyToCustomer, fetchCustomers } from '../../utils/api/api';
import Modal from '../../elements/Modal';

const AssignPropertyModal = ({ property, onClose, onAssignSuccess }) => {
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [paymentType, setPaymentType] = useState('');
    const [amount, setAmount] = useState('');
    const [deposit, setDeposit] = useState('');
    const [monthlyInstallment, setMonthlyInstallment] = useState('');
    const [months, setMonths] = useState('');

    useEffect(() => {
        const getCustomers = async () => {
            try {
                const response = await fetchCustomers();
                setCustomers(response.customers.map(c => ({ value: c.id, label: `${c.first_name} ${c.last_name}` })));
            } catch (error) {
                toast.error('Failed to fetch customers');
            }
        };
        getCustomers();
    }, []);

    const handleAssign = async () => {
        const data = {
            customer_id: selectedCustomer?.value.toString(),
            property_id: property.id.toString(),
            payment_type: paymentType,
            amount: Number(amount),
            deposit: Number(deposit),
            monthly_installment: Number(monthlyInstallment),
            months: Number(months),
        };

        try {
            await assignPropertyToCustomer(data);
            toast.success('Property assigned successfully!');
            onAssignSuccess();
            onClose();
        } catch (error) {
            toast.error('Failed to assign property');
        }
    };

    return (
        <Modal onClose={onClose} title="Assign Property">
            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Select Customer</label>
                <Select
                    options={customers}
                    value={selectedCustomer}
                    onChange={setSelectedCustomer}
                    placeholder="Select customer"
                />
            </div>

            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Payment Type</label>
                <select
                    className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                >
                    <option value="" disabled>Select payment type</option>
                    <option value="one_time">One-time</option>
                    <option value="installments">Installments</option>
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-black text-[14px] font-medium mb-1">Amount</label>
                <input
                    type="number"
                    className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Enter amount"
                />
            </div>

            {paymentType === 'installments' && (
                <>
                    <div className="mb-4">
                        <label className="block text-black text-[14px] font-medium mb-1">Deposit</label>
                        <input
                            type="number"
                            className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                            value={deposit}
                            onChange={(e) => setDeposit(e.target.value)}
                            placeholder="Enter deposit"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-black text-[14px] font-medium mb-1">Monthly Installment</label>
                        <input
                            type="number"
                            className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                            value={monthlyInstallment}
                            onChange={(e) => setMonthlyInstallment(e.target.value)}
                            placeholder="Enter monthly installment"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-black text-[14px] font-medium mb-1">Number of Months</label>
                        <input
                            type="number"
                            className="border-b w-full border-gray-200 bg-transparent outline-none focus:text-secondary px-3.5 py-1.5 focus:border-secondary text-[14px] text-gray-600"
                            value={months}
                            onChange={(e) => setMonths(e.target.value)}
                            placeholder="Enter number of months"
                        />
                    </div>
                </>
            )}

            <button
                className="bg-secondary text-white py-2 px-4 rounded-md mt-4"
                onClick={handleAssign}
            >
                Assign Property
            </button>
        </Modal>
    );
};

export default AssignPropertyModal;
